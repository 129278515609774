export const deleteRegisteredDevices = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `delete_warranty/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
