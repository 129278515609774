import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks"; // Assuming you're using this hook for HTTP requests
import Select from "react-select";

const OverSaleSelloutModal = (props) => {
  const { toastDispatch } = useToastContext();
  const [remark, setRemark] = useState(""); // State to store the remark
  const [password, setPassword] = useState(""); // State to store the password
  const [email, setEmail] = useState(""); // State to store the password
  const [selectedDefects, setSelectedDefects] = useState([]); // State to store selected defects
  const [isRemarkSubmitted, setIsRemarkSubmitted] = useState(false); // Flag to toggle between remark and password field
  const { error, loading, sendHttpRequest: postStoreHistory } = useHttp();

  // Defect options for the dropdown
  const defectOptions = [
    { value: "does_not_switch_on", label: "Does Not Switch On" },
    { value: "blank_display", label: "Blank Display" },
    { value: "touch_screen_not_working", label: "Touch Screen Not Working" },
    { value: "not_charging", label: "Not Charging" },
    { value: "device_overheating", label: "Device Overheating" },
    { value: "battery_drains_fast", label: "Battery Drains Fast" },
    { value: "speaker_not_working", label: "Speaker Not Working" },
    { value: "mic_not_working", label: "Mic Not Working" },
    { value: "speaker_crackling", label: "Speaker Crackling" },
    { value: "earpiece_crackling", label: "Earpiece Crackling" },
    { value: "battery_bulge", label: "Battery Bulge/Swell" },
    { value: "screen_color_issue", label: "Screen Color Issue" },
    { value: "line_on_screen", label: "Line On Screen" },
    { value: "phone_restarts", label: "Phone Restarts / Reboots" },
    { value: "phone_hanging", label: "Phone Hanging" },
    { value: "screen_damaged", label: "Screen Damaged" },
    { value: "dent_on_screen", label: "Dent On The Screen" },
    { value: "sim_card_not_reading", label: "Sim Card Not Reading" },
    { value: "vibrator_not_working", label: "Vibrator Not Working" },
    { value: "app_crashing", label: "App Crashing" },
    { value: "user_frp_locks", label: "User / Frp Locks" },
    { value: "camera_blur_image", label: "Camera Blur Image" },
    { value: "camera_not_functioning", label: "Camera Not Functioning" },
    { value: "flash_light_not_working", label: "Flash Light Not Working" },
    {
      value: "cellular_network_not_working",
      label: "Cellular Network Not Working",
    },
    { value: "data_connectivity_issues", label: "Data Connectivity Issues" },
    {
      value: "poor_network_reception",
      label: "Poor Network Reception/Bad Receiving",
    },
    { value: "wifi_not_working", label: "Wifi Not Working" },
    { value: "bluetooth_not_working", label: "Bluetooth Not Working" },
    { value: "backcover_poor_fit", label: "Backcover Poor Fit" },
    { value: "screen_poor_fit", label: "Screen Poor Fit" },
    { value: "cosmetics_scratched", label: "Cosmetics Scratched" },
    { value: "screen_flickering", label: "Screen Flickering" },
    { value: "screen_broken", label: "Screen Broken" },
    { value: "dust_dirt_rust_inside", label: "Dust/ Dirt/Rust Inside" },
    { value: "keys_do_not_work", label: "Keys Do Not Work" },
    { value: "sidekey_missing", label: "Sidekey Missing" },
    { value: "sidekey_hard", label: "Sidekey Hard" },
    { value: "memory_card_not_working", label: "Memory Card Not Working" },
    { value: "poor_network_connectivity", label: "Poor Network Connectivity" },
    { value: "boot_failure", label: "Boot Failure" },
    { value: "phone_switches_off", label: "Phone Switches Off" },
    {
      value: "fingureprint_sensor_not_working",
      label: "Fingerprint Sensor Not Working",
    },
    {
      value: "proximity_sensor_not_working",
      label: "Proximity Sensor Not Working",
    },
    { value: "touch_screen_auto_work", label: "Touch Screen Auto Work" },
    { value: "backcover_broken", label: "Backcover Broken" },
    { value: "backcover_scratches", label: "Backcover Scratches" },
    { value: "sim_tray_missing", label: "Sim Tray Missing" },
    { value: "software_problem", label: "Software Problem" },
    { value: "screen_burnt", label: "Screen Burnt" },
    { value: "power_button_hard", label: "Power Button Hard" },
    { value: "frame_dented", label: "Frame Dented" },
    { value: "wrongItemDescription", label: "Wrong Item Description" },
    { value: "deliverfailed", label: "Deliver Failed" },
  ];

  // Handle remark submission
  const handleRemarkSubmit = () => {
    if (!remark) {
      toastDispatch({
        type: ADD,
        payload: {
          content: "Please enter a remark before proceeding.",
          type: "error",
        },
      });
      return;
    }
    setIsRemarkSubmitted(true); // Switch to password input after remark is submitted
  };

  // Handle full form submission (with password)
  const handlePasswordSubmit = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    if (!password) {
      toastDispatch({
        type: ADD,
        payload: {
          content: "Please enter your password to authorize.",
          type: "error",
        },
      });
      return;
    }
    try {
      await postStoreHistory(
        "manage_sellout",
        "Put",
        {
          remark,
          defects: selectedDefects.map((defect) => defect.value), // Pass selected defects
          password,
          flag:
            props.saleflag === "Overturn Sale"
              ? "overturnSale"
              : "returnFromSellout",
          selectedBulk: props?.selectedBulk,
          userId: loggedInUser.id,
          email,
        },
        ({ msg }) => {
          props.refresh();
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        }
      );
    } catch (error) {
      toastDispatch({
        type: ADD,
        payload: {
          content: "Failed to submit data.",
          type: "error",
        },
      });
    }
  };

  return (
    <Modal
      close={props.close}
      onAccept={() =>
        isRemarkSubmitted ? handlePasswordSubmit() : handleRemarkSubmit()
      }
      title={props.saleflag}
      form="overSaleForm"
      loading={loading}
    >
      <form id="overSaleForm" onSubmit={(e) => e.preventDefault()}>
        {!isRemarkSubmitted ? (
          <>
            {props.saleflag === "⁠Return From Sellout" && (
              <div className="form-group">
                <label>Select Defects</label>
                <Select
                  isMulti
                  name="defects"
                  options={defectOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select defects"
                  onChange={setSelectedDefects}
                  value={selectedDefects}
                />
              </div>
            )}
            <div className="form-group">
              <label>Remark</label>
              <input
                name="remark"
                type="text"
                placeholder="Enter your remark"
                onChange={(e) => setRemark(e.target.value)}
                className="form-control"
                required
                value={remark}
              />
            </div>
          </>
        ) : (
          <>
            <b>Authorize By Tech Leader (Bulk {props.saleflag})</b>
            <div className="form-group">
              <label>Email</label>
              <input
                name="email"
                type="email"
                placeholder="Enter your authorize Email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                required
                value={email}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                name="password"
                type="password"
                placeholder="Enter your authorize password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                required
                value={password}
              />
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default OverSaleSelloutModal;
