import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOrders } from "./OrderService";
import { format } from "date-fns";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { Loader, Table } from "components";
import ModalService from "components/modal/ModalService";
import CreateNewOrderModal from "./CreateNewOrderModal";
import { useOrders } from "./hooks/useOrders";

const OrderHistory = () => {
  const navigate = useNavigate();
  const [newOrderHistry, setNewOrderHistry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [rowProductsPaidCount, setProductsRowPaidCount] = useState(0);
  const [rowProductsUnPaidCount, setProductsRowUnPaidCount] = useState(0);
  const [rowCount, setRowCount] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const { data, gettingOrders } = useOrders();

  const ordersData = data?.orders?.map((val, _) => ({
    order_id: `PKE-${val?.order?.orderNumber}`,
    name: val?.order?.user?.fname + " " + val?.order?.user?.lname,
    payment_status: val?.order?.payment,
    modelName: val?.products[0]?.model?.modelName || "-",
    variant:
      val?.products[0]?.variant?.ram !== undefined
        ? `${val?.products[0]?.variant?.ram}GB/${val?.products[0]?.variant?.storage}${val?.products[0]?.variant?.storageType}`
        : "-",
    order_date_time:
      format(new Date(val?.order?.createdAt), "PP") +
      " " +
      format(new Date(val?.order?.createdAt), "HH:mm a"),
    units: val?.products?.length,
    paymentMethod: val?.order?.paymentMethod,
    total: val?.total ? thousandCommaSeparator(val.total) : "-",
    status: val?.order?.status,
    action: { id: val?.order?._id },
  }));

  const paidOrders = data?.orders?.filter(
    (order) => order?.order?.payment?.toLowerCase() === "paid"
  );

  const unpaidOrders = data?.orders?.filter(
    (order) => order?.order?.payment?.toLowerCase() === "unpaid"
  );

  useEffect(() => {
    setProductsRowCount(newOrderHistry?.length);
    setProductsRowPaidCount(
      newOrderHistry?.filter(
        (val) => val?.payment_status?.toLowerCase() === "paid"
      ).length
    );
    setProductsRowUnPaidCount(
      newOrderHistry?.filter(
        (val) => val?.payment_status?.toLowerCase() === "unpaid"
      ).length
    );
    // Update the row count whenever the data changes
    setRowCount(newOrderHistry?.length);
  }, [newOrderHistry]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);

  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{rowCount || data?.orders.length}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };

  const viewOrder = (id) => {
    navigate(`/sales/order/${id}`);
  };

  const columns = [
    // {
    //   label: "#",
    //   name: "",
    //   options: {
    //     sort: false,
    //     filter: false,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, update) => {
    //       let rowIndex = Number(tableMeta.rowIndex) + 1;
    //       return <span>{rowIndex}</span>;
    //     },
    //   },
    // },
    {
      label: "Order ID",
      name: "order_id",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <span>
              <b>{value}</b>
            </span>
          ) : (
            "-"
          );
        },
      },
    },
    {
      label: "Product",
      name: "modelName",
      options: {
        sort: true,
        filter: false,
        print: false,
        download: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <>
        //       <div className="media d-flex flex-column ">
        //         <span href="#" className="mr-3">
        //           <img src={value?.image} height={35} alt />
        //         </span>
        //         <div className="media-body">
        //           {value?.productLength > 1 && (
        //             <small>
        //               {value?.modelNameLength +
        //                 " " +
        //                 `+ ${value?.productLength} more..`}
        //             </small>
        //           )}
        //           {value?.productLength <= 1 && (
        //             <small>{value?.modelName}</small>
        //           )}
        //         </div>
        //       </div>
        //     </>
        //   );
        // },
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "CUSTOMER",
      name: "name",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return value ? value : "-";
      },
    },
    {
      label: "Payment Status",
      name: "payment_status",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.toLowerCase() === "unpaid" ? (
            <>
              <span
                className="text-danger text-center"
                style={{ fontWeight: "bolder" }}
              >
                {value?.toUpperCase()}
              </span>
            </>
          ) : value.toLowerCase() === "paid" ? (
            <>
              <span
                className="text-success text-center"
                style={{ fontWeight: "bolder" }}
              >
                {value?.toUpperCase()}
              </span>
            </>
          ) : (
            ""
          );
          // <span
          //   style={{ width: "100%", padding: "5px 3px 3px 3px" }}
          //   className={`badge ${
          //     value.toLowerCase() === "unpaid"
          //       ? "badge-danger"
          //       : value.toLowerCase() === "paid"
          //       ? "badge-success"
          //       : "badge-warning"
          //   }`}
          // >
          //   {value?.toUpperCase()}
          // </span>
        },
      },
    },
    {
      label: "Order Date/Time",
      name: "order_date_time",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Payment Method",
      name: "paymentMethod",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value?.toUpperCase() : "-";
        },
      },
    },
    {
      label: "Units",
      name: "units",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },

    {
      label: "Amount",
      name: "total",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {" "}
              <div className="text-muted font-size-sm">
                <span
                  className={`badge badge-mark ${
                    value === "dispatched"
                      ? "bg-warning border-warning"
                      : value === "processing"
                      ? "bg-primary border-primary"
                      : value === "pending"
                      ? "bg-primary border-primary"
                      : "bg-success border-success"
                  } mr-1`}
                />
                {value === "pending" ? "processing" : value?.toUpperCase()}
              </div>
            </>
          );
        },
      },
    },
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <div className="list-icons-item dropdown">
                  <a
                    href="#"
                    className="list-icons-item"
                    data-toggle="dropdown"
                  >
                    <i className="icon-menu7" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a
                      href
                      className="dropdown-item"
                      onClick={() => viewOrder(value.id)}
                    >
                      <i className="icon-file-eye" /> View
                    </a>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];

  const openModal = () => {
    ModalService.open(CreateNewOrderModal);
  };

  return (
    <>
      {gettingOrders && <Loader />}
      {!gettingOrders && (
        <>
          {" "}
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {data?.orders?.length}
                </h3>
                <span>Total Orders </span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-spam"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {unpaidOrders?.length}
                </h3>
                <span>Unpaid </span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icon-cash3"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {paidOrders?.length}
                </h3>
                <span>Paid </span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="btn btn-sm bg-teal-400 ml-auto"
                onClick={() => openModal()}
              >
                <i className="icon-add mr-2" /> New Order
              </button>
            </div>{" "}
          </div>
          <Table
            title={"Orders History"}
            data={ordersData}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default OrderHistory;
