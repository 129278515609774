import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Loader } from "components";
import { getRegisteredDevices } from "./WarrantyService";
import { format } from "date-fns";

const ListDevices = () => {
  const navigate = useNavigate();

  const [warranty, setWarranty] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDevices = async () => {
    setLoading(true);
    try {
      const { warranty, success } = await getRegisteredDevices();
      if (success) {
        setWarranty(warranty);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDevices();
  }, []);

  const viewProduct = (id) => {
    navigate(`/sales/warranty/${id}`);
  };

  return (
    <>
      {loading && warranty.length === 0 && <Loader />}

      {!loading && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">Registered Devices</h4>
          </div>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <a
                href="#"
                className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon"
              >
                <i className="icon-stack" />
              </a>
              <div className="ml-3">
                <h5 className="font-weight-semibold mb-0">{warranty.length}</h5>
                <span className="badge badge-mark border-primary mr-1" />{" "}
                <span className="text-muted">Total Devices</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <a
                href="#"
                className="btn bg-transparent border-success text-success rounded-round border-2 btn-icon"
              >
                <i className="icon-users" />
              </a>
              <div className="ml-3">
                <h5 className="font-weight-semibold mb-0">
                  {warranty.filter((el) => el.active === true).length}
                </h5>
                <span className="badge badge-mark border-success mr-1" />{" "}
                <span className="text-muted">Under Warranty</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <a
                href="#"
                className="btn bg-transparent border-danger text-danger rounded-round border-2 btn-icon"
              >
                <i className="icon-copy" />
              </a>
              <div className="ml-3">
                <h5 className="font-weight-semibold mb-0">
                  {warranty.filter((el) => el.active !== true).length}
                </h5>
                <span className="badge badge-mark border-danger mr-1" />{" "}
                <span className="text-muted">Expired Warranty</span>
              </div>
            </div>
          </div>
          {!loading && warranty.length === 0 && (
            <div className="card-body d-flex justify-content-center">
              No registered devices
            </div>
          )}
          {!loading && warranty.length !== 0 && (
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr className="table-active ">
                    <td colSpan={4}>Total Devices</td>
                    <td />
                    <td />
                    <td />
                    <td className="text-right">
                      <span className="badge bg-blue badge-pill">
                        {warranty.length}
                      </span>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th style={{ width: 300 }}>Product Name</th>
                    <th style={{ width: 200 }}>Product IMEI</th>
                    <th style={{ width: 50 }}>Date of purchase</th>
                    <th style={{ width: 50 }}>Expiry Date</th>
                    <th style={{ width: 200 }}>Warranty Status</th>
                    {/* <th style={{ width: 50 }}>Purchase Store</th> */}
                    <th style={{ width: 200 }}>Customer Name</th>
                    <th style={{ width: 200 }}>Customer Contact</th>
                    <th style={{ width: 200 }}>Email</th>

                    {/* <th className="" style={{ width: 20 }}>
                      <i className="icon-arrow-down12" />
                    </th> */}
                  </tr>
                  {warranty?.map((el) => (
                    <tr>
                      {/* <td>
                        {`${el?.model[0]?.modelName} (${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType})`}
                      </td> */}
                      <td>
                        {`${el?.model[0]?.modelName} (${
                          el?.variant[0]?.ram !== undefined
                            ? el?.variant[0]?.ram + "GB/"
                            : ""
                        }${
                          el?.variant[0]?.storage !== undefined
                            ? el?.variant[0]?.storage
                            : ""
                        }${
                          el?.variant[0]?.storageType !== undefined
                            ? el?.variant[0]?.storageType
                            : ""
                        })`}
                      </td>
                      <td>{el.imei}</td>
                      <td className="">
                        {format(new Date(el.purchaseDate), "PP")}
                      </td>
                      <td className="">
                        {format(new Date(el.warrantyDate), "PP")}
                      </td>
                      <td>
                        <div className="font-weight-semibold ">
                          {" "}
                          {el.active && (
                            <>
                              <span className="badge badge-mark border-success mr-1" />{" "}
                              Active
                            </>
                          )}
                          {!el.active && (
                            <>
                              <span className="badge badge-mark border-danger mr-1" />{" "}
                              Expired
                            </>
                          )}
                        </div>
                      </td>
                      {/* <td className="">{el.location}</td> */}

                      <td>{el.fname + " " + el.lname}</td>
                      <td>{el.phone}</td>

                      <td>
                        <a
                          href
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary font-weight-semibold letter-icon-title"
                        >
                          {el.email}
                        </a>
                      </td>

                      {/* <td className="">
                        <div className="list-icons">
                          <div className="list-icons-item dropdown">
                            <a
                              href="#"
                              className="list-icons-item dropdown-toggle caret-0"
                              data-toggle="dropdown"
                            >
                              <i className="icon-menu7" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a
                                href
                                className="dropdown-item"
                                onClick={() => viewProduct(el._id)}
                              >
                                <i className="icon-file-eye" /> View
                              </a>
                            </div>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ListDevices;
