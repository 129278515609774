export const assignDealer = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "new_rep", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};
export const reAssignDealer = async (data, user_id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `reassign_dealer/${user_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};
export const deleteRepresenative = async (id) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + `/delete_rep/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};

export const getAgents = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_bas", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};

// export const getDealers = async () => {
//   let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_dealers", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_API_KEY,
//       secret: process.env.REACT_APP_API_SECRET,
//       country: process.env.REACT_APP_COUNTRY,
//     },
//   });
//   return await res.json();
// };
export const getRepresentativeDetails = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_rep_by_id/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
