import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, Link } from "react-router-dom";
import { format } from "date-fns";
import { Loader } from "components";
import ModalService from "components/modal/ModalService";
import CancelOrderModal from "./CancelOrderModal";
import { useHttp } from "hooks";

const TransferDetails = () => {
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { error, loading, sendHttpRequest: getSingleProduct } = useHttp();

  useEffect(() => {
    getSingleProduct(
      `get_single_transfer/${id}`,
      "GET",
      null,
      ({ data: { request, payload } }) => {
        setProduct(request);
        setImages(payload);
      }
    );
  }, []);

  const openModal = () => {
    ModalService.open(CancelOrderModal, {
      refresh: () =>
        getSingleProduct(
          `get_single_transfer/${id}`,
          "GET",
          null,
          ({ data: { request, payload } }) => {
            setProduct(request);
            setImages(payload);
          }
        ),
    });
  };
  return (
    <>
      {loading && !product && <Loader />}
      {!loading && !product && "No product available"}
      {!loading && product && (
        <div className="d-flex align-items-start flex-column flex-md-row">
          <div className="w-100 overflow-auto order-2 order-md-1">
            <div className="card">
              <div className="card-header header-elements-md-inline">
                <h5 className="card-title">Pre Order Details</h5>
              </div>
              <div className="nav-tabs-responsive bg-light border-top"></div>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="course-overview">
                  <div className="card-body">
                    <div className="mt-1 ">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="mb-4">
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Source
                              </dt>
                              <dd>Badili warehouse</dd>

                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Total Units
                              </dt>
                              <dd>310</dd>
                            </dl>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="mb-4">
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Destination
                              </dt>
                              <dd>Warehouse B</dd>
                            </dl>
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Scheduled Date
                              </dt>
                              <dd>18/06/2022</dd>
                            </dl>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="mb-4">
                            <dl>
                              <dt className="font-size-sm font-weight-bold text-uppercase">
                                Created Date
                              </dt>
                              <dd>08/06/2022</dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Device Name</th>
                          <th>Variants</th>
                          <th>Ordered Units</th>
                          <th> Units in Stock</th>
                          <th>Remaining Units</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <a href="#">Iphone 6</a>
                          </td>
                          <td>3GB/128GB</td>
                          <td>10</td>
                          <td>10</td>
                          <td>0</td>
                          <td>
                            <span className="badge bg-success">Closed</span>
                          </td>
                          <td>Oct 21st, 2016</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <a href="#">Samsung A03S</a>
                          </td>
                          <td>2GB/64GB</td>
                          <td>2</td>
                          <td>2</td>
                          <td>0</td>
                          <td>
                            <span className="badge bg-success">Closed</span>
                          </td>
                          <td>Oct 22nd, 2016</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            <a href="#">Samsung Galaxy S10</a>
                          </td>
                          <td>4GB/128GB</td>
                          <td>35</td>
                          <td>15</td>
                          <td>20</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>Oct 23rd, 2016</td>
                          <td>
                            <a
                              href="#"
                              className="text-danger"
                              onClick={openModal}
                            >
                              <i className="icon-cross3" /> Cancel
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className=" wmin-md-400 px-md-3 border-0 shadow-0 order-1 order-md-2 ">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <span className="text-uppercase font-size-sm font-weight-semibold">
                  Transfer Details
                </span>
              </div>
              <table className="table table-borderless table-xs my-2">
                <tbody>
                  <tr>
                    <td>Source:</td>
                    <td className="text-right">
                      <a href="#">{product.details.model.modelName}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Destination:</td>
                    <td className="text-right">
                      {product.details.variant[0]?.storage +
                        "GB / " +
                        product.details.variant[0]?.ram +
                        "GB"}
                    </td>
                  </tr>

                  <tr>
                    <td>Status:</td>
                    <td className="text-right">
                      <div className="btn-group">
                        <a href="#" className="badge bg-info">
                          {product.details.current_status[0]?.name}
                        </a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Total Units:</td>
                    <td className="text-right text-muted">
                      {format(new Date(product.details.updatedAt), "PP")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default TransferDetails;
