import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useHttp } from "hooks";
import Select from "react-select";

const EditRMModal = (props) => {
  console.log(props?.val?.assignedDealers);
  const { error, loading, sendHttpRequest: EditRM } = useHttp();
  const { errord, deloading, sendHttpRequest: getDealers } = useHttp();
  const [dealersDatas, setDealersDatas] = useState([]);
  const [assignedDealer, setAssignedDealer] = useState([]);
  const [RMBranch, setRMBranch] = useState({
    fname: props?.fname,
    lname: props?.lname,
    email: props?.email,
    assignedDealers: props?.val?.assignedDealers,
  });

  function handleSelect(e) {
    setAssignedDealer(
      Array.isArray(e) ? e.map((x) => ({ value: x.value, label: x.label })) : []
    );
  }
  useEffect(() => {
    getDealersFun();
  }, []);
  const getDealersFun = () => {
    getDealers("get_dealers", "GET", null, ({ data: { dealers } }) => {
      const temp = dealers.map((el) => {
        let tempObj = {
          value: el._id,
          label: el.name,
        };
        return tempObj;
      });
      setDealersDatas(temp);
      let getFilterData = temp.filter((ele) => {
        return RMBranch?.assignedDealers?.some((elem) => {
          return ele.value == elem;
        });
      });
      setAssignedDealer(getFilterData);
    });
  };
  const EditNewRM = async () => {
    EditRM(
      `update_rm/${props.id}`,
      "PUT",
      {
        fname: RMBranch?.fname,
        lname: RMBranch?.lname,
        email: RMBranch?.email,
        assignedDealers: assignedDealer?.map((curVal) => curVal?.value),
      },
      () => {
        props.refresh();
        props.close();
      }
    );
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setRMBranch({ ...RMBranch, [name]: value });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => EditNewRM()}
      title="Edit RM"
      loading={loading}
      size="md"
    >
      {!deloading && (
        <form onSubmit={(e) => e.preventDefault()} id="">
            <div class="row">
            <div className=" col-md-6 col-lg-12">
          <div className="form-group ">
            <label >Select Dealers:</label>
           
              <Select
              styles={{
                valueContainer: (base) => ({
                  ...base,
                  overflowy: 'scroll',
                }),
              }}
                name="dealer"
                options={dealersDatas}
                placeholder="Select dealers"
                value={assignedDealer}
                onChange={(e) => handleSelect(e)}
                isSearchable={true}
                isMulti
              />
            </div>
          </div>
           <div class="col-6 col-md-6">
          <div className="form-group">
            <label> First Name : </label>
            <input
              name="fname"
              type="text"
              placeholder=""
              value={RMBranch?.fname}
              required
              onChange={onChange}
              className="form-control"
            />
          </div></div>
          <div class="col-6 col-md-6">
          <div className="form-group">
            <label> Last Name : </label>
            <input
              name="lname"
              type="text"
              placeholder=""
              value={RMBranch?.lname}
              required
              onChange={onChange}
              className="form-control"
            />
          </div></div>
          <div class="col-md-12">
          <div className="form-group">
            <label>Email : </label>{" "}
            <input
              name="email"
              type="email"
              placeholder=""
              required
              value={RMBranch?.email}
              onChange={onChange}
              className="form-control"
            />
          </div>  </div>
         
          </div>
        </form>
      )}
    </Modal>
  );
};

export default EditRMModal;
