import React, { useState, useRef, useEffect } from "react";

import { Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Loader } from "components";

import GoogleMapContainer from "components/map/GoogleMapContainer";
import "components/map/styles.css";
import AddRepModal from "../representatives/AssignRepresentativeModal";
import AddDealerModal from "../dealers/AddDealerModal";
import AddWarehouseModal from "../warehouses/AddWarehouseModal";
import ModalService from "components/modal/ModalService";
import { useHttp } from "hooks";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const CountyDetails = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams();
  const [regionDetails, setRegionDetails] = useState(false);
  const [reps, setReps] = useState([]);
  const [dealers, setDealers] = useState([]);
  const {
    error: error,
    loading: loadingRegionDetails,
    sendHttpRequest: getRegionDetails,
  } = useHttp();
  const {
    error: errorReps,
    loading: loadingReps,
    sendHttpRequest: getRep,
  } = useHttp();
  const {
    error: errorDealer,
    loading: loadingDealer,
    sendHttpRequest: getDealers,
  } = useHttp();

  const getDetails = () => {
    getRegionDetails(
      `get_region_by_id/${id}`,
      "GET",
      null,
      ({ data: { region } }) => {
        setRegionDetails(region);
        getRepByRegion();
        getDealersByRegion();
      }
    );
  };

  const getRepByRegion = () => {
    getRep(`get_reps_by_region/${id}`, "GET", null, ({ data: { reps } }) =>
      setReps(reps)
    );
  };
  const getDealersByRegion = () => {
    getDealers(
      `get_dealer_by_region/${id}`,
      "GET",
      null,
      ({ data: { dealer } }) => setDealers(dealer)
    );
  };
  useEffect(() => {
    getDetails();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModal = () => {
    ModalService.open(AddRepModal, {
      refresh: () => getRepByRegion(),
      region: id,
    });
  };
  const openDealerModal = () => {
    ModalService.open(AddDealerModal, {
      refresh: () => getDetails(),
      region: id,
    });
  };
  const openWarehouseModal = () => {
    ModalService.open(AddWarehouseModal, {
      refresh: () => getDetails(),
      region: id,
    });
  };

  function a11yProps(index) {
    return {
      id: `full-width-tabpanel-${index}`,
      "aria-controls": `full-width-tab-${index}`,
    };
  }

  return (
    <>
      {loadingRegionDetails && <Loader />}
      {!loadingRegionDetails && (
        <div className="row">
          <div className="col-xl-5">
            <div className="card border-y-1 border-top-info border-bottom-info rounded-0">
              <div className="card-body">
                <h6>{regionDetails.name}</h6>
                <div id="mapContainer">
                  {" "}
                  <GoogleMapContainer />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <Box sx={{ bgcolor: "#2e1534" }}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="styled tabs example"
              >
                <StyledTab label="Dealers" {...a11yProps(0)} />
                <StyledTab label="Sales Representatives" {...a11yProps(1)} />
                <StyledTab label="Warehouses" {...a11yProps(2)} />
              </StyledTabs>
              <Box sx={{ p: 1 }} />
            </Box>
            <TabPanel value={value} index={0}>
              <div className="card border-y-1 border-top-info border-bottom-info rounded-0">
                <div className="card-header header-elements-sm-inline">
                  <h6 className="card-title">{`${regionDetails.name} Badili Dealers`}</h6>
                  <div className="header-elements">
                    <button
                      onClick={openDealerModal}
                      className="btn btn-sm bg-indigo-300 legitRipple"
                    >
                      <i className="icon-popout mr-2" /> New dealer
                    </button>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table text-nowrap">
                    <thead>
                      <tr>
                        <th>Dealer Name</th>
                        <th>Region</th>
                        <th>Sales</th>
                        <th>Status</th>
                        <th className="text-center" style={{ width: 20 }}>
                          <i className="icon-arrow-down12" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-active table-border-double">
                        <td colSpan={4}>Total Dealers</td>
                        <td className="text-right">{dealers.length}</td>
                      </tr>
                      {dealers.map((dealer) => (
                        <tr key={dealer._id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="mr-3">
                                <a href="#">
                                  <img
                                    src="../../../../global_assets/images/brands/facebook.png"
                                    className="rounded-circle"
                                    alt
                                    width={32}
                                    height={32}
                                  />
                                </a>
                              </div>
                              <div>
                                <a
                                  href="#"
                                  className="text-default font-weight-semibold"
                                >
                                  {dealer.name}
                                </a>
                                <div className="text-muted font-size-sm">
                                  <span className="badge badge-mark border-blue mr-1" />
                                  <a href="#">View exact location</a>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-muted">{dealer.region}</span>
                          </td>

                          <td>
                            <h6 className="font-weight-semibold mb-0">
                              $5,489
                            </h6>
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                dealer.active ? "bg-success-400" : "bg-error"
                              }`}
                            >
                              {`${dealer.active ? "Active" : "Inactive"}`}
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="list-icons">
                              <div className="list-icons-item dropdown">
                                <a
                                  href="#"
                                  className="list-icons-item dropdown-toggle caret-0"
                                  data-toggle="dropdown"
                                >
                                  <i className="icon-menu7" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    to={`/sales/dealers/${dealer._id}`}
                                    className="dropdown-item"
                                  >
                                    <i className="icon-file-stats" /> View Info
                                  </Link>
                                  <a href="#" className="dropdown-item">
                                    <i className="icon-file-text2" /> New Member
                                  </a>
                                  <a href="#" className="dropdown-item">
                                    <i className="icon-file-locked" /> Remove
                                    Dealer
                                  </a>
                                  <div className="dropdown-divider" />
                                  <a href="#" className="dropdown-item">
                                    <i className="icon-gear" /> Settings
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="card border-y-1 border-top-info border-bottom-info rounded-0">
                <div className="card-header header-elements-inline">
                  <h5 className="card-title">Sales Representatives</h5>
                  <div className="header-elements">
                    <button
                      className="btn btn-sm bg-indigo-300 legitRipple"
                      onClick={openModal}
                    >
                      <i className="icon-user mr-2" /> New member
                    </button>
                  </div>
                </div>
                <ul className="media-list media-list-linked">
                  <li className="media bg-light font-weight-semibold py-2">
                    Total Representatives
                  </li>
                  {reps.map((rep) => (
                    <li key={rep._id}>
                      <a href="#" className="media">
                        <div className="mr-3">
                          <img
                            src="https://via.placeholder.com/150x150"
                            className="rounded-circle"
                            alt="rep"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className="media-body">
                          <div className="media-title font-weight-semibold">
                            {rep.fname + " " + rep.lname}
                          </div>
                        </div>
                        <div className="media-body">
                          <div className="media-title font-weight-semibold">
                            ID Number
                          </div>
                          <span className="text-muted">{rep.idNumber}</span>
                        </div>
                        <div className="media-body">
                          <div className="media-title font-weight-semibold">
                            {rep.phone}
                          </div>
                          <span className="text-muted">{rep.email}</span>
                        </div>
                        <div className="align-self-center ml-3 text-nowrap">
                          <span className="text-muted">
                            <i className="icon-pin-alt font-size-base mr-1" />
                            I-salute
                          </span>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="card border-y-1 border-top-info border-bottom-info rounded-0">
                <div className="card-header header-elements-inline">
                  <h5 className="card-title">Warehouses</h5>
                  <div className="header-elements">
                    <button
                      className="btn btn-sm bg-indigo-300 legitRipple"
                      onClick={openWarehouseModal}
                    >
                      <i className="icon-color-sampler mr-2" /> New Warehouse
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table text-nowrap">
                    <thead>
                      <tr>
                        <th>Warehouse Name</th>
                        <th>Sales</th>
                        <th>Stock Level</th>
                        <th className="text-center" style={{ width: 20 }}>
                          <i className="icon-arrow-down12" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-active table-border-double">
                        <td colSpan={4}>Total Warehouses</td>
                        <td className="text-right">{dealers.length}</td>
                      </tr>
                      {dealers.map((dealer) => (
                        <tr key={dealer._id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="mr-3">
                                <a href="#">
                                  <img
                                    src="../../../../global_assets/images/brands/facebook.png"
                                    className="rounded-circle"
                                    alt
                                    width={32}
                                    height={32}
                                  />
                                </a>
                              </div>
                              <div>
                                <a
                                  href="#"
                                  className="text-default font-weight-semibold"
                                >
                                  {dealer.name}
                                </a>
                                <div className="text-muted font-size-sm">
                                  <span className="badge badge-mark border-blue mr-1" />
                                  <a href="#">View exact location</a>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>
                            <h6 className="font-weight-semibold mb-0">
                              $5,489
                            </h6>
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                dealer.active ? "bg-success-400" : "bg-error"
                              }`}
                            >
                              {`${dealer.active ? "High" : "Low"}`}
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="list-icons">
                              <div className="list-icons-item dropdown">
                                <a
                                  href="#"
                                  className="list-icons-item dropdown-toggle caret-0"
                                  data-toggle="dropdown"
                                >
                                  <i className="icon-menu7" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link
                                    to={`/sales/dealers/${dealer._id}`}
                                    className="dropdown-item"
                                  >
                                    <i className="icon-file-stats" /> View Info
                                  </Link>
                                  <a href="#" className="dropdown-item">
                                    <i className="icon-file-text2" /> New Member
                                  </a>
                                  <a href="#" className="dropdown-item">
                                    <i className="icon-file-locked" /> Remove
                                    Dealer
                                  </a>
                                  <div className="dropdown-divider" />
                                  <a href="#" className="dropdown-item">
                                    <i className="icon-gear" /> Settings
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
      )}
    </>
  );
};

export default CountyDetails;
