import { useMutation } from "@tanstack/react-query";
import { sendMessage } from "../CampaignService";

export function useSendMessage() {
  const {
    mutate: sendingMessage,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: sendMessage,
  });

  return { sendingMessage, isLoading, error };
}
