import React, { useState, useEffect, useContext } from "react";
import Modal from "components/modal/Modal";
import { AppContext } from "context/AppContext";
import { useHttp } from "hooks";

const DeleteDealerModal = (props) => {
  // const [savingDealer, setSavingDealer] = useState(false);
  const {
    error,
    loading: savingDealer,
    sendHttpRequest: deleteDealer,
  } = useHttp();
  const { status, setShowAlert } = useContext(AppContext);

  const deleteNewDealer = async () => {
    deleteDealer(`delete_dealer/${props.id}`, "DELETE", null, () => {
      props.refresh();
      props.close();
      setShowAlert(true);
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => deleteNewDealer()}
      title="Are you sure?"
      loading={savingDealer}
      size="sm"
    >
      You are about to delete <span className="text-danger">{props.name}</span>{" "}
      Dealer
    </Modal>
  );
};

export default DeleteDealerModal;
