import React, { useState, createContext, useContext } from "react";
const AppContext = createContext();
export const useAuth = () => useContext(AppContext);
const AppContextProvider = (props) => {
  const [status, setStatus] = useState();
  const [user, setUser] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  return (
    <AppContext.Provider
      value={{
        status,
        setStatus,
        user,
        setUser,
        showAlert,
        setShowAlert,
        // transferDetails,
        // setTransferDetails,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const AppConsumer = AppContext.Consumer;
export { AppContextProvider, AppConsumer, AppContext };
