import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddStoresModal = (props) => {
  const [couponDetails, setStoresDetails] = useState({
    name: "",
    location: "",
  });
  const { toastDispatch } = useToastContext();
  const {
    error: errorStores,
    loading: isAddingStores,
    sendHttpRequest: addStores,
  } = useHttp();
  const adStores = async () => {
    let data = {
      name: couponDetails.name,
      location: couponDetails.location,
    };
    addStores("new_store", "POST", data, ({ msg }) => {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();

  const onChange = (e) => {
    const { name, value } = e.target;
    setStoresDetails({ ...couponDetails, [name]: value });
  };

  return (
    <Modal close={props.close} title={`Add Store`} size="sm">
      {!loadingModels && (
        <Formik
          enableReinitialize
          initialValues={couponDetails}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Store name is required"),
            location: Yup.string().required("Store Location is required"),
          })}
          onSubmit={() => adStores()}
        >
          {({ errors, status, touched }) => (
            <Form id="addCupon">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Name </label>
                    <div class="form-group">
                      <input
                        className={
                          "form-control form-control-sm" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        onChange={onChange}
                        name="name"
                        value={couponDetails.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label> Location </label>
                    <div class="form-group">
                      <input
                        className={
                          "form-control form-control-sm" +
                          (errors.location && touched.location
                            ? " is-invalid"
                            : "")
                        }
                        onChange={onChange}
                        name="location"
                        value={couponDetails.location}
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn bg-primary ld-ext-right float-right"
                disabled={isAddingStores}
              >
                Submit
                {isAddingStores && (
                  <>
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                )}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddStoresModal;
