import { useQuery } from "@tanstack/react-query";
import { getAllTeamLeaders } from "../AssignToTeamLeaderService";

export function useTeamLeader() {
  const {
    data: teamLeaders,
    isLoading,
    error,
  } = useQuery({
    queryFn: getAllTeamLeaders,
    queryKey: ["teamLeaders"],
  });

  return { teamLeaders, isLoading, error };
}
