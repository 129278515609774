import React, { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { useHttp } from "hooks";
import { useToastContext, ADD } from "context/ToastContext";
import { getModels, getBrands, getVariants } from "./OrderService";
import { useQueryClient } from "@tanstack/react-query";

const CreateNewOrderModal = (props) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [isDispatchingOrder, setIsDispatchingOrder] = useState(false);
  const { toastDispatch } = useToastContext();
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [variants, setVariants] = useState([]);

  const { error, sendHttpRequest: addNewOrders } = useHttp();

  useEffect(() => {
    if (selectedBrandId) {
      getDeviceModels(selectedBrandId);
    }
  }, [selectedBrandId]);

  useEffect(() => {
    getDeviceBrands();
  }, []);

  useEffect(() => {
    getDeviceVariants(selectedModelId);
  }, [selectedModelId]);

  const handleSelect = (event, type) => {
    const selectedValue = event.target.value;
    if (type === "brand") {
      setSelectedBrandId(selectedValue);
      setSelectedModelId("");
      setSelectedVariantId("");
      getDeviceModels(selectedValue);
    } else if (type === "model") {
      setSelectedModelId(selectedValue);
    } else if (type === "variant") {
      setSelectedVariantId(selectedValue);
    }
  };

  const getDeviceModels = async (brandId) => {
    setLoading(true);
    try {
      const { success, deviceModels, total } = await getModels(brandId);
      if (success) {
        setModels(deviceModels);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getDeviceBrands = async () => {
    setLoading(true);
    try {
      const { success, brands, total } = await getBrands();
      if (success) {
        setBrands(brands);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getDeviceVariants = async (model_id) => {
    setLoading(true);
    try {
      const { success, variants, total } = await getVariants(model_id);
      if (success) {
        setVariants(variants);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const addOrder = async () => {
    // Construct the data object using selectedBrandId, selectedModelId, selectedVariantId, and specifications
    const data = {
      brand_id: selectedBrandId,
      model_id: selectedModelId,
      variant_id: selectedVariantId,
      shopifyOrder: specifications.shopifyOrder,
    };

    addNewOrders(`make_order`, "POST", data, ({ msg }) => {
      queryClient.invalidateQueries({
        queryKey: ["orders"],
      });
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setSpecifications({ ...specifications, [name]: value });
  };

  return (
    <Modal
      close={props.close}
      onAccept={addOrder}
      title={`Create New Order`}
      form="createorderForm"
      loading={isDispatchingOrder}
      size="sm"
    >
      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="select-brand-label">Select Brand</InputLabel>
          <Select
            labelId="select-brand-label"
            onChange={(event) => handleSelect(event, "brand")}
            value={selectedBrandId}
          >
            <MenuItem value="">Select Brand</MenuItem>
            {brands.map((brand) => (
              <MenuItem key={brand._id} value={brand._id}>
                {brand.brand}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="select-model-label">Select Model</InputLabel>
          <Select
            labelId="select-model-label"
            onChange={(event) => handleSelect(event, "model")}
            value={selectedModelId}
          >
            <MenuItem value="">Select Model</MenuItem>
            {models.map((model) => (
              <MenuItem key={model.details._id} value={model.details._id}>
                {model.details.modelName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="select-variant-label">Select Variant</InputLabel>
          <Select
            labelId="select-variant-label"
            onChange={(event) => handleSelect(event, "variant")}
            value={selectedVariantId}
          >
            <MenuItem value="">Select Variant</MenuItem>
            {variants?.map((variant) => {
              return (
                <MenuItem key={variant.variant._id} value={variant.variant._id}>
                  {variant?.variant?.ram}GB/{variant?.variant?.storage}
                  {variant?.variant?.storageType}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ minWidth: 180 }}>
        <FormControl fullWidth variant="standard">
          <TextField
            id="standard-basic"
            label="Shopify Order"
            variant="standard"
            name="shopifyOrder"
            onChange={onChange}
          />
        </FormControl>
      </Box>
    </Modal>
  );
};

export default CreateNewOrderModal;
