import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const EditSpecificationsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { toastDispatch } = useToastContext();
  const {
    error,
    loading: loadingPublish,
    sendHttpRequest: publish,
  } = useHttp();
  const {
    error: errorFeatured,
    loading: loadingFeatured,
    sendHttpRequest: markAsFeatured,
  } = useHttp();
  const {
    error: errorFavorite,
    loading: loadingFavorite,
    sendHttpRequest: markAsFavorite,
  } = useHttp();

  const pubLishProduct = async () => {
    let data = {
      publish: !props.product.details.published,
    };

    let hasPriorityVariant = props.product.details.variants.some(
      (variant) => variant["priority"] === true
    );

    if (hasPriorityVariant) {
      publish(
        `publish_inventory_item/${props.product.details._id}`,
        "PUT",
        data,
        ({ msg }) => {
          props.refresh();
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        }
      );
    }
  };
  const featureProduct = async () => {
    let data = {
      featured: !props.product.details.featured,
    };
    let hasPriorityVariant = props.product.details.variants.some(
      (variant) => variant["priority"] === true
    );

    if (hasPriorityVariant) {
      markAsFeatured(
        `update_device_model/${props.product.details._id}`,
        "PUT",
        data,
        ({ msg }) => {
          props.refresh();
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        }
      );
    }
  };
  const favoriteProduct = async () => {
    let data = {
      favorite: !props.product.details.favorite,
    };

    let hasPriorityVariant = props.product.details.variants.some(
      (variant) => variant["priority"] === true
    );
    if (hasPriorityVariant) {
      markAsFavorite(
        `toggle_favorite/${props.product.details._id}`,
        "PUT",
        data,
        ({ msg }) => {
          props.refresh();
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        }
      );
    }
  };
  const bestSellerProduct = async () => {
    let data = {
      bestseller: !props.product.details.bestseller,
    };
    let hasPriorityVariant = props.product.details.variants.some(
      (variant) => variant["priority"] === true
    );

    if (hasPriorityVariant) {
      markAsFeatured(
        `update_device_model/${props.product.details._id}`,
        "PUT",
        data,
        ({ msg }) => {
          props.refresh();
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        }
      );
    }
  };

  return (
    <Modal
      close={props.close}
      onAccept={
        props.publish
          ? () => pubLishProduct()
          : props.bestseller
          ? () => bestSellerProduct()
          : props.favorite
          ? () => favoriteProduct()
          : () => featureProduct()
      }
      title="Are you Sure?"
      form={
        props.publish
          ? "confirmPublicationForm"
          : props.bestseller
          ? "confirmBestSellerForm"
          : props.favorite
          ? "confirmFavorite"
          : "confirmFeaturedForm"
      }
      size="sm"
      loading={loading || loadingPublish || loadingFeatured}
    >
      <form
        onSubmit={(e) => e.preventDefault()}
        id={
          props.publish
            ? "confirmPublicationForm"
            : props.bestseller
            ? "confirmBestSellerForm"
            : props.favorite
            ? "confirmFavorite"
            : "confirmFeaturedForm"
        }
      >
        {props.publish && (
          <h5>
            You are about to{" "}
            <span
              className={`${
                props.product.details.published ? "text-danger" : "text-success"
              } font-weight-bold `}
            >
              {props.product.details.published ? "unpublish" : "Publish"}
            </span>{" "}
            {props.product.details.modelName} model
          </h5>
        )}
        {props.featured && (
          <h5>
            You are about to{" "}
            <span
              className={`${
                props.product.details.featured ? "text-danger" : "text-success"
              } font-weight-bold `}
            >
              {props.product.details.featured ? `unmark` : "mark"}
            </span>{" "}
            {props.product.details.modelName} model as featured
          </h5>
        )}

        {props.bestseller && (
          <h5>
            You are about to{" "}
            <span
              className={`${
                props.product.details.bestseller
                  ? "text-danger"
                  : "text-success"
              } font-weight-bold `}
            >
              {props.product.details.bestseller ? "unmark" : "mark"}
            </span>{" "}
            {props.product.details.modelName} model as a bestseller
          </h5>
        )}
        {props.favorite && (
          <h5>
            You are about to{" "}
            <span
              className={`${
                props.product.details.favorite ? "text-danger" : "text-success"
              } font-weight-bold `}
            >
              {props.product.details.favorite ? "unmark" : "mark"}
            </span>{" "}
            {props.product.details.modelName} model as a favorite
          </h5>
        )}
      </form>
    </Modal>
  );
};

export default EditSpecificationsModal;
