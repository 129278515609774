import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";

import { useUploadCSV } from "./hooks/useUploadCSV";

const AddUploadCSVModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { isLoading, uploadingCSV } = useUploadCSV();

  const [leadCSV, setLeadDetails] = useState(null);

  async function handleSubmitCSV() {
    uploadingCSV(leadCSV, {
      onSuccess: ({ success, msg }) => {
        if (success) {
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        } else {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg || "Unexpected Error Occured. Try again!",
              type: "danger",
            },
          });
        }
      },
      onError: (error) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: error?.message || "Unexpected Error Occured. Try again!",
            type: "danger",
          },
        });
      },
    });
  }

  return (
    <Modal
      close={props.close}
      onAccept={() => handleSubmitCSV()}
      title="Upload CSV"
      form="warehouseForm"
      loading={isLoading}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: ".9rem" }}>
        <div style={{ display: "flex", alignItems: "start", gap: ".4rem" }}>
          <a href="/leads.csv" download>
            <img
              src="/csv.png"
              height={"30rem"}
              width={"30rem"}
              alt="csv"
              style={{ cursor: "pointer" }}
            />
          </a>

          <div>
            <div style={{ display: "flex", alignItems: "start" }}>
              <p
                style={{ fontSize: ".85rem", fontWeight: "500", color: "red" }}
              >
                Only csv file required
                <span>
                  <a
                    href="/leads.csv"
                    download
                    style={{
                      color: "black",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    (template)
                  </a>
                </span>
              </p>
              <sup style={{ marginTop: ".58rem", color: "red" }}>*</sup>
            </div>

            <p>
              Please download this CSV template and match your client
              information exactly how its is. Please do not change the titles of
              the cells.
            </p>
          </div>
        </div>
        <input
          type="file"
          accept=".csv"
          onChange={(e) => setLeadDetails(e.target.files[0])}
        />
      </div>
    </Modal>
  );
};

export default AddUploadCSVModal;
