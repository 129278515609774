import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "context/AppContext";
import { useToastContext, ADD } from "context/ToastContext";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import { useHttp } from "hooks";

const Login = () => {
  const navigate = useNavigate();
  const { toastDispatch } = useToastContext();
  const [showOTP, setShowOtp] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    role: "",
  });
  const [useLogin, setUseLogin] = useState({
    id: "",
    otp: "",
  });
  const { setUser } = useContext(AppContext);
  const {
    error: errorOTP,
    loading: loadingOTP,
    sendHttpRequest: requestOTP,
  } = useHttp();
  const {
    error: errorResend,
    loading: loadingResend,
    sendHttpRequest: resend,
  } = useHttp();
  const {
    error: errorRoles,
    loading: loadingRoles,
    sendHttpRequest: getRoles,
  } = useHttp();
  const {
    error: errorAuth,
    loading: loadingAuth,
    sendHttpRequest: login,
  } = useHttp();

  const handleOTPChange = (otp) => setUseLogin({ ...useLogin, otp });

  const onChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const requestOneTimePass = () => {
    sessionStorage.clear();

    let _data = {
      auth: credentials.username,
      country: credentials.country,
      password: credentials.password,
      role: credentials.role,
    };
    requestOTP("admin_login", "POST", _data, ({ data: { admId }, msg }) => {
      setUseLogin({ ...useLogin, id: admId });
      setShowOtp(true);
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };
  const resendOTPass = (e) => {
    sessionStorage.clear();
    e.preventDefault();
    setUseLogin({ ...useLogin, otp: "" });

    let _data = {
      admId: useLogin.id,
      password: credentials.password,
    };
    resend("resend_otp", "POST", _data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
    setUseLogin({ ...useLogin, otp: "" });
  };

  useEffect(() => {
    getRoles("get_role_sales", "GET", null, ({ data: { roles } }) => {
      setCredentials({
        ...credentials,
        role: roles.find((role) => role.name.toLowerCase() === "sales")._id,
      });
    });
  }, []);

  const authenticate = async () => {
    sessionStorage.clear();

    let data = {
      admId: useLogin.id,
      otp: Number(useLogin.otp),
    };

    login("checkadminotp", "POST", data, ({ data: { token, user } }) => {
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("token", token);
      setUser(user);
      navigate("/inventory/products");
      toastDispatch({
        type: ADD,
        payload: {
          content: `Welcome ${
            user.fname + " " + user.lname
          } to your Dashboard!`,
          type: "info",
        },
      });
      setUseLogin({ ...useLogin, otp: "" });
    });
  };
  return (
    <>
      {!showOTP && (
        <Formik
          enableReinitialize
          initialValues={credentials}
          validationSchema={Yup.object().shape({
            country: Yup.string().required("Country is required"),
            username: Yup.string().email('Enter a valid email email').required("Email is required"),
            password: Yup.string().required("Password is required"),
          })}
          onSubmit={() => requestOneTimePass()}
        >
          {({ errors, status, touched }) => (
            <div className="page-content">
              <div className="content-wrapper">
                <div className="content d-flex justify-content-center align-items-center">
                  {/* Login form */}
                  <Form className="login-form">
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="text-center mb-3">
                          {/* <i className="icon-user icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" /> */}
                          <h5 className="mb-0">Login to your account</h5>
                          <span className="d-block text-muted">
                            Enter your credentials below
                          </span>
                        </div>
                        <div className="form-group form-group-feedback form-group-feedback-left ">
                          <select
                            className={
                              "form-control" +
                              (errors.country && touched.country
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Country"
                            name="country"
                            value={credentials.country}
                            onChange={onChange}
                          >
                            <option value="">select country</option>
                            <option value="ke">
                              {" "}
                              {"\uD83C\uDDF0\uD83C\uDDEA"} Kenya
                            </option>
                            <option value="ug">
                              {"\uD83C\uDDFA\uD83C\uDDEC"} Uganda
                            </option>
                            <option value="tz">
                             {"\u{1f1f9}\u{1f1ff}"} Tanzania
                          </option>
                          </select>
                          <div className="form-control-feedback">
                            <i className="icon-flag3 text-muted" />
                          </div>

                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group form-group-feedback form-group-feedback-left">
                          <input
                            type="text"
                            className={
                              "form-control" +
                              (errors.username && touched.username
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Email"
                            name="username"
                            value={credentials.username}
                            onChange={onChange}
                          />
                          <div className="form-control-feedback">
                            <i className="icon-envelop3 text-muted" />
                          </div>
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group form-group-feedback form-group-feedback-left">
                          <input
                            type="password"
                            name="password"
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Password"
                            value={credentials.password}
                            onChange={onChange}
                          />

                          <div className="form-control-feedback">
                            <i className="icon-lock2 text-muted" />
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loadingOTP}
                          >
                            {loadingOTP && (
                              <>
                                <div className="lds-ring mr-2">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </>
                            )}
                            Request OTP
                          </button>
                        </div>
                        <div className="text-center">
                          <Link to="/forgot-password">Forgot password?</Link>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                {/* <Footer/> */}
              </div>
            </div>
          )}
        </Formik>
      )}
      {showOTP && (
        <Formik
          enableReinitialize
          initialValues={useLogin}
          validationSchema={Yup.object().shape({
            otp: Yup.string().required("Otp is required"),
          })}
          onSubmit={() => authenticate()}
        >
          {({ errors, status, touched }) => (
            <div className="page-content">
              <div className="content-wrapper">
                <div className="content d-flex justify-content-center align-items-center">
                  {/* Login form */}
                  <Form className="login-form">
                    <div className="card mb-0">
                      <div className="card-body">
                        <div
                          style={{
                            border: 0,
                            cursor: "pointer",
                            width: "fit-content",
                          }}
                          onClick={() => {
                            setShowOtp(false);
                            setUseLogin({ ...useLogin, otp: "" });
                          }}
                        >
                          {" "}
                          <i className="icon-arrow-left8 mr-1" />
                          Back
                        </div>
                        <div className="text-center mb-3">
                          <i className="icon-user icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" />
                          <h5 className="mb-0">Login to your account</h5>
                          <span className="d-block text-muted">
                            Enter your otp below
                          </span>
                        </div>
                        <div className="form-group">
                          {/* <input
                            type="text"
                            className={
                              "form-control" +
                              (errors.otp && touched.otp
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter your OTP"
                            name="otp"
                            value={credentials.otp}
                            onChange={onChange}
                          /> */}
                          <OtpInput
                            value={useLogin.otp}
                            onChange={handleOTPChange}
                            numInputs={4}
                            isInputNum={true}
                            separator={<span>-</span>}
                            placeholder=""
                            inputStyle={{
                              width: "2.5em",
                              height: "2.5em",
                              border: `${
                                errors.otp && touched.otp
                                  ? "1px solid red"
                                  : "1px solid #2196f3"
                              }`,
                              margin: "20px 10px",
                            }}
                            containerStyle={{
                              justifyContent: "center",
                            }}
                          />

                          <ErrorMessage
                            name="otp"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            disabled={loadingAuth}
                          >
                            {loadingAuth && (
                              <>
                                <div className="lds-ring mr-2">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </>
                            )}
                            Sign in
                          </button>
                        </div>
                        <div className="text-center">
                          Didn't receive otp,{" "}
                          <a
                            href
                            className="text-primary"
                            onClick={(e) => resendOTPass(e)}
                            style={{ cursor: "pointer" }}
                          >
                            <u>Resend</u>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                {/* <Footer/> */}
              </div>
            </div>
          )}
        </Formik>
      )}
    </>
  );
};
export default Login;
