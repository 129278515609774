import React, { useState, useEffect, useContext } from "react";
import Modal from "components/modal/Modal";
import { AppContext } from "context/AppContext";
import { useHttp } from "hooks";

const EditDealerModal = (props) => {
  const {
    error,
    loading: savingDealer,
    sendHttpRequest: EditDealer,
  } = useHttp();
  const {
    partnerError,
    loading: getParnter,
    sendHttpRequest: GetPartner,
  } = useHttp();
  const { status, setShowAlert } = useContext(AppContext);
  const [DealerBranch, setDealerBranch] = useState({
    name: props?.name,
    email: props?.email,
    partner: props?.partner,
  });

  const [partners, setPartners] = useState([]);
  const EditNewDealer = async () => {
    console.log(DealerBranch);
    EditDealer(
      `update_dealer/${props.id}`,
      "PUT",
      {
        name: DealerBranch?.name,
        email: DealerBranch?.email,
        partnerId: DealerBranch?.partner,
      },
      () => {
        props.refresh();
        props.close();
        setShowAlert(true);
      }
    );
  };
  useEffect(() => {
    get_partnersFun();
  }, []);
  const get_partnersFun = () => {
    GetPartner("get_partners", "GET", null, ({ data: { partners } }) => {
      setPartners(partners);
    });
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setDealerBranch({ ...DealerBranch, [name]: value });
  };
  return (
    <Modal
      close={props.close}
      onAccept={() => EditNewDealer()}
      title="Edit Dealer"
      loading={savingDealer}
      size="sm"
    >
      <form onSubmit={(e) => e.preventDefault()} id="">
        <div className="form-group">
          <label>Name : </label>
          <input
            name="name"
            type="text"
            placeholder=""
            value={DealerBranch?.name}
            required
            onChange={onChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Email : </label>{" "}
          <input
            name="email"
            type="email"
            placeholder=""
            required
            value={DealerBranch?.email}
            onChange={onChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Select Parnter : </label>{" "}
          <select
            required
            placeholder="select a parnter"
            className={"form-control form-control-sm"}
            name="partner"
            value={DealerBranch?.partner}
            onChange={onChange}
          >
            <option value="" disabled>
              Select a Parnter
            </option>
            {partners?.map((patn) => (
              <option value={patn?._id} key={patn?._id}>
                {patn?.name}
              </option>
            ))}
          </select>
        </div>
      </form>
    </Modal>
  );
};

export default EditDealerModal;