import GeneralRoutes from "./general/GeneralRoutes";
import RegionRoutes from "./localities/LocalitiesRoutes";
import RepresentativesRoutes from "./representatives/RepresentativeRoutes";
import RMRoutes from "./rm-badili/RMRoutes";
import AddRMsRoutes from "./rms/AddRMs";
import DealersRoutes from "./dealers/DealersRoutes";
import PartnerRoutes from "./partners/PartnerRoutes";
import WarehousesRoutes from "./warehouses/WarehouseRoutes";
import OrderRoutes from "./orders/OrderRoutes";
import WarrantyRoutes from "./warranty/WarrantyRoutes";
import TotalDevicesRoutes from "./devices/TotalDevicesRoutes";
import SoldDevicesRoutes from "./solddevices/SoldDevicesRoutes";
import CouponRoutes from "./coupons/CouponRoutes";
import ShippingRoutes from "./shipping/ShippingRoutes";
import City from "./localities/CityRoutes";
import CityManager from "./localities/CityManagerRoutes";
import CourierRoutes from "./couriers/CouriersRoutes";
import ConsignmentRoutes from "./consignment/ConsignmentRoutes";
import CustomerRoutes from "./sms/SmsRoute";
import CampaignRoutes from "./campaign/CampaignRoutes";
import StoresRoutes from "./stores/StoresRoutes";
import ReturnRoutes from "./return/ReturnRoutes";
import SalesAgentRoutes from "./salesAgent/SalesAgentRoutes";
import SellOutHistoryRoutes from "./sellOutHistory/SellOutHistoryRoutes";
import ChannelsRoutes from "./Channels/ChannelsRoutes";

export const SalesRoutes = [
  ...CourierRoutes,
  ...ConsignmentRoutes,
  ...CityManager,
  ...City,
  ...GeneralRoutes,
  ...RegionRoutes,
  ...RepresentativesRoutes,
  ...DealersRoutes,
  ...SalesAgentRoutes,
  ...PartnerRoutes,
  ...WarehousesRoutes,
  ...OrderRoutes,
  ...WarrantyRoutes,
  ...CouponRoutes,
  ...ShippingRoutes,
  ...TotalDevicesRoutes,
  ...SoldDevicesRoutes,
  ...RMRoutes,
  ...AddRMsRoutes,
  ...CustomerRoutes,
  ...CampaignRoutes,
  ...StoresRoutes,
  ...ReturnRoutes,
  ...SellOutHistoryRoutes,
  ...ChannelsRoutes,
];
