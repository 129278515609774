import RMBadili from "./RMBadili";
import ListRMBadilis from './RMBadiliDetails'

const RMBadilisRoutes = [
  {
    path: "/rm-badili",
    element: <RMBadili />,
  },
  {
    path: "/rm-badili/:id",
    element: <ListRMBadilis />,
  },
];

export default RMBadilisRoutes;
