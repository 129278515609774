import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadBulk } from "../LeadService";

export function useUploadCSV() {
  const queryClient = useQueryClient();
  const {
    mutate: uploadingCSV,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: uploadBulk,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["leads"],
      });
    },
  });

  return { uploadingCSV, isLoading, error };
}
