import { useQuery } from "@tanstack/react-query";
import { getOrders } from "../OrderService";

export function useOrders() {
  const {
    data,
    isLoading: gettingOrders,
    error,
  } = useQuery({
    queryKey: ["orders"],
    queryFn: getOrders,
  });

  return { data, gettingOrders, error };
}
