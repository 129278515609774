import { useQuery } from "@tanstack/react-query";
import { getSoldDevices } from "../SoldDevicesService";

export function useInvetorySold() {
  const {
    data: soldDevices,
    isLoading,
    error,
  } = useQuery({
    queryFn: getSoldDevices,
    queryKey: ["soldDevices"],
  });

  return { soldDevices, isLoading, error };
}
