import React, { useEffect, useContext } from "react";
import { MainNavbar, Sidebar, PageHeader, Footer } from "../components";
import { Route, Routes } from "react-router-dom";
import { SalesRoutes } from "../app/modules/sales/SalesRoutes";
import ModalRoot from "components/modal/ModalRoot";
import { AppContext } from "context/AppContext";
import { useHttp } from "hooks";
// import '../assets/js/app.js'

const Dashboard = () => {
  const { setStatus } = useContext(AppContext);
  const {
    error: errorAuth,
    loading: loadingAuth,
    sendHttpRequest: getStatus,
  } = useHttp();

  useEffect(
    () =>
      getStatus("get_status", "GET", null, ({ data: { status } }) => {
        setStatus(status);
      }),
    []
  );

  return (
    <div>
      <MainNavbar />
      <ModalRoot />
      {/* Page content   */}
      <div className="page-content">
        <Sidebar />
        {/* Main content  */}
        <div className="content-wrapper">
          <PageHeader />
          {/* Content area */}
          <div className="content">
            <Routes>
              {SalesRoutes.map((item, i) => (
                <Route
                  key={i}
                  exact
                  path={item.path}
                  element={
                    <React.Suspense fallback={"Loading...."}>
                      {item.element}
                    </React.Suspense>
                  }
                />
              ))}
            </Routes>
          </div>
          {/* /content area */}
          <Footer />
        </div>
        {/* /main content   */}
      </div>
      {/* /page content   */}
    </div>
  );
};

export default Dashboard;
