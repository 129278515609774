import ListDealers from "./Dealers";
import DealerDetails from "./DealerDetails";
import AddDealer from './AddDealer'

const DealersRoutes = [
  {
    path: "/dealers",
    element: <ListDealers />,
  },
  {
    path: "/add-dealer",
    element: <AddDealer />,
  },
  {
    path: "/dealers/:id",
    element: <DealerDetails />,
  },
];

export default DealersRoutes;
