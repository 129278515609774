import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useQueryClient } from "@tanstack/react-query";
import { useToastContext, ADD } from "context/ToastContext";
import { Input } from "antd";
import { createCampaign } from "./CampaignService";
const { TextArea } = Input;

const AddCampaignModal = (props) => {
  const queryClient = useQueryClient();
  const { toastDispatch } = useToastContext();
  const [loading, setLoading] = useState(false);

  const [campaignDetails, setCampaignDetails] = useState({
    campaignName: "",
    to: "",
    message: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails({ ...campaignDetails, [name]: value });
  };

  async function handleSubmit() {
    setLoading(true);
    const data = {
      ...campaignDetails,
      to: campaignDetails.to === "" ? "All" : campaignDetails.to,
    };

    const { success, msg } = await createCampaign(data);

    if (success) {
      queryClient.invalidateQueries({
        queryKey: ["campaigns"],
      });
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    } else {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg || "Unexpected Error Occured. Try again!",
          type: "danger",
        },
      });
    }

    setLoading(false);
  }

  return (
    <Modal
      close={props.close}
      onAccept={() => handleSubmit()}
      title="New Campaign"
      form="campaignform"
      loading={loading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="campaignform">
        <div className="form-group">
          <label>Campaign Name</label>

          <input
            name="campaignName"
            type="text"
            placeholder=""
            onChange={onChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Message To:</label>
          <select
            className="form-control form-control-sm"
            onChange={onChange}
            name="to"
            required
            defaultValue={"All"}
          >
            <option>All</option>
            <option>Cold</option>
            <option>Warm</option>
            <option>Hot</option>
          </select>
        </div>

        <div
          className="form-group"
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <label>Message</label>
          <TextArea
            onChange={onChange}
            name="message"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddCampaignModal;
