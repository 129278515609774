import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Form, Select } from "antd";

const { Option } = Select;

const EditLocationModel = (props) => {
  const { toastDispatch } = useToastContext();
  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: ReturnToWip,
  } = useHttp();
  const [selectedLocation, setSelectedLocation] = useState(null);

  const assign = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      calculationId: props?.selectedRepairRequest,
      userId: loggedInUser?.id,
      storageLocationId: selectedLocation,
      flag: 'sales'
    };
    ReturnToWip("device_assign_storage_location", "Put", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };
  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title="Update Location"
      form="updateLocation"
      size="sm"
      loading={assigningWarehouse}
    >
      <br />
      <form onSubmit={(e) => e.preventDefault()} id="updateLocation">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Item
            label="Location"
            name="location"
            rules={[{ required: true, message: "Please select a location" }]}
          >
            <Select
              placeholder="Select a location"
              onChange={setSelectedLocation}
            >
              {props?.LocationData?.map((location) => (
                <Option key={location.value} value={location.value}>
                  {location.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </form>
    </Modal>
  );
};

export default EditLocationModel;
