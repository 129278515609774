import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { addCityManager } from "./LocalitiesServices";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AssignManagerModal = (props, val) => {
  const [savingManager, setSavingManager] = useState(false);
  const [cityDetails, setcityDetails] = useState({
    city: ""
  });
  const [cities, setCities] = useState([]);
  const { toastDispatch } = useToastContext();
  const { sendHttpRequest: getcities } = useHttp();

  const AddCityManager = async () => {
    setSavingManager(true);
    let data = {
      city: cityDetails.city,
      manager:val?.id
    };
    try {
      const { msg, success } = await addCityManager(data);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setSavingManager(false);
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setSavingManager(false);
      }
    } catch (err) {
      console.log(err);
      setSavingManager(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setcityDetails({ ...cityDetails, [name]: value });
  };

  useEffect(() => {
    getcities("get_all_cities", "GET", null, ({ data: { cities } }) =>
      setCities(cities)
    );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => AddCityManager()}
      title="Assign City to Manager"
      form="assignManagerForm"
      loading={savingManager}
    >
      <form onSubmit={(e) => e.preventDefault()} id="assignManagerForm">
        <div className="form-group">
          <label>Manager Name : </label>{" "}
          <span>
            <b>{val?.name}</b> 
          </span>
          {/* <input
            name="name"
            type="text"
            placeholder=""
            value={name}
            readOnly
            className="form-control"
          /> */}
        </div>

        <div className="form-group">
          <label>City Name</label>
          <select
            placeholder="select a city"
            className={"form-control form-control-sm"}
            name="city"
            onChange={(e) => {
              onChange(e);
            }}
          >
            <option value="">Select City</option>
            {cities?.map((resultVal) => (
              <option value={resultVal?._id} key={resultVal?._id}>
                {resultVal?.name}
              </option>
            ))}
          </select>
        </div>
      </form>
    </Modal>
  );
};

export default AssignManagerModal;
