export const getSoldDevices = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "get_inventory_sold", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};