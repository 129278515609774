import { useState, useEffect } from "react";
import * as d3 from "d3";
import dataa from "app/modules/sales/localities/counties.json";

const jsonUrl =
  "https://data.humdata.org/dataset/e66dbc70-17fe-4230-b9d6-855d192fc05c/resource/51939d78-35aa-4591-9831-11e61e555130/download/kenya.geojson";

const useDataApi = () => {
  const [data, setData] = useState(dataa);

  useEffect(() => {
    d3.select("body")
      .append("div")
      .attr("id", "tooltip")
      .attr("style", "position: absolute; opacity: 0");
  }, []);

  // useEffect(() => {
  //   const makeAPICall = async () => {
  //     const ls = localStorage.getItem(url);
  //     if (ls) {
  //       setData(JSON.parse(ls));
  //     } else {
  //       setIsLoading(true);
  //       try {
  //         const res = await fetch(url);
  //         const json = await res.json();
  //      
  //         setData([json]);
  //         localStorage.setItem(url, JSON.stringify([json]));
  //       } catch (err) {

  //       }
  //     }
  //     setIsLoading(false);
  //   };
  //   makeAPICall();
  // }, [url]);

  return data;
};

export default useDataApi;
