import React from "react";
const ModalComponent = ({
  children,
  title,
  close,
  onAccept,
  onReject,
  size = "md",
  AcceptText = "Submit",
  form,
  loading = false,
  footerOntop = false,
  invoiceDown = false,
  invoiceDownContent = false,
  renderButtons = (onAccept, onReject) => (
    <>
      {typeof onAccept === "function" && (
        <button
          type="submit"
          className="btn bg-primary ld-ext-right"
          onClick={onAccept}
          form={form}
          disabled={loading}
        >
          {AcceptText}

          {loading && (
            <>
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </>
          )}
        </button>
      )}
      {typeof onReject === "function" && (
        <button
          type="button"
          class="btn btn-link"
          data-dismiss="modal"
          onClick={onReject || close}
        >
          Cancel
        </button>
      )}
    </>
  ),
}) => {
  return (
    <div className="modal d-block">
      <div
        className={`modal-dialog modal-dialog-centered modal-${size}`}
        style={invoiceDown === true ? { maxWidth: "650px" } : {}}
      >
        <div
          className="modal-content"
          style={
            invoiceDownContent
              ? {
                  marginTop: "45px",
                  overflowY: "scroll",
                  padding: "10px",
                  height: "450px ",
                  border: "1px solid #ccc",
                }
              : {}
          }
        >
          {title && (
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <span className="close">
                {footerOntop ? renderButtons(onAccept, onReject) : ""}
              </span>
              {footerOntop === false ? (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={close}
                >
                  &times;
                </button>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="modal-body h-50">{children}</div>

          {footerOntop == false ? (
            <div className="modal-footer">
              {renderButtons(onAccept, onReject)}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
