import SoldDevicesList from "./SoldDevices";

const ProductRoutes = [
  {
    path: "/sold-devices",
    element: <SoldDevicesList />,
  },
];

export default ProductRoutes;
