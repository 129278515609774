import { useQuery } from "@tanstack/react-query";
import { getTelesales } from "../LeadService";

export function useAssignTelesale() {
  const {
    data,
    isFetching: isLoading,
    error,
  } = useQuery({
    queryKey: ["telesales"],
    queryFn: getTelesales,
  });

  return { data, isLoading, error };
}
