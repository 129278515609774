import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assignTelesales } from "../LeadService";

export function useAssignTelesales() {
  const queryClient = useQueryClient();
  const {
    mutate: assigningTelesales,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: assignTelesales,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["leads"],
      });
    },
  });

  return { assigningTelesales, isLoading, error };
}
