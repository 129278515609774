import Modal from "components/modal/Modal";
import { Select } from "antd";
import { useAssignTelesale } from "./hooks/useAssignTelesale";
import { useToastContext, ADD, REMOVE } from "context/ToastContext";

import { useAssignTelesales } from "./hooks/useAssignTelesales";

const AssignTelesaleModal = (props) => {
  const { data: telesales, isLoading } = useAssignTelesale();
  const { toastDispatch } = useToastContext();
  const { assigningTelesales, isLoading: isAssigningLeads } =
    useAssignTelesales();

  let newObj;

  const telesalesArrray = telesales?.users?.map((telesale) => ({
    value: telesale?._id,
    label: telesale?.fname + " " + telesale?.lname,
  }));

  const onChange = (telesale) => {
    newObj = { leads: props?.selectedBulk, telesaleId: telesale };
  };

  function handleSubmit() {
    assigningTelesales(newObj, {
      onSuccess: (data) => {
        if (data?.success === true) {
          toastDispatch({
            type: ADD,
            payload: {
              content: data?.msg,
              type: "success",
            },
          });

          props.close();
          props.refresh();
        } else {
          toastDispatch({
            type: ADD,
            payload: {
              content: data?.msg,
              type: "danger",
            },
          });
        }
      },
    });
  }

  return (
    <Modal
      close={props.close}
      onReject={() => {
        props.close();
      }}
      onAccept={() => handleSubmit()}
      title={`Assign to Telesale`}
      form="assignTelesale"
      loading={isAssigningLeads}
      size="sm"
    >
      <form onSubmit={(e) => e.preventDefault()} id="telesalesForm">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Select and assign a Telesale.</label>
          <Select
            disabled={isLoading}
            style={{ width: "50%", height: "3rem" }}
            showSearch
            placeholder="Select a Telesale"
            optionFilterProp="children"
            onChange={onChange}
            options={telesalesArrray}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AssignTelesaleModal;
