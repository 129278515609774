import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Loader } from "components";
import { useHttp } from "hooks";
import AssignManagerModal from "./AssignManagerModal";
import ModalService from "components/modal/ModalService";

const ListCityManager = () => {
  const [cityManager, setCityManager] = useState([]);
  const { loading, sendHttpRequest: getCityManager } = useHttp();

  const getCityManagerData = () => {
    getCityManager("get_city_managers", "GET", null, ({ data: { users } }) => {
      setCityManager(users);
    });
  };
  useEffect(() => {
    getCityManagerData();
  }, []);
  const openModal = (val) => {
    ModalService.open((e) => AssignManagerModal(e, val), {
      refresh: () => getCityManagerData(),
    });
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">City Manager</h4>
            <div className="header-elements"></div>
          </div>
          <div className="card-body">
            <>
              <div className="card-header header-elements-sm-inline">
                <h6 className="card-title">Active City Managers</h6>
              </div>
              {cityManager.length === 0 && "No available cityManager"}
              {cityManager.length !== 0 && (
                <div className="table-responsive">
                  <table className="table text-nowrap">
                    <thead>
                      <tr>
                        <th className="font-weight-bold">Name</th>
                        <th className="font-weight-bold">Email</th>
                        <th className="font-weight-bold">Phone</th>
                        <th className="font-weight-bold">City</th>
                        <th className="font-weight-bold">Created Date</th>
                        <th className="font-weight-bold">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-active table-border-double">
                        <td colSpan={6}>Total City Manager</td>
                        <td className="text-right font-weight-bold">
                          <span className="badge badge-primary badge-pill">
                            {cityManager.length}
                          </span>
                        </td>
                      </tr>
                      {cityManager.map((valManager) => (
                        <tr key={valManager?._id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                {valManager?.fname + " " + valManager?.lname}
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-muted">
                              {valManager?.email}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {valManager?.phone}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {valManager?.city.length !== 0 ? valManager?.city[0]?.name :'-'}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {format(new Date(valManager?.createdAt), "PPpp")}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                valManager?.active
                                  ? "bg-success-400"
                                  : "bg-danger"
                              }`}
                            >
                              {valManager?.active ? "Active" : "InActive"}
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="list-icons">
                              <div className="list-icons-item dropdown">
                                <a
                                  href="#"
                                  className="list-icons-item dropdown-toggle caret-0"
                                  data-toggle="dropdown"
                                >
                                  <i className="icon-menu7" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <a
                                    href="#"
                                    onClick={() =>
                                      openModal({name:valManager?.fname +
                                        " " +
                                        valManager?.lname,id:valManager?._id}
                                        )
                                    }
                                    className="dropdown-item"
                                  >
                                    <i className="icon-pencil4" /> Assign City
                                    Manager
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default ListCityManager;
