import React, { useEffect, useState, useContext } from "react";
import { getSinglePartner } from "./PartnersService";
import { useParams } from "react-router";
import AddPartnerBranchModal from "./AddPartnerBranches";
import EditPartnerModal from "./EditPartnerModal";
import DeleteStoreModal from "./DeleteStoreModal";
import ModalService from "components/modal/ModalService";
import { getAllBranchesByPartner } from "./PartnersService";
import { Loader } from "components";

import { format } from "date-fns";

const PartnerDetails = () => {
  const [partnerDetails, setPartnerDetails] = useState({});
  const [partnerBranches, setPartnerBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
 

  const getPartnerDetails = async () => {
    setLoading(true);
    try {
      const {
        success: branchSuccess,
        msg: branchMsg,
        partner_location,
      } = await getAllBranchesByPartner(id);
      const { success, msg, partner } = await getSinglePartner(id);

      if (success) {
        setPartnerDetails(partner);
        setPartnerBranches(partner_location);
        setLoading(false);
      } else if (branchSuccess) {
        setPartnerBranches(partner_location);
        setLoading(false);
      } else if (!success) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPartnerDetails();
  }, []);

  const openModal = () => {
    ModalService.open(AddPartnerBranchModal, {
      refresh: () => getPartnerDetails(),

      id,
    });
  };
  const openDelStoreModal = (name, id) => {
    ModalService.open(DeleteStoreModal, {
      refresh: () => getPartnerDetails(),
      name,
      id,
    });
  };
  const openEditModal = () => {
    ModalService.open(EditPartnerModal, {
      refresh: () => getPartnerDetails(),
      details: partnerDetails,
    });
  };

  return (
    <>
  
      {loading &&
        !partnerDetails &&
        partnerBranches.length === 0 &&
        <Loader/>}
      {!loading && partnerDetails && (
        <div className="d-flex align-items-start flex-column flex-md-row">
          <div className="w-100 overflow-auto order-2 order-md-1">
            <div className="card">
              <div className="card-header header-elements-md-inline">
                <h5 className="card-title">{partnerDetails.name}</h5>
                <div className="header-elements">
                  <a href="#" onClick={openEditModal}>
                    <i className="icon-pencil4 " /> Edit
                  </a>
                </div>
              </div>
              <div className="nav-tabs-responsive bg-light border-top">
                <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
                  <li className="nav-item">
                    <a
                      href="#branches"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      <i className="icon-menu7 mr-2" /> Stores
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="branches">
                  <div className="card-body">
                    <div className="mt-1 mb-4">
                      <table className="table text-nowrap">
                        <thead>
                          <tr>
                            <th>Store Name</th>
                            <th>Region</th>
                            <th>Created Date</th>
                            <th>Status</th>
                            <th className="text-center" style={{ width: 20 }}>
                              <i className="icon-arrow-down12" />
                            </th>
                          </tr>
                        </thead>
                        {partnerBranches.length === 0 && "No stores available"}
                        {partnerBranches.length !== 0 && (
                          <tbody>
                            {partnerBranches.map((branch) => (
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="mr-3">
                                      <a href="#">
                                        {/* <img
                                          src="../../../../global_assets/images/brands/bing.png"
                                          className="rounded-circle"
                                          alt
                                          width={32}
                                          height={32}
                                        /> */}
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="#"
                                        className="text-default font-weight-semibold"
                                      >
                                        {branch.name}
                                      </a>
                                      <div className="text-muted font-size-sm">
                                        <span className="badge badge-mark border-success mr-1" />
                                        <a href="#">view exact location</a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="text-muted">
                                    {branch.region}
                                  </span>
                                </td>
                                <td>
                                  <span className="">
                                    {format(new Date(branch.createdAt), "PPpp")}
                                  </span>
                                </td>

                                <td>
                                  <span
                                    className={`badge ${
                                      branch.active
                                        ? "bg-success-400"
                                        : "bg-danger"
                                    }`}
                                  >
                                    {branch.active ? "Active" : "In-active"}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className="list-icons">
                                    <div className="list-icons-item dropdown">
                                      <a
                                        href="#"
                                        className="list-icons-item dropdown-toggle caret-0"
                                        data-toggle="dropdown"
                                      >
                                        <i className="icon-menu7" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#" className="dropdown-item">
                                          <i className="icon-file-stats" /> View
                                          Store
                                        </a>
                                        <a href="#" className="dropdown-item">
                                          <i className="icon-file-text2" /> Edit
                                          Store
                                        </a>
                                        <a
                                          href="#"
                                          className="dropdown-item"
                                          onClick={() =>
                                            openDelStoreModal(
                                              branch.name,
                                              branch._id
                                            )
                                          }
                                        >
                                          <i className="icon-trash-alt" />{" "}
                                          Delete Store
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="orders">
                  <div className="card-body">
                    <div className="schedule" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sidebar sidebar-light ml-md-2 sidebar-component sidebar-component-right border-0 shadow-0 order-1 order-md-2 sidebar-expand-md"
            style={{ zIndex: 1 }}
          >
            <div className="sidebar-content">
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    At a glance
                  </span>
                </div>
                <div className="card-body pb-0">
                  <button
                    onClick={openModal}
                    className="btn bg-teal-400 btn-block mb-2"
                  >
                    New Store
                  </button>
                </div>
                <table className="table table-borderless table-xs border-top-0 my-2">
                  <tbody>
                    <tr>
                      <td className="font-weight-semibold">Stores:</td>
                      <td className="text-right">{partnerBranches.length}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Status:</td>
                      <td className="text-right">
                        <span className="badge bg-success">Active</span>
                      </td>
                    </tr>

                    <tr>
                      <td className="font-weight-semibold">Contacts:</td>
                      <td className="text-right">0712213456</td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Email:</td>
                      <td className="text-right">email@test.com</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerDetails;
