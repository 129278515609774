import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import EditSpecsModal from "./EditSpecifications";
import ModalService from "components/modal/ModalService";
import { DropzoneArea } from "react-mui-dropzone";
import { makeStyles } from "@mui/styles";
import { AppContext } from "context/AppContext";
import { useToastContext, ADD } from "context/ToastContext";
import ConfirmPublishModal from "./ConfirmPublishModal";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { getFileFromUrl } from "helpers/getFileFromUrl";
import { Loader } from "components";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHttp } from "hooks";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "& .MuiDropzoneArea-root": {
      border: "2px dashed #ddd",
      padding: "1.2rem",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: ".8125rem",
      marginTop: "1.5rem",
    },
    "& .MuiDropzoneArea-icon": {
      height: "25px",
      width: "25px",
    },
  },
});

const SingleProductConfig = () => {
  const [product, setProduct] = useState();
  // const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [initialImages, setInitialImages] = useState([]);
  const [images, setImages] = useState([]);
  const [configureBasePrice, setConfigureBasePrice] = useState(false);
  const [updatePrice, setUpdatedPrice] = useState({
    saleprice: "",
    discounted: "",
    wsp: "",
  });
  const [isEditingImages, setIsEditingImages] = useState(false);
  const navigate = useNavigate();
  const [isEditingPrice, setIsEditingPrice] = useState("");
  const [basePrice, setBasePrice] = useState(0);
  const { id } = useParams();
  const classes = useStyles();
  const { toastDispatch } = useToastContext();

  const {
    error: errorAuth,
    loading: isUploading,
    sendHttpRequest: addEcomImages,
  } = useHttp();

  const {
    error: error,
    loading,
    sendHttpRequest: getSingleModelConfig,
  } = useHttp();
  const {
    error: errorUpdatingPrice,
    loading: isUpdatingPrice,
    sendHttpRequest: addPrice,
  } = useHttp();
  const {
    error: errorUpdatingBasePrice,
    loading: isUpdatingBasePrice,
    sendHttpRequest: addBasePrice,
  } = useHttp();
  const {
    error: errorUpdatingPriority,
    loading: isUpdatingPriority,
    sendHttpRequest: markVariantAsPriority,
  } = useHttp();

  const getSingleProductInven = async () => {
    getSingleModelConfig(
      `get_full_device/${id}`,
      "GET",
      null,
      ({ data: { payload, inventory } }) => {
        let tempImg = [];
        for (var i = 0; i < payload.length; i++) {
          let file = getFileFromUrl(payload[i].upload, `image${i + 1}`);
          tempImg.push(file);
        }

        setInitialImages(tempImg);
        setUploadedImages(payload);
        setProduct(inventory);
      }
    );
  };

  const handleFileChange = (images) => {
    setImages(images);
  };

  const openHighlightsModal = (details) => {
    ModalService.open(EditSpecsModal, {
      refresh: () => getSingleProductInven(),
      title: `Edit Specifications`,
      details,
      dataKey: "highlights",
      modelId: product.details.modelId,
    });
  };

  const confirmPublishModal = () => {
    ModalService.open(ConfirmPublishModal, {
      refresh: () => getSingleProductInven(),
      product,
      publish: "publish",
    });
  };
  const confirmBestSellerModal = () => {
    ModalService.open(ConfirmPublishModal, {
      refresh: () => getSingleProductInven(),
      product,
      bestseller: "bestseller",
    });
  };
  const confirmFeaturedModal = () => {
    ModalService.open(ConfirmPublishModal, {
      refresh: () => getSingleProductInven(),
      product,
      featured: "featured",
    });
  };
  const confirmFavoriteModal = () => {
    ModalService.open(ConfirmPublishModal, {
      refresh: () => getSingleProductInven(),
      product,
      favorite: "favorite",
    });
  };

  // const mapColors = (str) => {
  //   if (str) {
  //     var colors = str.split(",");

  //     for (var i = 0; i < colors.length; i++) {
  //       // Trim the excess whitespace.
  //       colors[i] = colors[i].replace(/^\s*/, "").replace(/\s*$/, "");
  //       return colors;
  //     }
  //   }
  // };

  const onPriceChange = (e) => {
    const { name, value } = e.target;
    setUpdatedPrice({ ...updatePrice, [name]: value });
  };
  const onBasePriceChange = (e) => {
    setBasePrice(e.target.value);
  };

  const addImages = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    for (const image of images) {
      formData.append("files", image);
    }

    formData.append("modelId", product.details._id);

    addEcomImages(
      "configure_device_model_uploads",
      "POST",
      formData,
      ({ data: { id } }, msg) => {
        getSingleProductInven();
        setIsEditingImages(false);
      },
      { "Content-Type": "" }
    );
  };

  useEffect(() => {
    getSingleProductInven();
  }, []);

  const editPrice = (id) => {
    setIsEditingPrice(id);
  };
  const addNewPrice = async (id) => {
    addPrice(`update_variant/${id}`, "PUT", updatePrice, ({ msg }) => {
      setIsEditingPrice("");
      getSingleProductInven();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };
  const prioritizeVariant = async (id, discounted, saleprice) => {
    if (discounted && saleprice) {
      markVariantAsPriority(
        `update_priority_variant/${id}`,
        "PUT",
        null,
        ({ msg }) => {
          getSingleProductInven();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        }
      );
    }
  };
  const addNewbasePrice = async () => {
    let body = {
      baseprice: Number(basePrice),
    };
    addBasePrice(`update_pricing/${product.details._id}`, "PUT", body, () => {
      getSingleProductInven();
      setBasePrice(0);
      setConfigureBasePrice(false);
    });
  };

  return (
    <div>
      {loading && <Loader />}
      {/* {!loading && "No product available"} */}
      {!loading && product && (
        <div className={` card ${classes.root}`}>
          <div className="card-header header-elements-md-inline">
            <div>
              {" "}
              <button
                className="btn btn-sm bg-transparent ml-auto "
                onClick={() => navigate(-1)}
              >
                <i className="icon-circle-left2 text-teal-400 mr-2" /> Back
              </button>
            </div>
            <h3 className="card-title">
              {`Model ${product.details.modelName} configuration`}
            </h3>

            <div className="header-elements">
              {product.details.bestseller && (
                <>
                  {/* <div className="mr-2">Best Seller:</div> */}
                  <span className="mr-2">
                    <i className="icon-star-full2 font-size-base text-purple" />
                    <i className="icon-star-full2 font-size-base text-purple" />
                    <i className="icon-star-full2 font-size-base text-purple" />
                    <i className="icon-star-full2 font-size-base text-purple" />
                    <i className="icon-star-full2 font-size-base text-purple" />
                  </span>
                </>
              )}
              {product.details.featured && (
                <span className="badge badge-info  mr-2">Featured Model</span>
              )}
              {product.details.favorite && (
                <i className="icon-heart5 text-danger mr-2" />
              )}
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-sm bg-teal-400 btn-labeled btn-labeled-left dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <b>
                    <i className="icon-list-unordered" />
                  </b>{" "}
                  More Actions
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button
                    className="dropdown-item bg-transparent border-0 outline-0"
                    onClick={() =>
                      confirmPublishModal(product.details.modelName)
                    }
                  >
                    <i
                      className={`${
                        product.details.published
                          ? "icon-file-minus text-danger"
                          : "icon-file-plus text-success"
                      }`}
                    />
                    {product.details.published
                      ? "Unpublish Model"
                      : "Publish Model"}
                  </button>
                  <button
                    className="dropdown-item bg-transparent border-0 outline-0"
                    onClick={() =>
                      confirmFeaturedModal(product.details.modelName)
                    }
                  >
                    <i className="icon-medal text-info" />
                    {product.details.featured
                      ? "Unmark as Featured"
                      : "Mark as Featured"}
                  </button>

                  <button
                    className="dropdown-item bg-transparent border-0 outline-0"
                    onClick={() =>
                      confirmBestSellerModal(product.details.modelName)
                    }
                  >
                    <i className="icon-stars text-purple" />
                    {product.details.bestseller
                      ? "Unmark as Bestseller"
                      : "Mark as Bestseller"}
                  </button>
                  <button
                    className="dropdown-item bg-transparent border-0 outline-0"
                    onClick={() =>
                      confirmFavoriteModal(product.details.modelName)
                    }
                  >
                    <i className="icon-heart5 text-danger" />
                    {product.details.favorite
                      ? "Unmark as Favorite"
                      : "Mark as Favorite"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mt-md-3">
              <div className="col-md-3">
                <img
                  src={product.image}
                  alt="device"
                  style={{ width: "100%", objectFit: "cover" }}
                />
              </div>
              <div className="col-md-1" />
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="font-weight-bold">Available Variants</h6>
                    {
                      <ul className="list list-unstyled">
                        {product.details.variants?.map((variant) => (
                          <li key={variant._id}>
                            <i className="icon-checkmark3 text-success mr-2" />
                            {`${variant?.storage} GB/${variant?.ram}GB`}-
                            {
                              <span className="text-capitalize">
                                {variant.color}
                              </span>
                            }
                          </li>
                        ))}
                      </ul>
                    }

                    <h6 className="font-weight-bold">Colors available </h6>
                    {!product?.details?.specs?.highlights ||
                    !product?.details?.specs?.highlights?.colors ||
                    product.details.specs.highlights.colors.length === 0 ? (
                      <p>No added colors for this model</p>
                    ) : (
                      ""
                    )}

                    {product.details.specs && (
                      <ul className="list list-unstyled ">
                        {product.details.specs.highlights.colors &&
                          Array.isArray(
                            product.details.specs.highlights.colors
                          ) &&
                          product.details.specs.highlights.colors.map(
                            (color) => (
                              <li key={color}>
                                <span
                                  className="badge rounded-circle badge-icon d-inline-block"
                                  style={{ background: color?.hex }}
                                />{" "}
                                {"  "}
                                {color.label}
                              </li>
                            )
                          )}
                      </ul>
                    )}
                    {/* <h6 className="font-weight-bold">
                      Base Price{" "}
                      <span className="mr-1">
                        {" "}
                        <button
                          className="font-size-xs bg-transparent border-0 outline-0"
                          onClick={() => setConfigureBasePrice(true)}
                        >
                          <i
                            className="icon-pencil text-primary"
                            style={{ fontSize: ".75rem" }}
                          />
                        </button>
                      </span>
                    </h6> */}
                    {/* {!configureBasePrice && (
                      <p>
                        {product.details.baseprice === 0 &&
                          "Base Price has not been configured"}
                        {product.details.baseprice !== 0 &&
                          thousandCommaSeparator(product.details.baseprice)}
                      </p>
                    )} */}
                    {configureBasePrice && (
                      <div className="form-group form-group-feedback form-group-feedback-right mb-4">
                        <input
                          type="text"
                          className="form-control"
                          name="baseprice"
                          placeholder={`${thousandCommaSeparator(
                            product.details.baseprice
                              ? product.details.baseprice
                              : 0
                          )}`}
                          onChange={onBasePriceChange}
                          value={basePrice}
                        />
                        <div className="form-control-feedback">
                          <button
                            className="bg-transparent border-0 outline-0"
                            onClick={() => {
                              setConfigureBasePrice(false);
                              setBasePrice(0);
                            }}
                          >
                            <i class="icon-x text-danger"></i>
                          </button>
                          {isUpdatingBasePrice && (
                            <button className="bg-transparent border-0 outline-0">
                              <i className="icon-spinner2 spinner text-primary"></i>
                            </button>
                          )}
                          {!isUpdatingBasePrice && (
                            <button
                              className="bg-transparent border-0 outline-0"
                              onClick={addNewbasePrice}
                            >
                              <i class="icon-floppy-disk  text-success"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <h6 className="font-weight-bold">
                      Device Specifications{" "}
                      <span className="mr-1">
                        {" "}
                        <button
                          className="font-size-xs bg-transparent border-0 outline-0"
                          onClick={() => openHighlightsModal(product)}
                        >
                          <i
                            className="icon-pencil text-primary"
                            style={{ fontSize: ".75rem" }}
                          />
                        </button>
                      </span>
                    </h6>
                    {product.details.specs &&
                      product.details.specs.highlights && (
                        <ul className="list list-unstyled">
                          <li className="mb-2">
                            <i className="icon-iphone  mr-2 " />
                            {product.details.specs.highlights.os}
                          </li>
                          <li className="mb-2">
                            <i className="icon-arrow-resize7 mr-2" />
                            {product.details.specs.highlights.connectivity}
                          </li>
                          <li className="mb-2">
                            <i className="icon-chip mr-2" />
                            {product.details.specs.highlights.processor}
                          </li>
                          <li className="mb-2">
                            <i className="icon-checkbox-unchecked2 mr-2" />
                            {product.details.specs.highlights.display}
                          </li>
                          <li className="mb-2">
                            <i className="icon-shutter mr-2" />
                            {product.details.specs.highlights.main_camera}
                          </li>
                          <li className="mb-2">
                            <i className="icon-battery-charging mr-2" />
                            {product.details.specs.highlights.battery}
                          </li>
                        </ul>
                      )}
                    {!product.details.specs && (
                      <p>No specifications added for this model</p>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <h6 className="font-weight-bold">Variant Price </h6>
                    <Formik
                      enableReinitialize
                      initialValues={updatePrice}
                      validationSchema={Yup.object().shape({
                        discounted: Yup.number()
                          .required("Required")
                          .moreThan(0, "Should not be zero or less than zero")
                          .lessThan(
                            Yup.ref("saleprice"),
                            "Should be less than Sale Price"
                          ),

                        saleprice: Yup.number()
                          .required("Required")
                          .moreThan(0, "Should not be zero or less than zero"),
                        wsp: Yup.number()
                          .required("Required")
                          .moreThan(0, "Should not be zero or less than zero"),
                      })}
                      onSubmit={() => addNewPrice(isEditingPrice)}
                    >
                      {({ errors, status, touched }) => (
                        <Form>
                          <div className="table-responsive">
                            <table className="table text-nowrap">
                              <thead>
                                <tr>
                                  <th>Variant</th>

                                  <th className=" text-center">Priority</th>
                                  <th>Sale Price</th>
                                  <th>RRP</th>
                                  <th>WSP</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {product.details.variants.map((variant) => (
                                  <>
                                    <tr>
                                      <td className="px-1">{`${variant.storage}GB/${variant.ram}GB`}</td>

                                      <td className="px-1 text-capitalize text-center">
                                        {variant.priority ? (
                                          <span className="text-success">
                                            Yes
                                          </span>
                                        ) : (
                                          <span className="text-primary">
                                            No
                                          </span>
                                        )}
                                      </td>
                                      <td className="px-1  ">
                                        <span className="font-size-sm">
                                          {isEditingPrice === variant._id && (
                                            <>
                                              <input
                                                type="text"
                                                name="saleprice"
                                                className={
                                                  "form-control" +
                                                  (errors.saleprice &&
                                                  touched.saleprice
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                value={updatePrice.saleprice}
                                                placeholder={`${thousandCommaSeparator(
                                                  variant.saleprice
                                                )}`}
                                                onChange={onPriceChange}
                                              />
                                              <ErrorMessage
                                                name="saleprice"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </>
                                          )}{" "}
                                          {isEditingPrice !== variant._id &&
                                            thousandCommaSeparator(
                                              variant.saleprice
                                            )}
                                        </span>
                                      </td>

                                      <td className="px-1  ">
                                        {isEditingPrice === variant._id && (
                                          <>
                                            <input
                                              type="text"
                                              name="discounted"
                                              value={updatePrice.discounted}
                                              className={
                                                "form-control" +
                                                (errors.discounted &&
                                                touched.discounted
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder={`${thousandCommaSeparator(
                                                variant.discounted
                                              )}`}
                                              onChange={onPriceChange}
                                            />
                                            <ErrorMessage
                                              name="discounted"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </>
                                        )}

                                        {isEditingPrice !== variant._id &&
                                          thousandCommaSeparator(
                                            variant.discounted
                                          )}
                                      </td>
                                      <td className="px-1  ">
                                        {isEditingPrice === variant._id && (
                                          <>
                                            <input
                                              type="text"
                                              name="wsp"
                                              value={setUpdatedPrice.wsp}
                                              className={
                                                "form-control" +
                                                (errors.wsp && touched.wsp
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder={`${thousandCommaSeparator(
                                                variant.wsp
                                              )}`}
                                              onChange={onPriceChange}
                                            />
                                            <ErrorMessage
                                              name="wsp"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </>
                                        )}

                                        {isEditingPrice !== variant._id &&
                                          thousandCommaSeparator(variant.wsp)}
                                      </td>
                                      <td>
                                        {isEditingPrice === variant._id && (
                                          <>
                                            <button
                                              className="font-size-xs bg-transparent border-0 outline-0"
                                              onClick={() => editPrice("")}
                                            >
                                              <i
                                                className="icon-x text-danger"
                                                style={{ fontSize: ".95rem" }}
                                              />
                                            </button>
                                            <button
                                              className="font-size-xs bg-transparent border-0 outline-0"
                                              type="submit"
                                              // onClick={() =>
                                              //   addNewPrice(variant._id)
                                              // }
                                              disabled={isUpdatingPrice}
                                            >
                                              {!isUpdatingPrice && (
                                                <i
                                                  className="icon-floppy-disk text-success"
                                                  style={{ fontSize: ".95rem" }}
                                                />
                                              )}
                                              {isUpdatingPrice && (
                                                <i
                                                  className="icon-spinner2 spinner text-primary"
                                                  style={{ fontSize: ".95rem" }}
                                                />
                                              )}
                                            </button>
                                            {!variant.priority && (
                                              <button
                                                className="font-size-xs bg-transparent border-0 outline-0"
                                                onClick={() =>
                                                  prioritizeVariant(
                                                    variant._id,
                                                    variant.discounted,
                                                    variant.saleprice
                                                  )
                                                }
                                                disabled={isUpdatingPriority}
                                              >
                                                {!isUpdatingPriority && (
                                                  <i
                                                    className="icon-bookmark3 text-warning"
                                                    style={{
                                                      fontSize: ".95rem",
                                                    }}
                                                  />
                                                )}
                                                {isUpdatingPriority && (
                                                  <i
                                                    className="icon-spinner2 spinner text-primary"
                                                    style={{
                                                      fontSize: ".95rem",
                                                    }}
                                                  />
                                                )}
                                              </button>
                                            )}
                                          </>
                                        )}
                                        {isEditingPrice !== variant._id && (
                                          <span className="mr-1">
                                            {" "}
                                            <button
                                              className="font-size-xs bg-transparent border-0 outline-0"
                                              onClick={() =>
                                                editPrice(variant._id)
                                              }
                                            >
                                              <i
                                                className="icon-pencil text-primary"
                                                style={{ fontSize: ".95rem" }}
                                              />
                                            </button>
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>

                  <div className="col-md-12 mt-3">
                    <h5 className="font-weight-bold mb-3">
                      Ecommerce Images
                      {!isEditingImages && (
                        <span className="mr-1">
                          {" "}
                          <button
                            className="font-size-xs bg-transparent border-0"
                            onClick={() => setIsEditingImages(true)}
                          >
                            <i
                              className="icon-pencil text-primary"
                              style={{ fontSize: ".75rem" }}
                            />
                          </button>
                        </span>
                      )}
                    </h5>
                    {isEditingImages && (
                      <form
                        className="form-validate-jquery"
                        onSubmit={addImages}
                        noValidate="novalidate"
                      >
                        <div>
                          <DropzoneArea
                            onChange={handleFileChange}
                            filesLimit={5}
                            initialFiles={initialImages}
                          />
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-3">
                          <button
                            onClick={() => setIsEditingImages(false)}
                            className="btn btn-sm btn-danger ml-3 legitRipple"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm btn-primary ml-3 legitRipple  ld-ext-right"
                            disabled={isUploading}
                          >
                            {isUploading && (
                              <>
                                <div className="lds-ring mr-2">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                                Please wait...
                              </>
                            )}
                            {!isUploading && <>Upload Images </>}
                          </button>
                        </div>
                      </form>
                    )}
                    {!isEditingImages && (
                      <div className="row">
                        {uploadedImages &&
                          uploadedImages.map((image, i) => (
                            <div className="col-md-2 mb-3" key={i}>
                              <img
                                src={image.upload}
                                alt="device"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          ))}

                        {uploadedImages.length === 0 && (
                          <p className="pl-2">No images available</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleProductConfig;
