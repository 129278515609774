import { headers } from "../../../../../../helpers/apiConfigs";

export async function pushToWIPRTS({ inventoryId, teamLeaderId, status }) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `return_device_wip`,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          inventoryId: inventoryId,
          teamleaderId: teamLeaderId,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function pushToRTS(inventoryId) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `return_device_rts`,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          inventoryId: inventoryId,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
export async function pushBackToSold(inventoryId) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + `return_from_returns_to_sold`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          inventoryId: inventoryId,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
export async function getReturns() {
  try {
    var response = await fetch(
      process.env.REACT_APP_BASE_URL + `get_returned_devices`,
      {
        method: "GET",
        headers: headers,
      }
    );

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
