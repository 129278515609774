import ListProducts from "./products/ListProducts";

const ReturnRoutes = [
  {
    path: "/returns",
    element: <ListProducts />,
  },
];

export default ReturnRoutes;
