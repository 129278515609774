import React, { useState, useEffect } from "react";
import ModalService from "components/modal/ModalService";
import AddChannelsModal from "./AddChannelsModal";
import { format } from "date-fns";
import { Loader, Table } from "components";
import { useHttp } from "hooks";

const Channels = () => {
  const [channelData, setChannels] = useState([]);
  const { error, loading, sendHttpRequest: getChannels } = useHttp();
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setRowCount(channelData?.length);
    setProductsRowCount(channelData?.length);
  }, [channelData]);
  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };
  const getAllChannels = () => {
    getChannels("get_channel", "GET", null, ({ data: { channels } }) =>
      setChannels(channels)
    );
  };
  const addCourierModal = () => {
    ModalService.open(AddChannelsModal, {
      refresh: () => getAllChannels(),
    });
  };
  useEffect(() => {
    getAllChannels();
  }, []);
  const columns = [
    {
      label: "NAME",
      name: "name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ fontWeight: "bolder" }}> {value ? value : "-"}</p>;
        },
      },
    },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              {" "}
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {rowProductsCount}
                </h3>
                <span>Total Channels</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="dt-button buttons-pdf buttons-html5 btn bg-blue legitRipple "
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                onClick={() => addCourierModal()}
              >
                <span>New Channel</span>
              </button>
            </div>{" "}
          </div>
          <Table
            title={"Channels"}
            data={channelData}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default Channels;
