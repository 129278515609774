import React from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const SetAsUnsoldModal = (props) => {
  const queryClient = useQueryClient();
  const { toastDispatch } = useToastContext();
  const { loading, sendHttpRequest: toggleSoldDevice } = useHttp();
  const navigate = useNavigate();

  const toggleUnSold = async () => {
    let data = {
      sold: false,
    };

    toggleSoldDevice(`toggle_sold/${props.id}`, "PUT", data, ({ msg }) => {
      navigate("/sales/returns");
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      queryClient.invalidateQueries({
        queryKey: ["soldDevices"],
      });
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => toggleUnSold()}
      title="Mark as Returned"
      form="set_as_unsold"
      size="sm"
      loading={loading}
    >
      <p>
        Do you want to mark <span className="text-danger">{props?.name}</span>{" "}
        as returned?
      </p>
    </Modal>
  );
};

export default SetAsUnsoldModal;
