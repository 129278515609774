import ListCouriers from "./Couriers";
//import CouponDetails from "./CouponDetails"

const CouponRoutes = [
  {
    path: "/couriers",
    element: <ListCouriers />,
  },
//   {
//     path: "/couriers/:id",
//     element: <CourierDetails />,
//   },
];

export default CouponRoutes;
