import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Input } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

;

const MultiSelect = ({array=[]}) => {
  const [state, setState] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

  

   

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o._id === item._id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x._id === item._id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setState(duplicateRemoved);
  };
  return (
    <div>
      {" "}
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Color</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={state}
          onChange={handleChange}
          input={<Input label="Colors" />}
          renderValue={(selected) => selected.map((x) => x.name).join(", ")}
          MenuProps={MenuProps}
        >
          {array.map((color) => (
            <MenuItem key={color._id} value={color}>
              <Checkbox
                checked={
                  state.findIndex((item) => item._id === color._id) >= 0
                }
              />
              <ListItemText primary={color.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultiSelect;
