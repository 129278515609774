import styled from "styled-components";

export const ToastWrapper = styled.div`
  position: fixed;
  top: 0px;
  right: 10px;
  width: 300px;
  max-height: 90vh;
  z-index: 4000;
  /* overflow-y: scroll; */
  .toast-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .toast-container-item {
    border: #1652f0 solid 1px;
    margin: 5px 0px;
    border-radius: 4px;
    width: 100%;
    min-height: 100px;
    word-wrap: break-word;
    background-color: #fff;
    box-shadow: 4px 4px 15px 2px rgba(black, 0.75);
    color: #212121;
    transition: 0.2s;
    z-index: 4000;
  }
  .toast-container-item:not(:first-child) {
    margin-top: -3rem;
    z-index: 6000;
  }

  .toast-container-item:hover ~ .toast-container-item,
  .toast-container-item:focus-within ~ .toast-container-item {
    transform: translateY(3rem);
  }

  .toast-container-item.info {
    border: #1652f0 solid 1px;
    background-color: #154de0;
  }
  .toast-container-item.danger {
    border: #d9605a solid 1px;
    background-color: #d9605a;
  }
  .toast-close {
    cursor: pointer;
    position: relative;
    top: 5px;
    font-size: 20px;
    font-weight: 800;
  }
`;
