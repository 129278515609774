import Campaign from "./Campaign";

const CampaignRoutes = [
  {
    path: "/sms",
    element: <Campaign />,
  },
];

export default CampaignRoutes;
