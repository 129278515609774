import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { addCourier } from "./CouriersService";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AddCourierModal = (props) => {
   const [loading, setLoading] = useState(false);
  const [savingCourier, setSavingCourier] = useState(false);
  const [courierDetails, setCourierDetails] = useState({
    name: "",
    
  });
  
  const { toastDispatch } = useToastContext();


  const addNewCourier = async () => {
    setSavingCourier(true);

    let data = {
      name: courierDetails.name,
      
    };

    try {
      const { msg, success } = await addCourier(data);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setSavingCourier(false);
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setSavingCourier(false);
      }
    } catch (err) {
      console.log(err);
      setSavingCourier(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setCourierDetails({ ...courierDetails, [name]: value });
  };

 
 
  return (
    <Modal
      close={props.close}
      onAccept={() => addNewCourier()}
      title="Add new courier"
      form="courierForm"
      loading={savingCourier}
    >
      {loading && "Preparing couriers form...."}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="courierForm">
          <div className="form-group">
            <label>Courier Name</label>
            <input
              name="name"
              type="text"
              placeholder=""
              onChange={onChange}
              className="form-control"
            />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddCourierModal;
