import ShippingConfig from "./Shipping";

const ShippingRoutes = [
  {
    path: "/shipping-delivery",
    element: <ShippingConfig />,
  },
];

export default ShippingRoutes;
