import React, { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { Loader } from "components";
import { useHttp } from "hooks";

const AddColorModal = (props) => {
  let tempData = [];
  const { toastDispatch } = useToastContext();
  const [colors, setColors] = useState([]);
  const { error, loading, sendHttpRequest: getModalColor } = useHttp();
  const [selectedOptions, setSelectedOptions] = useState();
  const {
    error: errorStores,
    loading: assigningStore,
    sendHttpRequest: assignStore,
  } = useHttp();

  useEffect(() => {
    getModalColor(
      `colors_for_model_update/${props?.id}`,
      "GET",
      null,
      handleData
    );
  }, []);

  const handleData = ({ data: { colors } }) => {
    colors?.highlights?.colors?.map((el) => {
      tempData.push({ value: el?.value, label: el?.label });
    });
    setColors(tempData);
  };

  const assign = async () => {
    let data = {
      color_id: selectedOptions,
      Inventory_id: props?.InventoryId,
      source: 'inventory'
    };
    assignStore("update_color_sales", "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title={props?.title}
      form="add_color"
      size="sm"
      loading={assigningStore}
    >
      {loading && (
        <div
          style={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="add_color">
          <div>
            <label>Select a Color</label>
            <select
              className="form-control form-control-sm"
              onChange={(e) => setSelectedOptions(e.target.value)}
              name="color"
              defaultValue=""
              required={true}
            >
              <option value="" selected>
                Select a Color
              </option>
              {colors?.map((details) => (
                <option value={details.value} key={details.value}>
                  {details.label}
                </option>
              ))}
            </select>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddColorModal;
