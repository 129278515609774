import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { Formik, Form, Field } from "formik";
import Avatar from "assets/images/avatar.png";
import * as Yup from "yup";
import { Loader } from "components";
import { useHttp } from "hooks";
import { getRepresentativeDetails } from "../representatives/RepresentativeService";

const ListRMBadilisDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [repDetails, setRepDetails] = useState({});
  const [regions, setRegions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const {
    state: { agent },
  } = useLocation();
  const {
    error,
    loading: loadingRegions,
    sendHttpRequest: getRegions,
  } = useHttp();
  const {
    error: errorDealers,
    loading: loadingDealers,
    sendHttpRequest: getDealers,
  } = useHttp();

  const settingsSchema = Yup.object().shape({
    firstName: Yup.string()

      .min(2, "Too Short!")

      .max(50, "Too Long!")

      .required("Required"),
  });

  const getRepDetails = async () => {
    setLoading(true);
    try {
      getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
        setRegions(regions)
      );
      getDealers("get_dealers", "GET", null, ({ data: { dealers } }) => {
        setDealers(dealers);
      });
      const { rep, success } = await getRepresentativeDetails(id);
      if (success) {
        setRepDetails(rep);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRepDetails();
  }, []);
  return (
    <>
      {loading && <Loader />}
      {!loading && !repDetails && <Loader />}
      {!loading && repDetails && (
        <div>
          <div className="row">
            <div className="col-lg-3">
              <div className="card">
                <div
                  className="card-body bg-indigo-400 text-center card-img-top"
                  style={{
                    backgroundImage:
                      "url(../../../../global_assets/images/backgrounds/panel_bg.png)",
                    backgroundSize: "contain",
                  }}
                >
                  <div className="card-img-actions d-inline-block mb-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={Avatar}
                      width={120}
                      height={120}
                      alt=""
                    />
                    <div className="card-img-actions-overlay rounded-circle">
                      <a
                        href="#"
                        className="btn btn-outline bg-white text-white border-white border-2 btn-icon rounded-round"
                      >
                        <i className="icon-upload" />
                      </a>
                    </div>
                  </div>
                  <h6 className="font-weight-semibold mb-0">
                    {agent.fname + " " + agent.lname}
                  </h6>
                  <span className="d-block opacity-75">
                    {agent?.dealer[0]?.name || "Unassigned"}
                  </span>
                </div>
                <div className="card-body p-0">
                  <ul className="nav nav-sidebar mb-2">
                    <li className="nav-item-header">Navigation</li>
                    <li className="nav-item">
                      <a
                        href="#summary"
                        className="nav-link active"
                        data-toggle="tab"
                      >
                        <i className="icon-graph" />
                        Statistics
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#settings"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="icon-gear" />
                        Settings
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#sales-rep"
                        className="nav-link"
                        data-toggle="tab"
                      >
                        <i className="icon-users" />
                        Sales Reps
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="tab-content w-100 col-lg-9">
              <div className="tab-pane fade active show" id="summary">
                <div className="card">
                  <div className="card-header header-elements-sm-inline">
                    <h6 className="card-title">Weekly statistics</h6>
                    <div className="header-elements">
                      <span>
                        <i className="icon-history mr-2 text-success" /> Updated
                        3 hours ago
                      </span>
                      <div className="list-icons ml-3">
                        <a className="list-icons-item" data-action="reload" />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="chart-container">
                      <div
                        className="chart has-fixed-height"
                        id="weekly_statistics"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="settings">
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Profile information</h5>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        firstName: repDetails.fname,
                        lastName: repDetails.lname,
                        email: repDetails.email,
                        phone: repDetails.phone,
                        idNumber: repDetails.idNumber,
                        region: repDetails.region,
                        associatedDealer: repDetails.associatedDealer,
                      }}
                      validationSchema={settingsSchema}
                      onSubmit={(values) => {
                        // same shape as initial values
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>First Name</label>
                                <Field
                                  type="text"
                                  name="firstName"
                                  className="form-control"
                                />
                                {errors.firstName && touched.firstName ? (
                                  <div className="text-danger">
                                    {errors.firstName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Last name</label>
                                <Field
                                  type="text"
                                  name="lastName"
                                  className="form-control"
                                />
                                {errors.lastName && touched.lastName ? (
                                  <div className="text-danger">
                                    {errors.lastName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Phone #</label>
                                <Field
                                  type="text"
                                  name="phone"
                                  className="form-control"
                                />
                                <span className="form-text text-muted">
                                  07********
                                </span>
                                {errors.phone && touched.phone ? (
                                  <div className="text-danger">
                                    {errors.phone}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email</label>
                                <Field
                                  type="email"
                                  name="email"
                                  className="form-control"
                                />
                                {errors.email && touched.email ? (
                                  <div className="text-danger">
                                    {errors.email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>ID Number</label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="idNumber"
                                />
                                {errors.idNumber && touched.idNumber ? (
                                  <div className="text-danger">
                                    {errors.idNumber}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Your Region</label>
                                <Field
                                  as="select"
                                  className="form-control form-control-sm"
                                  name="region"
                                >
                                  {regions.map((region) => (
                                    <option value={region._id} key={region._id}>
                                      {region.name}
                                    </option>
                                  ))}

                                  <option value="uk">United Kingdom</option>
                                </Field>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Associated Dealer</label>
                                <Field
                                  as="select"
                                  className="form-control form-control-sm"
                                  name="associatedDealer"
                                >
                                  {dealers.map((dealer) => (
                                    <option value={dealer._id} key={dealer._id}>
                                      {dealer.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="text-right">
                            <button type="submit" className="btn btn-primary">
                              Save changes
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Account settings</h5>
                   
                  </div>
                  <div className="card-body">
                    <form action="#">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Username</label>
                            <input
                              type="text"
                              defaultValue="Kopyov"
                              readOnly="readonly"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Current password</label>
                            <input
                              type="password"
                              defaultValue="password"
                              readOnly="readonly"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>New password</label>
                            <input
                              type="password"
                              placeholder="Enter new password"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Repeat password</label>
                            <input
                              type="password"
                              placeholder="Repeat new password"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-6">
                            <label>Notifications</label>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-input-styled"
                                  defaultChecked
                                  data-fouc
                                />
                                Password expiration notification
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-input-styled"
                                  defaultChecked
                                  data-fouc
                                />
                                New message notification
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-input-styled"
                                  defaultChecked
                                  data-fouc
                                />
                                New task notification
                              </label>
                            </div>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-input-styled"
                                />
                                New contact request notification
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <button type="submit" className="btn btn-primary">
                          Save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div> */}
              </div>

              <div className="tab-pane fade" id="sales-rep">
                <div className="card">
                  <div className="card-header header-elements-inline">
                    <h5 className="card-title">Sales Reps</h5>
                    <div className="header-elements"></div>
                  </div>
                  <div className="card-body">
                    {" "}
                    Sales Reps under this agent go here
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListRMBadilisDetails;
