import { useMutation } from "@tanstack/react-query";
import { pushBackToSold } from "../services/ProductService";

export function usePushBackToSold() {
  const {
    mutate: pushingBackToSold,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: pushBackToSold,
  });

  return { pushingBackToSold, isLoading, error };
}
