import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import DeleteDealerModal from "./DeleteDealerModal";
import EditDealerModal from "./EditDealerModal";
import UpdateDealerModal from "./UpdateDealerModal";
import ModalService from "components/modal/ModalService";
import { useHttp } from "hooks";
import { Loader, Table } from "components";
const Dealers = () => {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const [partners, setPartners] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [dealersData, setDealersData] = useState([]);
  const navigate = useNavigate();
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setRowCount(dealersData?.length);
    setProductsRowCount(dealersData?.length);
  }, [dealersData]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginBottom: "16px", fontSize: "18px",marginLeft: "-58px" }}>
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };
  const { error, loading, sendHttpRequest: getDealers } = useHttp();
  const {
    partnerError,
    loading: getParnter,
    sendHttpRequest: GetPartner,
  } = useHttp();
  // const handleChange = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  useEffect(() => {
    getDealersFun();
    get_partnersFun();
  }, []);
  const get_partnersFun = () => {
    GetPartner("get_partners", "GET", null, ({ data: { partners } }) => {
      setPartners(partners);
    });
  };
  const openDelModal = (name, id) => {
    ModalService.open(DeleteDealerModal, {
      refresh: () => getDealersFun(),
      name,
      id,
    });
  };

  const openUpdateLocation = (name, id) => {
    ModalService.open(UpdateDealerModal, {
      refresh: () => getDealersFun(),
      name,
      id,
    });
  };
  const openEditModal = (name, id, country, region, email) => {
    ModalService.open(EditDealerModal, {
      refresh: () => getDealersFun(),
      name,
      id,
      country,
      region,
      email,
    });
  };

  const getDealersFun = () => {
    getDealers("get_dealers", "GET", null, ({ data: { dealers } }) => {
      setDealers(dealers);
      let tempVal = [];
      dealers?.map((val, index) =>
        tempVal?.push({
          createdAt: val?.createdAt
            ? format(new Date(val?.createdAt), "PPpp")
            : "-",
          _id: val,
          name: val?.name,
          email: val?.email || "-",
          partnerId: val?.partnerId
            ? partners?.map((data) =>
                val?.partnerId === data?._id ? data?.name : null
              )
            : "-",
          status: val?.active === true ? "Active" : "In-Active",
          del_latitude: val?.lat || "-",
          del_longitude: val?.long || "-",
        })
      );
      setDealersData(tempVal);
    });
  };
  const columns = [
    // {
    //   label: "#",
    //   name: "",
    //   options: {
    //     sort: false,
    //     filter: false,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, update) => {
    //       let rowIndex = Number(tableMeta.rowIndex) + 1;
    //       return <span>{rowIndex}</span>;
    //     },
    //   },
    // },
    {
      name: "dealerId",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <h6 className="media-title font-weight-semibold">
                <Link to={`/sales/dealers/${tableMeta.rowData[0]}`}>
                  {value}
                </Link>
              </h6>
            </>
          ) : (
            "-"
          );
        },
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      label: "Latitude",
      name: "del_latitude",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      label: "Longitude",
      name: "del_longitude",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      label: "Partner",
      name: "partnerId",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      label: "Location",
      name: "_id",
      options: {
        sort: false,
        filter: false,
        search: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <ul className="list-inline list-inline-dotted  mb-2">
                <li className="list-inline-item">
                  <>
                    {value?.region[0]?.name ? value?.region[0]?.name : ""} -{" "}
                    {value?.region[0]?.country
                      ? value?.region[0]?.country?.toUpperCase()
                      : ""}
                  </>
                </li>
              </ul>
            </>
          ) : (
            "-"
          );
        },
      },
    },
    {
      label: "Created on",
      name: "createdAt",
      options: {
        sort: true,
        filter: true,
      },
    },

    {
      label: "ACTION",
      name: "_id",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <button
                  className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-menu7" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    href
                    className="dropdown-item"
                    onClick={() =>
                      openEditModal(
                        value.name,
                        value._id,
                        value?.region[0]?.country?.toUpperCase(),
                        value?.region[0]?.name,
                        value?.email
                      )
                    }
                  >
                    <i className="icon-pencil" /> Edit
                  </a>
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => openUpdateLocation(value.name, value._id)}
                  >
                    <i className="icon-pin" /> Update Location
                  </a>
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => openDelModal(value.name, value._id)}
                  >
                    <i className="icon-trash" /> Delete
                  </a>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              {" "}
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">{rowProductsCount}</h3>
                <span>Total Dealers</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="dt-button buttons-pdf buttons-html5 btn bg-blue legitRipple "
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                onClick={() => navigate(`/sales/add-dealer`)}
              >
                <span>Add Dealer</span>
              </button>
            </div>
          </div>
          <Table
            title={
              "All Dealers"
              // <>
              //   <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
              //     <div className="d-flex align-items-center mb-3 mb-md-0">
              //       <h4 className="card-title">
              //         {/* <i className="icon-cash3 mr-2" /> */}

              //       </h4>
              //     </div>

              //     <div className="d-flex align-items-center mb-3 mb-md-0"></div>
              //   </div>
              //   <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
              //     <div className="d-flex align-items-center mb-3 mb-md-0">
              //       <button
              //         className="dt-button buttons-pdf buttons-html5 btn bg-blue legitRipple "
              //         tabIndex={0}
              //         aria-controls="DataTables_Table_0"
              //         type="button"
              //         onClick={() => navigate(`/sales/add-dealer`)}
              //       >
              //         <span>Add New Dealer</span>
              //       </button>
              //     </div>
              //     <div className="d-flex align-items-center mb-3 mb-md-0">
              //       <div id="tickets-status" />
              //       <button className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon">
              //         <i className="icon-stack" />
              //       </button>
              //       <div className="ml-3">
              //         <h5 className="font-weight-semibold mb-0">
              //           {dealers?.length}
              //         </h5>
              //         <span className="badge badge-mark border-success mr-1" />{" "}
              //         <span className="text-muted">Total dealers</span>
              //       </div>
              //     </div>
              //   </div>
              // </>
            }
            data={dealersData}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default Dealers;
