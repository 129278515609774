import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { banks } from "helpers/banks";
import { useHttp } from "hooks";

const AddProductModal = (props) => {
  // const [loading, setLoading] = useState(false);
  // const [savingDealer, setSavingDealer] = useState(false);
  const [dealerDetails, setDealerDetails] = useState({
    name: "",
    region: props.region,
  });
  const [regions, setRegions] = useState([]);
  const [bankBranches, setBankBranches] = useState([]);
  const { toastDispatch } = useToastContext();
  const {
    error: errorDealers,
    loading: savingDealer,
    sendHttpRequest: addDealer,
  } = useHttp();
  const {
    error: errorRegions,
    loading,
    sendHttpRequest: getRegions,
  } = useHttp();



  const addNewDealer = () => {
    let data = {
      name: dealerDetails.name,
      region: dealerDetails.region,
    };

    addDealer("new_dealer", "POST", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setDealerDetails({ ...dealerDetails, [name]: value });
  };

  useEffect(() => {
  getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
    setRegions(regions)
  );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => addNewDealer()}
      title="Add new dealer"
      form="dealerForm"
      loading={savingDealer}
    >
      {loading && "Preparing dealers form...."}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="dealerForm">
          <div className="form-group">
            <label>Dealer Name</label>
            <input
              name="name"
              type="text"
              placeholder=""
              onChange={onChange}
              value={dealerDetails.name}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Region</label>
            <div class="form-group">
              <select
                className="form-control form-control-sm"
                onChange={onChange}
                name="region"
                value={dealerDetails.region}
                disabled={props.region}
              >
                <option value="" selected>
                  Select a Region
                </option>
                {regions.map((region) => (
                  <option value={region._id} key={region._id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <label className="font-weight-bold">Payment Details</label>
          <div className="form-group">
            <label>Bank Name</label>
            <div class="form-group">
              <select
                className="form-control form-control-sm"
                // onChange={onChange}
                // name="bank_name"
                // value={dealerDetails.region}
                disabled={props.region}
              >
                <option value="" selected>
                  Select a bank
                </option>
                {banks.map(({ name, bank_code, branches }) => (
                  <option
                    value={name}
                    key={bank_code}
                    onClick={() => {
                      setBankBranches(branches);
                    }}
                  >
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Branch Name</label>
            <div class="form-group">
              <select
                className="form-control form-control-sm"
                // onChange={onChange}
                // name="bank_branch"
                // value={dealerDetails.branch}
                disabled={bankBranches.length < 1}
              >
                <option value="" selected>
                  Select a branch
                </option>
                {bankBranches.map(({ name, code }) => (
                  <option value={name} key={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Bank Account</label>
            <input
              name="account"
              type="text"
              placeholder=""
              // onChange={onChange}
              // value={dealerDetails.name}
              className="form-control"
            />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddProductModal;
