import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AddPartnerModal from "./AddPartnerModal";
import DeletePartnerModal from "./DeletePartnerModal";
import ModalService from "components/modal/ModalService";
import { getPartners } from "./PartnersService";
import { format } from "date-fns";
import {Loader} from 'components'

const Dealers = () => {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState([]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const getAllPartners = async () => {
    setLoading(true);
    try {
      const { msg, success, partners } = await getPartners();
      if (success) {
        setPartners(partners);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  const openModal = () => {
    ModalService.open(AddPartnerModal, { refresh: () => getAllPartners() });
  };
  const openDelModal = (name, id) => {
    ModalService.open(DeletePartnerModal, {
      refresh: () => getAllPartners(),
      name,
      id,
    });
  };
  const { gilad, jason, antoine } = state;
  return (
    <>
   
      {loading && <Loader/>}
      {!loading && (
        <div className="d-md-flex align-items-md-start">
          {/* <div
            className="sidebar sidebar-light mr-md-2 sidebar-component sidebar-component-left border-0 shadow-0 sidebar-expand-md"
            style={{ zIndex: 10 }}
          >
            <div className="sidebar-content">
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    Filter
                  </span>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse" />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form action="#">
                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Partner Name"
                      />
                      <div className="form-control-feedback">
                        <i className="icon-reading text-muted" />
                      </div>
                    </div>

                    <button type="submit" className="btn bg-blue btn-block">
                      <i className="icon-search4 font-size-base mr-2" />
                      Find partner
                    </button>
                  </form>
                </div>
              </div>
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    Top Searches
                  </span>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse" />
                    </div>
                  </div>
                </div>
                <form action="#">
                  <div className="card-body">
                    <div className="form-check">
                      <label className="form-check-label">
                        <div className="uniform-checker border-primary-600 text-primary-800">
                          <span className="checked">
                            <input
                              type="checkbox"
                              className="form-check-input-styled-primary"
                              checked={jason}
                              onChange={handleChange}
                              name="jason"
                              data-fouc
                            />
                          </span>
                        </div>
                        Nakuru
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <div className="uniform-checker border-primary-600 text-primary-800">
                          <span className="checked">
                            <input
                              type="checkbox"
                              className="form-check-input-styled-primary"
                              checked={gilad}
                              onChange={handleChange}
                              name="gilad"
                              data-fouc
                            />
                          </span>
                        </div>
                        Nairobi
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <div className="uniform-checker border-primary-600 text-primary-800">
                          <span className="checked">
                            <input
                              type="checkbox"
                              className="form-check-input-styled-primary"
                              checked={antoine}
                              onChange={handleChange}
                              name="antoine"
                              data-fouc
                            />
                          </span>
                        </div>
                        Mombasa
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          
          </div> */}
          <div className="flex-fill overflow-auto">
            <div className="card">
              <div className="card-header bg-white header-elements-inline">
                <h4 className="card-title">All Promoters</h4>
                <div className="header-elements">
                  <button
                    className="btn btn-sm badge bg-success"
                    onClick={openModal}
                  >
                    Add New Promoter
                  </button>
                </div>
              </div>
              <ul className="media-list">
                {partners.map((partner) => (
                  <li
                    className="media card-body flex-column flex-sm-row"
                    key={partner._id}
                  >
                    <div className="mr-sm-3 mb-2 mb-sm-0">
                      <a href="#">
                        {/* <img
                          src="../../../../global_assets/images/placeholders/placeholder.jpg"
                          className="rounded"
                          width={44}
                          height={44}
                          alt
                        /> */}
                      </a>
                    </div>
                    <div className="media-body">
                      <h6 className="media-title font-weight-semibold">
                        <Link to={`/sales/partners/${partner._id}`}>
                          {partner.name}
                        </Link>
                      </h6>
                      <ul className="list-inline list-inline-dotted text-muted mb-2">
                        <li className="list-inline-item">
                          <a href="#" className="text-muted">
                            International Lane
                          </a>
                        </li>
                        <li className="list-inline-item">Nairobi</li>
                      </ul>
                    </div>
                    <div className="ml-sm-3 mt-2 mt-sm-0 d-flex flex-column justify-content-between">
                      <span className="d-block  text-right">
                        <a
                          href="#"
                          onClick={() =>
                            openDelModal(partner.name, partner._id)
                          }
                        >
                          <i className="icon-trash-alt text-danger" />
                        </a>
                      </span>
                      <span className="mt-3 text-muted">
                        {`Created on ${format(
                          new Date(partner.createdAt),
                          "PPpp"
                        )}`}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="d-flex justify-content-center pt-3 mb-3">
              <ul className="pagination shadow-1">
                <li className="page-item">
                  <a href="#" className="page-link page-link-white">
                    <i className="icon-arrow-small-right" />
                  </a>
                </li>
                <li className="page-item active">
                  <a href="#" className="page-link page-link-white">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link page-link-white">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link page-link-white">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link page-link-white">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link page-link-white">
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a href="#" className="page-link page-link-white">
                    <i className="icon-arrow-small-left" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dealers;
