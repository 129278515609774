import React, { useEffect, useState } from "react";
import AddProductModal from "./AddComponentsModal";
import ModalService from "components/modal/ModalService";
import { useHttp } from "hooks";

const ListRepairComponentsProducts = () => {
  const [inventory, setInventory] = useState([]);
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();

  const openModal = () => {
    ModalService.open(AddProductModal, {
      refresh: () =>
        getInventory("/get_inventory", "GET", null, ({ data: { requests } }) =>
          setInventory(requests)
        ),
    });
  };

  useEffect(() => {
    getInventory("/get_inventory", "GET", null, ({ data: { requests } }) =>
      setInventory(requests)
    );
  }, []);

  return (
    <>
      {loadingInventory && inventory.length === 0 && "Please wait...."}
      {!loadingInventory &&
        inventory.length === 0 &&
        "No repair components available"}

      {!loadingInventory && inventory.length !== 0 && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h6 className="card-title">Repair Components</h6>
            <div>
              <a
                onClick={openModal}
                type="button"
                className="btn btn-sm bg-teal-400"
              >
                <i className="icon-add mr-2" /> New Component
              </a>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table text-nowrap">
              <thead>
                <tr className="table-active ">
                  <td colSpan={3}>Total Components</td>
                  <td />
                  <td />
                  <td />
                  <td className="text-right">
                    <span className="badge bg-blue badge-pill">24</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Part Name</th>
                  <th>Model Name</th>
                  <th>Variant</th>
                  <th>Quantity</th>
                  <th>Description</th>
                  <th>Description</th>
                  <th className="text-center" style={{ width: 20 }}>
                    <i className="icon-arrow-down12" />
                  </th>
                </tr>
                {inventory.map((el) => (
                  <tr>
                    <td className="text-center">
                      <h6 className="mb-0">12/01</h6>
                      <div className="font-size-sm text-muted line-height-1">
                        2022
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-teal-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            {el.details.model[0]?.modelName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href
                        className="text-default font-weight-semibold letter-icon-title"
                      >
                        {el.details.imei}
                      </a>
                    </td>

                    <td>
                      <a
                        href
                        className="text-default font-weight-semibold letter-icon-title"
                      >
                        {el.details.source}
                      </a>
                    </td>
                    <td>
                      <a
                        href
                        className="text-default font-weight-semibold letter-icon-title"
                      >
                        {el.details.current_status[0].name}
                      </a>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div className="font-weight-semibold">
                          {el.details.variant[0]?.storage}GB/{" "}
                          {el.details.variant[0]?.ram}GB Variant
                        </div>
                        {/* <span className="font-weight-semibold">
                          Includes:{""}
                          <span className="text-muted">
                            {el.details.accessories.map((access) => access)}
                          </span>
                        </span> */}
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-eye" /> View
                            </a>
                            {/* <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-checkmark3 text-success" />{" "}
                              Resolve issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ListRepairComponentsProducts;
