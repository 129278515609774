// export const getBrands = async () => {
//   let res = await fetch(
//     process.env.REACT_APP_BASE_URL + "/get_device/6165c8453c6ca93814dc21aa",
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         key: process.env.REACT_APP_API_KEY,
//         secret: process.env.REACT_APP_API_SECRET,
//         country: process.env.REACT_APP_COUNTRY,
//       },
//     }
//   );
//   return await res.json();
// };

// export const getModels = async (brand_id) => {
//   let res = await fetch(
//     process.env.REACT_APP_BASE_URL + "/get_device_model/" + brand_id,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         key: process.env.REACT_APP_API_KEY,
//         secret: process.env.REACT_APP_API_SECRET,
//         country: process.env.REACT_APP_COUNTRY,
//       },
//     }
//   );
//   return await res.json();
// };

// export const getVariants = async (model_id) => {
//   let res = await fetch(
//     process.env.REACT_APP_BASE_URL + "/get_variant_model/" + model_id,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         key: process.env.REACT_APP_API_KEY,
//         secret: process.env.REACT_APP_API_SECRET,
//         country: process.env.REACT_APP_COUNTRY,
//       },
//     }
//   );
//   return await res.json();
// };

// export const getQuantity = async (variant_id) => {
//   let res = await fetch(
//     process.env.REACT_APP_BASE_URL + "/get_quantity_by_variant/" + variant_id,
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         key: process.env.REACT_APP_API_KEY,
//         secret: process.env.REACT_APP_API_SECRET,
//       },
//     }
//   );
//   return await res.json();
// };
export const getQuantity = async (data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/get_transafer_quantity",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};
// export const getStores = async () => {
//   let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_stores", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_API_KEY,
//       secret: process.env.REACT_APP_API_SECRET,
//       country: process.env.REACT_APP_COUNTRY,
//     },
//   });
//   return await res.json();
// };

export const singleRegion = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_region_by_id/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};

export const getTransfers = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_transfer", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};
// export const getDealers = async () => {
//   let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_dealers", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_API_KEY,
//       secret: process.env.REACT_APP_API_SECRET,
//       country: process.env.REACT_APP_COUNTRY,
//     },
//   });
//   return await res.json();
// };
export const getDealerTransfers = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_transfers_by_dealer/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const getSingleDealerTransfer = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_single_transfered/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const newTransfer = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/transfer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });

  return await res.json();
};

export const initiateStockTransfer = async (data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/initiate_stock_return",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );

  return await res.json();
};
