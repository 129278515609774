import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Table } from "components";
import { getTransfers } from "./consignmentService";
import { format } from "date-fns";
import { useHttp } from "hooks";

const ConsignmentTable = () => {
  const navigate = useNavigate();
  const [transfers, setTransfers] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const { error, loading, sendHttpRequest: getDealers } = useHttp();

  const getInven = () => {
    let tempData = [];
    getDealers("get_dealers", "GET", null, async ({ data: { dealers } }) => {
      setDealers(dealers);
      const { success, transfer } = await getTransfers();
      if (success) {
        transfer?.map((el) => {
          return tempData.push({
            agent_name: el?.user?.fname + " " + el?.user?.lname,
            fulfilled: el?.fulfilled,
            request_date: el?.createdAt
              ? format(new Date(el?.createdAt), "dd-MMM-yyyy")
              : "-",
            source: el.source.name ? el.source.name : "-",
            destination: el?.destination.name ? el?.destination.name : "-",
            units: el?.stock?.length ? el?.stock?.length : "-",
            stockType: el?.stockType ? el?.stockType?.toUpperCase() : "-",
            invId: el?.invId ? el?.invId : "-",
            deliveryId: el?.deliveryId ? el?.deliveryId : "-",
            notes: el?.notes ? el?.notes : "-",
            status: el?.status ? el?.status?.toUpperCase() : "-",
            action: el?._id,
          });
        });
      }
      setTransfers(tempData);
    });
  };
  useEffect(() => {
    setRowCount(transfers?.length);
    setProductsRowCount(transfers?.length);
  }, [transfers]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };
  useEffect(() => {
    getInven();
  }, []);

  const newTransfer = () => {
    navigate(`/inventory/stock-transfers/new`, { state: { dealers } });
  };
  const viewTransfer = (id) => {
    navigate(`/inventory/stock-transfer/${id}`);
  };

  const columns = [
    {
      label: "Source",
      name: "source",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Destination",
      name: "destination",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Fulfilled",
      name: "fulfilled",
      options: {
        filter: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <span className="text-success">Yes</span>
              ) : (
                <span className="text-danger">No</span>
              )}
            </>
          );
        },
      },
    },
    {
      label: "Units",
      name: "units",
      options: {
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Agent Name",
      name: "agent_name",
      options: {
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Stock Type",
      name: "stockType",
      options: {
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Invioce",
      name: "invId",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Delivery",
      name: "deliveryId",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Request Date",
      name: "request_date",
      options: {
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Remarks",
      name: "notes",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <button
                  className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-menu7" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => viewTransfer(value)}
                  >
                    <i className="icon-file-eye" /> View
                  </a>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      {loading && transfers.length === 0 && <Loader />}
      {!loading && transfers.length !== 0 && (
        <>
          {/* <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {rowProductsCount}
                </h3>
                <span>Total Consignment</span>
              </div>
            </div>
          </div> */}
          <Table
            title={"Consignment"}
            data={transfers}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default ConsignmentTable;
