import GeneralRoutes from "./general/GeneralRoutes";
import ProductRoutes from "./products/ProductRoutes";
import RepairComponentsRoutes from "./repairComponents/RepairComponentsRoutes";
import StockTransferRoutes from "./stockTransfers/StockTransferRoutes";

export const InventoryRoutes = [
  ...GeneralRoutes,
  ...ProductRoutes,
  ...RepairComponentsRoutes,
  ...StockTransferRoutes,
];
