import ListPartners from "./Partners";
import PartnerDetails from "./PartnerDetails";

const PartnerRoutes = [
  {
    path: "/partners",
    element: <ListPartners />,
  },
  {
    path: "/partners/:id",
    element: <PartnerDetails />,
  },
];

export default PartnerRoutes;
