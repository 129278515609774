export const thousandCommaSeparator = (number) => {
  let nf = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: JSON.parse(sessionStorage.getItem("user"))?.country ==='ug' 
    ? 'UGX' 
    : JSON.parse(sessionStorage.getItem("user"))?.country ==='ke' 
    ?"KES"
    :'TZS',
  });

  return nf.format(number);
};
