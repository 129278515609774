import React, { useState, useEffect, useRef, useMemo } from "react";

import useDataApi from "../../hooks/useDataApi";
import Marks from "./Marks";
import "./styles.css";


const Map = () => {
  const data = useDataApi();

  // const { height, width } = document
  //   .getElementById("map")
  //   .getBoundingClientRect();


 

  const counties = data.features.map((data) => {

    const region_name = data.properties["COUNTY_NAM"];
    return (
      <Marks
        key={data.properties.ID_}
        pathData={data}
        tooltipData={region_name}
      />
    );
  });

  return (
    <svg className="map-canvas" id="map">
      <g>{counties}</g>
    </svg>
  );
};

export default Map;
