export const addNewCity = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/new_city", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};

export const addCityManager = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "assign_city_to_manager", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};
