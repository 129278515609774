import ListWarehouses from "./ListWarehouses";
import WarehouseDetails from "./WarehouseDetails";

const WarehouseRoutes = [
  {
    path: "/warehouses",
    element: <ListWarehouses />,
  },
  {
    path: "/warehouse/:id",
    element: <WarehouseDetails />,
  },
];

export default WarehouseRoutes;
