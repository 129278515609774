import React from "react";
import Modal from "components/modal/Modal";
import { useToastContext, REMOVE } from "context/ToastContext";
import { useHttp } from "hooks";

const DeleteCouponModal = (props) => {
  const { toastDispatch } = useToastContext();
  const {
    error: errorCoupon,
    loading,
    sendHttpRequest: deleteCoupon,
  } = useHttp();

  const deleteCouponById = async () => {
    deleteCoupon(`delete_coupon/${props?.id}`, "DELETE",null,({ msg }) => {
      toastDispatch({
        type: REMOVE,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => deleteCouponById()}
      title="Delete Coupon"
      form="delete_coupon"
      size="sm"
      loading={loading}
    >
     You are about to remove <span className="text-danger">{props?.name}</span>{" "}
     Coupon
    </Modal>
  );
};

export default DeleteCouponModal;