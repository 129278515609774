import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { addDropoff, getRegions } from "./ShippingService";
import { useToastContext, ADD } from "context/ToastContext";
import { Loader } from "components";
import { useHttp } from "hooks";

const AddDropoffModal = (props) => {
  // const [loading, setLoading] = useState(false);
  const [savingDealer, setSavingDealer] = useState(false);
  const [dealerDetails, setDealerDetails] = useState({
    name: "",
    region: props.region,
  });
  const [regions, setRegions] = useState([]);
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getRegions } = useHttp();

  const addNewDealer = async () => {
    setSavingDealer(true);

    let data = {
      name: dealerDetails.name,
      region: dealerDetails.region,
    };

    try {
      const { msg, success } = await addDropoff(data);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setSavingDealer(false);
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setSavingDealer(false);
      }
    } catch (err) {
      console.log(err);
      setSavingDealer(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setDealerDetails({ ...dealerDetails, [name]: value });
  };

  useEffect(() => {
    getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => addNewDealer()}
      title="Add new dropoff Point"
      form="dealerForm"
      loading={savingDealer}
    >
      {loading && <Loader />}
      {!loading && (
        <form
          onSubmit={(e) => e.preventDefault()}
          id="dealerForm"
          className="row"
        >
          <div class="form-group col-md-12">
            <label>Region</label>
            <select
              className="form-control form-control-sm"
              onChange={onChange}
              name="region"
              value={dealerDetails.region}
              disabled={props.region}
            >
              <option value="" selected>
                Select a Region
              </option>
              {regions.map((region) => (
                <option value={region._id} key={region._id}>
                  {region.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-12">
            <label>Tier 1</label>
            <input
              name="sub_region"
              type="text"
              placeholder=""
              onChange={onChange}
              value={dealerDetails.sub_region}
              className="form-control"
            />
          </div>
          <div className="form-group col-md-12">
            <label>Tier 2</label>
            <input
              name="point_name"
              type="text"
              placeholder=""
              onChange={onChange}
              value={dealerDetails.point_name}
              className="form-control"
            />
          </div>
          <div className="form-group col-md-6">
            <label>Latitude</label>
            <input
              name="latitude"
              type="text"
              placeholder=""
              onChange={onChange}
              value={dealerDetails.latitude}
              className="form-control"
            />
          </div>
          <div className="form-group col-md-6">
            <label>Longitude</label>
            <input
              name="latitude"
              type="text"
              placeholder=""
              onChange={onChange}
              value={dealerDetails.longitude}
              className="form-control"
            />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddDropoffModal;
