export const addWarehouse = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/new_warehouse", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};
export const editWarehouse = async (id, data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/update_warehouse/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};

// export const getBrands = async () => {
//   let res = await fetch(
//     process.env.REACT_APP_BASE_URL + "/get_device/6165c8453c6ca93814dc21aa",
//     {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         key: process.env.REACT_APP_API_KEY,
//         secret: process.env.REACT_APP_API_SECRET,
//         country: process.env.REACT_APP_COUNTRY,
//       },
//     }
//   );
//   return await res.json();
// };
export const inventorybywarehouse = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL +
      `get_inventory_by_warehouse/${"629f34a94a5e3484ec808c83"}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const singleWarehouse = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_single_warehouse/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const delWarehouse = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/delete_warehouse/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const updateWarehouse = async (id,data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/update_warehouse/${id}`,
    {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },   body: JSON.stringify(data),
    }
  );
  return await res.json();
};
export const getWarehouses = async () => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/get_all_warehouses",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};

export const allRegions = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_regions", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};
// export const getDealers = async () => {
//   let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_dealers", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_API_KEY,
//       secret: process.env.REACT_APP_API_SECRET,
//       country: process.env.REACT_APP_COUNTRY,
//     },
//   });
//   return await res.json();
// };
