import { useQuery } from "@tanstack/react-query";
import { getWarehouses } from "../WarehouseService";

export function useWarehouses() {
  const {
    data,
    isLoading: gettingWarehouses,
    error,
  } = useQuery({
    queryKey: ["warehouses"],
    queryFn: getWarehouses,
  });

  return { data, gettingWarehouses, error };
}
