import React from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { usePushBackToSold } from "./hooks/usePushBackToSold";

const BackToSoldModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { pushingBackToSold, isLoading } = usePushBackToSold();

  async function pushRTS(inventoryID) {
    pushingBackToSold(inventoryID, {
      onSuccess: ({ msg, success }) => {
        if (success) {
          props.refresh();
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        } else {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg || "Unexpected Error Occured. Try again!",
              type: "danger",
            },
          });
        }
      },
    });
  }

  return (
    <Modal
      close={props.close}
      onAccept={() => pushRTS(props?.inventoryId)}
      title="Push Back to Sold"
      form="push_back_To_Sold"
      size="sm"
      loading={isLoading}
    >
      <p>
        Should <span className="text-danger">{props?.name}</span> be Pushed Back
        to Sold?
      </p>
    </Modal>
  );
};

export default BackToSoldModal;
