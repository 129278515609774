import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { useParams } from "react-router";
import { Loader } from "components";
import {
  getSingleOrder,
  getUnitsByModel,
  fulfillOrder,
  getOrderPaymentSchedule,
} from "./OrderService";
import { format } from "date-fns";
import Drawer from "@mui/material/Drawer";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import Checkbox from "@mui/material/Checkbox";
// import InputLabel from "@mui/material/InputLabel";
import Select from "react-select";
const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [fulfillDrawer, setFulfillDrawer] = useState(false);
  //const [loadingCouriers, setLoadingCouriers] = useState(false);
  const [loadingSkus, setLoadingSkus] = useState(false);
  const [models, setModels] = useState([]);
  const [assignedModels, setAssignedModels] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [dispatchDetails, setDispatchDetails] = useState({
    courier: "",
    comments: "",
    notify: false,
    cart: [],
    dropoff_location: "",
    order_number: "",
    free_delivery: "true",
  });
  const [isDispatching, setIsDispatching] = useState(false);
  const [availableSkus, setAvailableSkus] = React.useState([]);
  const [skuName, setSkuName] = React.useState([]);
  const { toastDispatch } = useToastContext();
  const {
    error,
    loading: loadingCouriers,
    sendHttpRequest: getCouriers,
  } = useHttp();
  // const handleChange = (event) => {
  //   const { target: { value }} = event;
  //   setSkuName(typeof value === "string" ? value.split(",") : value );
  // };
  const onChange = (e) => {
    // setAssignedModels(e);
    // console.log(e);
    const { name } = e.target;
    // if (name) {
    //   console.log('--------@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@--------');
    //   let multiSelectVal = Array.from(e.target.selectedOptions, option => option.value);
    //   console.log(name,'multiSelectVal',multiSelectVal);
    //   setDispatchDetails({ ...dispatchDetails, [name]: multiSelectVal });
    // }
    // else
    // {
    const value =
      e?.target?.type === "checkbox" ? e?.target?.checked : e?.target?.value;
    setDispatchDetails({ ...dispatchDetails, [name]: value });
    //  }
  };

  function handleSelect(e) {
    //console.log('=========',e);
    setAssignedModels(e);

    //console.log(e);

    //setSkuName(data)
    // let multiSelectVal = Array.from(e.target.selectedOptions, option => option.value);
    // console.log('multiSelectVal',multiSelectVal);
  }

  const onSKUSelectChange = async (
    id,
    modelId,
    variantId,
    colorId,
    quantity,
    key
  ) => {
    setLoadingSkus(true);
    try {
      const { success, inv } = await getUnitsByModel(
        modelId,
        variantId,
        colorId,
        quantity
      );
      if (success) {
        // console.log(inv);
        const temp = inv.map((el) => {
          let tempObj = {
            value: el.imei,
            label: el.imei,
            name: [id, colorId],
          };

          return tempObj;
        });

        setAvailableSkus(temp);
      }
      setLoadingSkus(false);
    } catch (err) {
      setLoadingSkus(false);
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const { id } = useParams();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const getPaymentSchedule = async () => {
    setLoading(true);
    try {
      const res = await getOrderPaymentSchedule(id);
      if (res.success) {
        if (res) {
          setPaymentSchedule({
            'MerchantRequestID': res.payment?.MerchantRequestID,
            'accountNumber': res.payment?.accountNumber,
            'amount': res.payment?.amount,
            'phone': res.payment?.phone,
            'status': res.payment?.status,
            'createdAt': res.payment?.createdAt
          });
        
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  console.log('orders');

  const getOrder = async () => {
    setLoading(true);
    try {
      const res = await getSingleOrder(id);

      getPaymentSchedule();
      if (res.success) {
        if (res) {
          res.orders[0]?.carts.map((order, i) => {
            onSKUSelectChange(
              order?.cart?._id,
              order?.cart?.modelId,
              order?.cart?.variantId,
              order?.cart?.quickDetails?.color,
              order?.cart?.quantity,
              `${order.cart.modelId}-${i}`
            );
          });
        }

        setOrderDetails(res);
        setDispatchDetails({
          ...dispatchDetails,
          order_number: res.orders[0].order.orderNumber,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getAllCouriers = () => {
    getCouriers("get_courier", "GET", null, ({ data: { couriers } }) =>
      setCouriers(couriers)
    );
  };

  useEffect(() => {
    getOrder();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFulfillDrawer(open);
    getAllCouriers();
  };

  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }

    return value;
  };

  const dispatchOrder = async (e) => {
    setIsDispatching(true);
    e.preventDefault();
    let delivery = str2bool(dispatchDetails.free_delivery);

    try {
      const { courier, comments, notify, dropoff_location } = dispatchDetails;
      let cart = [];

      // if(Array.isArray(value)){
      //   let i;
      //   for (i = 0; i = value.length; i++) {
      //     console.log("=====", value);
      //     value.map((el) => {   console.log("===el==",el);})
      //     // if (options[i].selected) {
      //     //   value.push(options[i].value);
      //     // }
      //   }
      //   }
      if (assignedModels.length !== 0) {
        // Object.entries(dispatchDetails).forEach(([key, value]) => {
        let cartId = assignedModels.map((currentValue) => currentValue.name[0]);
        let colorId = assignedModels.map(
          (currentValue) => currentValue.name[1]
        );
        // console.log(
        //   modalId.toString(),
        //   "assignedModels.map((currentValue) => currentValue.value",
        //   assignedModels.map((currentValue) => currentValue.name)
        // );
        let device = {
          _id: cartId.toString(),
          colorId: colorId.toString(),
          imei: assignedModels.map((currentValue) => currentValue.value),
        };
        cart.push(device);
        setDispatchDetails({ ...dispatchDetails, cart });
        //  });
      }
      // console.log('==^^^^^^^^^^^^==',cart);
      // return false
      // setDispatchDetails({ ...dispatchDetails, imei:skuName });
      let data_free = {
        //comments,
        notify,
        cart,
        orderId: orderDetails.orders[0].order._id,
        freeDelivery: delivery,
      };
      let data_courier = {
        courier,
        // comments,
        notify,
        dropoff_location: Number(dropoff_location),
        cart,
        orderId: orderDetails.orders[0].order._id,
        freeDelivery: delivery,
      };
      // console.log(data_courier,'==@@@@@@@==',data_free);
      // return false
      if (delivery) {
        const { success, msg, message } = await fulfillOrder(data_free);
        if (success) {
          getOrder();
          setIsDispatching(false);
          setFulfillDrawer(false);
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          setDispatchDetails({
            ...dispatchDetails,
            courier: "",
            comments: "",
            notify: false,
            cart: "",
            dropoff_location: "",
            order_number: "",
            loading: false,
          });
        } else {
          setIsDispatching(false);
          setFulfillDrawer(false);
          toastDispatch({
            type: ADD,
            payload: {
              content: message,
              type: "danger",
            },
          });
          setDispatchDetails({
            ...dispatchDetails,
            courier: "",
            comments: "",
            notify: false,
            cart: "",
            dropoff_location: "",
            order_number: "",
            loading: false,
          });
        }
      } else {
        const { success, msg, message } = await fulfillOrder(data_courier);
        if (success) {
          getOrder();
          setIsDispatching(false);
          setFulfillDrawer(false);
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          setDispatchDetails({
            ...dispatchDetails,
            courier: "",
            comments: "",
            notify: false,
            cart: "",
            dropoff_location: "",
            order_number: "",
            loading: false,
          });
        } else {
          setIsDispatching(false);
          setFulfillDrawer(false);
          toastDispatch({
            type: ADD,
            payload: {
              content: message,
              type: "danger",
            },
          });
          setDispatchDetails({
            ...dispatchDetails,
            courier: "",
            comments: "",
            notify: false,
            cart: "",
            dropoff_location: "",
            order_number: "",
            loading: false,
          });
        }
      }
    } catch (err) {
      setIsDispatching(false);
      toggleDrawer(false);
      toastDispatch({
        type: ADD,
        payload: {
          content: "An error occured",
          type: "danger",
        },
      });
    }
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && orderDetails && (
        <div>
          <Drawer
            PaperProps={{
              sx: {
                width: {
                  xs: "90%", // theme.breakpoints.up('xs')
                  sm: "90%", // theme.breakpoints.up('sm')
                  md: "50%", // theme.breakpoints.up('md')
                },
              },
            }}
            anchor="right"
            open={fulfillDrawer}
            onClose={toggleDrawer(false)}
          >
            {loadingCouriers && <Loader />}
            {!loadingCouriers && (
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {`Fulfill Order PKE-${orderDetails.orders[0].order.orderNumber}`}
                  </h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => dispatchOrder(e)} id="fulfillForm">
                    <div className="form-group">
                      <div className="table-responsive">
                        <table className="table text-nowrap">
                          <thead>
                            <tr>
                              <th className="font-weight-bold">Model</th>
                              <th className="font-weight-bold">Color</th>
                              <th className="font-weight-bold">SKU(S)</th>
                            </tr>
                          </thead>

                          <tbody>
                            {orderDetails.orders[0]?.carts.map((order, i) => (
                              <tr key={order.cart._id}>
                                <td className="border-0 px-0">
                                  <div className="d-flex align-items-center">
                                    <div className="mr-3">
                                      <img
                                        src={order.image}
                                        className=""
                                        alt="device"
                                        width={50}
                                        height={50}
                                      />
                                    </div>
                                    <div>
                                      {order.cart.quickDetails.name}

                                      <div className="text-muted font-size-sm">
                                        {order.cart.quickDetails.storage +
                                          "GB /" +
                                          order.cart.quickDetails.ram +
                                          "GB"}{" "}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="border-0 ">
                                  <span className="text-muted font-size-sm">
                                    {order.cart?.quickDetails?.colorLabel ||
                                      "-"}
                                  </span>
                                </td>
                                <td className="border-0 px-0">
                                  {/* <FormControl sx={{ m: 1, width: 250 }}>
                                    <InputLabel id="demo-simple-select-label">
                                      SKU
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name={`${order.cart.modelId}-${i}`}
                                      multiple
                                      label="SKU"
                                      onChange={handleChange}
                                      onFocus={() =>
                                        onSKUSelectChange(
                                          order?.cart?.modelId,
                                          order?.cart?.variantId,
                                          order?.cart?.quickDetails?.color,
                                          order?.cart?.quantity,
                                          `${order.cart.modelId}-${i}`
                                        )
                                      }
                                      value={skuName}
                                      input={<OutlinedInput label="Tag" />}
                                      renderValue={(selected) =>
                                        selected.join(", ")
                                      }
                                      MenuProps={MenuProps}
                                    >
                                      {loadingSkus &&
                                      `${order.cart.modelId}-${i}` ? (
                                        <MenuItem value={""}>
                                          {" "}
                                          <ListItemText
                                            primary={"Loading..."}
                                          />
                                        </MenuItem>
                                      ) : (
                                        availableSkus?.inv?.map((name) => (
                                          <MenuItem
                                            key={name.imei}
                                            value={name.imei} >
                                            <Checkbox
                                              checked={
                                                skuName.indexOf(name.imei) > -1
                                              }
                                            />
                                            <ListItemText primary={name.imei} />
                                          </MenuItem>
                                        ))
                                      )}
                                    </Select>
                                  </FormControl> */}
                                  {/* {order.cart._id} */}
                                  <Select
                                    options={availableSkus}
                                    placeholder="Select SKU"
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    value={assignedModels}
                                    name={`${order.cart._id}-${i},${order.cart.quickDetails.color}`}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    isMulti
                                  />

                                  {/* <select
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    id="dd"
                                    name={`${order.cart.modelId}-${i},${order.cart.quickDetails.color}`}
                                    multiple
                                 
                                  >
                                    <option value=" " disabled >
                                      {loadingSkus &&
                                      `${order.cart.modelId}-${i}`
                                        ? "Loading..."
                                        : "Select SKU"}
                                    </option>
                                    {availableSkus?.inv?.map(sku => (<><option key={sku.imei} value={sku.imei}>{sku.imei}</option></>))}
                                  </select>  */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {orderDetails.orders[0]?.order.shippingMethod.toLowerCase() ===
                      "deliver" && (
                      <div className="form-group">
                        <label className="d-block font-weight-bold">
                          Delivery
                        </label>
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="free_delivery"
                              checked={dispatchDetails.free_delivery === "true"}
                              value={"true"}
                              onChange={onChange}
                            />
                            Free Delivery
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="free_delivery"
                              value={"false"}
                              checked={
                                dispatchDetails.free_delivery === "false"
                              }
                              onChange={onChange}
                            />
                            Courier
                          </label>
                        </div>
                      </div>
                    )}

                    {dispatchDetails.free_delivery === "false" &&
                      orderDetails.orders[0]?.order.shippingMethod.toLowerCase() ===
                        "deliver" && (
                        <>
                          <div className="form-group">
                            <label className="font-weight-bold">
                              Select Courier
                            </label>

                            <select
                              className="form-control form-control-sm"
                              onChange={onChange}
                              name="courier"
                              value={dispatchDetails.courier}
                            >
                              <option value="" selected>
                                Select a Courier
                              </option>
                              {couriers.map((courier) => (
                                <option value={courier._id} key={courier._id}>
                                  {" "}
                                  {`${courier.fname + " " + courier.lname}`}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group">
                            <label className="font-weight-bold">
                              Shipping Center
                            </label>

                            <select
                              className="form-control form-control-sm"
                              onChange={onChange}
                              name="dropoff_location"
                              value={dispatchDetails.dropoff_location}
                            >
                              <option value="" selected>
                                Select a Center
                              </option>
                              <option value="1" selected>
                                Center 1
                              </option>
                              <option value="2" selected>
                                Center 2
                              </option>
                            </select>
                          </div>
                        </>
                      )}
                    <div className="form-group">
                      <label className="font-weight-bold">Tracking No</label>
                      <input
                        type="text"
                        className="form-control"
                        name="order_number"
                        readOnly
                        value={dispatchDetails.order_number}
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold">
                        Notify Customer of Shipment
                      </label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            onChange={onChange}
                            name="notify"
                            value={dispatchDetails.notify}
                          />
                          Send Shipment details to your customer now
                        </label>
                      </div>
                    </div>

                    {/* <div className="form-group">
                      <label className="font-weight-bold">Comments</label>
                      <textarea
                        className="form-control form-control-sm"
                        rows={3}
                        onChange={onChange}
                        name="comments"
                        value={dispatchDetails.comments}
                      />
                    </div> */}
                  </form>
                </div>
                <div className="card-footer">
                  {" "}
                  <button
                    type="submit"
                    className="btn bg-primary ld-ext-right float-right"
                    form="fulfillForm"
                    disabled={isDispatching}
                  >
                    Dispatch
                    {isDispatching && (
                      <>
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
          </Drawer>
          <div className="card border-x-1 border-left-blue border-right-blue rounded-0">
            <div className="card-header bg-light header-elements-sm-inline">
              <h5 className="card-title font-weight-semibold">
                {`Order PKE-${orderDetails.orders[0]?.order.orderNumber}`}
              </h5>
              <div class="header-elements">
                {/* <button
                  type="button"
                  className="btn btn-success btn-sm mr-2"
                  onClick={() => openCollectionModal()}
                >
                  Mark as Collected
                </button> */}

                <button
                  type="button"
                  className="btn btn-success btn-labeled btn-labeled-left btn-sm legitRipple"
                  onClick={toggleDrawer(true)}
                  disabled={
                    orderDetails.orders[0]?.order.payment.toLowerCase() ===
                      "unpaid" ||
                    orderDetails.orders[0]?.order.status.toLowerCase() ===
                      "dispatched"
                  }
                >
                  <b>
                    <i className="icon-truck" />
                  </b>{" "}
                  {/* {console.log(orderDetails.orders[0]?.order.status)} */}
                  {orderDetails.orders[0]?.order.status.toLowerCase() ===
                    "dispatched" && "Dispatched"}
                  {orderDetails.orders[0]?.order.status.toLowerCase() ===
                    "processing" && "Fulfill Order"}
                  {orderDetails.orders[0]?.order.status.toLowerCase() ===
                    "pending" && "Fulfill Order"}
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-group list-group-flush ">
                    <li className="list-group-item">
                      <span className="font-weight-semibold">Order ID:</span>
                      <div className="ml-2">
                        {`PKE-${orderDetails.orders[0]?.order.orderNumber}`}
                      </div>
                    </li>
                    <li className="list-group-item">
                      <span className="font-weight-semibold">Order Date:</span>
                      <div className="ml-2">
                        {format(
                          new Date(orderDetails.orders[0]?.order.createdAt),
                          "PP"
                        )}
                      </div>
                    </li>
                    <li className="list-group-item">
                      <span className="font-weight-semibold">
                        Payment Status:
                      </span>
                      <div className="ml-2">
                        <span
                          className={`badge ${
                            orderDetails.orders[0]?.order.payment.toLowerCase() ===
                            "unpaid"
                              ? "badge-danger"
                              : orderDetails.orders[0]?.order.payment.toLowerCase() ===
                                "paid"
                              ? "badge-success"
                              : "badge-warning"
                          }`}
                        >
                          {" "}
                          {orderDetails.orders[0]?.order.payment}
                        </span>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <span className="font-weight-semibold">
                        {" "}
                        {orderDetails.orders[0]?.order.shippingMethod.toLowerCase() ===
                          "deliver" && "Delivery Date:"}
                        {orderDetails.orders[0]?.order.shippingMethod.toLowerCase() ===
                          "pickup" && "Collection Date:"}
                      </span>
                      <div className="ml-2 text-capitalize">
                        {orderDetails.orders[0]?.order.expectedDate}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-group list-group-flush ">
                    <li className="list-group-item">
                      <span className="font-weight-semibold">
                        {orderDetails.orders[0]?.order.shippingMethod.toLowerCase() ===
                          "deliver" && "Delivery Address:"}
                        {orderDetails.orders[0]?.order.shippingMethod.toLowerCase() ===
                          "pickup" && "Pickup Location:"}
                      </span>
                      <div className="ml-2">
                        {" "}
                        {orderDetails?.orders[0]?.order?.shippingMethod.toLowerCase() ===
                        "deliver"
                          ? `${
                              orderDetails?.orders[0]?.order.physicaladdress
                                ?.address +
                              " ," +
                              orderDetails?.orders[0]?.order?.physicaladdress
                                ?.city
                            }`
                          : orderDetails?.orders[0]?.order?.billing?.name}
                      </div>
                    </li>
                    <li className="list-group-item">
                      <span className="font-weight-semibold">Order Type:</span>
                      <div className="ml-2 text-capitalize">
                        {orderDetails.orders[0]?.order.shippingMethod}
                      </div>
                    </li>

                    <li className="list-group-item">
                      <span className="font-weight-semibold">
                        Order Status:
                      </span>
                      <div className="ml-2">
                        <span
                          className={`badge badge-flat ${
                            orderDetails.orders[0]?.order.status.toLowerCase() ===
                            "processing"
                              ? "border-primary"
                              : orderDetails.orders[0]?.order.status.toLowerCase() ===
                                "pending"
                              ? "border-primary"
                              : orderDetails.orders[0]?.order.status.toLowerCase() ===
                                "dispatched"
                              ? "border-warning"
                              : "border-success"
                          } ${
                            orderDetails.orders[0]?.order.status.toLowerCase() ===
                            "processing"
                              ? "text-primary-600"
                              : orderDetails.orders[0]?.order.status.toLowerCase() ===
                                "pending"
                              ? "text-primary-600"
                              : orderDetails.orders[0]?.order.status.toLowerCase() ===
                                "dispatched"
                              ? "text-warning-600"
                              : "text-success-600"
                          }`}
                        >
                          {" "}
                          {orderDetails.orders[0]?.order.status}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead>
                    <tr>
                      <th className="font-weight-bold">Product</th>

                      <th className="font-weight-bold">Preferred Color</th>
                      <th className="font-weight-bold">Quantity</th>
                      <th className="font-weight-bold">Unit Price</th>
                      <th className="font-weight-bold">Total Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.orders[0]?.carts.map((order) => (
                      <tr key={order.cart._id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="">
                              <img
                                src={order.image}
                                className=""
                                alt="device"
                                width={120}
                                height={120}
                              />
                            </div>
                            <div>
                              <a
                                href="#"
                                className="text-default font-weight-semibold"
                              >
                                {order.cart.quickDetails.name}
                              </a>
                              <div className="text-muted font-size-sm">
                                {order.cart.quickDetails.storage +
                                  "GB /" +
                                  order.cart.quickDetails.ram +
                                  "GB"}{" "}
                              </div>
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <span className="badge badge-mark border-blue mr-1" />
                          <span className="text-muted">
                            {order.order.status === "pending"
                              ? "processing"
                              : order.order.status}
                          </span>
                        </td> */}
                        <td>
                          <span className="text-success-600">
                            {order?.cart?.quickDetails?.colorLabel || "-"}
                          </span>
                        </td>
                        <td>
                          <h6 className="font-weight-semibold mb-0">
                            {order.cart.quantity}
                          </h6>
                        </td>
                        <td>
                          <h6 className="font-weight-semibold mb-0">
                            {thousandCommaSeparator(order.cart.unit)}
                          </h6>
                        </td>

                        <td>
                          <span className="">
                            {thousandCommaSeparator(order.cart.total)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <span>Order Subtotal:</span> {thousandCommaSeparator(0)}
                      </td>
                    </tr>

                    <tr>
                      <td className="border-top-0" />

                      <td className="border-top-0"></td>
                      <td className="border-top-0" />
                      <td className="border-top-0" />

                      <td className="border-top-0">
                        <span>Tax:</span> {thousandCommaSeparator(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-top-0" />
                      <td className="border-top-0" />
                      <td className="border-top-0" />
                      <td className="border-top-0" />

                      <td className="border-top-0">
                        <span>Shipping:</span> {thousandCommaSeparator(0)}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-top-0" />
                      <td className="border-top-0" />
                      <td className="border-top-0" />
                      <td className="border-top-0" />

                      <td className="font-weight-bold border-top-0">
                        <span className="font-weight-normal">Order Total:</span>{" "}
                        {thousandCommaSeparator(
                          orderDetails.orders[0]?.order.cummulativeTotal
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="card border-x-1 border-left-blue border-right-blue rounded-0">
            {/* <div className="card-header bg-light">
              <h5 className="card-title font-weight-semibold">
                Billing and Payment Information
              </h5>
            </div> */}
            <div className="nav-tabs-responsive bg-light border-top">
              <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
                <li className="nav-item">
                  <a
                    href="#billing"
                    className="nav-link active"
                    data-toggle="tab"
                  >
                    <i className="icon-credit-card mr-2" /> Billing Information
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#schedule" className="nav-link" data-toggle="tab">
                    <i className="icon-list mr-2" /> Payment Schedule
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="billing">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush ">
                        <li className="list-group-item">
                          <span className="font-weight-semibold">
                            Client Name:
                          </span>
                          <div className="ml-2">
                            {orderDetails.orders[0]?.order.user?.fname +
                              " " +
                              orderDetails.orders[0]?.order.user?.fname}
                          </div>
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-semibold">
                            Mobile Number:
                          </span>
                          <div className="ml-2">
                            {orderDetails.orders[0]?.order.user?.phone}
                          </div>
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-semibold">
                            Email Address:
                          </span>
                          <div className="ml-2">
                            {orderDetails.orders[0]?.order.user?.email}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="list-group list-group-flush ">
                        <li className="list-group-item">
                          <span className="font-weight-semibold">
                            Billing Method:
                          </span>
                          <div className="ml-2">
                            {orderDetails.orders[0]?.order.paymentMethod}
                          </div>
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-semibold">Amount:</span>
                          <div className="ml-2">
                            {thousandCommaSeparator(
                              orderDetails.orders[0]?.order.cummulativeTotal
                            )}
                          </div>
                        </li>
                        <li className="list-group-item">
                          <span className="font-weight-semibold">Balance:</span>
                          <div className="ml-2">
                            {thousandCommaSeparator(
                              orderDetails.orders[0]?.order.deficit
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade show " id="schedule">
                {/* <div className="card-body">
                  <div className="d-flex justify-content-center align-items-center">
                    {(orderDetails.orders[0]?.transactions &&
                      orderDetails.orders[0]?.transactions?.length === 0 &&
                      "No payment has been made") ||
                      (!orderDetails.orders[0]?.transactions &&
                        "No payment has been made")}
                    {orderDetails.orders[0]?.transactions &&
                      orderDetails.orders[0]?.transactions?.length !== 0 && (
                        <table className="table table-orders-history">
                          <thead>
                            <tr>
                              <th className="font-weight-bold">Order ID</th>
                              <th className="font-weight-bold">Payment Date</th>
                              <th className="font-weight-bold">
                                Phone Number{" "}
                              </th>
                              <th className="font-weight-bold">Amount Paid</th>
                              <th className="font-weight-bold">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails.orders[0]?.transactions &&
                              orderDetails.orders[0]?.transactions.map(
                                (trans) => (
                                  <tr key={trans._id}>
                                    <td>{trans.orderNumber}</td>
                                    <td>{trans.createdAt}</td>
                                    <td>{trans.phone}</td>
                                    <td>
                                      {thousandCommaSeparator(trans.paidAmount)}
                                    </td>
                                    <td>
                                      {thousandCommaSeparator(
                                        trans.deficit ? trans.deficit : 0
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      )}
                  </div>
                </div> */}
                <div className="card-body">
                  {
                  (Object.keys(paymentSchedule).length) ?   <div className="row">
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush ">
                      <li className="list-group-item">
                        <span className="font-weight-semibold">
                          Account Number:
                        </span>
                        <div className="ml-2">
                          {paymentSchedule?.accountNumber}
                        </div>
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-semibold">
                          Merchant Request ID :
                        </span>
                        <div className="ml-2">
                          {paymentSchedule?.MerchantRequestID}
                        </div>
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-semibold">Status :</span>
                        <div className="ml-2">{(paymentSchedule?.status === true)?<><span className="badge badge-flat border-primary text-primary-600">
                          <span className="text-muted">Active</span></span></>:<><span className="badge badge-flat border-primary text-danger-600">
                          <span className="text-muted">InActive</span></span></>}</div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-group list-group-flush ">
                      <li className="list-group-item">
                        <span className="font-weight-semibold">Amount:</span>
                        <div className="ml-2">   {thousandCommaSeparator(paymentSchedule?.amount)}</div>
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-semibold">Phone:</span>
                        <div className="ml-2">
                          {paymentSchedule?.phone}
                        </div>
                      </li>
                      <li className="list-group-item">
                        <span className="font-weight-semibold">Payment Time:</span>
                        <div className="ml-2">
                        {format(
                          new Date(paymentSchedule?.createdAt),
                          "PP"
                        )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>:'No payment has been made'
                  }
                
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
