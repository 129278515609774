export const addPartner = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/new_partner", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};


export const getPartners = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_partners", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};
export const getSinglePartner = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_partner_by_id/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};

export const addPartnerBranch = async (data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/new_partner_location",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};

export const getAllBranches = async () => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_partner_locations`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
};
export const getAllBranchesByPartner = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_location_by_partner_id/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const updatePartner = async (id, data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/update_partner/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};
export const deletePartner = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/delete_partner/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const deleteStore = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/delete_partner_location/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
