import React, { useState} from "react";
import Modal from "components/modal/Modal";


const CancelOrderModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [isDispatchingOrder, setIsDispatchingOrder] = useState(false);


  // const dispatchOrder = async () => {
  //   setIsDispatchingOrder(true);

  //   try {
  //     const { msg, success } = await dispatch(
  //       props.details.orders[0].order._id,
  //       dispatchDetails
  //     );
  //     if (success) {
  //       await props.refresh();
  //       props.close();
  //       setShowAlert(true);
  //       setIsDispatchingOrder(false);
  //     } else {
  //       setShowAlert(true);
  //       setIsDispatchingOrder(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setIsDispatchingOrder(false);
  //   }
  // };

 

  return (
    <Modal
      close={props.close}
      onAccept={() => {}}
      title={`Are you Sure?`}
      form="cancelorderForm"
      loading={isDispatchingOrder}
      size="sm"
    >
      <form onSubmit={(e) => e.preventDefault()} id="cancelorderForm">
       You are about to cancel <b>Samsung Galaxy S10</b> pre-order. This action is irreversible
      </form>
    </Modal>
  );
};

export default CancelOrderModal;
