import React, { useEffect, useState } from "react";
import { getDropoff } from "./ShippingService";
import { useParams } from "react-router";
import { Loader } from "components";
import AddDropoffModal from "./AddDropoffModal";
import ModalService from "components/modal/ModalService";

const Shipping = () => {
  const [dealerDetails, setDealerDetails] = useState({});

  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const getDropoffPoints = async () => {
    setLoading(true);
    try {
      const { success, msg, dealer } = await getDropoff(id);

      if (success) {
        setDealerDetails(dealer);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const openModal = () => {
    ModalService.open(AddDropoffModal, { refresh: () => getDropoffPoints() });
  };
  useEffect(() => {
    getDropoffPoints();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="d-flex align-items-start flex-column flex-md-row">
          <div className="w-100 overflow-auto order-2 order-md-1">
            <div className="card">
              <div className="card-header header-elements-md-inline">
                <h5 className="card-title">Delivery and Shipping</h5>
                <div className="header-elements"></div>
              </div>
              <div className="nav-tabs-responsive bg-light border-top">
                <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
                  <li className="nav-item">
                    <a
                      href="#drop-off"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      <i className="icon-pin mr-2" /> Drop-off Locations
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#delivery-fee"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="icon-truck mr-2" /> Configure Delivery Fee
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="drop-off">
                  <div className="card-header">
                    <div className="header-elements-md-inline">
                      <div />
                      <div className="header-elements">
                        <button
                          onClick={() => openModal()}
                          className="btn btn-sm bg-teal-400"
                        >
                          <i className="icon-pin mr-1" /> New Location
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="">
                      <table className="table text-nowrap">
                        {/* <thead>
                            <tr className="table-active ">
                              <td colSpan={3}>Total Products</td>
                              <td />
                              <td />
                              <td />
                              <td className="text-right">
                                <span className="badge bg-blue badge-pill">
                                 23
                                </span>
                              </td>
                            </tr>
                          </thead> */}

                        <tbody>
                          <tr>
                            <th className="font-weight-bold">Region</th>
                            <th className="font-weight-bold">Sub-Region</th>
                            <th className="font-weight-bold">Drop-off Point</th>

                            <th className="font-weight-bold">Current Status</th>

                            <th className="text-center" style={{ width: 20 }}>
                              <i className="icon-arrow-down12" />
                            </th>
                          </tr>

                          <tr>
                            <td className="text-center">
                              dss
                              <div className="font-size-sm text-muted line-height-1">
                                ssfsf
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                sdsd
                              </div>
                            </td>

                            <td>
                              <a
                                href
                                className="text-default font-weight-semibold letter-icon-title"
                              >
                                w
                              </a>
                            </td>
                            <td>q</td>
                            <td className="text-center">
                              <div className="list-icons">
                                <div className="list-icons-item dropdown">
                                  <button
                                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent"
                                    data-toggle="dropdown"
                                  >
                                    <i className="icon-menu7" />
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <button className="dropdown-item bg-transparent border-0 outline-0">
                                      <i className="icon-pin" /> Exact Location
                                    </button>
                                    <button className="dropdown-item bg-transparent border-0 outline-0">
                                      <i className="icon-edit" /> Edit
                                    </button>
                                    <button className="dropdown-item bg-transparent border-0 outline-0">
                                      <i className="icon-trash2" /> Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="delivery-fee">
                  <div className="card-body"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Shipping;
