import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { assignDealer, reAssignDealer } from "./RepresentativeService";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AssignRepresentativetModal = (props) => {
  let currentUser = JSON.parse(sessionStorage.getItem("user"));
  const [savingRep, setSavingRep] = useState(false);
  const [assignedDealer, setAssignedDealer] = useState("");
  const [dealers, setDealers] = useState([]);
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getDealers } = useHttp();

  const addNewRep = async () => {
    setSavingRep(true);

    let data = {
      admId: props.details._id,
      dealerId: assignedDealer,
    };

    try {
      const { msg, success } = await assignDealer(data);

      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSavingRep(false);
    }
  };
  const reAssignRep = async () => {
    setSavingRep(true);

    let data = {
      dealerId: assignedDealer,
    };

    try {
      const { msg, success } = await reAssignDealer(data, props.details._id);

      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSavingRep(false);
    }
  };

  useEffect(() => {
    getDealers("get_dealers", "GET", null, ({ data: { dealers } }) => {
      setDealers(dealers);
    });
  }, []);

  return (
    <Modal
      close={props.close}
      onAccept={() =>
        props.details.dealer.length < 1 ? addNewRep() : reAssignRep()
      }
      title={`${props.details.dealer.length < 1 ? "Assign" : "Reassign"} ${
        props.details?.fname + " " + props.details?.lname
      } to a dealer`}
      form="representativeForm"
      loading={savingRep}
    >
      {loading && "Preparing agent form...."}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="representativeForm">
          <div className="row">
            <div className="col-sm-12">
              <div class="form-group">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => setAssignedDealer(e.target.value)}
                  name="dealer"
                  value={assignedDealer}
                >
                  <option value="" selected>
                    Select a Dealer
                  </option>
                  {dealers.map((dealer) => (
                    <option value={dealer._id} key={dealer._id}>
                      {dealer.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AssignRepresentativetModal;
