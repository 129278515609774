/* eslint-disable jsx-a11y/anchor-is-valid */
import { Loader, Table } from "components";
import ModalService from "components/modal/ModalService";
import AddCampaignModal from "./AddCampaignModal";
import AddSendMessageModal from "./AddSendMessageModal";
import { useGetAllMessages } from "./hooks/useGetAllMessages";
import moment from "moment/moment";
import { Tag } from "antd";

const columnns = [
  {
    label: "Campaign Name",
    name: "name",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <div>{value}</div>
          </>
        );
      },
    },
  },

  {
    label: "Category",
    name: "to",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <div>{value}</div>
          </>
        );
      },
    },
  },
  {
    label: "Message",
    name: "message",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <div style={{ width: "60%" }}>{value}</div>
          </>
        );
      },
    },
  },
  {
    label: "Dispatched By",
    name: "dispatched",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <div>{value}</div>
          </>
        );
      },
    },
  },
  {
    label: "Date",
    name: "date",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <div>{moment(value).format("MMMM Do YYYY, h:mm:ss a")}</div>
          </>
        );
      },
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <>
            <Tag color={value === "Active" ? "success" : "error"}>{value}</Tag>
          </>
        );
      },
    },
  },
  {
    label: "ACTION",
    name: "_id",
    options: {
      filter: false,
      download: false,
      print: false,
      sort: false,
      setCellProps: () => ({
        style: { whiteSpace: "pre" },
      }),
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div className="list-icons">
            <div className="list-icons-item dropdown">
              <a
                href="#"
                className="list-icons-item dropdown-toggle caret-0"
                data-toggle="dropdown"
              >
                <i className="icon-menu7" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  href
                  className="dropdown-item"
                  onClick={() => {
                    console.log("");
                  }}
                >
                  <i className="icon-file-eye" /> View
                </a>
                <button
                  className="dropdown-item bg-transparent border-0"
                  onClick={() => {
                    console.log("");
                  }}
                >
                  <i className="text-danger icon-trash-alt" /> Delete
                </button>
              </div>
            </div>
          </div>
        );
      },
    },
  },
];
// Opens Campaign Modal
const openAddCampaignModal = () => {
  ModalService.open(AddCampaignModal);
};

// Adds Send Message Modal
const openSendMessageModal = () => {
  ModalService.open(AddSendMessageModal);
};

function Campaign() {
  const { campaigns, isLoading } = useGetAllMessages();
  console.log(campaigns);
  const campaign = campaigns?.sms?.map((campaign) => ({
    name: campaign?.campaignName,
    to: campaign?.category,
    date: campaign?.createdAt,
    message: campaign?.message,
    status: campaign?.active === true ? "Active" : "Inactive",
    dispatched: campaign?.userId?.fname + " " + campaign?.userId?.lname,
  }));

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <div
              style={{ alignSelf: "flex-end", display: "flex", gap: "2rem" }}
            >
              <button
                className="btn btn-sm bg-teal-400 ml-auto"
                onClick={() => openAddCampaignModal()}
              >
                <i className="icon-add mr-2" /> New Campaign
              </button>

              <button
                className="btn btn-sm bg-teal-400 ml-auto"
                onClick={() => openSendMessageModal()}
              >
                <i className="icon-comment mr-2" /> Send Message
              </button>
            </div>

            <Table columns={columnns} data={campaign} title={"Campaigns"} />
          </div>
        </div>
      )}
    </>
  );
}

export default Campaign;
