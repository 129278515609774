import React, { useEffect, useState } from "react";

import { Loader } from "components";
import { format } from "date-fns";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useHttp } from "hooks";
import { Link } from "react-router-dom";

const ProductConfiguration = () => {
  const [devices, setInventory] = useState([]);
  const { error, loading, sendHttpRequest: getProductConfigs } = useHttp();

  useEffect(() => {
    getProductConfigs(
      "get_models_with_variants",
      "GET",
      null,
      ({ data: { devices } }) => {
        setInventory(devices);
      }
    );
  }, []);

  return (
    <>
      {loading && devices.length === 0 && <Loader />}

      {!loading && devices.length === 0 && (
        <div className="card-body d-flex justify-content-center">
          No devices
        </div>
      )}
      {!loading && devices.length !== 0 && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">Available Models for Configuration</h4>
            <div className="header-elements"></div>
          </div>

          <div className="table-responsive">
            <table className="table text-nowrap">
              <thead>
                <tr className="table-active ">
                  <td colSpan={3}>Total Models</td>
                  <td />
                  <td />
                  <td className="text-right">
                    <span className="badge bg-blue badge-pill">
                      {devices.length}
                    </span>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th style={{ width: 300 }}>Model</th>
                  <th style={{ width: 200 }}>Variants</th>
                  <th style={{ width: 200 }}>Variant Price</th>
                  <th style={{ width: 200 }}>Variant Discount</th>
                  <th style={{ width: 200 }}>Created Date</th>
                  <th className="text-center" style={{ width: 120 }}>
                    Action
                  </th>
                </tr>
                {devices.map((el) => (
                  <tr key={el.devices._id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="rounded-round btn-icon btn-sm">
                          <img
                            src={el.image}
                            alt="product"
                            style={{
                              height: "2.5rem",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            {el.devices.modelName}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <ul className="list-unstyled mb-0">
                        {el.devices.variants.map((variant) => (
                          <li
                            className="mt-2"
                            key={variant._id}
                          >{`${variant.ram}GB/${variant.storage}${variant.storageType}`}</li>
                        ))}
                      </ul>
                    </td>

                    <td>
                      <ul className="list-unstyled mb-0">
                        {el.devices.variants.map((variant) => (
                          <li key={variant._id}>
                            <span className="text-default font-weight-normal">
                              {thousandCommaSeparator(variant.saleprice)}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul className="list-unstyled mb-0">
                        {el.devices.variants.map((variant) => (
                          <li key={variant._id}>
                            <span className="text-default font-weight-normal">
                              {thousandCommaSeparator(variant.discounted)}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </td>

                    <td>
                      <a href="#" className="text-default">
                        {format(new Date(el.devices.updatedAt), "PPpp")}
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <Link
                            to={`/inventory/product-configuration/${el.devices._id}`}
                          >
                            <i className="icon-gear mr-1" /> Configure
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductConfiguration;
