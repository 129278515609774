import React from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const SoldDevicesModel = (props) => {
  const queryClient = useQueryClient();
  const { toastDispatch } = useToastContext();
  const navigate = useNavigate();
  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: send_to_rts,
  } = useHttp();

  const assign = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    send_to_rts(
      `mark_returned_bulk`,
      "PUT",
      {
        devices: props?.selectedRepairRequest,
        userId: loggedInUser?.id,
      },
      ({ msg }) => {
        navigate("/sales/returns");
        queryClient.invalidateQueries({
          queryKey: ["soldDevices"],
        });
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });

        props.close();
      }
    );
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title="Do you want to mark as returned?"
      form="assign_rts"
      size="sm"
      loading={assigningWarehouse}
    >
      <form onSubmit={(e) => e.preventDefault()} id="assign_rts">
        <div style={{ height: "200px", overflowY: "scroll" }}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Model</th>
                <th scope="col">IMEI</th>
              </tr>
            </thead>
            {props?.tableData?.map((data, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <strong>{data.model}</strong> - {data.color}
                </td>
                <td>{data.imei}</td>
              </tr>
            ))}
          </table>
        </div>
      </form>
    </Modal>
  );
};

export default SoldDevicesModel;
