import { useMutation } from "@tanstack/react-query";
import { pushToWIPRTS } from "../services/ProductService";

export function useAssignToTeam() {
  const {
    mutate: assigningToTeam,
    isPending: isLoading,
    error,
  } = useMutation({
    mutationFn: pushToWIPRTS,
    onSuccess: (data) => {
      console.log(data);
    },
  });

  return { assigningToTeam, isLoading, error };
}
