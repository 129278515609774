import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Loader } from "components";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
const useStyles = makeStyles({
  root: {
    "& .MuiDropzoneArea-root": {
      border: "2px dashed #ddd",
      padding: "1.2rem",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: ".8125rem",
      marginTop: "1.5rem",
    },
    "& .MuiDropzoneArea-icon": {
      height: "25px",
      width: "25px",
    },
  },
});

const RMSchema = Yup.object().shape({
  fname: Yup.string().required("rm first name is required"),
  email: Yup.string().required("rm email is required"),
  region: Yup.string().required("Please select a region"),
});
const AddRM = () => {
  const [RMDetails, setRMDetails] = useState({
    fname: "",
    lname: "",
    email: "",
    region: "",
  });
  const [regions, setRegions] = useState([]);
  const [regionKey, setRegionKey] = useState({ id: "" });
  const { toastDispatch } = useToastContext();
  const [dealers, setDealers] = useState([]);
  const [dealersDatas, setDealersDatas] = useState([]);
  const [assignedDealer, setAssignedDealer] = useState([]);
  const navigate = useNavigate();
  const {
    error: errorRM,
    loading: savingRM,
    sendHttpRequest: addRM,
  } = useHttp();
  const {
    error: errorRegions,
    loading,
    sendHttpRequest: getRegions,
    sendHttpRequest: getcities,
  } = useHttp();
  const { error, deloading, sendHttpRequest: getDealers } = useHttp();
  const classes = useStyles();
  const getDealersFun = () => {
    getDealers("get_dealers", "GET", null, ({ data: { dealers } }) => {
      setDealers(dealers);
      const temp = dealers.map((el) => {
        let tempObj = {
          value: el._id,
          label: el.name,
        };
        return tempObj;
      });

      setDealersDatas(temp);
    });
  };
  const addNewRM = (values) => {
   // console.log("0000000000000000000000000000");
    let data = {
      fname: values.fname,
      lname: values.lname,
      region: values.region,
      dealer: values.dealer,
      email: values.email,
      assignedDealers: assignedDealer.map((currentValue) => currentValue.value),
    };
    addRM("new_rm", "POST", data, ({ msg }) => {
      setRMDetails({ fname: "", lname: "", region: "" });
      navigate("/sales/rms");
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setRMDetails({ ...RMDetails, [name]: value });
  };
  function handleSelect(data) {
    setAssignedDealer(data);
  }
  useEffect(() => {
    getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
    getDealersFun();
  }, []);
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className={`card`}>
          <div className="card-header header-elements-inline">
            {" "}
            <div>
              {" "}
              <button
                className="btn btn-sm bg-transparent ml-auto "
                onClick={() => navigate(-1)}
              >
                <i className="icon-circle-left2 text-teal-400 mr-2" /> Back
              </button>
            </div>
            <h5 className="card-title">Create New RM</h5>
            <div className="header-elements"></div>
          </div>

          <div className="card-body">
            <Formik
              initialValues={RMDetails}
              validationSchema={RMSchema}
              onSubmit={(values) => {
                addNewRM(values);
              }}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-12">
                    <fieldset>
                          <legend className="font-weight-semibold">
                            <i className="icon-reading mr-2" /> RM details
                          </legend>
                        </fieldset>
                      <div className="row">
                      
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              RM First Name:
                            </label>
                            <div className="col-lg-9">
                              <Field
                                name="fname"
                                type="text"
                                placeholder=""
                                className={
                                  "form-control" +
                                  (errors.fname && touched.fname
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fname"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              RM Last Name:
                            </label>
                            <div className="col-lg-9">
                              <Field
                                name="lname"
                                type="text"
                                placeholder=""
                                className={"form-control"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              RM Email:
                            </label>
                            <div className="col-lg-9">
                              <Field
                                name="email"
                                type="email"
                                placeholder=""
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              Select Dealers:
                            </label>
                            <div className="col-lg-9">
                              <Select
                                name="dealer"
                                options={dealersDatas}
                                placeholder="Select dealers"
                                value={assignedDealer}
                                onChange={handleSelect}
                                isSearchable={true}
                                isMulti
                              />
                              <ErrorMessage
                                name="dealer"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              Select region:
                            </label>
                            <div className="col-lg-9">
                              <select
                                required
                                placeholder="select a region"
                                className={
                                  "form-control form-control-sm" +
                                  (errors.region && touched.region
                                    ? " is-invalid"
                                    : "")
                                }
                                name="region"
                                value={RMDetails?.region}
                                onChange={(e) => {
                                  onChange(e);
                                  setFieldValue("region", e.target.value);
                                  setRegionKey({ id: e.target.value });
                                }}
                              >
                                <option value="">Select RM's region</option>
                                {regions?.map((region) => (
                                  <option value={region?._id} key={region?._id}>
                                    {region?.name}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="region"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      type="submit"
                      className="btn btn-primary legitRipple"
                    >
                      Submit <i className="icon-paperplane ml-2" />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default AddRM;
