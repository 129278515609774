import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Loader } from "components";
import { useHttp } from "hooks";

const Table = ({
  loading,
  data = [],
  columns = [],
  title = "Table Title",
  count = data.length, // to be retured by api total records
  setPage,
  page,
  setRowsPerPage,
  option
}) => {
  // const { error, loading: loading, sendHttpRequest: getRequests } = useHttp();
  const [tableState, setTableState] = useState([]);
  // const [data2, setData2] = useState(data);
  const [page2, setPage2] = useState(0);
  const [total, setTotal] = useState(count);
  const changePage = async (page, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    setPage(page);
  };
  function changePage2(newTableState) {
    let state = {
      ...tableState,
      ...newTableState,
      pageNo: newTableState.page + 1,
      pageSize: newTableState.rowsPerPage,
      searchText: newTableState.searchText,
    };
    setTableState(state);
    console.log("we call api here");
    // CALL API with state parameter
    // setData(data);
    // setPage(page);
    // setTotal(total);
  }

  const handleFilterSubmit = (props) => {
    console.log("received filter props", props);
  };

  let tempCols = columns;
  tempCols.map((el) => {
    let tempOptions = { ...el };
    if (tempOptions.options) {
      let options = {
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      };
      tempOptions.options = { ...tempOptions.options, ...options };
    } else {
      let options = {
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      };
      tempOptions["options"] = options;
    }
  });

  const options = {
    filter: true,
    filterType: "multiselect",
    selectableRows: false,
    responsive: "scroll",
    ...option,
    // onFilterChange: (column, filters) => {
    //   console.log(column, filters);
    // },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
   },
    // serverSide: true,
    // count: total,
    rowsPerPageOptions: [10, 50, 100, 250, 500, 1000,1500,2000],
    page,
    textLabels: {
      body: {
        noMatch: loading ? (
          <Loader loading={loading} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },

    // onTableChange: (action, newTableState) => {
    //   switch (action) {
    //     case "changePage":
    //     case "changeRowsPerPage":
    //       changePage2(newTableState);
    //       break;
    //     case "search":
    //       changePage2(newTableState);
    //       break;
    //     case "filterChange":
    //       handleFilterSubmit(newTableState.filterList);
    //       break;
    //     default:
    //     //       console.log("action not handled.");
    //   }
    // },
    // onTableChange: (action, tableState) => {
    //   switch (action) {
    //     case "changePage":
    //       changePage(tableState.page, tableState.rowsPerPage);
    //       break;
    //     case "changeRowsPerPage":
    //       changePage(tableState.page, tableState.rowsPerPage);
    //       break;

    //     default:
    //       console.log("action not handled.");
    //   }
    // },
  };
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={tempCols}
      options={options}
    />
  );
};

export default Table;
