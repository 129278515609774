import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Loader } from "components";
import { useHttp } from "hooks";

const DealerDetails = () => {
  const [dealerDetails, setDealerDetails] = useState({});
  const [reps, setReps] = useState([]);
  // const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { error, loading, sendHttpRequest: getSingleDealer } = useHttp();
  const {
    error: errorSalesRep,
    loading: loadingSalesRep,
    sendHttpRequest: getSalesRepByDealer,
  } = useHttp();

  const getDealerDetails = () => {
    getSingleDealer(
      `get_dealer_by_id/${id}`,
      "GET",
      null,
      ({ data: { dealer } }) => {
        setDealerDetails(dealer);
        getSalesRepByDealer(
          `get_reps_by_dealer/${id}`,
          "GET",
          null,
          ({ data: { reps } }) => setReps(reps)
        );
      }
    );
  };
  useEffect(() => {
    getDealerDetails();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="d-flex align-items-start flex-column flex-md-row">
          <div className="w-100 overflow-auto order-2 order-md-1">
            <div className="card">
              <div className="card-header header-elements-md-inline">
                <h5 className="card-title">{dealerDetails.name}</h5>
                <div className="header-elements">
                  <a href="#">
                    <i className="icon-pencil4 " /> Edit
                  </a>
                </div>
              </div>
              <div className="nav-tabs-responsive bg-light border-top">
                <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
                  <li className="nav-item">
                    <a
                      href="#course-overview"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      <i className="icon-menu7 mr-2" /> Overview
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#sales-reps"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="icon-people mr-2" /> Sales Reps
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#course-schedule"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      <i className="icon-stack mr-2" /> Stock available
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" data-toggle="tab">
                      <i className="icon-calendar3 mr-2" /> Branches
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" data-toggle="tab">
                      <i className="icon-calendar3 mr-2" /> Orders
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="course-overview">
                  <div className="card-body">
                    <div className="mt-1 mb-4">
                      {/* <h6 className="font-weight-semibold">Dealer overview</h6>
                      <p>
                        Then sluggishly this camel learned woodchuck far
                        stretched unspeakable notwithstanding the walked owing
                        stung mellifluously glumly rooster more examined one
                        that combed until a less less witless pouted up voluble
                        timorously glared elaborate giraffe steady while grinned
                        and got one beaver to walked. Connected picked rashly
                        ocelot flirted while wherever unwound much more one
                        inside emotionally well much woolly amidst upon far
                        burned ouch sadistically became.
                      </p> */}
                      {/* <p>
                        A collection of textile samples lay spread out on the
                        table - Samsa was a travelling salesman - and above it
                        there hung a picture that he had recently cut out of an
                        illustrated magazine and housed in a nice, gilded frame.
                        It showed a lady fitted out with a fur hat and fur boa
                        who sat upright, raising a heavy fur muff that covered
                        the whole of her lower arm towards the viewer. Gregor
                        then turned to look out the window at the dull weather.
                      </p> */}
                    </div>
                    <h6 className="font-weight-semibold">
                      Location Map here below
                    </h6>
                  </div>
                </div>
                <div className="tab-pane fade" id="sales-reps">
                  <div className="card-body">
                    <div className="row">
                      {reps.map((rep) => (
                        <div className="col-xl-4 col-md-6">
                          <div className="card card-body">
                            <div className="media">
                              <div className="mr-3">
                                <a href="#">
                                  <img
                                    src="https://via.placeholder.com/150x150"
                                    className="rounded-circle"
                                    width={42}
                                    height={42}
                                    alt="rep"
                                  />
                                </a>
                              </div>
                              <div className="media-body">
                                <h6 className="mb-0">
                                  {rep.fname + " " + rep.lname}
                                </h6>
                                <span className="text-muted">{rep.phone}</span>
                              </div>
                              <div className="ml-3 align-self-center">
                                <div className="list-icons">
                                  <div className="list-icons-item dropdown">
                                    <a
                                      href="#"
                                      className="list-icons-item dropdown-toggle caret-0"
                                      data-toggle="dropdown"
                                    >
                                      <i className="icon-menu7" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a href="#" className="dropdown-item">
                                        <i className="icon-arrow-right7" /> View
                                        more
                                      </a>

                                      <a href="#" className="dropdown-item">
                                        <i className="icon-comment-discussion" />{" "}
                                        Send mail
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="d-flex justify-content-center mt-3 mb-3">
                      <ul className="pagination">
                        <li className="page-item">
                          <a href="#" className="page-link">
                            <i className="icon-arrow-small-right" />
                          </a>
                        </li>
                        <li className="page-item active">
                          <a href="#" className="page-link">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            4
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            5
                          </a>
                        </li>
                        <li className="page-item">
                          <a href="#" className="page-link">
                            <i className="icon-arrow-small-left" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="course-schedule">
                  <div className="card-body">
                    <div className="schedule" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar sidebar-light ml-md-2 sidebar-component sidebar-component-right border-0 shadow-0 order-1 order-md-2 sidebar-expand-md">
            <div className="sidebar-content">
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    At a glance
                  </span>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse" />
                    </div>
                  </div>
                </div>
                <div className="card-body pb-0">
                  <a href="#" className="btn bg-teal-400 btn-block mb-2">
                    New Branch
                  </a>
                </div>
                <table className="table table-borderless table-xs border-top-0 my-2">
                  <tbody>
                    <tr>
                      <td className="font-weight-semibold">Branches:</td>
                      <td className="text-right">1</td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Status:</td>
                      <td className="text-right">
                        <span className="badge bg-success">Open</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Total Devices:</td>
                      <td className="text-right">216</td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Contacts:</td>
                      <td className="text-right">0712213456</td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Location:</td>
                      <td className="text-right">Nairobi CBD</td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Lead Rep:</td>
                      <td className="text-right">
                        <a href="#">Eugene Kopyov</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-semibold">Sales Reps:</td>
                      <td className="text-right">{reps.length}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DealerDetails;
