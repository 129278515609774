import React from 'react';

const MainView = () => {
  return (
    <>
      {/* Main charts */}
      <div className="row">
        <div className="col-xl-7">
          {/*Inventory Summary */}
          {/* <div className="card">
            <div className="card-header header-elements-inline">
              <h6 className="card-title">Inventory Summary</h6>
              <div className="header-elements">
                <div className="form-check form-check-right form-check-switchery form-check-switchery-sm">
                  <label className="form-check-label">
                    Live update:
                    <input
                      type="checkbox"
                      className="form-input-switchery"
                      defaultChecked
                      data-fouc
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="card-body py-0">
              <div className="row">
                <div className="col-sm-4">
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <a
                      href="#"
                      className="btn bg-transparent border-teal text-teal rounded-round border-2 btn-icon mr-3"
                    >
                      <i className="icon-plus3" />
                    </a>
                    <div>
                      <div className="font-weight-semibold">To be Packed</div>
                      <span className="text-muted">2,349 avg</span>
                    </div>
                  </div>
                  <div className="w-75 mx-auto mb-3" id="new-visitors" />
                </div>
                <div className="col-sm-4">
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <a
                      href="#"
                      className="btn bg-transparent border-warning-400 text-warning-400 rounded-round border-2 btn-icon mr-3"
                    >
                      <i className="icon-watch2" />
                    </a>
                    <div>
                      <div className="font-weight-semibold">To be delivered</div>
                      <span className="text-muted">08:20 avg</span>
                    </div>
                  </div>
                  <div className="w-75 mx-auto mb-3" id="new-sessions" />
                </div>
                <div className="col-sm-4">
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <a
                      href="#"
                      className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon mr-3"
                    >
                      <i className="icon-people" />
                    </a>
                    <div>
                      <div className="font-weight-semibold">To be Invoiced</div>
                      <span className="text-muted">
                        <span className="badge badge-mark border-success mr-2" />{" "}
                        5,378 avg
                      </span>
                    </div>
                  </div>
                  <div className="w-75 mx-auto mb-3" id="total-online" />
                </div>
              </div>
            </div>
            <div className="chart position-relative" id="traffic-sources" />
          </div> */}
          {/* /inventory summary*/}
        </div>
        <div className="col-xl-5">
          {/* Product stats */}
          {/* <div className="card">
            <div className="card-header header-elements-inline">
              <h6 className="card-title">Product Details</h6>
              <div className="header-elements">
                <select className="form-control" id="select_date" data-fouc>
                  <option value="val1">June, 29 - July, 5</option>
                  <option value="val2">June, 22 - June 28</option>
                  <option value="val3" selected>
                    June, 15 - June, 21
                  </option>
                  <option value="val4">June, 8 - June, 14</option>
                </select>
              </div>
            </div>
            <div className="card-body py-0">
              <div className="row text-center">
                <div className="col-4">
                  <div className="mb-3">
                    <h5 className="font-weight-semibold mb-0">5,689</h5>
                    <span className="text-muted font-size-sm">All products</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <h5 className="font-weight-semibold mb-0">568</h5>
                    <span className="text-muted font-size-sm">All Item groups</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <h5 className="font-weight-semibold mb-0">64</h5>
                    <span className="text-muted font-size-sm">
                      Low stock items
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart mb-2" id="app_sales" />
            <div className="chart" id="monthly-sales-stats" />
          </div> */}
          {/* /product stats */}
        </div>
      </div>
      {/* /main charts */}
      {/* Dashboard content */}
      <div className="row">
        <div className="col-xl-8">
        
          
          {/* Latest product to be added to Inventory */}
          {/* <div className="card">
            <div className="card-header header-elements-sm-inline">
              <h6 className="card-title">Last Products Inventory</h6>
              <div className="header-elements">
                <a className="text-default daterange-ranges font-weight-semibold cursor-pointer dropdown-toggle">
                  <i className="icon-calendar3 mr-2" />
                  <span />
                </a>
              </div>
            </div>
            <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <div id="tickets-status" />
                <div className="ml-3">
                  <h5 className="font-weight-semibold mb-0">
                    14,327{" "}
                    <span className="text-success font-size-sm font-weight-normal">
                      <i className="icon-arrow-up12" /> (+2.9%)
                    </span>
                  </h5>
                  <span className="badge badge-mark border-success mr-1" />{" "}
                  <span className="text-muted">Jun 16, 10:00 am</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <a
                  href="#"
                  className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon"
                >
                  <i className="icon-alarm-add" />
                </a>
                <div className="ml-3">
                  <h5 className="font-weight-semibold mb-0">1,132</h5>
                  <span className="text-muted">total tickets</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <a
                  href="#"
                  className="btn bg-transparent border-indigo-400 text-indigo-400 rounded-round border-2 btn-icon"
                >
                  <i className="icon-spinner11" />
                </a>
                <div className="ml-3">
                  <h5 className="font-weight-semibold mb-0">06:25:00</h5>
                  <span className="text-muted">response time</span>
                </div>
              </div>
              <div>
                <a href="#" className="btn bg-teal-400">
                  <i className="icon-statistics mr-2" /> Report
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr>
                    <th style={{ width: 50 }}>Due</th>
                    <th style={{ width: 300 }}>User</th>
                    <th>Description</th>
                    <th className="text-center" style={{ width: 20 }}>
                      <i className="icon-arrow-down12" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-active table-border-double">
                    <td colSpan={3}>Total Products</td>
                    <td className="text-right">
                      <span className="badge bg-blue badge-pill">24</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <h6 className="mb-0">12</h6>
                      <div className="font-size-sm text-muted line-height-1">
                        hours
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-teal-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Annabelle Doney
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-blue mr-1" />{" "}
                            Active
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div className="font-weight-semibold">
                          [#1183] Workaround for OS X selects printing bug
                        </div>
                        <span className="text-muted">
                          Chrome fixed the bug several versions ago, thus
                          rendering this...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-checkmark3 text-success" />{" "}
                              Resolve issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <h6 className="mb-0">16</h6>
                      <div className="font-size-sm text-muted line-height-1">
                        hours
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a href="#">
                            <img
                              src="../../../../global_assets/images/placeholders/placeholder.jpg"
                              className="rounded-circle"
                              width={32}
                              height={32}
                              alt
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold"
                          >
                            Chris Macintyre
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-blue mr-1" />{" "}
                            Active
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div className="font-weight-semibold">
                          [#1249] Vertically center carousel controls
                        </div>
                        <span className="text-muted">
                          Try any carousel control and reduce the screen width
                          below...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-checkmark3 text-success" />{" "}
                              Resolve issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <h6 className="mb-0">20</h6>
                      <div className="font-size-sm text-muted line-height-1">
                        hours
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-blue rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Robert Hauber
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-blue mr-1" />{" "}
                            Active
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div className="font-weight-semibold">
                          [#1254] Inaccurate small pagination height
                        </div>
                        <span className="text-muted">
                          The height of pagination elements is not consistent
                          with...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-checkmark3 text-success" />{" "}
                              Resolve issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <h6 className="mb-0">40</h6>
                      <div className="font-size-sm text-muted line-height-1">
                        hours
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-warning-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Robert Hauber
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-blue mr-1" />{" "}
                            Active
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div className="font-weight-semibold">
                          [#1184] Round grid column gutter operations
                        </div>
                        <span className="text-muted">
                          Left rounds up, right rounds down. should keep
                          everything...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-checkmark3 text-success" />{" "}
                              Resolve issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-active table-border-double">
                    <td colSpan={3}>Resolved tickets</td>
                    <td className="text-right">
                      <span className="badge bg-success badge-pill">42</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <i className="icon-checkmark3 text-success" />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-success-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Alan Macedo
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-success mr-1" />{" "}
                            Resolved
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div>[#1046] Avoid some unnecessary HTML string</div>
                        <span className="text-muted">
                          Rather than building a string of HTML and then parsing
                          it...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-plus3 text-blue" /> Unresolve
                              issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <i className="icon-checkmark3 text-success" />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-pink-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Brett Castellano
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-success mr-1" />{" "}
                            Resolved
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div>[#1038] Update json configuration</div>
                        <span className="text-muted">
                          The <code>files</code> property is necessary to
                          override the files property...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-plus3 text-blue" /> Unresolve
                              issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <i className="icon-checkmark3 text-success" />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a href="#">
                            <img
                              src="../../../../global_assets/images/placeholders/placeholder.jpg"
                              className="rounded-circle"
                              width={32}
                              height={32}
                              alt
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold"
                          >
                            Roxanne Forbes
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-success mr-1" />{" "}
                            Resolved
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div>[#1034] Tooltip multiple event</div>
                        <span className="text-muted">
                          Fix behavior when using tooltips and popovers that
                          are...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-plus3 text-blue" /> Unresolve
                              issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-active table-border-double">
                    <td colSpan={3}>Closed tickets</td>
                    <td className="text-right">
                      <span className="badge bg-danger badge-pill">37</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <i className="icon-cross2 text-danger-400" />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a href="#">
                            <img
                              src="../../../../global_assets/images/placeholders/placeholder.jpg"
                              className="rounded-circle"
                              width={32}
                              height={32}
                              alt
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold"
                          >
                            Mitchell Sitkin
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-danger mr-1" />{" "}
                            Closed
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div>
                          [#1040] Account for static form controls in form group
                        </div>
                        <span className="text-muted">
                          Resizes control label's font-size and account for the
                          standard...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-plus3 text-blue" /> Unresolve
                              issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-spinner11 text-grey" /> Reopen
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">
                      <i className="icon-cross2 text-danger" />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-brown-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Katleen Jensen
                          </a>
                          <div className="text-muted font-size-sm">
                            <span className="badge badge-mark border-danger mr-1" />{" "}
                            Closed
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href="#" className="text-default">
                        <div>
                          [#1038] Proper sizing of form control feedback
                        </div>
                        <span className="text-muted">
                          Feedback icon sizing inside a larger/smaller
                          form-group...
                        </span>
                      </a>
                    </td>
                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a href="#" className="dropdown-item">
                              <i className="icon-undo" /> Quick reply
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-plus3 text-blue" /> Unresolve
                              issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-spinner11 text-grey" /> Reopen
                              issue
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* / product  added to Inventory */}
         
        </div>
        <div className="col-xl-4">
       
          {/* Daily activity */}
          {/* <div className="card">
            <div className="card-header header-elements-inline">
              <h6 className="card-title">Daily activity overview</h6>
              <div className="header-elements">
               
                <div className="list-icons ml-3">
                  <div className="list-icons-item dropdown">
                    <a
                      href="#"
                      className="list-icons-item dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i className="icon-cog3" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a href="#" className="dropdown-item">
                        <i className="icon-sync" /> Update data
                      </a>
                      <a href="#" className="dropdown-item">
                        <i className="icon-list-unordered" /> Detailed log
                      </a>
                      <a href="#" className="dropdown-item">
                        <i className="icon-pie5" /> Statistics
                      </a>
                      <div className="dropdown-divider" />
                      <a href="#" className="dropdown-item">
                        <i className="icon-cross3" /> Clear list
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="chart" id="sales-heatmap" />
            </div>
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr>
                    <th className="w-100">Activity</th>
                    <th>Time</th>
                 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-primary-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Sigma application
                          </a>
                          <div className="text-muted font-size-sm">
                            <i className="icon-checkmark3 font-size-sm mr-1" />{" "}
                            New order
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-muted font-size-sm">06:28 pm</span>
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-danger-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Alpha application
                          </a>
                          <div className="text-muted font-size-sm">
                            <i className="icon-spinner11 font-size-sm mr-1" />{" "}
                            Renewal
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-muted font-size-sm">04:52 pm</span>
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-indigo-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Delta application
                          </a>
                          <div className="text-muted font-size-sm">
                            <i className="icon-lifebuoy font-size-sm mr-1" />{" "}
                            Support
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-muted font-size-sm">01:26 pm</span>
                    </td>
                  
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-success-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Omega application
                          </a>
                          <div className="text-muted font-size-sm">
                            <i className="icon-lifebuoy font-size-sm mr-1" />{" "}
                            Support
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-muted font-size-sm">11:46 am</span>
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="mr-3">
                          <a
                            href="#"
                            className="btn bg-danger-400 rounded-round btn-icon btn-sm"
                          >
                            <span className="letter-icon" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Alpha application
                          </a>
                          <div className="text-muted font-size-sm">
                            <i className="icon-spinner11 font-size-sm mr-2" />{" "}
                            Renewal
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-muted font-size-sm">10:29 am</span>
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* /Daily activity */}
       
         
        </div>
      </div>
      {/* /dashboard content */}
    </>
  );
};

export default MainView;
