import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddChannelsModal = (props) => {
  const [channelDetails, setChannelsDetails] = useState({ name: "" });
  const { toastDispatch } = useToastContext();
  const {
    error: errorChannels,
    loading: isAddingChannels,
    sendHttpRequest: addChannels,
  } = useHttp();
  const adChannels = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      name: channelDetails.name,
      userId: loggedInUser?.id,
    };
    addChannels("new_channel", "POST", data, ({ msg }) => {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();

  const onChange = (e) => {
    const { name, value } = e.target;
    setChannelsDetails({ ...channelDetails, [name]: value });
  };

  return (
    <Modal close={props.close} title={`Add Channel`} size="sm">
      {!loadingModels && (
        <Formik
          enableReinitialize
          initialValues={channelDetails}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Channel name is required"),
          })}
          onSubmit={() => adChannels()}
        >
          {({ errors, status, touched }) => (
            <Form id="addCupon">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Name </label>
                    <div class="form-group">
                      <input
                        className={
                          "form-control form-control-sm" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        onChange={onChange}
                        name="name"
                        value={channelDetails.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn bg-primary ld-ext-right float-right"
                disabled={isAddingChannels}
              >
                Submit
                {isAddingChannels && (
                  <>
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                )}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddChannelsModal;
