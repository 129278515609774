import { headers } from "../../../../../helpers/apiConfigs";

export async function getAllTeamLeaders() {
  try {
    var response = await fetch(
      process.env.REACT_APP_BASE_URL + "get_all_team_leaders",
      {
        method: "GET",
        headers: headers,
      }
    );

    var data = await response.json();
    console.log(data);

    return data;
  } catch (error) {
    return { succcess: false, msg: error?.message || "Something went wrong" };
  }
}
