import { headers } from "../../../../helpers/apiConfigs";

export async function createCampaign({ campaignName, to, message }) {
  try {
    var response = await fetch(
      process.env.REACT_APP_BASE_URL + "send_sms_campaign",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          campaignName: campaignName,
          category: to.toLowerCase(),
          message: message,
          userId: JSON.parse(sessionStorage.getItem("user"))?.id,
        }),
      }
    );

    var data = await response.json();

    return data;
  } catch (error) {
    return { succcess: false, msg: error?.message || "Something went wrong" };
  }
}

export async function sendMessage({ phone, message }) {
  try {
    var response = await fetch(
      process.env.REACT_APP_BASE_URL + "single_sms_campaign",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          phone: phone,
          message: message,
        }),
      }
    );

    var data = await response.json();

    return data;
  } catch (error) {
    return { succcess: false, msg: error?.message || "Something went wrong" };
  }
}

export async function getAllCampaigns() {
  try {
    var response = await fetch(
      process.env.REACT_APP_BASE_URL + "get_sms_campaigns",
      {
        method: "GET",
        headers: headers,
      }
    );

    var data = await response.json();

    return data;
  } catch (error) {
    return { succcess: false, msg: error?.message || "Something went wrong" };
  }
}
