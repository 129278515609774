import React, { useState } from "react";
import Modal from "components/modal/Modal";

const EditLeadModal = (props) => {
  const [loading] = useState(false);
  const [warehouseDetails, setWarehouseDetails] = useState({
    name: "",
    region: "",
    dealer: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setWarehouseDetails({ ...warehouseDetails, [name]: value });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => {
        console.log("Holla");
      }}
      title="Edit Lead"
      form="warehouseForm"
      loading={loading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="warehouseForm">
        <div className="form-group">
          <label>Lead Name</label>
          <input
            name="name"
            type="text"
            placeholder={props.data?.name}
            onChange={onChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Lead Phone</label>
          <input
            name="phone"
            type="text"
            placeholder={props.data?.phone}
            onChange={onChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Select Category</label>
          <select
            className="form-control form-control-sm"
            onChange={onChange}
            name="region"
            required
            defaultValue={props.data?.category}
          >
            <option>Cold</option>
            <option>Warm</option>
            <option>Hot</option>
          </select>
        </div>
      </form>
    </Modal>
  );
};

export default EditLeadModal;
