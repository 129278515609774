import ListRepresentatives from "./Representatives";
import RepresentativeDetails from './RepresentativeDetails'

const RepresentativesRoutes = [
  {
    path: "/representatives",
    element: <ListRepresentatives />,
  },
  {
    path: "/representative/:id",
    element: <RepresentativeDetails />,
  },
];

export default RepresentativesRoutes;
