import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Loader } from "components";
import { useHttp } from "hooks";
import AddCityModal from "./AddCityModal";
import ModalService from "components/modal/ModalService";

const ListCity = () => {
  const [cities, setCities] = useState([]);
  const {
    loading,
    sendHttpRequest: getcities,
    sendHttpRequest: getRegions,
  } = useHttp();
  const [regions, setRegions] = useState([]);
  const getAllCities = async () => {
    await getcities("get_all_cities", "GET", null, ({ data: { cities } }) =>
      setCities(cities)
    );
    await getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
  };

  const openModal = () => {
    ModalService.open(AddCityModal, { refresh: () => getAllCities() });
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">City</h4>
          </div>
          <div className="card-body">
            {
              <>
                <div className="card-header header-elements-sm-inline">
                  <h6 className="card-title">Active Cities</h6>
                  <div className="header-elements">
                    <div className="form-group row">
                      <div className="input-group">
                        <span className="input-group-append">
                          <button
                            className="btn btn-sm bg-teal legitRipple"
                            type="button"
                            onClick={openModal}
                          >
                            Add City
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {cities?.length === 0 && "No available cities"}
                {cities?.length !== 0 && (
                  <div className="table-responsive">
                    <table className="table text-nowrap">
                      <thead>
                        <tr>
                          <th className="font-weight-bold">City Name</th>
                          <th className="font-weight-bold">Region Name</th>
                          <th className="font-weight-bold">Created Date</th>
                          <th className="font-weight-bold">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="table-active table-border-double">
                          <td colSpan={3}>Total City</td>
                          <td className="text-right font-weight-bold">
                            <span className="badge badge-primary badge-pill">
                              {cities?.length}
                            </span>
                          </td>
                        </tr>
                        {cities?.map((resultVal) => (
                          <tr key={resultVal?._id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                    {resultVal?.name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                    {regions?.map((region) =>
                                      region?._id === resultVal?.region
                                        ? region?.name
                                        : ""
                                    )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="text-muted">
                                {format(new Date(resultVal?.createdAt), "PPpp")}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge ${
                                  resultVal?.active
                                    ? "bg-success-400"
                                    : "bg-danger"
                                }`}
                              >
                                {resultVal?.active ? "Active" : "InActive"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default ListCity;
