export const specifications = [
  {
    body: {
      dimensions: "",
      weight: "",
      build: "",
      sim: "",
    },
    network: {
      technology: "",
    },
    display: {
      type: "",
      size: "",
      resolution: "",
      protection: "",
    },
    platform: {
      os: "",
      chipset: "",
      cpu: "",
      gpu: "",
    },
    memory: {
      cardslot: "",
      internal: "",
    },
    mainCamera: {
      type: "",
      features: "",
      video: "",
    },
    selfieCamera: {
      dual: "",
      features: "",
      video: "",
    },
    sound: {
      loudspeaker: "",
      "3.5mm jack": "",
    },
    comms: {
      wlan: "",
      bluetooth: "",
      gps: "",
      nfc: "",
      radio: "",
      usb: "",
    },
    features: {
      sensors: "",
    },
    battery: {
      type: "",
      charging: "",
    },
    misc: {
      color: "",
      models: "",
      price: "",
      warranty: "",
      inTheBox: "",
    },
  },
];

export const highlights = {
  battery: "",
  main_camera: "",
  front_camera: "",
  display: "",
  processor: "",
  connectivity: "",
  colors: "",
  os: "",
};
