import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import DeleteRMModal from "./DeleteRMModal";
import EditRMModal from "./EditRMModal";
import ModalService from "components/modal/ModalService";
import { useHttp } from "hooks";
import { Loader, Table } from "components";

const RMs = () => {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const [RMs, setRMs] = useState([]);
  const [RMsData, setRMsData] = useState([]);
  const navigate = useNavigate();
  const { error, loading, sendHttpRequest: getRMs } = useHttp();
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setRowCount(RMsData?.length);  setProductsRowCount(RMsData?.length);
  }, [RMsData]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginBottom: "16px", fontSize: "18px",marginLeft: "-58px" }}>
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };

  useEffect(() => {
    getRMsFun();
  }, []);

  const openDelModal = (name, id) => {
    ModalService.open(DeleteRMModal, {
      refresh: () => getRMsFun(),
      name,
      id,
    });
  };
  const openEditModal = (fname, lname, id, country, region, email, val) => {
    ModalService.open(EditRMModal, {
      refresh: () => getRMsFun(),
      fname,
      lname,
      id,
      country,
      region,
      email,
      val,
    });
  };

  const getRMsFun = () => {
    getRMs("get_rms", "GET", null, ({ data: { rms } }) => {
      setRMs(rms);
      let tempVal = [];
      rms?.map((val, index) =>
        tempVal?.push({
          createdAt: val?.createdAt
            ? format(new Date(val?.createdAt), "PPpp")
            : "-",
          _id: val,
          name:
            (val?.fname ? val?.fname : " ") +
            " " +
            (val?.lname ? val?.lname : "-"),
          email: val?.email ? val.email : "-",
          status_rms: val?.active ? "Active" : "InActive",
        })
      );
      setRMsData(tempVal);
    });
  };
  const columns = [
    {
      label: "#",
      name: "",
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, update) => {
          let rowIndex = Number(tableMeta.rowIndex) + 1;
          return <span>{rowIndex}</span>;
        },
      },
    },
    {
      name: "RMId",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        sort: true,
        filter: true,
      },
    },

    {
      label: "EMAIL",
      name: "email",
      options: {
        sort: true,
        filter: true,
        search: true,
      },
    },
    {
      label: "LOCATION",
      name: "_id",
      options: {
        sort: false,
        filter: false,
        search: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <ul className="list-inline list-inline-dotted  mb-2">
                <li className="list-inline-item">
                  <>
                    {value?.region[0]?.name ? value?.region[0]?.name : "-"} -{" "}
                    {value?.region[0]?.country
                      ? value?.region[0]?.country?.toUpperCase()
                      : "-"}
                  </>
                </li>
              </ul>
            </>
          ) : (
            "-"
          );
        },
      },
    },
    {
      label: "Status",
      name: "status_rms",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === "Active" ? (
            <span className="text-success" style={{ fontWeight: "bolder" }}>
              {value}
            </span>
          ) : (
            <span className="text-warning" style={{ fontWeight: "bolder" }}>
              {value}
            </span>
          );
        },
      },
    },
    {
      label: "Created on",
      name: "createdAt",
      options: {
        sort: true,
        filter: true,
      },
    },

    {
      label: "ACTION",
      name: "_id",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <button
                  className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-menu7" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    href
                    className="dropdown-item"
                    onClick={() =>
                      openEditModal(
                        value.fname,
                        value.lname,
                        value._id,
                        value?.region[0]?.country?.toUpperCase(),
                        value?.region[0]?.name,
                        value?.email,
                        value
                      )
                    }
                  >
                    <i className="icon-pencil" /> Edit
                  </a>
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => openDelModal(value.name, value._id)}
                  >
                    <i className="icon-trash" /> Delete
                  </a>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];
  const { gilad, jason, antoine } = state;
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">{rowProductsCount}</h3>
                <span>Total RMS</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="dt-button buttons-pdf buttons-html5 btn bg-blue legitRipple "
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                onClick={() => navigate(`/sales/add-rms`)}
              >
                <span>Add New RM</span>
              </button>
            </div>{" "}
          </div>
          <Table
            title={"All RMS"}
            data={RMsData}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default RMs;
