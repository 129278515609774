import React from "react";
import Modal from "components/modal/Modal";
import { useToastContext, REMOVE } from "context/ToastContext";
import { useDeleteDevice } from "./hooks/useDeleteDevice";

const DeleteTotalDevicesModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { deletingRegiteredDevice, isLoading } = useDeleteDevice();

  return (
    <Modal
      close={props.close}
      onAccept={() =>
        deletingRegiteredDevice(props?.id, {
          onSuccess: ({ success, msg }) => {
            toastDispatch({
              type: REMOVE,
              payload: {
                content: msg,
                type: "success",
              },
            });
            props.close();
          },
        })
      }
      title="Total Devices"
      form="total_devices"
      size="sm"
      loading={isLoading}
    >
      You are about to remove <span className="text-danger">{props?.name}</span>
    </Modal>
  );
};

export default DeleteTotalDevicesModal;
