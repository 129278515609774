import Stores from "./Stores";

const StoresRoutes = [
  {
    path: "/stores",
    element: <Stores />,
  },
];

export default StoresRoutes;
