/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import "../../../src/assets/js/app.js";
const Sidebar = () => {
  let currentUser = JSON.parse(sessionStorage.getItem("user"));

  return (
    <>
      {/* Main sidebar   */}
      <div className="sidebar sidebar-light sidebar-main sidebar-expand-md">
        {/* Sidebar mobile toggler */}
        <div className="sidebar-mobile-toggler text-center">
          <a href="#" className="sidebar-mobile-main-toggle">
            <i className="icon-arrow-left8" />
          </a>
          <span className="font-weight-semibold">Navigation</span>
          <a href="#" className="sidebar-mobile-expand">
            <i className="icon-screen-full" />
            <i className="icon-screen-normal" />
          </a>
        </div>
        {/* /sidebar mobile toggler */}
        {/* Sidebar content */}
        <div className="sidebar-content">
          {/* User menu */}
          <div className="sidebar-user-material">
            <div
              className="sidebar-user-material-body"
              style={{ marginTop: "15px" }}
            >
              <div className="card-body text-center">
                {/* <a href="#">
                  <img
                    src={Avatar}
                    className="img-fluid rounded-circle shadow-1 mb-3"
                    width={80}
                    height={80}
                    alt="avatar"
                  />
                </a> */}
                <h5 className="mb-0 text-white text-shadow-dark">
                  <strong>
                    {currentUser?.fname + " " + currentUser?.lname}
                  </strong>
                </h5>
                <span className="font-size-sm text-white text-shadow-dark">
                  <strong> {currentUser?.role?.name}</strong>
                </span>
              </div>
              <div className="sidebar-user-material-footer">
                <a
                  style={{ backgroundColor: "#58cab9" }}
                  href="#user-nav"
                  className="d-flex justify-content-between align-items-center text-shadow-dark dropdown-toggle"
                  data-toggle="collapse"
                >
                  <span>My account</span>
                </a>
              </div>
            </div>
            <div className="collapse" id="user-nav">
              <ul className="nav nav-sidebar">
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="icon-user-plus" />
                    <span>My profile</span>
                  </a>
                </li>

                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="icon-comment-discussion" />
                    <span>Messages</span>
                    <span className="badge bg-teal-400 badge-pill align-self-center ml-auto">
                      58
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="icon-cog5" />
                    <span>Account settings</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="icon-switch2" />
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* /user menu */}
          {/* Main navigation */}
          <div className="card card-sidebar-mobile">
            <ul className="nav nav-sidebar" data-nav-type="accordion">
              <li className="nav-item ">
                <Link to="/inventory/products" className="nav-link">
                  <i className="icon-home4" /> <span>RTS</span>
                </Link>
              </li>
              <li className="nav-item ">
                <Link to="/sales/returns" className="nav-link">
                  <i className="icon-database-refresh" /> <span>Returns</span>
                </Link>
              </li>
              <li className="nav-item ">
                <Link to="/sales/sold-devices" className="nav-link">
                  <i className="icon-store2" /> <span>Sold Devices</span>
                </Link>
              </li>{" "}
              <li className="nav-item ">
                <Link to="/sales/sellout-history" className="nav-link">
                  <i className="icon-mobile" /> <span> Sellout History</span>
                </Link>
              </li>{" "}
              <li className="nav-item">
                <Link to="/sales/channels" className="nav-link">
                  <i className="icon-server" /> <span>Channels</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/sales/warehouses" className="nav-link">
                  <i className="icon-office" /> <span>Warehouses</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/inventory/stock-transfers" className="nav-link">
                  <i className="icon-transmission" />
                  <span>Sales Orders</span>
                </Link>
              </li>{" "}
              <li className="nav-item ">
                <Link to="/sales/total-devices" className="nav-link">
                  <i className="icon-clipboard" />
                  <span>Registered Devices</span>
                </Link>
              </li>
              <div className="sidebar-user-material-footer">
                <a
                  style={{ backgroundColor: "#58cab9" }}
                  href="#admin-nav"
                  className="d-flex justify-content-between align-items-center text-shadow-dark dropdown-toggle"
                  data-toggle="collapse"
                >
                  <span>Administration</span>
                </a>
              </div>
              <div className="collapse" id="admin-nav">
                <ul className="nav nav-sidebar">
                  <li className="nav-item ">
                    <Link to="/sales/city" className="nav-link">
                      <i className="icon-city" /> <span>City</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/city-managers" className="nav-link">
                      <i className="icon-location4" /> <span>City Manager</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/dealers" className="nav-link">
                      <i className="icon-users4" /> <span>Dealers</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/sales-agents" className="nav-link">
                      <i className="icon-users4" /> <span>Sales Agents</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/representatives" className="nav-link">
                      <i className="icon-user-tie" /> <span>Badili Agents</span>
                    </Link>
                    <li className="nav-item ">
                      <Link to="/sales/rms" className="nav-link">
                        <i className="icon-people" /> <span>RMS</span>
                      </Link>
                    </li>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/rm-badili" className="nav-link">
                      <i className="icon-users2" /> <span>Badili RMs</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/partners" className="nav-link">
                      <i className="icon-quotes-left2" /> <span>Promoters</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/stores" className="nav-link">
                      <i className="icon-store2" /> <span>Stores</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/sales/couriers" className="nav-link">
                      <i className="icon-ship" /> <span>Couriers</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/regions" className="nav-link">
                      <i className="icon-location3" /> <span>Regions</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <li className="nav-item ">
                <Link to="/sales/consignment" className="nav-link">
                  <i className="icon-drawer3" /> <span>Consignment</span>
                </Link>
              </li>  */}
              {/* <li className="nav-item ">
                <Link to="/sales/registered-devices" className="nav-link">
                  <i className="icon-copy" /> <span>Registered Devices</span>
                </Link>
              </li> */}
              <div className="sidebar-user-material-footer">
                <a
                  style={{ backgroundColor: "#58cab9" }}
                  href="#Ecom-nav"
                  className="d-flex justify-content-between align-items-center text-shadow-dark dropdown-toggle"
                  data-toggle="collapse"
                >
                  <span>Ecom</span>
                </a>
              </div>
              {/* Lead category */}
              <div className="collapse" id="Ecom-nav">
                <ul className="nav nav-sidebar">
                  <li className="nav-item ">
                    <Link to="/sales/coupons" className="nav-link">
                      <i className="icon-cash3" /> <span>Coupons</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/orders" className="nav-link">
                      <i className="icon-history" /> <span>Orders History</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Lead Nav */}
              <div className="sidebar-user-material-footer">
                <a
                  style={{ backgroundColor: "#58cab9" }}
                  href="#Lead-nav"
                  className="d-flex justify-content-between align-items-center text-shadow-dark dropdown-toggle"
                  data-toggle="collapse"
                >
                  <span>Customer Tracking</span>
                </a>
              </div>
              {/* Lead category */}
              <div className="collapse" id="Lead-nav">
                <ul className="nav nav-sidebar">
                  <li className="nav-item ">
                    <Link to="/sales/leads" className="nav-link">
                      <i className="icon-task" /> <span>Leads</span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/sales/sms" className="nav-link">
                      <i className="icon-comment" /> <span>SMS Campaign</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
          {/* /main navigation */}
        </div>
        {/* /sidebar content */}
      </div>
      {/* /main sidebar   */}
    </>
  );
};

export default Sidebar;
