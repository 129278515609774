import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { addPartnerBranch } from "./PartnersService";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AddPartnerBranchesModal = (props) => {
  // const [loading, setLoading] = useState(false);
  const [savingPartnerBranch, setSavingPartnerBranch] = useState(false);
  const [partnerBranch, setPartnerBranch] = useState({
    name: "",
    region: "",
    address: "",
  });
  const [regions, setRegions] = useState([]);
  const { toastDispatch } = useToastContext();
    const {
      error,
      loading,
      sendHttpRequest: getRegions,
    } = useHttp();



  const addNewPartnerBranch = async () => {
    setSavingPartnerBranch(true);

    let data = {
      name: partnerBranch.name,
      region: partnerBranch.region,
      partnerId: props.id,
      address: partnerBranch.address,
    };

    try {
      const { msg, success } = await addPartnerBranch(data);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });

        setSavingPartnerBranch(false);
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });

        setSavingPartnerBranch(false);
      }
    } catch (err) {
      console.log(err);
      setSavingPartnerBranch(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setPartnerBranch({ ...partnerBranch, [name]: value });
  };

  useEffect(() => {
 getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
   setRegions(regions)
 );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => addNewPartnerBranch()}
      title="Add new store"
      form="partnerBranchesForm"
      loading={savingPartnerBranch}
    >
      {loading && "Preparing store form...."}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="partnerBranchesForm">
          <div className="form-group">
            <label>Store Name</label>
            <input
              name="name"
              type="text"
              placeholder=""
              onChange={onChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Region</label>
            <select className="form-control" name="region" onChange={onChange}>
              <option value="">Select a region</option>
              {regions.map((region) => (
                <option value={region._id}>{region.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              name="address"
              type="text"
              placeholder=""
              onChange={onChange}
              className="form-control"
            />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddPartnerBranchesModal;
