import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useToastContext, ADD } from "context/ToastContext";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHttp } from "hooks";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: updatePassword } = useHttp();

  const ResetSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be atleast 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm password is required"),
  });

  const updatePass = (values) => {
    updatePassword(
      "admin_reset",
      "PUT",
      {
        email: urlParams.email,
        random: urlParams.random,
        password: values.password,
        role: urlParams.role,
        country: urlParams.country
      },
      ({ msg }) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        navigate("/");
      }
    );
  };

  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content d-flex justify-content-center align-items-center">
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={ResetSchema}
            onSubmit={(values) => {
              updatePass(values);
            }}
          >
            {({ errors, touched }) => (
              <Form className="login-form">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="text-center mb-3">
                      <i className="icon-lope-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" />
                      <h5 className="mb-0">Set you new password</h5>
                      <span className="d-block text-muted">
                        Enter your new password below
                      </span>
                    </div>

                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <Field
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <div className="form-control-feedback">
                        <i className="icon-lock2 text-muted" />
                      </div>
                      {touched.password && errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>
                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <Field
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Retype Password"
                      />
                      <div className="form-control-feedback">
                        <i className="icon-lock2 text-muted" />
                      </div>
                      {touched.confirmPassword && errors.confirmPassword && (
                        <div className="text-danger">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {loading && (
                          <>
                            <div className="lds-ring mr-2">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </>
                        )}
                        Update Password
                      </button>
                    </div>
                    <div className="text-center">
                      <Link to="/">Back to Sign In</Link>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Footer/> */}
      </div>
    </div>
  );
};
export default SetNewPassword;
