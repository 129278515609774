import ListCityManager from "./ListCityManager";

const RegionRoutes = [
  {
    path: "/city-managers",
    element: <ListCityManager />,
  },
];

export default RegionRoutes;
