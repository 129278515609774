import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createLead } from "../LeadService";

export function useCreateLead() {
  const queryClient = useQueryClient();
  const {
    mutate: creatingLead,
    error,
    isPending: isLoading,
  } = useMutation({
    mutationFn: createLead,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["leads"],
      });
    },
    onError: () => {
      console.log("Error");
    },
  });

  return { creatingLead, isLoading, error };
}
