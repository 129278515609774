import React, { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { Loader } from "components";
import { useHttp } from "hooks";

const StoreModal = (props) => {
  // const [addingColor, setAddingColor] = useState(false);
  const { toastDispatch } = useToastContext();
  const [selectedStore, setSelectedStore] = useState("");
  const [stores, setStores] = useState([]);
  const { error, loading, sendHttpRequest: getStores } = useHttp();

  const {
    error: errorStores,
    loading: assigningStore,
    sendHttpRequest: assignStore,
  } = useHttp();

  useEffect(() => {
    getStores("get_stores", "GET", null, ({ data: { stores } }) =>
      setStores(stores)
    );
  }, []);

  const assign = async () => {
    let data = {
      storeId: selectedStore,
    };

    assignStore(
      `set_storage/${props.device.details._id}`,
      "PUT",
      data,
      ({ msg }) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        props.refresh();
        props.close();
      }
    );
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title="Assign to a store"
      form="assign_store"
      size="sm"
      loading={assigningStore}
    >
      {loading && (
        <div
          style={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="assign_store">
          <div className="form-group">
            <label>Select store</label>
            <select
              className="form-control form-control-sm"
              onChange={(e) => setSelectedStore(e.target.value)}
              name="via"
              defaultValue=""
            >
              <option value="" selected>
                Select an option
              </option>

              {stores.map(({ _id, name }) => (
                <option value={_id} key={_id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default StoreModal;
