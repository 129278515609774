import { useQuery } from "@tanstack/react-query";
import { getAllCampaigns } from "../CampaignService";

export function useGetAllMessages() {
  const {
    data: campaigns,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["campaigns"],
    queryFn: getAllCampaigns,
  });

  return { campaigns, isLoading, error };
}
