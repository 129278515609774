import React, { useRef, useEffect, useState, memo, useContext } from "react";
import { useField } from "formik";
import { getQuantity } from "./StockTransferService";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useHttp } from "hooks";

const SingleRow = ({
  index,
  row,
  brands,
  transferDetails,
  setTransferDetails,
  newTransactionType,
  newWarehouse,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [availableColors, setColors] = useState([]);
  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();
  const {
    error: errorVariants,
    loading: loadingVariants,
    sendHttpRequest: getVariants,
  } = useHttp();

  const rowsInput = [...transferDetails.items];

  // Binding `brand` using index of items array
  const [brandField, brandMeta, brandHelpers] = useField(
    `items[${index}].brand`
  );
  const onBrandInput = async (e) => {
    getModels(
      `/get_device_model/${e.target.value}`,
      "GET",
      null,
      ({ data: { deviceModels } }) => {
        rowsInput[index]["models"] = deviceModels;
      }
    );

    setTransferDetails({ ...transferDetails, items: rowsInput });
    rowsInput[index]["brand"] = e.target.value;
    brandHelpers.setValue(e.target.value);
  };
  const onBrandBlur = () => {
    setIsFocused(false);
    brandHelpers.setTouched(true);
  };

  // Binding `model` using index of items array
  const [modelField, modelMeta, modelHelpers] = useField(
    `items[${index}].modelId`
  );
  const onModelInput = async (e) => {
    getVariants(
      `/get_variant_model/${e.target.value}`,
      "GET",
      null,
      ({ data: { variants, color } }) => {
        Array.isArray(color?.highlights?.colors) &&
          setColors(color?.highlights?.colors);

        rowsInput[index]["variants"] = variants;
        rowsInput[index]["modelId"] = e.target.value;
        setTransferDetails({ ...transferDetails, items: rowsInput });
      }
    );

    modelHelpers.setValue(e.target.value);
  };
  const onModelBlur = () => {
    setIsFocused(false);
    modelHelpers.setTouched(true);
  };

  const [unitField, unitMeta, unitHelpers] = useField(`items[${index}].units`);
  const [wspField, wspMeta, wspHelpers] = useField(`items[${index}].wsp`);
  // const [rrpField, rrpMeta, rrpHelpers] = useField(`items[${index}].rrp`);
  const [subtotalField, subtotalMeta, subtotalHelpers] = useField(
    `items[${index}].subtotal`
  );

  // Binding `variant` using index of items array
  const [variantField, variantMeta, variantHelpers] = useField(
    `items[${index}].variantId`
  );
  const onVariantInput = async (e) => {
    rowsInput[index]["colorId"] = "";
    // const { success: variantSuccess, quantity } = await getQuantity(
    //   e.target.value.split("&&")[0]
    // );
    // rowsInput[index]["rrp"] = e.target.value.substring(
    //   e.target.value.indexOf("&&") + 2,
    //   e.target.value.lastIndexOf("_")
    // );
    if (newTransactionType === "b2b") {
      rowsInput[index]["wsp"] = e.target.value.substring(
        e.target.value.indexOf("_") + 1
      );
    }
    if (newTransactionType === "b2c") {
      rowsInput[index]["wsp"] = e.target.value.substring(
        e.target.value.indexOf("&&") + 2,
        e.target.value.lastIndexOf("_")
      );
    }
    rowsInput[index]["variantId"] = e.target.value.split("&&")[0];
    setTransferDetails({ ...transferDetails, items: rowsInput });
    variantHelpers.setValue(e.target.value.split("&&")[0]);
    // rrpHelpers.setValue(Number(
    //   e.target.value.substring(
    //     e.target.value.indexOf("&&") + 2,
    //     e.target.value.lastIndexOf("_")
    //   ))
    // );
    wspHelpers.setValue(
      Number(e.target.value.substring(e.target.value.indexOf("_") + 1))
    );
  };
  const onVariantBlur = () => {
    setIsFocused(false);
    variantHelpers.setTouched(true);
  };

  const calculateSubtotal = (quantity) => {
    let wsp = rowsInput[index]["wsp"];

    let subtotal = Number(wsp) * Number(quantity);
    subtotalHelpers.setValue(subtotal);
    rowsInput[index]["subtotal"] = subtotal;
    setTransferDetails({ ...transferDetails, items: rowsInput });
  };

  // Binding `quantity` using index of items array
  const [quantityField, quantityMeta, quantityHelpers] = useField(
    `items[${index}].quantity`
  );
  const onQuantityInput = (e) => {
    calculateSubtotal(e.target.value);
    rowsInput[index]["quantity"] = e.target.value;
    quantityHelpers.setValue(Number(e.target.value));
  };
  const onQuantityBlur = () => {
    setIsFocused(false);
    quantityHelpers.setTouched(true);
  };
  // Binding `color` using index of items array
  const [colorField, colorMeta, colorHelpers] = useField(
    `items[${index}].colorId`
  );
  const onColorInput = async (e) => {
    const { success: variantSuccess, quantity } = await getQuantity({
      variantId: rowsInput[index]["variantId"],
      colorId: e.target.value,
      warehouse: newWarehouse,
    });

    if (variantSuccess) {
      unitHelpers.setValue(Number(quantity));

      rowsInput[index]["units"] = Number(quantity);
    } else {
      rowsInput[index]["units"] = 0;
    }
    rowsInput[index]["colorId"] = e.target.value;
    colorHelpers.setValue(e.target.value);
  };
  const onColorBlur = () => {
    setIsFocused(false);
    colorHelpers.setTouched(true);
  };

  // Binding `discount` using index of items array
  const [discountField, discountMeta, discountHelpers] = useField(
    `items[${index}].discount`
  );
  const onDiscountInput = (e) => {
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value)) {
      calculateDiscount(e.target.value);
      discountHelpers.setValue(Number(e.target.value / 100));
    }
  };
  const onDiscountBlur = () => {
    setIsFocused(false);
    discountHelpers.setTouched(true);
  };

  const calculateDiscount = (discount) => {
    let rrp = rowsInput[index]["rrp"];
    let discountAmnt = (Number(rrp) * Number(discount)) / 100;
    wspHelpers.setValue(Number(rrp) - discountAmnt);
    rowsInput[index]["wsp"] = Number(rrp) - discountAmnt;
    setTransferDetails({ ...transferDetails, items: rowsInput });
  };

  const deleteRow = (e, index) => {
    e.preventDefault();
    const tempRows = [...transferDetails.items];
    tempRows.splice(index, 1);
    setTransferDetails({ ...transferDetails, items: tempRows });
  };

  return (
    <>
      {" "}
      <tr>
        <td>
          <div className="form-group">
            <select
              className={[
                "form-control form-control-sm",
                brandMeta.error && brandMeta.touched && "is-invalid",
              ].join(" ")}
              onChange={onBrandInput}
              onFocus={() => setIsFocused(true)}
              onBlur={onBrandBlur}
              name="brand"
            >
              <option value="">Select Brand</option>
              {brands?.map((brand) => (
                <option value={brand._id} key={brand._id}>
                  {brand.brand}
                </option>
              ))}
            </select>
            {brandMeta.touched && brandMeta.error ? (
              <div className="invalid-feedback">{brandMeta.error}</div>
            ) : null}
          </div>
        </td>
        <td>
          {" "}
          <div className="form-group">
            <select
              className={[
                "form-control form-control-sm",
                modelMeta.error && modelMeta.touched && "is-invalid",
              ].join(" ")}
              onChange={onModelInput}
              onFocus={() => setIsFocused(true)}
              onBlur={onModelBlur}
              name="modelId"
              disabled={row?.models?.length === 0}
            >
              <option value="">Select Model</option>
              {row?.models?.map(({ details }) => (
                <option value={details._id} key={details._id}>
                  {details.modelName}
                </option>
              ))}
            </select>
            {modelMeta.touched && modelMeta.error ? (
              <div className="invalid-feedback">{modelMeta.error}</div>
            ) : null}
          </div>
        </td>
        <td>
          {" "}
          <div className="form-group">
            <select
              className={[
                "form-control form-control-sm",
                variantMeta?.error && variantMeta?.touched && "is-invalid",
              ].join(" ")}
              onChange={onVariantInput}
              onFocus={() => setIsFocused(true)}
              onBlur={onVariantBlur}
              name="variantId"
              disabled={row?.variants?.length === 0}
            >
              <option value="">Select Variant</option>

              {row?.variants?.map(({ variant }) => (
                <option
                  value={`${variant._id}&&${variant?.discounted}_${variant?.wsp}`}
                  key={variant._id}
                >
                  {variant?.ram}GB/{variant?.storage}GB
                </option>
              ))}
            </select>
            {variantMeta?.touched && variantMeta?.error ? (
              <div className="invalid-feedback">{variantMeta?.error}</div>
            ) : null}
          </div>
        </td>
        <td>
          <div className="form-group">
            <select
              className={"form-control form-control-sm"}
              onChange={onColorInput}
              onFocus={() => setIsFocused(true)}
              onBlur={onColorBlur}
              name="colorId"
              // disabled={!row.variant}
            >
              <option value="">Select Color</option>

              {availableColors?.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </select>
            {/* {colorMeta.touched && colorMeta.error ? (
              <div className="invalid-feedback">{colorMeta.error}</div>
            ) : null} */}
          </div>
        </td>
        <td className="">
          {" "}
          <div className="form-group">
            {" "}
            <input
              className={[
                "form-control form-control-sm",
                unitMeta.error && unitMeta.touched && "is-invalid",
              ].join(" ")}
              disabled
              name="units"
              value={row.units}
              type="text"
              placeholder="Units available"
            />
            {unitMeta.touched && unitMeta.error ? (
              <div className="invalid-feedback">{unitMeta.error}</div>
            ) : null}
          </div>
        </td>

        <td>
          {" "}
          <div className="form-group">
            <input
              className={[
                "form-control form-control-sm",
                quantityMeta.error && quantityMeta.touched && "is-invalid",
              ].join(" ")}
              onChange={onQuantityInput}
              onFocus={() => setIsFocused(true)}
              onBlur={onQuantityBlur}
              name="quantity"
              type="text"
              placeholder="Quantity"
            />
            {quantityMeta.touched && quantityMeta.error ? (
              <div className="invalid-feedback">{quantityMeta.error}</div>
            ) : null}
          </div>
        </td>
        {/* <td>
          <div className="form-group">{thousandCommaSeparator(row.rrp)}</div>
        </td> */}
        {/* <td>
          <div class="form-group">
            <input
              className={[
                "form-control form-control-sm",
                discountMeta.error && discountMeta.touched && "is-invalid",
              ].join(" ")}
              onChange={onDiscountInput}
              onFocus={() => setIsFocused(true)}
              onBlur={onDiscountBlur}
              name="discount"
              disabled={!row.rrp}
              type="text"
              placeholder="Discount"
            />
            {discountMeta.touched && discountMeta.error ? (
              <div className="invalid-feedback">{discountMeta.error}</div>
            ) : null}
          </div>
        </td> */}
        <td>
          <div className="form-group">{thousandCommaSeparator(row.wsp)}</div>
        </td>
        <td>
          <div className="form-group">
            {thousandCommaSeparator(row.subtotal)}
          </div>
        </td>

        <td className="text-center">
          <div className="form-group">
            <button
              onClick={(e) => deleteRow(e, index)}
              className="border-0 bg-transparent border-outline-0 "
            >
              <i className="icon-trash-alt text-danger" />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
