import ListStockTransfers from "./ListTransfers";
import NewTransfer from "./NewTransfer";
 import TransferDetails from "./TransferDetails";

const StockTransfersRoutes = [
  {
    path: "/stock-transfers",
    element: <ListStockTransfers />,
  },
  {
    path: "/stock-transfers/new",
    element: <NewTransfer />,
  },
  {
    path: "/stock-transfer/:id",
    element: <TransferDetails />,
  },
];

export default StockTransfersRoutes;
