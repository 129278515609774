import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { delWarehouse } from "./WarehouseService";
import { useToastContext, ADD } from "context/ToastContext";
import { useQueryClient } from "@tanstack/react-query";

const DeleteWarehouseModal = (props) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { toastDispatch } = useToastContext();

  const deleteWarehouse = async () => {
    setLoading(true);

    try {
      const { msg, success } = await delWarehouse(props.warehouse);
      if (success) {
        queryClient.invalidateQueries({
          queryKey: ["warehouses"],
        });
        props.close();
        props.refresh();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => deleteWarehouse()}
      title="Are you Sure?"
      form="deleteWarehouse"
      size="sm"
      loading={loading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="deleteWarehouse">
        <h5>
          You are about to{" "}
          <span className="text-danger font-weight-bold">delete</span>{" "}
          {props.warehouse.name} warehouse. This action is irreversible.
        </h5>
      </form>
    </Modal>
  );
};

export default DeleteWarehouseModal;
