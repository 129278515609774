import React, { useState} from "react";
import Modal from "components/modal/Modal";
import { deletePartner } from "./PartnersService";
import { useToastContext, ADD } from "context/ToastContext";

const DeletePartnerModal = (props) => {
  const [savingPartner, setSavingPartner] = useState(false);
 const { toastDispatch } = useToastContext();

  const deleteNewPartner = async () => {
    setSavingPartner(true);

    try {
      const { msg, success } = await deletePartner(props.id);
      if (success) {
        props.refresh();
        props.close();
       toastDispatch({
              type: ADD,
              payload: {
                content:
                  msg,
                type: "success",
              },
            });
        setSavingPartner(false);
      } else {
       toastDispatch({
              type: ADD,
              payload: {
                content:
                  msg,
                type: "danger",
              },
            });
        setSavingPartner(false);
      }
    } catch (err) {
      console.log(err);
      setSavingPartner(false);
    }
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => deleteNewPartner()}
      title="Are you sure?"
      loading={savingPartner}
      size="sm"
    >
      You are about to delete <span className="text-danger">{props.name}</span>{" "}
      Partner
    </Modal>
  );
};

export default DeletePartnerModal;
