import React, { useRef, useEffect, useContext, useState } from "react";

import { Switch, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { format } from "date-fns";
import Map from "components/map/Map.js";
import { Loader } from "components";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const ListRegions = () => {
  const mapRef = useRef();
  const [mapView, setMapView] = useState(false);
  const [regions, setRegions] = useState([]);
  const { toastDispatch } = useToastContext();
  const {
    error: errorAddingRegions,
    loading: loadingAddRegions,
    sendHttpRequest: addRegion,
  } = useHttp();
  const { error, loading, sendHttpRequest: getRegions } = useHttp();

  const handleMapViewChange = (event) => {
    setMapView(event.target.checked);
  };

  function getAllRegions() {
    getRegions("get_regions", "GET", null, ({ data: { regions } }) => {
      setRegions(regions);
    });
  }
  useEffect(() => {
    getAllRegions();
  }, []);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    addRegion(
      "new_region",
      "POST",
      {
        name: values.new_region,
      },
      ({ msg }) => {
        setSubmitting(false);
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });

        getAllRegions();
        resetForm({ values: "" });
      }
    );
  };
  function validateRegion(value) {
    let error;

    if (!value) {
      error = "Region is Required";
    }
    return error;
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">Your Countrywide Reach</h4>
            <div className="header-elements">
              <Grid component="label" container alignItems="center">
                <Grid item>List view</Grid>
                <Grid item>
                  {<Switch checked={mapView} onChange={handleMapViewChange} />}
                </Grid>
                <Grid item>Map view</Grid>
              </Grid>
            </div>
          </div>
          <div className="card-body">
            {mapView ? (
              <div id="map" ref={mapRef}>
                <Map />
              </div>
            ) : (
              <>
                <div className="card-header header-elements-sm-inline">
                  <h6 className="card-title">Active Regions</h6>
                  <div className="header-elements">
                    <Formik
                      initialValues={{
                        new_region: "",
                      }}
                      onSubmit={(values, x) => {
                        handleSubmit(values, x);
                      }}
                    >
                      {({ errors, touched, isValidating, isSubmitting }) => (
                        <Form>
                          <div className="form-group row">
                            <label className="col-form-label col-lg-3">
                              New region:
                            </label>
                            <div className="col-lg-9">
                              <div className="input-group">
                                <Field
                                  name="new_region"
                                  validate={validateRegion}
                                  type="text"
                                  className="form-control border-right-0"
                                  placeholder="Region Name"
                                />

                                <span className="input-group-append">
                                  <button
                                    className="btn btn-sm bg-teal legitRipple"
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting && (
                                      <>
                                        <div className="lds-ring mr-2">
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                          <div></div>
                                        </div>
                                      </>
                                    )}{" "}
                                    Add Region
                                  </button>
                                </span>
                              </div>
                              {errors.new_region && touched.new_region && (
                                <small className="text-danger">
                                  {errors.new_region}
                                </small>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                {regions.length === 0 && "No available regions"}
                {regions.length !== 0 && (
                  <div className="table-responsive">
                    <table className="table text-nowrap">
                      <thead>
                        <tr>
                          <th className="font-weight-bold">Region Name</th>
                          <th className="font-weight-bold">Created Date</th>

                          <th className="font-weight-bold">Status</th>
                          <th className="text-center" style={{ width: 20 }}>
                            <i className="icon-arrow-down12" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="table-active table-border-double">
                          <td colSpan={3}>Total Regions</td>
                          <td className="text-right font-weight-bold">
                            <span className="badge badge-primary badge-pill">
                              {" "}
                              {regions.length}
                            </span>
                          </td>
                        </tr>
                        {regions.map((region) => (
                          <tr key={region._id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <a
                                    href="#"
                                    className="text-default font-weight-semibold"
                                  >
                                    {region.name}
                                  </a>
                                  {/* <div className="text-muted font-size-sm">
                              <span className="badge badge-mark border-blue mr-1" />
                              02:00 - 03:00
                            </div> */}
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="text-muted">
                                {format(new Date(region.createdAt), "PPpp")}
                              </span>
                            </td>

                            <td>
                              <span
                                className={`badge ${
                                  region.active ? "bg-success-400" : "bg-danger"
                                }`}
                              >
                                {region.active ? "Active" : "InActive"}
                              </span>
                            </td>
                            <td className="text-center">
                              <div className="list-icons">
                                <div className="list-icons-item dropdown">
                                  <a
                                    href="#"
                                    className="list-icons-item dropdown-toggle caret-0"
                                    data-toggle="dropdown"
                                  >
                                    <i className="icon-menu7" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link
                                      to={`/sales/region/${region._id}`}
                                      className="dropdown-item"
                                    >
                                      <i className="icon-file-eye" /> View
                                      Region
                                    </Link>
                                    {/* <a href="#" className="dropdown-item">
                                      <i className="icon-pencil4" /> Edit Region
                                    </a>
                                    <a href="#" className="dropdown-item">
                                      <i className="icon-bin2" /> Delete Region
                                    </a> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListRegions;
