/* eslint-disable jsx-a11y/anchor-is-valid */
import { Loader, Table } from "components";
import AddLeadModal from "./AddLeadModal";
import AddUploadCSVModal from "./AddUploadCSVModal";
import EditLeadModal from "./EditLeadModal";
import AssignTelesaleModal from "./AssignLeadsModal";
import ModalService from "components/modal/ModalService";
import { useLeads } from "./hooks/useLeads";
import { useState } from "react";

function Sms() {
  const { leads, gettingLeads } = useLeads();
  const [rowCount, setRowCount] = useState(0);
  const [leadsToTelesales, setLeadsToTelesales] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  let selectedDataBulk;

  const totalLeads = leads?.users?.length;

  const openEditLeadModal = (data) => {
    ModalService.open(EditLeadModal, {
      data: data,
    });
  };

  const openAssignTelesalesModal = (data) => {
    ModalService.open(AssignTelesaleModal, {
      data: data,
      selectedBulk: selectedDataBulk,
    });
  };

  const columnns = [
    {
      label: "Name",
      name: "name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "Phone",
      name: "phone",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "Category",
      name: "category",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "Prospects",
      name: "prospects",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div style={{ color: value?.at(1) }}>{value?.at(0)}</div>
            </>
          );
        },
      },
    },
    {
      label: "Closed",
      name: "closed",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "post" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="list-icons">
              <div className="list-icons-item dropdown">
                <a
                  href="#"
                  className="list-icons-item dropdown-toggle caret-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-menu7" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item bg-transparent border-0"
                    onClick={() => openAssignTelesalesModal(value)}
                  >
                    <i className="icon-compose" /> Assign Telesales
                  </a>
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => openEditLeadModal(value)}
                  >
                    <i className="icon-pencil" /> Edit
                  </a>
                  <button
                    className="dropdown-item bg-transparent border-0"
                    onClick={() => {}}
                  >
                    <i className="text-danger icon-trash-alt" /> Delete
                  </button>
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];

  // Generate Prospect[Name & Color] using Category
  function generateProspect(category) {
    switch (category.toLowerCase()) {
      case "cold":
        return ["NO", "#FF061F"];
      case "hot":
        return ["YES", "#4caf50"];
      case "warm":
        return ["YES", "#4caf50"];
      default:
        return ["YES", "#4caf50"];
    }
  }

  const openAddLeadModal = () => {
    ModalService.open(AddLeadModal);
  };

  const openAddLeadCSVModal = () => {
    ModalService.open(AddUploadCSVModal);
  };

  const leadsData = leads?.leads?.map((lead) => ({
    id: lead?._id,
    name: lead?.name,
    phone: lead?.phone,
    category: lead?.category.toUpperCase(),
    prospects: generateProspect(lead?.category),
    closed: lead?.closed ? "YES" : "NO",
    action: lead,
  }));

  // Handle Bulk select
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => leadsData[dataIndex]
    );

    let tempObjData = [];

    selectedData?.map((data, index) => {
      tempObjData.push({
        lead_id: data?.id,
      });
    });

    selectedDataBulk = tempObjData;
  };

  return (
    <>
      {gettingLeads && <Loader />}
      {!gettingLeads && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              gap: "2rem",
            }}
          >
            <div
              style={{ alignSelf: "flex-end", display: "flex", gap: "2rem" }}
            >
              <button
                className="btn btn-sm bg-teal-400 ml-auto"
                onClick={() => openAddLeadModal()}
              >
                <i className="icon-add mr-2" /> Add Lead
              </button>

              <button
                className="btn btn-sm bg-teal-400 ml-auto"
                onClick={() => openAddLeadCSVModal()}
              >
                <i className="icon-add mr-2" /> Add CSV
              </button>
            </div>

            <Table
              columns={columnns}
              data={leadsData}
              title="Leads"
              option={{
                customToolbar: () => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginBottom: "16px",
                        fontSize: "18px",
                        marginLeft: "-58px",
                      }}
                    >
                      Total Row Count: <b>{rowCount || totalLeads}</b>
                    </div>
                  </div>
                ),
                onTableChange: (action, tableState) => {
                  if (action === "filterChange" || action === "search") {
                    setFilteredData(
                      tableState?.displayData?.map((row) => row?.data)
                    );
                  }
                },
                selectableRows: "multiple",
                onRowSelectionChange: handleRowSelectionChange,
                customToolbarSelect: (
                  selectedRows,
                  displayData,
                  setSelectedRows
                ) => (
                  <div>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      onClick={() => openAssignTelesalesModal(leadsToTelesales)}
                    >
                      Assign to Telesales
                    </button>
                    &emsp;
                  </div>
                ),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Sms;
