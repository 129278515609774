import Sms from "./Sms";

const LeadRoutes = [
  {
    path: "/leads",
    element: <Sms />,
  },
];

export default LeadRoutes;
