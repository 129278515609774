import ListOrders from "./OrderHistory";
import OrderDetails from "./OrderDetails";
import PreOrders from './PreOrders'
import NewPreOrders from './NewPreOrder'
import PreOrderDetails from './PreOrderDetails'

const OrderRoutes = [
  {
    path: "/orders",
    element: <ListOrders />,
  },
  {
    path: "/order/:id",
    element: <OrderDetails />,
  },

  {
    path: "/pre-orders",
    element: <PreOrders />,
  },

  {
    path: "/pre-order/new",
    element: <NewPreOrders />,
  },

  {
    path: `/pre-order/:id`,
    element: <PreOrderDetails />,
  },
];

export default OrderRoutes;
