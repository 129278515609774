import RMs from "./RMs";
import AddRM from "./AddRM";

const DealersRoutes = [
  {
    path: "/rms",
    element: <RMs />,
  },
  {
    path: "/add-rms",
    element: <AddRM />,
  },
  
];

export default DealersRoutes;
