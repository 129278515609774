import Stores from "./HistorSellOutHistory";

const SellOutHistoryRoutes = [
  {
    path: "/sellout-history",
    element: <Stores />,
  },
];

export default SellOutHistoryRoutes;
