import React, { useEffect, useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  getModels,
  getBrands,
  getVariants,
} from "../../sales/orders/OrderService";
import { Row, Col } from "antd";
const UpdateInvModal = (props) => {
  console.log("props?.invData", props?.invData);
  const { toastDispatch } = useToastContext();
  const [variants, setVariants] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedVariantId, setSelectedVariantId] = useState("");
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSuperAdminEmail, setSelectedSuperAdminEmail] = useState("");
  const [selectedSuperAdminPassword, setSelectedSuperAdminPassword] =
    useState("");
  const [formValid, setFormValid] = useState(false); // State variable for form validation

  const {
    error,
    loadsing: assigningStore,
    sendHttpRequest: assignStore,
  } = useHttp();

  useEffect(() => {
    getDeviceBrands();
  }, []);

  const handleSelect = (event, type) => {
    const selectedValue = event.target.value;
    if (type === "brand") {
      setSelectedBrandId(selectedValue);
      getDeviceModels(selectedValue);
      setSelectedModelId("");
      setSelectedVariantId("");
      getDeviceModels(selectedValue);
    } else if (type === "model") {
      setSelectedModelId(selectedValue);
      getDeviceVariants(selectedValue);
    } else if (type === "variant") {
      setSelectedVariantId(selectedValue);
    } else if (type === "superAdminEmail") {
      setSelectedSuperAdminEmail(selectedValue);
    } else if (type === "superAdminPassword") {
      setSelectedSuperAdminPassword(selectedValue);
    }
  };

  const getDeviceModels = async (brandId) => {
    setLoading(true);
    try {
      const { success, deviceModels, total } = await getModels(brandId);
      if (success) {
        setModels(deviceModels);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getDeviceBrands = async () => {
    setLoading(true);
    try {
      const { success, brands, total } = await getBrands();
      if (success) {
        setBrands(brands);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getDeviceVariants = async (model_id) => {
    setLoading(true);
    try {
      const { success, variants, total } = await getVariants(model_id);
      if (success) {
        setVariants(variants);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const assign = async () => {
    // Check if all required fields are filled
    if (
      selectedBrandId &&
      selectedModelId &&
      selectedVariantId &&
      selectedSuperAdminEmail &&
      selectedSuperAdminPassword
    ) {
      assignStore(
        "update_inventory_variant",
        "PUT",
        {
          brand_id: selectedBrandId,
          model_id: selectedModelId,
          variant_id: selectedVariantId,
          inventoryid: props?.id,
          superAdminEmail: selectedSuperAdminEmail,
          superAdminPassword: selectedSuperAdminPassword,
        },
        ({ msg }) => {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          props.refresh();
          props.close();
        }
      );
    } else {
      // If any required field is missing, set formValid to false
      setFormValid(false);
    }
  };

  // Update formValid whenever any of the required fields change
  useEffect(() => {
    setFormValid(
      selectedBrandId &&
        selectedModelId &&
        selectedVariantId &&
        selectedSuperAdminEmail &&
        selectedSuperAdminPassword
    );
  }, [
    selectedBrandId,
    selectedModelId,
    selectedVariantId,
    selectedSuperAdminEmail,
    selectedSuperAdminPassword,
  ]);

  return (
    <Modal
      close={props.close}
      onAccept={assign}
      title={`Update Device Parameters for (${
        props?.invData?.model?.modelName
      }  - ${
        props?.invData?.variant[0]?.ram !== undefined
          ? props?.invData?.variant[0]?.ram + "GB/"
          : ""
      }${
        props?.invData?.variant[0]?.storage !== undefined
          ? props?.invData?.variant[0]?.storage
          : ""
      }${
        props?.invData?.variant[0]?.storageType !== undefined
          ? props?.invData?.variant[0]?.storageType
          : ""
      })`}
      form="update_inventory_variant"
      size="sm"
      loading={assigningStore}
    >
      <form onSubmit={(e) => e.preventDefault()} id="update_inventory_variant">
        <Row gutter={[16, 16]}>
          {" "}
          {/* Adjust the gutter as needed */}
          <Col span={12}>
            {/* Content for the first column */}
            <div>
              {" "}
              {props?.invData?.imei && (
                <p>
                  <strong>IMEI:</strong> {props?.invData?.imei || ""}
                </p>
              )}
            </div>
          </Col>
          <Col span={12}>
            {/* Content for the second column */}
            <div>
              {" "}
              {props?.invData?.serialNumber && (
                <p>
                  <strong>Serial No:</strong>{" "}
                  {props?.invData?.serialNumber || ""}
                </p>
              )}
            </div>
          </Col>
        </Row>

        <div>
          <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-brand-label">Select Brand</InputLabel>
              <Select
                labelId="select-brand-label"
                onChange={(event) => handleSelect(event, "brand")}
                value={selectedBrandId}
              >
                <MenuItem value="">Select Brand</MenuItem>
                {brands.map((brand) => (
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-model-label">Select Model</InputLabel>
              <Select
                disabled={models.length < 1}
                labelId="select-model-label"
                onChange={(event) => handleSelect(event, "model")}
                value={selectedModelId}
              >
                <MenuItem value="">Select Model</MenuItem>
                {models.map((model) => (
                  <MenuItem key={model.details._id} value={model.details._id}>
                    {model.details.modelName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth variant="standard">
              <InputLabel id="select-variant-label">Select Variant</InputLabel>
              <Select
                labelId="select-variant-label"
                disabled={variants.length < 1}
                onChange={(event) => handleSelect(event, "variant")}
                value={selectedVariantId}
              >
                <MenuItem value="">Select Variant</MenuItem>
                {variants?.map((variant) => {
                  return (
                    <MenuItem
                      key={variant.variant._id}
                      value={variant.variant._id}
                    >
                      {variant?.variant?.ram}GB/{variant?.variant?.storage}
                      {variant?.variant?.storageType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Row gutter={[16, 16]}>
            {" "}
            {/* Adjust the gutter as needed */}
            <Col span={12}>
              {/* Content for the first column */}
              <div>
                {" "}
                <TextField
                  fullWidth
                  label="Super Admin Email"
                  variant="standard"
                  value={selectedSuperAdminEmail}
                  onChange={(event) => handleSelect(event, "superAdminEmail")}
                  required // Mark the field as required
                  type="email" // Set input type as email
                />
              </div>
            </Col>
            <Col span={12}>
              {/* Content for the second column */}
              <div>
                {" "}
                <TextField
                  fullWidth
                  label="Super Admin Password"
                  variant="standard"
                  value={selectedSuperAdminPassword}
                  onChange={(event) =>
                    handleSelect(event, "superAdminPassword")
                  }
                  required // Mark the field as required
                  type="password" // Set input type as password
                />
              </div>
            </Col>
          </Row>

          {/* Display error message if form is invalid */}
          {!formValid && (
            <p style={{ color: "red" }}>All fields are required</p>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default UpdateInvModal;
