import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useToastContext, ADD } from "context/ToastContext";
import { banks } from "helpers/banks";
import { useHttp } from "hooks";
import { Loader } from "components";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles({
  root: {
    "& .MuiDropzoneArea-root": {
      border: "2px dashed #ddd",
      padding: "1.2rem",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: ".8125rem",
      marginTop: "1.5rem",
    },
    "& .MuiDropzoneArea-icon": {
      height: "25px",
      width: "25px",
    },
  },
});

const DealerSchema = Yup.object().shape({
  name: Yup.string().required("Dealer name is required"),
  region: Yup.string().required("Please select a region"),
  description: Yup.string().required("Brief Description is required"),
  location: Yup.string().required("Location is required"),
  city: Yup.string().required("City is required"),
});
const AddDealer = () => {
  const [dealerDetails, setDealerDetails] = useState({
    name: "",
    region: "",
    channel: "mpesa",
    description: "",
    self: false,
    location: "",
    city: "",
  });
  const [regions, setRegions] = useState([]);
  const [regionKey, setRegionKey] = useState({ id: "" });
  const [cities, setCities] = useState([]);
  const [bankBranches, setBankBranches] = useState([]);
  const { toastDispatch } = useToastContext();
  const navigate = useNavigate();
  const {
    error: errorDealer,
    loading: savingDealer,
    sendHttpRequest: addDealer,
  } = useHttp();
  const {
    error: errorRegions,
    loading,
    sendHttpRequest: getRegions,
    sendHttpRequest: getcities,
  } = useHttp();

  const classes = useStyles();

  const addNewDealer = (values) => {
    let data = {
      name: values.name,
      region: values.region,
      description: values.description,
      self: dealerDetails.self,
      location: values.location,
      city: values.city,
    };
    addDealer("new_dealer", "POST", data, ({ msg }) => {
      setDealerDetails({ name: "", region: "", channel: "mpesa", self: false });
      navigate("/sales/dealers");
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setDealerDetails({ ...dealerDetails, [name]: value });
  };

  useEffect(() => {
    getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
    getcities("get_all_cities", "GET", null, ({ data: { cities } }) =>
      setCities(cities)
    );
  }, []);
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className={` card ${classes.root}`}>
          <div className="card-header header-elements-inline">
            <h5 className="card-title">Create New Dealer</h5>
            <div className="header-elements">
              {" "}
              <button
                className="btn btn-sm bg-transparent"
                onClick={() => navigate(-1)}
              >
                <i className="icon-circle-left2 text-teal-400 mr-2" /> Back
              </button>
            </div>
          </div>

          <div className="card-body">
            <Formik
              initialValues={dealerDetails}
              validationSchema={DealerSchema}
              onSubmit={(values) => {
                addNewDealer(values);
              }}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <fieldset>
                        <legend className="font-weight-semibold">
                          <i className="icon-reading mr-2" /> Dealer details
                        </legend>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label">
                            Dealer Name:
                          </label>
                          <div className="col-lg-9">
                            <Field
                              name="name"
                              type="text"
                              placeholder=""
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                            {/* <input
                              name="name"
                              type="text"
                              placeholder=""
                              onChange={onChange}
                              value={dealerDetails.name}
                              className="form-control"
                            /> */}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label">
                            Select region:
                          </label>
                          <div className="col-lg-9">
                            <select
                              placeholder="select a region"
                              className={
                                "form-control form-control-sm" +
                                (errors.region && touched.region
                                  ? " is-invalid"
                                  : "")
                              }
                              name="region"
                              value={dealerDetails?.region}
                              onChange={(e) => {
                                onChange(e);
                                setFieldValue("region", e.target.value);
                                setRegionKey({ id: e.target.value });
                              }}
                            >
                              <option value="">Select Dealer's region</option>
                              {regions?.map((region) => (
                                <option value={region?._id} key={region?._id}>
                                  {region?.name}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              name="region"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label">
                            Select city:
                          </label>
                          <div className="col-lg-9">
                            <select
                              placeholder="select a city"
                              className={
                                "form-control form-control-sm" +
                                (errors.city && touched.city
                                  ? " is-invalid"
                                  : "")
                              }
                              name="city"
                              value={dealerDetails?.city}
                              onChange={(e) => {
                                onChange(e);
                                setFieldValue("city", e.target.value);
                              }}
                            >
                              <option value="">Select Dealer's city</option>
                              {cities?.map((city) =>
                                city?.region == regionKey.id ? (
                                  <option value={city?._id} key={city?._id}>
                                    {" "}
                                    {city?.name}{" "}
                                  </option>
                                ) : (
                                  ""
                                )
                              )}
                            </select>
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label">
                            Location:
                          </label>
                          <div className="col-lg-9">
                            <textarea
                              rows={1}
                              name="location"
                              className={
                                "form-control" +
                                (errors.location && touched.location
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter location details here"
                              value={dealerDetails.location}
                              onChange={(e) => {
                                onChange(e);
                                setFieldValue("location", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="location"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label">
                            Brief description:
                          </label>
                          <div className="col-lg-9">
                            <textarea
                              rows={3}
                              name="description"
                              className={
                                "form-control" +
                                (errors.description && touched.description
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter your brief desription here"
                              value={dealerDetails.description}
                              onChange={(e) => {
                                onChange(e);
                                setFieldValue("description", e.target.value);
                              }}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-md-6">
                      <fieldset>
                        <legend className="font-weight-semibold">
                          <i className="icon-wallet mr-2" /> Payment details
                        </legend>
                        <div className="form-group row">
                          <label className="col-lg-3 col-form-label">
                            Channel:
                          </label>
                          <div className="col-lg-9">
                            <div className="form-group">
                              <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                  <div className="uniform-choice">
                                    <span
                                      className={
                                        dealerDetails.channel === "mpesa" &&
                                        "checked"
                                      }
                                    >
                                      <input
                                        type="radio"
                                        name="channel"
                                        className="form-input-styled"
                                        onChange={onChange}
                                        value="mpesa"
                                        data-fouc
                                      />
                                    </span>
                                  </div>
                                  Mpesa
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                  <div className="uniform-choice">
                                    <span
                                      className={
                                        dealerDetails.channel === "bank" &&
                                        "checked"
                                      }
                                    >
                                      <input
                                        type="radio"
                                        name="channel"
                                        className="form-input-styled"
                                        data-fouc
                                        onChange={onChange}
                                        value="bank"
                                      />
                                    </span>
                                  </div>
                                  Bank
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {dealerDetails.channel === "bank" && (
                          <>
                            <div className="form-group row">
                              <label className="col-lg-3  col-form-label">
                                Bank Details:
                              </label>
                              <div className="col-lg-9">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <select
                                        placeholder="select bank"
                                        className="form-control form-control-sm"
                                        onChange={onChange}
                                        name="bank"
                                        value={dealerDetails.bank}
                                      >
                                        <option value="">Select a bank</option>
                                        {banks.map(
                                          ({ name, bank_code, branches }) => (
                                            <option
                                              value={name}
                                              key={bank_code}
                                              onClick={() => {
                                                setBankBranches(branches);
                                              }}
                                            >
                                              {name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <select
                                      placeholder="select a branch"
                                      className="form-control form-control-sm"
                                      onChange={onChange}
                                      name="branch"
                                      value={dealerDetails.branch}
                                      disabled={
                                        bankBranches.length < 1 ||
                                        !dealerDetails.bank
                                      }
                                    >
                                      <option value="">Select a branch</option>
                                      {bankBranches.map((region) => (
                                        <option
                                          value={region._id}
                                          key={region._id}
                                        >
                                          {region.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                Account Number:
                              </label>
                              <div className="col-lg-9">
                                <input
                                  type="text"
                                  placeholder="Dealers account number"
                                  className="form-control"
                                  disabled={
                                    bankBranches.length < 1 ||
                                    !dealerDetails.branch
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {dealerDetails.channel === "mpesa" && (
                          <>
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                Payment mode:
                              </label>
                              <div className="col-lg-9">
                                <select
                                  placeholder="select a payment mode"
                                  className="form-control form-control-sm"
                                  onChange={onChange}
                                  name="payment_mode"
                                  value={dealerDetails.payment_mode}
                                >
                                  <option value="">Select payment mode</option>
                                  <option value="phone">Mobile phone</option>
                                  <option value="paybill">Paybill</option>
                                  <option value="till">Till Number</option>
                                </select>
                              </div>
                            </div>
                            {dealerDetails.payment_mode === "phone" && (
                              <div className="form-group row">
                                <label className="col-lg-3 col-form-label">
                                  Mobile Number:
                                </label>
                                <div className="col-lg-9">
                                  <input
                                    name="phone"
                                    type="text"
                                    placeholder=""
                                    onChange={onChange}
                                    value={dealerDetails.phone}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            )}
                            {dealerDetails.payment_mode === "paybill" && (
                              <>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Paybill Number:
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      name="paybill_number"
                                      type="text"
                                      placeholder=""
                                      onChange={onChange}
                                      value={dealerDetails.paybill_number}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Account Number:
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      name="paybill_account"
                                      type="text"
                                      placeholder=""
                                      onChange={onChange}
                                      value={dealerDetails.paybill_account}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {dealerDetails.payment_mode === "till" && (
                              <div className="form-group row">
                                <label className="col-lg-3 col-form-label">
                                  Till Number:
                                </label>
                                <div className="col-lg-9">
                                  <input
                                    name="till_number"
                                    type="text"
                                    placeholder=""
                                    onChange={onChange}
                                    value={dealerDetails.till_number}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </fieldset>
                    </div>
                  </div>
                  <div className="text-right">
                    {" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => navigate(-1)}
                    >
                      <i className="icon-circle-left2 text-teal-400 mr-2" />{" "}
                      Back
                    </button>
                    &nbsp;&nbsp;
                    <button
                      type="submit"
                      className="btn btn-primary legitRipple"
                      disabled={savingDealer}
                    >
                      {savingDealer && "Please wait..."}
                      {!savingDealer && (
                        <>
                          Submit <i className="icon-paperplane ml-2" />
                        </>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default AddDealer;
