import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Loader } from "components";

const AddCouponModal = (props) => {
  const [couponDetails, setCouponDetails] = useState({
    name: "",
    start_date: "",
    end_date: "",
    description: "",
    value: "",
    models: [],
  });
  const [models, setModels] = useState([]);
  const [assignedModels, setAssignedModels] = useState([]);
  const { toastDispatch } = useToastContext();
  const {
    error: errorCoupon,
    loading: isAddingCoupon,
    sendHttpRequest: addCoupon,
  } = useHttp();
  const today = new Date().toISOString().split("T")[0];
  const adCoupon = async () => {
    let data = {
      name: couponDetails.name,
      start_date: couponDetails.start_date,
      end_date: couponDetails.end_date,
      description: couponDetails.description,
      value: Number(couponDetails.value),
      assignedModels: assignedModels.map((currentValue) => currentValue.value),
    };
    addCoupon("new_coupon", "POST", data, ({ msg }) => {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();

  const getAllModels = () => {
    getModels("get_all_device_models", "GET", null, ({ data: { devices } }) => {
      const temp = devices.map((el) => {
        let tempObj = {
          value: el._id,
          label: el.modelName,
        };

        return tempObj;
      });

      setModels(temp);
    });
  };

  useEffect(() => {
    getAllModels();
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setCouponDetails({ ...couponDetails, [name]: value });
  };

  function handleSelect(data) {
    setAssignedModels(data);
  }

  return (
    <Modal close={props.close} title={`Add Coupon`} size="md">
      {loadingModels && (
        <div
          style={{
            minHeight: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {!loadingModels && (
        <Formik
          enableReinitialize
          initialValues={couponDetails}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Coupon name is required"),
            start_date: Yup.string().required("Start Date is required"),
            end_date: Yup.string().required("Expiry Date is required"),
            description: Yup.string().required("Short Description is required"),
            value: Yup.number("Value must be a number").required(
              "Coupon Value is required"
            ),
          })}
          onSubmit={() => adCoupon()}
        >
          {({ errors, status, touched }) => (
            <Form id="addCupon">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Coupon Name </label>
                    <div class="form-group">
                      <input
                        className={
                          "form-control form-control-sm" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        onChange={onChange}
                        name="name"
                        value={couponDetails.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Coupon Value </label>
                    <input
                      className={
                        "form-control form-control-sm" +
                        (errors.value && touched.value ? " is-invalid" : "")
                      }
                      onChange={onChange}
                      name="value"
                      type="number"
                      value={couponDetails.value}
                    />
                    <ErrorMessage
                      name="value"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Start Date</label>
                    <input
                      className={
                        "form-control form-control-sm" +
                        (errors.start_date && touched.start_date
                          ? " is-invalid"
                          : "")
                      }
                      onChange={onChange}
                      name="start_date"
                      type="date"
                      min={today}
                      value={couponDetails.start_date}
                    />
                    <ErrorMessage
                      name="start_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>End Date</label>
                    <input
                      className={
                        "form-control form-control-sm" +
                        (errors.end_date && touched.end_date
                          ? " is-invalid"
                          : "")
                      }
                      onChange={onChange}
                      name="end_date"
                      type="date"
                      value={couponDetails.end_date}
                    />
                    <ErrorMessage
                      name="end_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Assigned Models</label>
                    <Select
                      options={models}
                      placeholder="Select model"
                      value={assignedModels}
                      onChange={handleSelect}
                      isSearchable={true}
                      isMulti
                    />
                    <ErrorMessage
                      name="models"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label>Applies to phones</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        onChange={onChange}
                        name="notify"
                        value={""}
                      />
                      All
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        onChange={onChange}
                        name="notify"
                        value={""}
                      />
                      Specific
                    </label>
                  </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "18px" }}>
                  <div className="form-group">
                    <label>Short Description</label>
                    <textarea
                      className={
                        "form-control form-control-sm" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                      onChange={onChange}
                      name="description"
                      rows={1}
                      value={couponDetails.description}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn bg-primary ld-ext-right float-right"
                disabled={isAddingCoupon}
              >
                Submit
                {isAddingCoupon && (
                  <>
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                )}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default AddCouponModal;
