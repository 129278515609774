import React from "react";
import "./styles.css";
import {
  handleMouseOver,
  handleMouseOut,
  handleMouseMove,
} from "helpers/handleTooltip";
import { setMapProjection } from "helpers/setMapProjection";
import * as d3 from "d3";
import useDataApi from "hooks/useDataApi";

import ViewCountyModal from "app/modules/sales/localities/ViewCountyModal";
import ModalService from "components/modal/ModalService";

const onHandleClick = (feature, tooltipData) => {
  ModalService.open(ViewCountyModal, {
    feature: {
      coordinates: feature,
      name: tooltipData,
    },
  });
};

const width = 960;
const height = 480;

const Marks = ({ pathData, tooltipData }) => {
  const data = useDataApi();
  const path = d3.geoPath().projection(setMapProjection(data, width, height));

  return (
    <path
      className="path"
      d={path(pathData)}
      onMouseOver={() => {
        handleMouseOver(tooltipData);
      }}
      onClick={() => onHandleClick(pathData, tooltipData)}
      onMouseOut={handleMouseOut}
      onMouseMove={(event) => {
        handleMouseMove(event);
      }}
    />
  );
};

export default Marks;
