import SalesAgent from "./SalesAgent";

const SalesAgentRoutes = [
  {
    path: "/sales-agents",
    element: <SalesAgent />,
  },
];

export default SalesAgentRoutes;
