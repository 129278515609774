import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Loader } from "components";
import { AppContext } from "context/AppContext";
import { getProducts } from "./OrderService";
import { format } from "date-fns";

const ListWarehouses = () => {
  const navigate = useNavigate();

 // const { showAlert } = useContext(AppContext);
  const [inventory, setInventory] = useState([]);
 // const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [newTransferActive, setNewTransferActive] = useState(false);

  const getInven = async () => {
    setLoading(true);
    try {
      const { requests, success } = await getProducts();
      if (success) {
        setInventory(requests);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

 

  useEffect(() => {
    getInven();
  }, []);

  const newTransfer = () => {
    navigate(`/sales/pre-order/new`);
  };
  const viewPreOrder = (id) => {
    navigate(`/sales/pre-order/${id}`);
  };
  return (
    <>
      {loading && inventory.length === 0 && <Loader />}

      {!loading && inventory.length === 0 && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">Available PreOrders</h4>
            <div className="header-elements">
              <div>
                <button
                  className="btn btn-sm bg-teal-400 ml-auto"
                  onClick={() => newTransfer()}
                >
                  <i className="icon-add mr-2" /> New Order
                </button>
              </div>
            </div>
          </div>
          <div className="card-body d-flex justify-content-center">
            No Pre-orders available
          </div>
        </div>
      )}

      {!loading && inventory.length !== 0 && (
        <div className="card">
          <div className="card-header header-elements-sm-inline">
            <h4 className="card-title">Pre-orders </h4>
            <div className="header-elements">
              <div>
                <button
                  className="btn btn-sm bg-teal-400 ml-auto"
                  onClick={() => newTransfer()}
                >
                  <i className="icon-add mr-2" /> New Pre-order
                </button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table text-nowrap">
              <thead>
                <tr className="table-active ">
                  <td colSpan={7}>Total Orders</td>

                  <td className="text-right">
                    <span className="badge bg-blue badge-pill">
                      {inventory.length}
                    </span>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>Ref Number</th>
                  <th>Source</th>
                  <th>Destination</th>
                  <th>Created Date</th>
                  <th>Scheduled Date</th>
                  <th>Quantity</th>
                  <th>Status</th>

                  <th className="text-center" style={{ width: 20 }}>
                    <i className="icon-arrow-down12" />
                  </th>
                </tr>
                {inventory.map((el) => (
                  <tr>
                    <td>2323442</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <a href="#" className="rounded-round btn-icon btn-sm">
                            <i className="icon-office" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Warehouse A
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="">
                          <a href="#" className="rounded-round btn-icon btn-sm">
                            <i className="icon-office" />
                          </a>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="text-default font-weight-semibold letter-icon-title"
                          >
                            Badili Warehouse
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <h6 className="mb-0">
                        {format(new Date(el.details.createdAt), "dd")}{" "}
                        {format(new Date(el.details.createdAt), "MMM")}{" "}
                        <span className="">
                          {format(new Date(el.details.createdAt), "yyyy")}
                        </span>
                      </h6>
                    </td>
                    <td className="">
                      <h6 className="mb-0">
                        {format(new Date(el.details.createdAt), "dd")}{" "}
                        {format(new Date(el.details.createdAt), "MMM")}{" "}
                        <span className=" ">
                          {format(new Date(el.details.createdAt), "yyyy")}
                        </span>
                      </h6>
                    </td>

                    <td>
                      <a
                        href
                        className="text-default font-weight-semibold letter-icon-title"
                      >
                        310
                      </a>
                    </td>
                    <td>
                      <a
                        href
                        className="text-default font-weight-semibold letter-icon-title"
                      >
                        Active
                      </a>
                    </td>

                    <td className="text-center">
                      <div className="list-icons">
                        <div className="list-icons-item dropdown">
                          <a
                            href="#"
                            className="list-icons-item dropdown-toggle caret-0"
                            data-toggle="dropdown"
                          >
                            <i className="icon-menu7" />
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              href
                              className="dropdown-item"
                              onClick={() =>
                                viewPreOrder("629f5df904fc527c854eb0ab")
                              }
                            >
                              <i className="icon-file-eye" /> View
                            </a>
                            {/* <a href="#" className="dropdown-item">
                              <i className="icon-history" /> Full history
                            </a>
                            <div className="dropdown-divider" />
                            <a href="#" className="dropdown-item">
                              <i className="icon-checkmark3 text-success" />{" "}
                              Resolve issue
                            </a>
                            <a href="#" className="dropdown-item">
                              <i className="icon-cross2 text-danger" /> Close
                              issue
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ListWarehouses;
