import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useCreateLead } from "./hooks/useCreateLead";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddLeadModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { isLoading, creatingLead } = useCreateLead();
  const [value, setValue] = useState();
  const [leadDetails, setLeadDetails] = useState({
    name: "",
    phone: "",
    category: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setLeadDetails({ ...leadDetails, [name]: value });
  };

  async function handleSubmit() {
    const data = {
      ...leadDetails,
      category: leadDetails.category.toLowerCase(),
    };

    console.log(data);

    creatingLead(data, {
      onSuccess: ({ success, msg }) => {
        if (success) {
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        } else {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg || "Unexpected Error Occured. Try again!",
              type: "danger",
            },
          });
        }
      },
      onError: (error) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: error || "Unexpected Error Occured. Try again!",
            type: "danger",
          },
        });
      },
    });
  }

  return (
    <Modal
      close={props.close}
      onAccept={() => handleSubmit()}
      title="Add Lead"
      form="warehouseForm"
      loading={isLoading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="warehouseForm">
        <div className="form-group">
          <label>Lead Name</label>
          <input
            name="name"
            type="text"
            placeholder=""
            onChange={onChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Lead Phone</label>
          <input
            name="phone"
            type="text"
            placeholder="e.g 0712345678"
            onChange={onChange}
            className="form-control"
            required
            maxLength={10}
          />
        </div>

        <div className="form-group">
          <label>Select Category</label>
          <select
            className="form-control form-control-sm"
            onChange={onChange}
            name="category"
            required
            defaultValue={"Cold"}
          >
            <option>Cold</option>
            <option>Warm</option>
            <option>Hot</option>
          </select>
        </div>
      </form>
    </Modal>
  );
};

export default AddLeadModal;
