import { headers } from "../../../../helpers/apiConfigs";

export async function getLeads() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "get_all_leads",
      {
        method: "GET",
        headers: headers,
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return data;
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}

export async function getTelesales() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "get_customer_care",
      {
        method: "GET",
        headers: headers,
      }
    );

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return await response.json();
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}

export async function createLead({ name, phone, category = "cold" }) {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "new_lead", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: name,
        phone: phone,
        category: category === "" ? "cold" : category.toLowerCase(),
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}

export async function updateLead(id) {
  try {
    var response = await fetch(process.env.REACT_APP_BASE_URL + "", {
      method: "PATCH",
      body: JSON.stringify({}),
    });

    if (!response.ok) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}

export async function deleteLead(id) {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + "", {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}

export async function uploadBulk(filePath) {
  const formData = new FormData();
  formData.append("files", filePath);

  try {
    var response = await fetch(
      process.env.REACT_APP_BASE_URL + "leads_bulk_upload",
      {
        method: "POST",
        headers: {
          key: process.env.REACT_APP_API_KEY,
          secret: process.env.REACT_APP_API_SECRET,
          country: JSON.parse(sessionStorage.getItem("user"))?.country,
        },
        body: formData,
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return data;
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}

export async function assignTelesales(newObj) {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "assign_telesale_leads",
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(newObj),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    return data;
  } catch (error) {
    throw new Error(error?.message || "Something went wrong");
  }
}
