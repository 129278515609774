import React, { useState, useEffect, useContext } from "react";
import Modal from "components/modal/Modal";
import { AppContext } from "context/AppContext";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AddProductModal = (props) => {
  const [productDetails, setProductDetails] = useState({ color: "" });
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const { status } = useContext(AppContext);
  const { toastDispatch } = useToastContext();
  const {
    error: error,
    loading: loading,
    sendHttpRequest: addInventory,
  } = useHttp();
  const {
    error: errorBrands,
    loading: loadingBrands,
    sendHttpRequest: getBrands,
  } = useHttp();
  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();
  const {
    error: errorVariants,
    loading: loadingVariants,
    sendHttpRequest: getVariants,
  } = useHttp();
  const addProduct = async () => {
    let statusId = status.find((el) => (el.id = "READY_TO_SELL"));

    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      modelId: productDetails.model,
      variantId: productDetails.variant,
      status_id: statusId._id,
      imei: productDetails.imei,
      serialNumber: productDetails.serial,
      accessories: "",
      userId: loggedInUser.id,
      source: "offline",
    };

    addInventory("offline_inventory", "POST", data, ({ msg }) => {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  const onBrandChange = async (e) => {
    const { name, value } = e.target;

    getModels(
      `/get_device_model/${value}`,
      "GET",
      null,
      ({ data: { deviceModels } }) => setModels(deviceModels)
    );

    setProductDetails({ ...productDetails, [name]: value });
  };

  const onModelChange = async (e) => {
    const { name, value } = e.target;

    getVariants(
      `/get_variant_model/${value}`,
      "GET",
      null,
      ({ data: { variants } }) => setVariants(variants)
    );

    setProductDetails({ ...productDetails, [name]: value });
  };
  const onVariantChange = async (e) => {
    const { name, value } = e.target;
    setProductDetails({ ...productDetails, [name]: value });
  };

  useEffect(() => {
    getBrands(
      "get_device/6165c8453c6ca93814dc21aa",
      "GET",
      null,
      ({ data: { brands } }) => {
        setBrands(brands);
      }
    );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => addProduct()}
      title="Add new component"
      form="productForm"
      loading={loading}
    >
      {loadingBrands && "Preparing component form...."}
      {!loadingBrands && (
        <form onSubmit={(e) => e.preventDefault()} id="productForm">
          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label>Phone Brand</label>
                <div class="form-group">
                  <select
                    className="form-control form-control-sm"
                    onChange={onBrandChange}
                    name="brand"
                    defaultValue=""
                  >
                    <option value="" selected>
                      Select a Brand
                    </option>
                    {brands.map((brand) => (
                      <option value={brand._id} key={brand._id}>
                        {brand.brand}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <label>Phone Model</label>
                <select
                  className="form-control form-control-sm"
                  onChange={onModelChange}
                  name="model"
                  defaultValue=""
                  disabled={models.length !== 0 ? false : true}
                >
                  <option value="" selected>
                    Select a Model
                  </option>
                  {models.map(({ details }) => (
                    <option value={details._id} key={details._id}>
                      {details.modelName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label>Component Name</label>
                <input
                  name="component"
                  type="text"
                  placeholder=""
                  onChange={onChange}
                  value={productDetails.component}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <label>Quantity</label>
                <input
                  name="quantity"
                  type="text"
                  placeholder=""
                  onChange={onChange}
                  value={productDetails.quantity}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <label>Buying Price</label>
                <input
                  name="bsc"
                  type="text"
                  placeholder=""
                  onChange={onChange}
                  value={productDetails.bsc}
                  className="form-control"
                />
              </div>
              <div className="col-sm-6">
                <label>Bsc Multiplier</label>
                <select
                  className="form-control form-control-sm"
                  onChange={onModelChange}
                  name="multiplier"
                  defaultValue=""
                >
                  <option value="" selected>
                    Select
                  </option>

                  <option value={1.15}>1.15</option>
                  <option value={1.2}>1.2</option>
                  <option value={1.25}>1.25</option>
                </select>
              </div>
            </div>
          </div>

          {/* <div className="form-group">
          <div className="row">
            <div className="col-sm-6">
              <label>Accessories Included</label>
              <div class="form-group">
                <label>Select with icons</label>
                <select
                  data-placeholder="Select a state..."
                  className="form-control select-icons"
                  data-fouc
                >
                  <optgroup label="Services">
                    <option value="wordpress2" data-icon="wordpress2">
                      Wordpress
                    </option>
                    <option value="tumblr2" data-icon="tumblr2">
                      Tumblr
                    </option>
                    <option value="stumbleupon" data-icon="stumbleupon">
                      Stumble upon
                    </option>
                    <option value="pinterest2" data-icon="pinterest2">
                      Pinterest
                    </option>
                    <option value="lastfm2" data-icon="lastfm2">
                      Lastfm
                    </option>
                  </optgroup>
                  <optgroup label="File types">
                    <option value="pdf" data-icon="file-pdf">
                      PDF
                    </option>
                    <option value="word" data-icon="file-word">
                      Word
                    </option>
                    <option value="excel" data-icon="file-excel">
                      Excel
                    </option>
                    <option value="openoffice" data-icon="file-openoffice">
                      Open office
                    </option>
                  </optgroup>
                  <optgroup label="Browsers">
                    <option value="chrome" data-icon="chrome" selected>
                      Chrome
                    </option>
                    <option value="firefox" data-icon="firefox">
                      Firefox
                    </option>
                    <option value="safari" data-icon="safari">
                      Safari
                    </option>
                    <option value="opera" data-icon="opera">
                      Opera
                    </option>
                    <option value="IE" data-icon="IE">
                      IE
                    </option>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>
        </div> */}
        </form>
      )}
    </Modal>
  );
};

export default AddProductModal;
