import React, { useContext } from "react";
import Modal from "components/modal/Modal";
import { AppContext } from "context/AppContext";
import { useHttp } from "hooks";

const DeleteRMModal = (props) => {
  const {
    error,
    loading: savingRM,
    sendHttpRequest: deleteRM,
  } = useHttp();
  const { status, setShowAlert } = useContext(AppContext);

  const deleteNewRM = async () => {
    deleteRM(`delete_rm/${props.id}`, "DELETE", null, () => {
      props.refresh();
      props.close();
      setShowAlert(true);
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => deleteNewRM()}
      title="Are you sure?"
      loading={savingRM}
      size="sm"
    >
      You are about to delete <span className="text-danger">{props.name}</span>{" "}
      RM
    </Modal>
  );
};

export default DeleteRMModal;
