import ListRepairComponents from "./ListRepairComponents";

const RepairComponentsRoutes = [
  {
    path: "/repair-components",
    element: <ListRepairComponents />,
  },
];

export default RepairComponentsRoutes;
