import { SalesDashboard, InventoryDashboard } from "./views";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./app/authentication/Login";
import RecoverPassword from "./app/authentication/RecoverPassword";
import SetNewPassword from "./app/authentication/SetNewPassword";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryCleint = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 100,
    },
  },
});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryCleint}>
        <ReactQueryDevtools initialOpen={false} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<RecoverPassword />} />
          <Route path="/reset-password" element={<SetNewPassword />} />

          <Route path="/inventory/*" element={<InventoryDashboard />} />
          <Route path="/sales/*" element={<SalesDashboard />} />
        </Routes>
      </QueryClientProvider>
    </div>
  );
}

export default App;
