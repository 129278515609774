import { useState, useCallback } from "react";
import { useToastContext, ADD } from "context/ToastContext";

const headers = {
  "Content-Type": "application/json",
  key: process.env.REACT_APP_API_KEY,
  secret: process.env.REACT_APP_API_SECRET,
  country: JSON.parse(sessionStorage.getItem("user"))?.country,
};

export const usePostQuery = (
  query,
  successFn = () => {
    console.log("hello");
  }
) => {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { toastDispatch } = useToastContext();

  const post = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL + query}`,
          {
            method: "POST",
            body: JSON.stringify(data),
            headers,
          }
        );
        const json = await response.json();

        if (json.success) {
          setResponseData(json);
          successFn(); //run success function if necessary
        } else {
          setError(json.msg);
          toastDispatch({
            type: ADD,
            payload: {
              content: json.msg,
              type: "danger",
            },
          });
        }
      } catch (error) {
        setError(error.message);
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message,
            type: "danger",
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [headers, query]
  );

  return { responseData, loading, error, post };
};
