import React, { Component, useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "100%",
  position: "relative",
};
// const containerStyle = {
//   position: "relative",
//   width: "100%",
//   height: "100%",
// };

export function GoogleMapContainer(props) {
  const [mapInfo, setMapInfo] = useState({
    showingInfoWindow: false, // Hides or shows the InfoWindow
    activeMarker: {}, // Shows the active marker upon click
    selectedPlace: {},
  });

  const onMarkerClick = (props, marker, e) =>
    setMapInfo({
      ...mapInfo,
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  const onClose = (props) => {
    if (mapInfo.showingInfoWindow) {
      setMapInfo({
        ...mapInfo,
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  return (
    <Map
      // containerStyle={containerStyle}
      google={props.google}
      zoom={14}
      style={mapStyles}
      initialCenter={{
        lat: -1.2884,
        lng: 36.8233,
      }}
    >
      <Marker
        onClick={onMarkerClick}
        name={"Kenyatta International Convention Centre"}
      />
      <InfoWindow
        marker={mapInfo.activeMarker}
        visible={mapInfo.showingInfoWindow}
        onClose={onClose}
      ></InfoWindow>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDzNTJNVJ59tgYXCdXtUdf1jN5qfFtvw7A",
})(GoogleMapContainer);
