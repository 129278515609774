import ListCouriers from "./Coupons";
import CouponDetails from "./CouponDetails"

const CouponRoutes = [
  {
    path: "/coupons",
    element: <ListCouriers />,
  },
  {
    path: "/coupon/:id",
    element: <CouponDetails />,
  },
];

export default CouponRoutes;
