export const getOrders = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_orders", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};

export const getSingleOrder = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_single_order/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const getOrderPaymentSchedule = async (id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/get_payment_schedule/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};
export const dispatch = async (id, data) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + `/disptach_order/${id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    }
  );
  return await res.json();
};

export const getCouriers = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_couriers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};
export const getUnitsByModel = async (modelId, variantId,colorId,quantity) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL +
      `inventory_by_model`,
    {
      method: "POST",
    
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify({
        modelId,
        variantId, 
        colorId,
        quantity
      }),
    }
  );
  return await res.json();
};
export const fulfillOrder = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/fulfill_order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};


// to delete
export const getProducts = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_inventory", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};

export const getBrands = async () => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/get_device/6165c8453c6ca93814dc21aa",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: process.env.REACT_APP_COUNTRY,
      },
    }
  );
  return await res.json();
};

export const getModels = async (brand_id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/get_device_model/" + brand_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: process.env.REACT_APP_COUNTRY,
      },
    }
  );
  return await res.json();
};

export const getVariants = async (model_id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "get_variant_model/" + model_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: process.env.REACT_APP_COUNTRY,
      },
    }
  );
  return await res.json();
};

export const getQuantity = async (variant_id) => {
  let res = await fetch(
    process.env.REACT_APP_BASE_URL + "/get_quantity_by_variant/" + variant_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    }
  );
  return await res.json();
};

// export const getModels = async () => {
//   let res = await fetch(process.env.REACT_APP_BASE_URL + "/available_models", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_API_KEY,
//       secret: process.env.REACT_APP_API_SECRET,
//       country: JSON.parse(sessionStorage.getItem("user"))?.country,
//     },
//   });
//   return await res.json();
// };

// export const getBrands = async (id) => {
//   let res = await fetch(process.env.REACT_APP_BASE_URL + `/get_device_brand${id}`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       key: process.env.REACT_APP_API_KEY,
//       secret: process.env.REACT_APP_API_SECRET,
//       country: JSON.parse(sessionStorage.getItem("user"))?.country,
//     },
//   });
//   return await res.json();
// };