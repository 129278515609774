import React, { useState, useEffect, useContext } from "react";

import { DropzoneArea } from "react-mui-dropzone";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useToastContext, ADD } from "context/ToastContext";
import { AppContext } from "context/AppContext";
import { Loader } from "components";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { useHttp } from "hooks";

const useStyles = makeStyles({
  root: {
    "& .MuiDropzoneArea-root": {
      border: "2px dashed #ddd",
      padding: "1.2rem",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: ".8125rem",
      marginTop: "1.5rem",
    },
    "& .MuiDropzoneArea-icon": {
      height: "25px",
      width: "25px",
    },
  },
});
const AddProduct = () => {
  // const [loading, setLoading] = useState(false);

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [batchUploadFile, setBatchUploadFile] = useState([]);
  // const [isUploadingBatch, setIsUploadingBatch] = useState(false);
  const navigate = useNavigate();
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: addInventory } = useHttp();
  const {
    error: errorBrands,
    loading: loadingBrands,
    sendHttpRequest: getBrands,
  } = useHttp();
  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();
  const {
    error: errorVariants,
    loading: loadingVariants,
    sendHttpRequest: getVariants,
  } = useHttp();
  const {
    error: errorUploadingBatch,
    loading: isUploadingBatch,
    sendHttpRequest: batchUpload,
  } = useHttp();

  const classes = useStyles();

  const addBatchProducts = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("file", batchUploadFile[0]);

    batchUpload(
      "import_csv",
      "POST",
      formData,
      ({ msg }) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
      },
      { "Content-Type": "" }
    );
  };
  const addProduct = (values) => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));

    let data = {
      modelId: values.model,
      variantId: values.variant,
      imei: values.imei,
      serialNumber: values.serialNumber,
      userId: loggedInUser.id,
      source: "offline",
    };

    addInventory("offline_inventory", "POST", data, ({ data: { id }, msg }) => {
      // getInventory();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      navigate(`/inventory/product/${id}`);
    });
  };

  const handleBatchFileChange = (files) => {
    setBatchUploadFile(files);
  };

  const onBrandChange = async (e) => {
    const { value } = e.target;
    getModels(
      `get_device_model/${value}`,
      "GET",
      null,
      ({ data: { deviceModels } }) => {
        setModels(deviceModels);
      }
    );
  };

  const onModelChange = async (e) => {
    const { value } = e.target;
    getVariants(
      `get_variant_model/${value}`,
      "GET",
      null,
      ({ data: { variants } }) => setVariants(variants)
    );
  };
  useEffect(() => {
    getBrands(
      "get_device/6165c8453c6ca93814dc21aa",
      "GET",
      null,
      ({ data: { brands } }) => {
        setBrands(brands);
      }
    );
  }, []);

  const ProductSchema = Yup.object().shape({
    imei: Yup.string()
      .required("IMEI Number is required")
      .matches(/^(?:\d*)$/, "IMEI should contain just number")
      .matches(
        /^[\d]{15}$/,
        "Please enter a valid IMEI. IMEI should be 15 characters"
      ),
    model: Yup.string().required("Please select a Model"),
    variant: Yup.string().required("Please select a Variant"),
    brand: Yup.string().required("Please select a Brand"),
  });
  return (
    <>
      {loadingBrands && <Loader />}

      {!loadingBrands && (
        <div className={` card ${classes.root}`}>
          <div className="card-header header-elements-inline">
            <h5 className="card-title">Create New Product(s)</h5>
            <div className="header-elements"></div>
          </div>

          <div className="nav-tabs-responsive bg-light border-top">
            <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
              <li className="nav-item">
                <a
                  href="#single-upload"
                  className="nav-link active"
                  data-toggle="tab"
                >
                  <i className="icon-file-upload mr-2" /> Single Upload
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="#batch-upload" className="nav-link" data-toggle="tab">
                  <i className="icon-stack-up mr-2" /> Batch Upload
                </a>
              </li> */}
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="single-upload">
              <div className="card-body">
                <Formik
                  initialValues={{
                    model: "",
                    variant: "",
                    imei: "",
                    brand: "",
                    serialNumber: "",
                  }}
                  validationSchema={ProductSchema}
                  onSubmit={(values) => {
                    addProduct(values);
                  }}
                >
                  {({ errors, touched, isSubmitting, setFieldValue }) => (
                    <Form className="form-validate-jquery">
                      <fieldset className="mb-3">
                        <legend className="text-uppercase font-size-sm font-weight-bold">
                          Enter Device Details below
                        </legend>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-3">
                            IMEI Number <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <Field
                              name="imei"
                              type="text"
                              placeholder=""
                              className={
                                "form-control" +
                                (errors.imei && touched.imei
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="imei"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-3">
                            Serial Number
                          </label>
                          <div className="col-lg-9">
                            <Field
                              name="serialNumber"
                              type="text"
                              placeholder=""
                              className={
                                "form-control" +
                                (errors.serialNumber && touched.serialNumber
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="serialNumber"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-form-label col-lg-3">
                            Phone Brand <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <div className="form-group-feedback form-group-feedback-right">
                              <select
                                className={
                                  "form-control form-control-sm" +
                                  (errors.brand && touched.brand
                                    ? " is-invalid"
                                    : "")
                                }
                                name="brand"
                                onChange={(e) => {
                                  onBrandChange(e);
                                  setFieldValue("brand", e.target.value);
                                }}
                              >
                                <option value="" selected>
                                  Select a Brand
                                </option>
                                {brands?.map((brand) => (
                                  <option value={brand._id} key={brand._id}>
                                    {brand.brand}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="brand"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-form-label col-lg-3">
                            Phone Model <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <div className="form-group-feedback form-group-feedback-right">
                              <select
                                className={
                                  "form-control form-control-sm" +
                                  (errors.model && touched.model
                                    ? " is-invalid"
                                    : "")
                                }
                                name="model"
                                onChange={(e) => {
                                  onModelChange(e);
                                  setFieldValue("model", e.target.value);
                                }}
                                disabled={models.length !== 0 ? false : true}
                              >
                                <option value="" selected>
                                  Select a Model
                                </option>
                                {models?.map(({ details }) => (
                                  <option value={details._id} key={details._id}>
                                    {details.modelName}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="model"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-3">
                            Variant <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-9">
                            <div className="form-group-feedback form-group-feedback-right">
                              <select
                                className={
                                  "form-control form-control-sm" +
                                  (errors.variant && touched.variant
                                    ? " is-invalid"
                                    : "")
                                }
                                name="variant"
                                onChange={(e) => {
                                  setFieldValue("variant", e.target.value);
                                }}
                                disabled={variants.length !== 0 ? false : true}
                              >
                                <option value="" selected>
                                  Select a Variant
                                </option>

                                {variants?.map(({ variant }) => (
                                  <option value={variant?._id} key={variant?._id}>
                                    {variant?.storage}{variant?.storageType}/{variant?.ram}GB
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage
                                name="variant"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                          type="submit"
                          className="btn btn-primary ml-3 legitRipple"
                          disabled={loading}
                        >
                          {loading && "Please wait..."}
                          {!loading && (
                            <>
                              Submit <i className="icon-paperplane ml-2" />
                            </>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="tab-pane fade" id="batch-upload">
              <div className="card-body">
                <form
                  className="form-validate-jquery"
                  onSubmit={addBatchProducts}
                  noValidate="novalidate"
                >
                  <fieldset className="mb-3">
                    <legend className="text-uppercase font-size-sm font-weight-bold">
                      Upload your excel file to add Products
                    </legend>

                    <div className="form-group row">
                      <div className="col-lg-12">
                        <DropzoneArea
                          onChange={handleBatchFileChange}
                          filesLimit={1}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <div className="d-flex justify-content-end align-items-center">
                    <button
                      type="submit"
                      disabled
                      className="btn btn-primary ml-3 legitRipple"
                      loading={isUploadingBatch}
                    >
                      Upload <i className="icon-upload ml-2" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProduct;
