import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { updatePartner } from "./PartnersService";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const EditPartnerModal = (props) => {
  // const [loading, setLoading] = useState(false);
  const [savingPartner, setSavingPartner] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({
    name: props.details.name,
    logo: "",
  });
  const [regions, setRegions] = useState([]);
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getRegions } = useHttp();

  const addNewPartner = async () => {
    setSavingPartner(true);

    let data = {
      name: partnerDetails.name,
    };

    try {
      const { msg, success } = await updatePartner(props.details._id, data);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setSavingPartner(false);
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setSavingPartner(false);
      }
    } catch (err) {
      console.log(err);
      setSavingPartner(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setPartnerDetails({ ...partnerDetails, [name]: value });
  };

  useEffect(() => {
    getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => addNewPartner()}
      title="Edit Partner Details"
      form="editPartnerForm"
      loading={savingPartner}
    >
      {loading && "Preparing partners form...."}
      {!loading && (
        <form onSubmit={(e) => e.preventDefault()} id="editPartnerForm">
          <div className="form-group">
            <label>Partner Name</label>
            <input
              name="name"
              type="text"
              value={partnerDetails.name}
              placeholder=""
              onChange={onChange}
              className="form-control"
            />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default EditPartnerModal;
