import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { Input } from "antd";
import { useSendMessage } from "./hooks/useSendMessage";

const { TextArea } = Input;

const AddSendMessageModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { sendingMessage, isLoading } = useSendMessage();
  const [campaignDetails, setCampaignDetails] = useState({
    phone: "",
    message: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails({ ...campaignDetails, [name]: value });
  };

  async function handleSubmit() {
    sendingMessage(campaignDetails, {
      onSuccess: ({ success, msg }) => {
        if (success) {
          props.close();
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
        } else {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg || "Unexpected Error Occured. Try again!",
              type: "danger",
            },
          });
        }
      },
      onError: (error) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: error?.message || "Unexpected Error Occured. Try again!",
            type: "danger",
          },
        });
      },
    });
  }

  return (
    <Modal
      close={props.close}
      onAccept={() => handleSubmit()}
      title="Send Message"
      form="messageform"
      loading={isLoading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="messageform">
        <div
          className="form-group"
          style={{ display: "flex", flexDirection: "column", gap: ".4rem" }}
        >
          <label>Phone Number</label>

          <input
            name="phone"
            type="text"
            placeholder="e.g 0712345678"
            onChange={onChange}
            className="form-control"
            required
            maxLength={10}
          />
        </div>

        <div
          className="form-group"
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <label>Message</label>
          <TextArea
            onChange={onChange}
            name="message"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddSendMessageModal;
