export const banks = [
  {
    name: "KENYA COMMERCIAL BANK LTD",
    branches: [
      {
        name: "Eastleigh",
        code: "91",
      },
      {
        name: "Cpc",
        code: "92",
      },
      {
        name: "Head Office",
        code: "94",
      },
      {
        name: "Wote",
        code: "95",
      },
      {
        name: "Head Office Finance",
        code: "96",
      },
      {
        name: "Moi Avenue Nairobi",
        code: "100",
      },
      {
        name: "Kipande House",
        code: "101",
      },
      {
        name: "Treasury Sq Mombasa",
        code: "102",
      },
      {
        name: "Nakuru",
        code: "103",
      },
      {
        name: "K.i.c.c",
        code: "104",
      },
      {
        name: "Kisumu",
        code: "105",
      },
      {
        name: "Kericho",
        code: "106",
      },
      {
        name: "Tom Mboya",
        code: "107",
      },
      {
        name: "Thika",
        code: "108",
      },
      {
        name: "Eldoret",
        code: "109",
      },
      {
        name: "Kakamega",
        code: "110",
      },
      {
        name: "Kilindini Mombasa",
        code: "111",
      },
      {
        name: "Nyeri",
        code: "112",
      },
      {
        name: "Industrial Area Nairobi",
        code: "113",
      },
      {
        name: "River Road",
        code: "114",
      },
      {
        name: "Muranga",
        code: "115",
      },
      {
        name: "Embu",
        code: "116",
      },
      {
        name: "Kangema",
        code: "117",
      },
      {
        name: "Kiambu",
        code: "119",
      },
      {
        name: "Karatina",
        code: "120",
      },
      {
        name: "Siaya",
        code: "121",
      },
      {
        name: "Nyahururu",
        code: "122",
      },
      {
        name: "Meru",
        code: "123",
      },
      {
        name: "Mumias",
        code: "124",
      },
      {
        name: "Nanyuki",
        code: "125",
      },
      {
        name: "Moyale",
        code: "127",
      },
      {
        name: "Kikuyu",
        code: "129",
      },
      {
        name: "Tala",
        code: "130",
      },
      {
        name: "Kajiado",
        code: "131",
      },
      {
        name: "Custody services",
        code: "133",
      },
      {
        name: "Matuu",
        code: "134",
      },
      {
        name: "Kitui",
        code: "135",
      },
      {
        name: "Mvita",
        code: "136",
      },
      {
        name: "Jogoo Rd Nairobi",
        code: "137",
      },
      {
        name: "Card Centre",
        code: "139",
      },
      {
        name: "Marsabit",
        code: "140",
      },
      {
        name: "Sarit Centre",
        code: "141",
      },
      {
        name: "Loitokitok",
        code: "142",
      },
      {
        name: "Nandi Hills",
        code: "143",
      },
      {
        name: "Lodwar",
        code: "144",
      },
      {
        name: "Un Gigiri",
        code: "145",
      },
      {
        name: "Hola",
        code: "146",
      },
      {
        name: "Ruiru",
        code: "147",
      },
      {
        name: "Mwingi",
        code: "148",
      },
      {
        name: "Kitale",
        code: "149",
      },
      {
        name: "Mandera",
        code: "150",
      },
      {
        name: "Kapenguria",
        code: "151",
      },
      {
        name: "Kabarnet",
        code: "152",
      },
      {
        name: "Wajir",
        code: "153",
      },
      {
        name: "Maralal",
        code: "154",
      },
      {
        name: "Limuru",
        code: "155",
      },
      {
        name: "Ukunda",
        code: "157",
      },
      {
        name: "Iten",
        code: "158",
      },
      {
        name: "Gilgil",
        code: "159",
      },
      {
        name: "Ongata Rongai",
        code: "161",
      },
      {
        name: "Kitengela",
        code: "162",
      },
      {
        name: "Eldama Ravine",
        code: "163",
      },
      {
        name: "Kibwezi",
        code: "164",
      },
      {
        name: "Kapsabet",
        code: "166",
      },
      {
        name: "University Way",
        code: "167",
      },
      {
        name: "Eldoret West",
        code: "168",
      },
      {
        name: "Garissa",
        code: "169",
      },
      {
        name: "Lamu",
        code: "173",
      },
      {
        name: "Kilifi",
        code: "174",
      },
      {
        name: "Milimani",
        code: "175",
      },
      {
        name: "Nyamira",
        code: "176",
      },
      {
        name: "Mukuruweini",
        code: "177",
      },
      {
        name: "Village Market",
        code: "180",
      },
      {
        name: "Bomet",
        code: "181",
      },
      {
        name: "Mbale",
        code: "183",
      },
      {
        name: "Narok",
        code: "184",
      },
      {
        name: "Othaya",
        code: "185",
      },
      {
        name: "Voi",
        code: "186",
      },
      {
        name: "Webuye",
        code: "188",
      },
      {
        name: "Sotik",
        code: "189",
      },
      {
        name: "Naivasha",
        code: "190",
      },
      {
        name: "Kisii",
        code: "191",
      },
      {
        name: "Migori",
        code: "192",
      },
      {
        name: "Githunguri",
        code: "193",
      },
      {
        name: "Machakos",
        code: "194",
      },
      {
        name: "Kerugoya",
        code: "195",
      },
      {
        name: "Chuka",
        code: "196",
      },
      {
        name: "Bungoma",
        code: "197",
      },
      {
        name: "Wundanyi",
        code: "198",
      },
      {
        name: "Malindi",
        code: "199",
      },
      {
        name: "Capital Hill",
        code: "201",
      },
      {
        name: "Karen",
        code: "202",
      },
      {
        name: "Lokichogio",
        code: "203",
      },
      {
        name: "Gateway Msa Road",
        code: "204",
      },
      {
        name: "Buruburu",
        code: "205",
      },
      {
        name: "Chogoria",
        code: "206",
      },
      {
        name: "Kangari",
        code: "207",
      },
      {
        name: "Kianyaga",
        code: "208",
      },
      {
        name: "Nkubu",
        code: "209",
      },
      {
        name: "Ol Kalou",
        code: "210",
      },
      {
        name: "Makuyu",
        code: "211",
      },
      {
        name: "Mwea",
        code: "212",
      },
      {
        name: "Njambini",
        code: "213",
      },
      {
        name: "Gatundu",
        code: "214",
      },
      {
        name: "Emali",
        code: "215",
      },
      {
        name: "Isiolo",
        code: "216",
      },
      {
        name: "Flamingo",
        code: "217",
      },
      {
        name: "Njoro",
        code: "218",
      },
      {
        name: "Mutomo",
        code: "219",
      },
      {
        name: "Mariakani",
        code: "220",
      },
      {
        name: "Mpeketoni",
        code: "221",
      },
      {
        name: "Mtitu Andei",
        code: "222",
      },
      {
        name: "Mtwapa",
        code: "223",
      },
      {
        name: "Taveta",
        code: "224",
      },
      {
        name: "Kengeleni",
        code: "225",
      },
      {
        name: "Garsen",
        code: "226",
      },
      {
        name: "Watamu",
        code: "227",
      },
      {
        name: "Bondo",
        code: "228",
      },
      {
        name: "Busia",
        code: "229",
      },
      {
        name: "Homa Bay",
        code: "230",
      },
      {
        name: "Kapsowar",
        code: "231",
      },
      {
        name: "Kehancha",
        code: "232",
      },
      {
        name: "Keroka",
        code: "233",
      },
      {
        name: "Kilgoris",
        code: "234",
      },
      {
        name: "Kimilili",
        code: "235",
      },
      {
        name: "Litein",
        code: "236",
      },
      {
        name: "Londiani",
        code: "237",
      },
      {
        name: "Luanda",
        code: "238",
      },
      {
        name: "Malaba",
        code: "239",
      },
      {
        name: "Muhoroni",
        code: "240",
      },
      {
        name: "Oyugis",
        code: "241",
      },
      {
        name: "Ugunja",
        code: "242",
      },
      {
        name: "United Mall",
        code: "243",
      },
      {
        name: "Serem",
        code: "244",
      },
      {
        name: "Sondu",
        code: "245",
      },
      {
        name: "Kisumu West",
        code: "246",
      },
      {
        name: "Marigat",
        code: "247",
      },
      {
        name: "Moi's Bridge",
        code: "248",
      },
      {
        name: "Mashariki",
        code: "249",
      },
      {
        name: "Naro Moro",
        code: "250",
      },
      {
        name: "Kiriaini",
        code: "251",
      },
      {
        name: "Egerton University",
        code: "252",
      },
      {
        name: "Maua",
        code: "253",
      },
      {
        name: "Kawangare",
        code: "254",
      },
      {
        name: "Kimathi Street",
        code: "255",
      },
      {
        name: "Namanga",
        code: "256",
      },
      {
        name: "Gikomba",
        code: "257",
      },
      {
        name: "Kwale",
        code: "258",
      },
      {
        name: "Prestige Plaza",
        code: "259",
      },
      {
        name: "Kariobangi",
        code: "260",
      },
      {
        name: "Biashara Street",
        code: "263",
      },
      {
        name: "Ngara",
        code: "266",
      },
      {
        name: "Kyuso",
        code: "267",
      },
      {
        name: "Masii",
        code: "270",
      },
      {
        name: "Menengai Crater",
        code: "271",
      },
      {
        name: "Town Centre",
        code: "272",
      },
      {
        name: "Makindu",
        code: "278",
      },
      {
        name: "Rongo",
        code: "283",
      },
      {
        name: "Isibania",
        code: "284",
      },
      {
        name: "Kiserian",
        code: "285",
      },
      {
        name: "Mwembe Tayari",
        code: "286",
      },
      {
        name: "Kisauni",
        code: "287",
      },
      {
        name: "Haile Selassie Avenue",
        code: "288",
      },
      {
        name: "Salama House Mortgage Centre",
        code: "289",
      },
      {
        name: "Garden Plaza",
        code: "290",
      },
      {
        name: "Sarit Centre Mortgage Centre",
        code: "291",
      },
      {
        name: "Cpc Bulk Corporate Cheques",
        code: "292",
      },
      {
        name: "Trade Services",
        code: "293",
      },
      {
        name: "Nairobi High Court",
        code: "295",
      },
      {
        name: "Mombasa High Court",
        code: "296",
      },
      {
        name: "Kisumu Airport",
        code: "297",
      },
      {
        name: "Port Victoria",
        code: "298",
      },
      {
        name: "Moi International Airport",
        code: "299",
      },
      {
        name: "Nyali",
        code: "300",
      },
      {
        name: "Westgate Advantage",
        code: "301",
      },
      {
        name: "Diaspora Banking",
        code: "302",
      },
      {
        name: "Kisii West",
        code: "303",
      },
      {
        name: "Mbita",
        code: "304",
      },
      {
        name: "Sori",
        code: "305",
      },
      {
        name: "Hurlingham",
        code: "306",
      },
      {
        name: "Kibera",
        code: "307",
      },
      {
        name: "Thika Road Mall",
        code: "308",
      },
      {
        name: "Kasarani",
        code: "309",
      },
      {
        name: "Maasai Mara",
        code: "310",
      },
      {
        name: "Karbatonjo",
        code: "311",
      },
      {
        name: "Eldoret East",
        code: "312",
      },
    ],
    bank_code: "1",
  },
  {
    name: "STANDARD CHARTERED",
    branches: [
      {
        name: "Eldoret",
        code: "0",
      },
      {
        name: "Kericho",
        code: "1",
      },
      {
        name: "Kisumu",
        code: "2",
      },
      {
        name: "Kitale",
        code: "3",
      },
      {
        name: "Treasury Square",
        code: "4",
      },
      {
        name: "Maritime House",
        code: "5",
      },
      {
        name: "Kenyatta Avenue",
        code: "6",
      },
      {
        name: "Moi Avenue",
        code: "8",
      },
      {
        name: "Nakuru",
        code: "9",
      },
      {
        name: "Nanyuki",
        code: "10",
      },
      {
        name: "Nyeri",
        code: "11",
      },
      {
        name: "Thika",
        code: "12",
      },
      {
        name: "Westlands",
        code: "15",
      },
      {
        name: "Machakos",
        code: "16",
      },
      {
        name: "Meru",
        code: "17",
      },
      {
        name: "Harambee Avenue",
        code: "19",
      },
      {
        name: "Kiambu",
        code: "20",
      },
      {
        name: "Industrial Area",
        code: "53",
      },
      {
        name: "Kakamega",
        code: "54",
      },
      {
        name: "Malindi",
        code: "60",
      },
      {
        name: "Koinange Street",
        code: "64",
      },
      {
        name: "Yaya Centre",
        code: "71",
      },
      {
        name: "Ruaraka",
        code: "72",
      },
      {
        name: "Langata",
        code: "73",
      },
      {
        name: "Makupa",
        code: "74",
      },
      {
        name: "Karen",
        code: "75",
      },
      {
        name: "Muthaiga",
        code: "76",
      },
      {
        name: "C.o.u",
        code: "78",
      },
      {
        name: "Ukay",
        code: "79",
      },
      {
        name: "Eastleigh",
        code: "80",
      },
      {
        name: "Kisii",
        code: "81",
      },
      {
        name: "Upper Hill",
        code: "82",
      },
      {
        name: "Nyali",
        code: "83",
      },
      {
        name: "CHIROMO",
        code: "84",
      },
      {
        name: "Green Span",
        code: "85",
      },
      {
        name: "T-Mall",
        code: "86",
      },
      {
        name: "The Junction",
        code: "87",
      },
      {
        name: "Kitengela",
        code: "89",
      },
      {
        name: "Bungoma",
        code: "90",
      },
      {
        name: "Thika Rd Mall",
        code: "91",
      },
    ],
    bank_code: "2",
  },
  {
    name: "BARCLAYS BANK OF KENYA LIMITED",
    branches: [
      {
        name: "Head Office - VPC",
        code: "1",
      },
      {
        name: "Kapsabet",
        code: "2",
      },
      {
        name: "Eldoret",
        code: "3",
      },
      {
        name: "Embu",
        code: "4",
      },
      {
        name: "Muranga",
        code: "5",
      },
      {
        name: "Kapenguria",
        code: "6",
      },
      {
        name: "Kericho",
        code: "7",
      },
      {
        name: "Kisii",
        code: "8",
      },
      {
        name: "Kisumu",
        code: "9",
      },
      {
        name: "South C",
        code: "10",
      },
      {
        name: "Limuru",
        code: "11",
      },
      {
        name: "Malindi",
        code: "12",
      },
      {
        name: "Meru",
        code: "13",
      },
      {
        name: "Eastleigh Branch",
        code: "14",
      },
      {
        name: "Kitui",
        code: "15",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "16",
      },
      {
        name: "Garissa",
        code: "17",
      },
      {
        name: "Nyamira",
        code: "18",
      },
      {
        name: "Kilifi",
        code: "19",
      },
      {
        name: "Office Park Westlands",
        code: "20",
      },
      {
        name: "Barclaycard Operations",
        code: "21",
      },
      {
        name: "Paymants And International Services",
        code: "22",
      },
      {
        name: "Gilgil",
        code: "23",
      },
      {
        name: "Githurai",
        code: "24",
      },
      {
        name: "Kakamega",
        code: "26",
      },
      {
        name: "Nakuru East",
        code: "27",
      },
      {
        name: "Buruburu",
        code: "28",
      },
      {
        name: "Bomet",
        code: "29",
      },
      {
        name: "Nyeri",
        code: "30",
      },
      {
        name: "Thika",
        code: "31",
      },
      {
        name: "Port Mombasa",
        code: "32",
      },
      {
        name: "Gikomba",
        code: "33",
      },
      {
        name: "Kawangware",
        code: "34",
      },
      {
        name: "Mbale",
        code: "35",
      },
      {
        name: "Plaza Premier Centre",
        code: "36",
      },
      {
        name: "River Road",
        code: "37",
      },
      {
        name: "Chomba House-river Road",
        code: "38",
      },
      {
        name: "Mumias",
        code: "39",
      },
      {
        name: "Machakos",
        code: "40",
      },
      {
        name: "Narok",
        code: "41",
      },
      {
        name: "Isiolo",
        code: "42",
      },
      {
        name: "Ngong Branch",
        code: "43",
      },
      {
        name: "Maua",
        code: "44",
      },
      {
        name: "Hurlingham",
        code: "45",
      },
      {
        name: "Makupa",
        code: "46",
      },
      {
        name: "Development House Branch",
        code: "47",
      },
      {
        name: "Bungoma",
        code: "48",
      },
      {
        name: "Lavington Branch",
        code: "49",
      },
      {
        name: "Tala",
        code: "50",
      },
      {
        name: "Homa Bay",
        code: "51",
      },
      {
        name: "Ongata Rongai",
        code: "52",
      },
      {
        name: "Othaya",
        code: "53",
      },
      {
        name: "Voi",
        code: "54",
      },
      {
        name: "Muthaiga",
        code: "55",
      },
      {
        name: "Barclays Advisory And Reg. Services",
        code: "56",
      },
      {
        name: "Githunguri",
        code: "57",
      },
      {
        name: "Webuye",
        code: "58",
      },
      {
        name: "Kasarani",
        code: "59",
      },
      {
        name: "Chuka Branch",
        code: "60",
      },
      {
        name: "Nakumatt-westgate",
        code: "61",
      },
      {
        name: "Kabarnet",
        code: "62",
      },
      {
        name: "Kerugoya",
        code: "63",
      },
      {
        name: "Taveta",
        code: "64",
      },
      {
        name: "Karen",
        code: "65",
      },
      {
        name: "Wundanyi",
        code: "66",
      },
      {
        name: "Ruaraka",
        code: "67",
      },
      {
        name: "Pamoja",
        code: "68",
      },
      {
        name: "Wote",
        code: "69",
      },
      {
        name: "Enterprise Road",
        code: "70",
      },
      {
        name: "Nakumatt Meru",
        code: "71",
      },
      {
        name: "Juja",
        code: "72",
      },
      {
        name: "Westlands",
        code: "73",
      },
      {
        name: "Kikuyu",
        code: "74",
      },
      {
        name: "Moi Avenue Nairobi",
        code: "75",
      },
      {
        name: "Kenyatta Avenue",
        code: "76",
      },
      {
        name: "Barcalys Plaza Corporate Service Centre",
        code: "77",
      },
      {
        name: "Kiriaini Branch",
        code: "78",
      },
      {
        name: "Avon Centre",
        code: "79",
      },
      {
        name: "Migori",
        code: "80",
      },
      {
        name: "Digo",
        code: "81",
      },
      {
        name: "Haile Selassie Avenue",
        code: "82",
      },
      {
        name: "Nairobi University",
        code: "83",
      },
      {
        name: "Bunyala Road",
        code: "84",
      },
      {
        name: "Nairobi West",
        code: "86",
      },
      {
        name: "Parklands",
        code: "87",
      },
      {
        name: "Busia",
        code: "88",
      },
      {
        name: "Pangani Branch",
        code: "89",
      },
      {
        name: "Abc Premier Life Centre",
        code: "90",
      },
      {
        name: "Kariobangi",
        code: "93",
      },
      {
        name: "Queensway House Branch",
        code: "94",
      },
      {
        name: "Nakumatt Embakasi",
        code: "95",
      },
      {
        name: "Barclays Merchant Finance Ltd.",
        code: "96",
      },
      {
        name: "Barclays Securities Services",
        code: "97",
      },
      {
        name: "Diani",
        code: "100",
      },
      {
        name: "Nairobi J.K.I.A",
        code: "103",
      },
      {
        name: "Village Market - Premier Life Centre",
        code: "105",
      },
      {
        name: "Sarit Centre - Premier Life Centre",
        code: "106",
      },
      {
        name: "Yaya Centre- Premier Life Centre",
        code: "109",
      },
      {
        name: "Naivasha",
        code: "111",
      },
      {
        name: "Market Branch",
        code: "113",
      },
      {
        name: "Changamwe Branch",
        code: "114",
      },
      {
        name: "Rahimtulla Trust Towers - Premier Life Centre",
        code: "117",
      },
      {
        name: "Nakuru West",
        code: "125",
      },
      {
        name: "Bamburi",
        code: "128",
      },
      {
        name: "Harambee Ave - Premier Life Centre",
        code: "130",
      },
      {
        name: "Kitale",
        code: "132",
      },
      {
        name: "Nyahururu",
        code: "139",
      },
      {
        name: "Treasury Operations",
        code: "144",
      },
      {
        name: "Moi Avenue Mombasa - Premier Life Centre",
        code: "145",
      },
      {
        name: "Cash Monitoring Unit",
        code: "151",
      },
      {
        name: "Nanyuki",
        code: "190",
      },
      {
        name: "Karatina",
        code: "206",
      },
      {
        name: "Mombasa Nyerere Ave - Premier Life Centre",
        code: "220",
      },
      {
        name: "Consumer Operations",
        code: "273",
      },
      {
        name: "Finance Department",
        code: "300",
      },
      {
        name: "Documents And Securities Dsc",
        code: "337",
      },
      {
        name: "Retail Credit Team",
        code: "340",
      },
      {
        name: "Credit Operations",
        code: "354",
      },
      {
        name: "Head office",
        code: "400",
      },
    ],
    bank_code: "3",
  },
  {
    name: "BANK OF INDIA",
    branches: [
      {
        name: "Kenyatta Avenue Nairobi",
        code: "0",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "1",
      },
      {
        name: "Industrial Area",
        code: "2",
      },
      {
        name: "Westlands",
        code: "3",
      },
    ],
    bank_code: "5",
  },
  {
    name: "BANK OF BARODA",
    branches: [
      {
        name: "Nairobi Main",
        code: "0",
      },
      {
        name: "Digo Road Mombasa",
        code: "2",
      },
      {
        name: "Thika",
        code: "4",
      },
      {
        name: "Kisumu",
        code: "5",
      },
      {
        name: "Sarit Centre",
        code: "6",
      },
      {
        name: "Industrial Area",
        code: "7",
      },
      {
        name: "Eldoret",
        code: "8",
      },
      {
        name: "Nakuru",
        code: "9",
      },
      {
        name: "Kakamega",
        code: "10",
      },
      {
        name: "Nyali Mombasa",
        code: "11",
      },
    ],
    bank_code: "6",
  },
  {
    name: "COMMERCIAL BANK OF AFRICA LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Upper Hill",
        code: "1",
      },
      {
        name: "Wabera Street",
        code: "2",
      },
      {
        name: "Mama Ngina",
        code: "3",
      },
      {
        name: "Westlands",
        code: "4",
      },
      {
        name: "Industrial Area",
        code: "5",
      },
      {
        name: "Mamlaka",
        code: "6",
      },
      {
        name: "Village Market",
        code: "7",
      },
      {
        name: "Cargo Centre",
        code: "8",
      },
      {
        name: "Park Side",
        code: "9",
      },
      {
        name: "Galleria Mall",
        code: "16",
      },
      {
        name: "Junction",
        code: "17",
      },
      {
        name: "Thika Road Mall",
        code: "18",
      },
      {
        name: "Moi Avenue Mombasa",
        code: "20",
      },
      {
        name: "Meru",
        code: "21",
      },
      {
        name: "Nakuru",
        code: "22",
      },
      {
        name: "Bamburi",
        code: "23",
      },
      {
        name: "Diani",
        code: "24",
      },
      {
        name: "Changamwe",
        code: "25",
      },
      {
        name: "Eldoret",
        code: "26",
      },
      {
        name: "Kisumu",
        code: "27",
      },
      {
        name: "Thika",
        code: "28",
      },
    ],
    bank_code: "7",
  },
  {
    name: "HABIB BANK LTD",
    branches: [
      {
        name: "Mombasa",
        code: "46",
      },
      {
        name: "Malindi",
        code: "47",
      },
      {
        name: "Kimathi Street",
        code: "48",
      },
      {
        name: "Kenyatta Avenue",
        code: "49",
      },
      {
        name: "Kisumu",
        code: "86",
      },
    ],
    bank_code: "8",
  },
  {
    name: "CENTRAL BANK OF KENYA",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Head Office",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Kisumu",
        code: "3",
      },
      {
        name: "Eldoret",
        code: "4",
      },
    ],
    bank_code: "9",
  },
  {
    name: "PRIME BANK LIMITED",
    branches: [
      {
        name: "H/o Riverside",
        code: "0",
      },
      {
        name: "Kenindia",
        code: "1",
      },
      {
        name: "Biashara",
        code: "2",
      },
      {
        name: "Mombasa",
        code: "3",
      },
      {
        name: "Westlands",
        code: "4",
      },
      {
        name: "Industrial Area",
        code: "5",
      },
      {
        name: "Kisumu",
        code: "6",
      },
      {
        name: "Parklands",
        code: "7",
      },
      {
        name: "Riverside Drive",
        code: "8",
      },
      {
        name: "Card Centre",
        code: "9",
      },
      {
        name: "Hurlingham",
        code: "10",
      },
      {
        name: "Capital Centre",
        code: "11",
      },
      {
        name: "Nyali",
        code: "12",
      },
      {
        name: "Kamukunji",
        code: "14",
      },
      {
        name: "Eldoret",
        code: "15",
      },
      {
        name: "Karen",
        code: "16",
      },
      {
        name: "Nakuru",
        code: "17",
      },
      {
        name: "Gigiri",
        code: "18",
      },
    ],
    bank_code: "10",
  },
  {
    name: "CO-OPERATIVE BANK",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Finance And Accounts",
        code: "1",
      },
      {
        name: "Co-op House",
        code: "2",
      },
      {
        name: "Kisumu",
        code: "3",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "4",
      },
      {
        name: "Meru",
        code: "5",
      },
      {
        name: "Nakuru",
        code: "6",
      },
      {
        name: "Industrial Area",
        code: "7",
      },
      {
        name: "Kisii",
        code: "8",
      },
      {
        name: "Machakos",
        code: "9",
      },
      {
        name: "Nyeri",
        code: "10",
      },
      {
        name: "Ukulima",
        code: "11",
      },
      {
        name: "Kerugoya",
        code: "12",
      },
      {
        name: "Eldoret",
        code: "13",
      },
      {
        name: "Moi Avenue",
        code: "14",
      },
      {
        name: "Naivasha",
        code: "15",
      },
      {
        name: "Nyahururu",
        code: "17",
      },
      {
        name: "Chuka",
        code: "18",
      },
      {
        name: "Wakulima Market",
        code: "19",
      },
      {
        name: "Eastleigh",
        code: "20",
      },
      {
        name: "Kiambu",
        code: "21",
      },
      {
        name: "Homa Bay",
        code: "22",
      },
      {
        name: "Embu",
        code: "23",
      },
      {
        name: "Kericho",
        code: "24",
      },
      {
        name: "Bungoma",
        code: "25",
      },
      {
        name: "Muranga",
        code: "26",
      },
      {
        name: "Kayole",
        code: "27",
      },
      {
        name: "Karatina",
        code: "28",
      },
      {
        name: "Ukunda",
        code: "29",
      },
      {
        name: "Mtwapa",
        code: "30",
      },
      {
        name: "University Way",
        code: "31",
      },
      {
        name: "Buru Buru",
        code: "32",
      },
      {
        name: "Athi River",
        code: "33",
      },
      {
        name: "Mumias",
        code: "34",
      },
      {
        name: "Stima Plaza",
        code: "35",
      },
      {
        name: "Westlands",
        code: "36",
      },
      {
        name: "Upper Hill",
        code: "37",
      },
      {
        name: "Ongata Rongai",
        code: "38",
      },
      {
        name: "Thika",
        code: "39",
      },
      {
        name: "Nacico",
        code: "40",
      },
      {
        name: "Kariobangi",
        code: "41",
      },
      {
        name: "Kawangware",
        code: "42",
      },
      {
        name: "Makutano",
        code: "43",
      },
      {
        name: "Canon House",
        code: "44",
      },
      {
        name: "Kimathi Street",
        code: "45",
      },
      {
        name: "Kitale",
        code: "46",
      },
      {
        name: "Githurai",
        code: "47",
      },
      {
        name: "Maua",
        code: "48",
      },
      {
        name: "City Hall",
        code: "49",
      },
      {
        name: "Digo Rd",
        code: "50",
      },
      {
        name: "NBC",
        code: "51",
      },
      {
        name: "Kakamega",
        code: "52",
      },
      {
        name: "Migori",
        code: "53",
      },
      {
        name: "Kenyatta Avenue",
        code: "54",
      },
      {
        name: "Nkubu",
        code: "55",
      },
      {
        name: "Enterprise Road",
        code: "56",
      },
      {
        name: "Busia",
        code: "57",
      },
      {
        name: "Siaya",
        code: "58",
      },
      {
        name: "Voi",
        code: "59",
      },
      {
        name: "Mariakani",
        code: "60",
      },
      {
        name: "Malindi",
        code: "61",
      },
      {
        name: "Zimmerman",
        code: "62",
      },
      {
        name: "Nakuru East",
        code: "63",
      },
      {
        name: "Kitengela",
        code: "64",
      },
      {
        name: "Aga Khan Walk",
        code: "65",
      },
      {
        name: "Narok",
        code: "66",
      },
      {
        name: "Kitui",
        code: "67",
      },
      {
        name: "Nanyuki",
        code: "68",
      },
      {
        name: "Embakasi",
        code: "69",
      },
      {
        name: "Kibera",
        code: "70",
      },
      {
        name: "Siakago",
        code: "71",
      },
      {
        name: "Kapsabet",
        code: "72",
      },
      {
        name: "Mbita",
        code: "73",
      },
      {
        name: "Kangemi",
        code: "74",
      },
      {
        name: "Dandora",
        code: "75",
      },
      {
        name: "Kajiado",
        code: "76",
      },
      {
        name: "Tala",
        code: "77",
      },
      {
        name: "Gikomba",
        code: "78",
      },
      {
        name: "River Road",
        code: "79",
      },
      {
        name: "Nyamira",
        code: "80",
      },
      {
        name: "Garissa",
        code: "81",
      },
      {
        name: "Bomet",
        code: "82",
      },
      {
        name: "Keroka",
        code: "83",
      },
      {
        name: "Gilgil",
        code: "84",
      },
      {
        name: "Tom Mboya",
        code: "85",
      },
      {
        name: "Likoni",
        code: "86",
      },
      {
        name: "Donholm",
        code: "87",
      },
      {
        name: "Mwingi",
        code: "88",
      },
      {
        name: "Ruaka",
        code: "89",
      },
      {
        name: "Webuye",
        code: "90",
      },
      {
        name: "Netbank",
        code: "95",
      },
      {
        name: "Clearing Centre",
        code: "97",
      },
      {
        name: "Co-op Card Centre",
        code: "98",
      },
      {
        name: "Ndhiwa",
        code: "100",
      },
      {
        name: "Oyugis",
        code: "101",
      },
      {
        name: "Isiolo",
        code: "102",
      },
      {
        name: "Eldoret West",
        code: "103",
      },
      {
        name: "Changamwe",
        code: "104",
      },
      {
        name: "Kisumu East",
        code: "105",
      },
      {
        name: "Githurai Kimbo",
        code: "106",
      },
      {
        name: "Mlolongo",
        code: "107",
      },
      {
        name: "Kilifi",
        code: "108",
      },
      {
        name: "Ol Kalau",
        code: "109",
      },
      {
        name: "Mbale",
        code: "110",
      },
      {
        name: "Kimilili",
        code: "111",
      },
      {
        name: "Kisii East",
        code: "112",
      },
      {
        name: "Kilgoris",
        code: "113",
      },
      {
        name: "WOTE",
        code: "114",
      },
      {
        name: "Malaba",
        code: "116",
      },
      {
        name: "Molo",
        code: "117",
      },
      {
        name: "Mwea",
        code: "118",
      },
      {
        name: "Kutus",
        code: "119",
      },
      {
        name: "Umoja",
        code: "120",
      },
      {
        name: "Embakasi Junction",
        code: "121",
      },
      {
        name: "KONGOWEA",
        code: "122",
      },
      {
        name: "Langata Road",
        code: "123",
      },
      {
        name: "Juja",
        code: "124",
      },
      {
        name: "NGONG",
        code: "125",
      },
      {
        name: "Kawangware 46",
        code: "126",
      },
      {
        name: "Mombasa Road",
        code: "127",
      },
      {
        name: "Marsabit",
        code: "128",
      },
      {
        name: "Othaya",
        code: "131",
      },
      {
        name: "LIMURU",
        code: "132",
      },
      {
        name: "Kikuyu",
        code: "133",
      },
      {
        name: "GITHUNGURI",
        code: "134",
      },
      {
        name: "Karen",
        code: "135",
      },
      {
        name: "Mpeketoni",
        code: "136",
      },
      {
        name: "Gatundu",
        code: "137",
      },
      {
        name: "Ruiru",
        code: "138",
      },
      {
        name: "Nyali Mall",
        code: "139",
      },
      {
        name: "Yala",
        code: "140",
      },
      {
        name: "Maasai Mall-Ongata Rongai",
        code: "141",
      },
      {
        name: "Thika Rd Mall",
        code: "142",
      },
      {
        name: "Kiserian",
        code: "143",
      },
      {
        name: "Nandi Hills",
        code: "144",
      },
      {
        name: "Lodwar",
        code: "145",
      },
      {
        name: "Engineer",
        code: "147",
      },
      {
        name: "Rongo",
        code: "148",
      },
      {
        name: "Shares Operations",
        code: "228",
      },
      {
        name: "E-Channels",
        code: "250",
      },
      {
        name: "Diaspora Banking",
        code: "254",
      },
      {
        name: "Kilindini Port",
        code: "266",
      },
      {
        name: "Money Transfers Agency",
        code: "270",
      },
    ],
    bank_code: "11",
  },
  {
    name: "NATIONAL BANK OF KENYA",
    branches: [
      {
        name: "Central Business Unit",
        code: "0",
      },
      {
        name: "Kenyatta",
        code: "2",
      },
      {
        name: "Harambee",
        code: "3",
      },
      {
        name: "Hill",
        code: "4",
      },
      {
        name: "Busia",
        code: "5",
      },
      {
        name: "Kiambu",
        code: "6",
      },
      {
        name: "Meru",
        code: "7",
      },
      {
        name: "Karatina",
        code: "8",
      },
      {
        name: "Narok",
        code: "9",
      },
      {
        name: "Kisii",
        code: "10",
      },
      {
        name: "Malindi",
        code: "11",
      },
      {
        name: "Nyeri",
        code: "12",
      },
      {
        name: "Kitale",
        code: "13",
      },
      {
        name: "Eastleigh",
        code: "15",
      },
      {
        name: "Limuru",
        code: "16",
      },
      {
        name: "Kitui",
        code: "17",
      },
      {
        name: "Molo",
        code: "18",
      },
      {
        name: "Bungoma",
        code: "19",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "20",
      },
      {
        name: "Kapsabet",
        code: "21",
      },
      {
        name: "Awendo",
        code: "22",
      },
      {
        name: "Portway-msa",
        code: "23",
      },
      {
        name: "Hospital Br.",
        code: "25",
      },
      {
        name: "Ruiru",
        code: "26",
      },
      {
        name: "Ongata Rongai",
        code: "27",
      },
      {
        name: "Embu",
        code: "28",
      },
      {
        name: "Kakamega",
        code: "29",
      },
      {
        name: "Nakuru",
        code: "30",
      },
      {
        name: "Ukunda",
        code: "31",
      },
      {
        name: "Upper Hill",
        code: "32",
      },
      {
        name: "Nandi Hills",
        code: "33",
      },
      {
        name: "Migori",
        code: "34",
      },
      {
        name: "Westlands",
        code: "35",
      },
      {
        name: "Times Tower",
        code: "36",
      },
      {
        name: "Maua",
        code: "37",
      },
      {
        name: "Wilson Airport",
        code: "38",
      },
      {
        name: "J.K.I.A.",
        code: "39",
      },
      {
        name: "Eldoret",
        code: "40",
      },
      {
        name: "Moi's Bridge",
        code: "41",
      },
      {
        name: "Mutomo",
        code: "42",
      },
      {
        name: "Kianjai",
        code: "43",
      },
      {
        name: "Kenyatta University",
        code: "44",
      },
      {
        name: "ST Pauls University",
        code: "45",
      },
      {
        name: "Moi Univesity Eldoret",
        code: "46",
      },
      {
        name: "Moi Int Airport Mombasa",
        code: "47",
      },
      {
        name: "Machakos",
        code: "48",
      },
      {
        name: "Kitengela",
        code: "49",
      },
      {
        name: "Kisumu",
        code: "50",
      },
      {
        name: "Mtwapa",
        code: "51",
      },
      {
        name: "Changamwe",
        code: "52",
      },
      {
        name: "Garissa",
        code: "53",
      },
      {
        name: "Thika",
        code: "54",
      },
      {
        name: "Mombasa Polytechnic",
        code: "55",
      },
      {
        name: "Bomet",
        code: "56",
      },
      {
        name: "GreenSpan",
        code: "58",
      },
      {
        name: "Sameer Park",
        code: "59",
      },
      {
        name: "Seku",
        code: "60",
      },
      {
        name: "Ngong Road",
        code: "61",
      },
      {
        name: "Moi Avenue",
        code: "62",
      },
      {
        name: "Mountain Mall",
        code: "63",
      },
      {
        name: "Nyali Centre",
        code: "65",
      },
      {
        name: "Kilifi",
        code: "66",
      },
      {
        name: "Wajir",
        code: "93",
      },
      {
        name: "Bondeni",
        code: "94",
      },
      {
        name: "Lunga Lunga",
        code: "95",
      },
      {
        name: "Card Centre",
        code: "98",
      },
      {
        name: "Head Office",
        code: "99",
      },
      {
        name: "Central CLearing Centre",
        code: "198",
      },
    ],
    bank_code: "12",
  },
  {
    name: "ORIENTAL COMMERCIAL BANK",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Koinange Street",
        code: "1",
      },
      {
        name: "Nakuru",
        code: "3",
      },
      {
        name: "Nakuru",
        code: "4",
      },
      {
        name: "Eldoret",
        code: "5",
      },
      {
        name: "Kitale",
        code: "6",
      },
      {
        name: "Westlands",
        code: "7",
      },
      {
        name: "Nakumatt Mega",
        code: "8",
      },
      {
        name: "Thika Road Mall",
        code: "9",
      },
      {
        name: "Mombasa",
        code: "10",
      },
    ],
    bank_code: "14",
  },
  {
    name: "CITIBANK N.A.",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Mombasa",
        code: "400",
      },
      {
        name: "Gigiri Agency",
        code: "500",
      },
      {
        name: "Kisumu",
        code: "700",
      },
    ],
    bank_code: "16",
  },
  {
    name: "HABIB BANK A.G.",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Mombasa",
        code: "1",
      },
      {
        name: "Industrial Area",
        code: "2",
      },
      {
        name: "Westlands",
        code: "3",
      },
    ],
    bank_code: "17",
  },
  {
    name: "MIDDLE EAST BANK (K) LIMITED",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Nairobi",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Milimani",
        code: "3",
      },
      {
        name: "Industrial Area",
        code: "4",
      },
    ],
    bank_code: "18",
  },
  {
    name: "BANK OF AFRICA KENYA LTD",
    branches: [
      {
        name: "Reinsurance Plaza Nairobi",
        code: "0",
      },
      {
        name: "Mombasa",
        code: "1",
      },
      {
        name: "Westlands",
        code: "2",
      },
      {
        name: "Uhuru Highway",
        code: "3",
      },
      {
        name: "River Road",
        code: "4",
      },
      {
        name: "Thika",
        code: "5",
      },
      {
        name: "Kisumu",
        code: "6",
      },
      {
        name: "Ruaraka",
        code: "7",
      },
      {
        name: "Monrovia Street",
        code: "8",
      },
      {
        name: "Nakuru",
        code: "9",
      },
      {
        name: "Ngong Road",
        code: "10",
      },
      {
        name: "Eldoret",
        code: "11",
      },
      {
        name: "Embakasi",
        code: "12",
      },
      {
        name: "Kericho",
        code: "13",
      },
      {
        name: "Changamwe",
        code: "14",
      },
      {
        name: "Bungoma",
        code: "15",
      },
      {
        name: "Ongata Rongai",
        code: "16",
      },
      {
        name: "Kisii",
        code: "17",
      },
      {
        name: "Meru",
        code: "18",
      },
      {
        name: "Kitengela",
        code: "19",
      },
      {
        name: "Nyali",
        code: "20",
      },
      {
        name: "Galleria",
        code: "21",
      },
      {
        name: "Greenspan Mall",
        code: "22",
      },
      {
        name: "Upperhill",
        code: "23",
      },
      {
        name: "Nanyuki",
        code: "24",
      },
      {
        name: "LUNGA LUNGA",
        code: "25",
      },
      {
        name: "Bank of Africa",
        code: "26",
      },
      {
        name: "Sameer Business Park",
        code: "27",
      },
      {
        name: "Moi Avenue Mombasa",
        code: "28",
      },
      {
        name: "Ongata Rongai II",
        code: "29",
      },
    ],
    bank_code: "19",
  },
  {
    name: "DUBAI BANK OF KENYA LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Eastleigh",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Mombasa",
        code: "3",
      },
      {
        name: "Nakuru",
        code: "4",
      },
      {
        name: "Nakuru",
        code: "20",
      },
    ],
    bank_code: "20",
  },
  {
    name: "CONSOLIDATED BANK OF KENYA LTD",
    branches: [
      {
        name: "Harambee Avenue",
        code: "0",
      },
      {
        name: "Muranga",
        code: "1",
      },
      {
        name: "Embu",
        code: "2",
      },
      {
        name: "Mombasa",
        code: "3",
      },
      {
        name: "Koinange Street",
        code: "4",
      },
      {
        name: "Thika",
        code: "5",
      },
      {
        name: "Meru",
        code: "6",
      },
      {
        name: "Nyeri",
        code: "7",
      },
      {
        name: "Laare",
        code: "8",
      },
      {
        name: "Maua",
        code: "9",
      },
      {
        name: "Isiolo",
        code: "10",
      },
      {
        name: "Head Office",
        code: "11",
      },
      {
        name: "Umoja",
        code: "13",
      },
      {
        name: "River Road",
        code: "14",
      },
      {
        name: "Eldoret",
        code: "15",
      },
      {
        name: "Nakuru",
        code: "16",
      },
      {
        name: "Kitengela",
        code: "17",
      },
      {
        name: "Embakasi",
        code: "18",
      },
    ],
    bank_code: "23",
  },
  {
    name: "CREDIT BANK LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Koinange Street",
        code: "1",
      },
      {
        name: "Kisumu",
        code: "2",
      },
      {
        name: "Nakuru",
        code: "3",
      },
      {
        name: "Kisii",
        code: "4",
      },
      {
        name: "Westlands",
        code: "5",
      },
      {
        name: "Industrial Area",
        code: "6",
      },
      {
        name: "Nakuru Kenyatta Avenue",
        code: "8",
      },
      {
        name: "Eldoret",
        code: "9",
      },
      {
        name: "Rongai",
        code: "10",
      },
      {
        name: "Mombasa - Nyali Centre",
        code: "11",
      },
      {
        name: "Thika",
        code: "12",
      },
    ],
    bank_code: "25",
  },
  {
    name: "TRANS-NATIONAL BANK",
    branches: [
      {
        name: "Head Office",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Eldoret",
        code: "3",
      },
      {
        name: "Nakuru",
        code: "4",
      },
      {
        name: "Mia",
        code: "5",
      },
      {
        name: "J.K.I.A.",
        code: "6",
      },
      {
        name: "Kirinyaga Rd Nairobi",
        code: "7",
      },
      {
        name: "Kabarak",
        code: "8",
      },
      {
        name: "Olenguruone",
        code: "9",
      },
      {
        name: "Kericho",
        code: "10",
      },
      {
        name: "Nandi Hills",
        code: "11",
      },
      {
        name: "Epz",
        code: "12",
      },
      {
        name: "Nandi Hills",
        code: "13",
      },
      {
        name: "Kabarnet",
        code: "14",
      },
      {
        name: "Kenyatta Street Kitale",
        code: "15",
      },
      {
        name: "Narok",
        code: "16",
      },
      {
        name: "Bomet",
        code: "17",
      },
    ],
    bank_code: "26",
  },
  {
    name: "CHASE BANK (KENYA) LIMITED",
    branches: [
      {
        name: "Central Office",
        code: "0",
      },
      {
        name: "Head Office",
        code: "1",
      },
      {
        name: "Village Market",
        code: "3",
      },
      {
        name: "Mombasa",
        code: "4",
      },
      {
        name: "Hurlingham",
        code: "5",
      },
      {
        name: "Eastleigh",
        code: "6",
      },
      {
        name: "Parklands",
        code: "7",
      },
      {
        name: "Riverside Mews",
        code: "8",
      },
      {
        name: "Thika",
        code: "10",
      },
      {
        name: "Nakuru",
        code: "11",
      },
      {
        name: "Donholm",
        code: "12",
      },
      {
        name: "Bondeni Chase Iman",
        code: "13",
      },
      {
        name: "Ngara Mini",
        code: "14",
      },
      {
        name: "Kisumu",
        code: "15",
      },
      {
        name: "Eldoret",
        code: "16",
      },
      {
        name: "DIAMOND PLAZA",
        code: "17",
      },
      {
        name: "Windsor",
        code: "18",
      },
      {
        name: "Malindi",
        code: "19",
      },
      {
        name: "Embakasi",
        code: "20",
      },
      {
        name: "Upper Hill",
        code: "21",
      },
      {
        name: "Nyali",
        code: "22",
      },
      {
        name: "Buruburu",
        code: "23",
      },
      {
        name: "Strathmore",
        code: "24",
      },
      {
        name: "Kisii",
        code: "25",
      },
      {
        name: "Virtual",
        code: "26",
      },
      {
        name: "Rafiki DTM Clearing Centre",
        code: "27",
      },
      {
        name: "Chase XPress Ngong Road",
        code: "28",
      },
      {
        name: "Chase Elite ABC Place",
        code: "29",
      },
      {
        name: "Sameer Business Park",
        code: "30",
      },
      {
        name: "Mtwapa",
        code: "31",
      },
      {
        name: "Ongata Rongai",
        code: "32",
      },
      {
        name: "Westlands",
        code: "33",
      },
      {
        name: "Machakos",
        code: "34",
      },
      {
        name: "Mombasa Old Town",
        code: "35",
      },
      {
        name: "River Road",
        code: "36",
      },
      {
        name: "Lunga Lunga",
        code: "37",
      },
    ],
    bank_code: "30",
  },
  {
    name: "STANBIC BANK KENYA LIMITED",
    branches: [
      {
        name: "CFC Stanbic Head Office",
        code: "0",
      },
      {
        name: "Kenyatta Ave",
        code: "2",
      },
      {
        name: "Digo road",
        code: "3",
      },
      {
        name: "Waiyaki Way",
        code: "4",
      },
      {
        name: "Industrial Area",
        code: "5",
      },
      {
        name: "Harambee Avenue",
        code: "6",
      },
      {
        name: "Chiromo Road",
        code: "7",
      },
      {
        name: "International House",
        code: "8",
      },
      {
        name: "Upper Hill",
        code: "10",
      },
      {
        name: "Naivasha",
        code: "11",
      },
      {
        name: "Westgate",
        code: "12",
      },
      {
        name: "Kisumu",
        code: "13",
      },
      {
        name: "Nakuru",
        code: "14",
      },
      {
        name: "Thika",
        code: "15",
      },
      {
        name: "Nanyuki",
        code: "17",
      },
      {
        name: "Meru",
        code: "18",
      },
      {
        name: "Buruburu",
        code: "19",
      },
      {
        name: "Gikomba",
        code: "20",
      },
      {
        name: "Ruaraka",
        code: "21",
      },
      {
        name: "Eldoret",
        code: "22",
      },
      {
        name: "Karen",
        code: "23",
      },
      {
        name: "Kisii",
        code: "24",
      },
      {
        name: "Warwick",
        code: "25",
      },
      {
        name: "Private Clients",
        code: "26",
      },
      {
        name: "Nyali",
        code: "27",
      },
      {
        name: "Malindi",
        code: "28",
      },
      {
        name: "Central Processing H/o",
        code: "999",
      },
    ],
    bank_code: "31",
  },
  {
    name: "AFRICAN BANKING CORPORATION",
    branches: [
      {
        name: "Head Office / Koinange St.",
        code: "0",
      },
      {
        name: "Westlands",
        code: "1",
      },
      {
        name: "Industrial Area",
        code: "2",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "3",
      },
      {
        name: "Kisumu",
        code: "4",
      },
      {
        name: "Eldoret",
        code: "5",
      },
      {
        name: "Meru",
        code: "6",
      },
      {
        name: "Libra House",
        code: "7",
      },
      {
        name: "Nakuru",
        code: "8",
      },
      {
        name: "Lamu",
        code: "9",
      },
    ],
    bank_code: "35",
  },
  {
    name: "IMPERIAL BANK LIMITED",
    branches: [
      {
        name: "Head Office",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Upper Hill",
        code: "3",
      },
      {
        name: "Parklands",
        code: "4",
      },
      {
        name: "Malindi",
        code: "5",
      },
      {
        name: "Industrial Area",
        code: "6",
      },
      {
        name: "Watamu",
        code: "7",
      },
      {
        name: "Diani",
        code: "8",
      },
      {
        name: "Kilifi",
        code: "9",
      },
      {
        name: "Eldoret",
        code: "10",
      },
      {
        name: "Karen",
        code: "11",
      },
      {
        name: "Thika",
        code: "12",
      },
      {
        name: "Changamwe",
        code: "14",
      },
      {
        name: "Riverside",
        code: "15",
      },
      {
        name: "Likoni",
        code: "16",
      },
      {
        name: "Haile Selassie Avenue",
        code: "17",
      },
      {
        name: "Village Market",
        code: "18",
      },
      {
        name: "Bamburi",
        code: "19",
      },
      {
        name: "Junction Mall",
        code: "20",
      },
      {
        name: "Greenspan",
        code: "21",
      },
      {
        name: "Westside Mall",
        code: "22",
      },
      {
        name: "Kenyatta Street",
        code: "23",
      },
      {
        name: "Westlands",
        code: "24",
      },
      {
        name: "Highridge",
        code: "26",
      },
      {
        name: "Nyali Cinemax",
        code: "27",
      },
    ],
    bank_code: "39",
  },
  {
    name: "NIC BANK",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "City Centre",
        code: "101",
      },
      {
        name: "NIC House",
        code: "102",
      },
      {
        name: "Harbour House",
        code: "103",
      },
      {
        name: "Head Office - Fargo",
        code: "104",
      },
      {
        name: "Westlands",
        code: "105",
      },
      {
        name: "The Junction Br.",
        code: "106",
      },
      {
        name: "Nakuru",
        code: "107",
      },
      {
        name: "Nyali",
        code: "108",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "109",
      },
      {
        name: "Harambee",
        code: "110",
      },
      {
        name: "Prestige - Ngong Road",
        code: "111",
      },
      {
        name: "Kisumu",
        code: "112",
      },
      {
        name: "Thika",
        code: "113",
      },
      {
        name: "Meru",
        code: "114",
      },
      {
        name: "Galleria Bomas",
        code: "115",
      },
      {
        name: "Eldoret",
        code: "116",
      },
      {
        name: "Village Market",
        code: "117",
      },
      {
        name: "Sameer Park",
        code: "118",
      },
      {
        name: "Karen",
        code: "119",
      },
      {
        name: "Taj Mall",
        code: "121",
      },
      {
        name: "ABC",
        code: "122",
      },
      {
        name: "Thika Road Mall",
        code: "123",
      },
      {
        name: "Changamwe",
        code: "124",
      },
      {
        name: "Kenyatta Avenue",
        code: "125",
      },
    ],
    bank_code: "41",
  },
  {
    name: "GIRO BANK LTD",
    branches: [
      {
        name: "Banda",
        code: "0",
      },
      {
        name: "Mombasa",
        code: "1",
      },
      {
        name: "Industrial Area",
        code: "2",
      },
      {
        name: "Kimathi Street",
        code: "3",
      },
      {
        name: "Kisumu",
        code: "4",
      },
      {
        name: "Westlands",
        code: "5",
      },
      {
        name: "Parklands 3rd Avenue",
        code: "7",
      },
    ],
    bank_code: "42",
  },
  {
    name: "ECOBANK KENYA LTD",
    branches: [
      {
        name: "Fedha Branch",
        code: "0",
      },
      {
        name: "Moi Avenue Nairobi",
        code: "1",
      },
      {
        name: "Akiba Hse Mombasa",
        code: "2",
      },
      {
        name: "Plaza 2000",
        code: "3",
      },
      {
        name: "Westminister",
        code: "4",
      },
      {
        name: "Chambers",
        code: "5",
      },
      {
        name: "Thika",
        code: "6",
      },
      {
        name: "Eldoret",
        code: "7",
      },
      {
        name: "Kisumu",
        code: "8",
      },
      {
        name: "Kisii",
        code: "9",
      },
      {
        name: "Kitale",
        code: "10",
      },
      {
        name: "Industrial Area",
        code: "11",
      },
      {
        name: "Karatina",
        code: "12",
      },
      {
        name: "Westlands",
        code: "13",
      },
      {
        name: "United Mall",
        code: "14",
      },
      {
        name: "Nakuru",
        code: "15",
      },
      {
        name: "Jomo Kenyatta Avenue",
        code: "16",
      },
      {
        name: "Nyeri",
        code: "17",
      },
      {
        name: "Busia",
        code: "18",
      },
      {
        name: "Malindi",
        code: "19",
      },
      {
        name: "Meru",
        code: "20",
      },
      {
        name: "Gikomba",
        code: "21",
      },
      {
        name: "UpperHill",
        code: "22",
      },
      {
        name: "Valley Arcade",
        code: "23",
      },
      {
        name: "Karen",
        code: "24",
      },
      {
        name: "Nyali",
        code: "25",
      },
      {
        name: "Ongata Rongai",
        code: "26",
      },
      {
        name: "Embakasi",
        code: "27",
      },
      {
        name: "Kitengela",
        code: "28",
      },
      {
        name: "Thika Mall",
        code: "29",
      },
      {
        name: "Head Office",
        code: "100",
      },
    ],
    bank_code: "43",
  },
  {
    name: "EQUITORIAL COMMERCIAL BANK LTD",
    branches: [
      {
        name: "Nairobi",
        code: "0",
      },
      {
        name: "Nyerere",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Westlands",
        code: "3",
      },
      {
        name: "Mombasa Road",
        code: "4",
      },
      {
        name: "Chester",
        code: "5",
      },
      {
        name: "Waiyaki Way",
        code: "7",
      },
      {
        name: "Kakamega",
        code: "8",
      },
      {
        name: "Eldoret",
        code: "9",
      },
      {
        name: "Senator Cards",
        code: "10",
      },
      {
        name: "Nyali",
        code: "11",
      },
      {
        name: "Kisumu",
        code: "12",
      },
      {
        name: "Industrial Area",
        code: "13",
      },
      {
        name: "Nakuru",
        code: "15",
      },
    ],
    bank_code: "49",
  },
  {
    name: "PARAMOUNT UNIVERSAL BANK LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Westlands",
        code: "1",
      },
      {
        name: "Parklands",
        code: "2",
      },
      {
        name: "Koinange Street",
        code: "3",
      },
      {
        name: "Mombasa",
        code: "4",
      },
      {
        name: "Eldoret",
        code: "6",
      },
    ],
    bank_code: "50",
  },
  {
    name: "JAMII BORA BANK LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Industrial Area",
        code: "1",
      },
      {
        name: "Kiongozi",
        code: "100",
      },
      {
        name: "Kayole",
        code: "101",
      },
      {
        name: "Mathare",
        code: "102",
      },
      {
        name: "Kawangware",
        code: "105",
      },
      {
        name: "Kibera",
        code: "106",
      },
      {
        name: "Kariobangi",
        code: "107",
      },
      {
        name: "Funzi Road",
        code: "114",
      },
      {
        name: "Ngong Road",
        code: "115",
      },
      {
        name: "Kirinyaga RD",
        code: "116",
      },
      {
        name: "Machakos",
        code: "209",
      },
      {
        name: "Mtwapa",
        code: "210",
      },
      {
        name: "Kiritiri",
        code: "213",
      },
      {
        name: "Thika",
        code: "301",
      },
      {
        name: "Muranga",
        code: "303",
      },
      {
        name: "Kikuyu",
        code: "306",
      },
      {
        name: "Banana",
        code: "307",
      },
      {
        name: "Kisumu",
        code: "402",
      },
      {
        name: "Ongata Rongai",
        code: "502",
      },
      {
        name: "Nakuru",
        code: "507",
      },
      {
        name: "Mombasa",
        code: "603",
      },
    ],
    bank_code: "51",
  },
  {
    name: "GUARANTY TRUST (KENYA) LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Kimathi Street",
        code: "1",
      },
      {
        name: "Industrial Area",
        code: "2",
      },
      {
        name: "Westlands",
        code: "3",
      },
      {
        name: "Lavington",
        code: "4",
      },
      {
        name: "Nkrumah Road Mombasa",
        code: "5",
      },
      {
        name: "Nakuru",
        code: "6",
      },
      {
        name: "Eldoret",
        code: "7",
      },
      {
        name: "Muthaiga",
        code: "8",
      },
      {
        name: "Nanyuki",
        code: "9",
      },
      {
        name: "Thika",
        code: "10",
      },
      {
        name: "Gikomba",
        code: "11",
      },
      {
        name: "Ngong Road",
        code: "12",
      },
      {
        name: "Meru",
        code: "13",
      },
      {
        name: "Nyali",
        code: "14",
      },
    ],
    bank_code: "53",
  },
  {
    name: "VICTORIA COMMERCIAL BANK LTD",
    branches: [
      {
        name: "Victoria Towers",
        code: "1",
      },
      {
        name: "Riverside Drive",
        code: "2",
      },
    ],
    bank_code: "54",
  },
  {
    name: "GUARDIAN BANK",
    branches: [
      {
        name: "Head Office",
        code: "1",
      },
      {
        name: "Westlands",
        code: "2",
      },
      {
        name: "Mombasa",
        code: "3",
      },
      {
        name: "Eldoret",
        code: "4",
      },
      {
        name: "Kisumu",
        code: "5",
      },
      {
        name: "Moi Ave",
        code: "6",
      },
      {
        name: "Mombasa Road",
        code: "7",
      },
      {
        name: "Nyali",
        code: "8",
      },
      {
        name: "Ngong Road",
        code: "9",
      },
    ],
    bank_code: "55",
  },
  {
    name: "INVESTMENTS AND MORTGAGES",
    branches: [
      {
        name: "Kenyatta Avenue",
        code: "0",
      },
      {
        name: "2nd Nong Avenue",
        code: "1",
      },
      {
        name: "Sarit Centre",
        code: "2",
      },
      {
        name: "Head Office",
        code: "3",
      },
      {
        name: "Biashara St",
        code: "4",
      },
      {
        name: "Mombasa",
        code: "5",
      },
      {
        name: "Industrial Area",
        code: "6",
      },
      {
        name: "Kisumu",
        code: "7",
      },
      {
        name: "Karen",
        code: "8",
      },
      {
        name: "Panari Centre",
        code: "9",
      },
      {
        name: "Parklands",
        code: "10",
      },
      {
        name: "Wilson Airport",
        code: "11",
      },
      {
        name: "Ongata Rongai",
        code: "12",
      },
      {
        name: "South C Shopping Centre",
        code: "13",
      },
      {
        name: "Nyali Cinemax",
        code: "14",
      },
      {
        name: "Langata Link",
        code: "15",
      },
      {
        name: "Lavington",
        code: "16",
      },
      {
        name: "Eldoret",
        code: "17",
      },
      {
        name: "Nakuru",
        code: "18",
      },
      {
        name: "Riverside",
        code: "19",
      },
      {
        name: "Kisii",
        code: "20",
      },
      {
        name: "Changamwe",
        code: "21",
      },
      {
        name: "Malindi",
        code: "22",
      },
      {
        name: "Nyeri",
        code: "23",
      },
      {
        name: "Card Center",
        code: "98",
      },
    ],
    bank_code: "57",
  },
  {
    name: "DEVELOPMENT BANK OF KENYA",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Loita Street",
        code: "1",
      },
    ],
    bank_code: "59",
  },
  {
    name: "FIDELITY COMMERCIAL BANK",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "City Centre",
        code: "1",
      },
      {
        name: "Westlands",
        code: "2",
      },
      {
        name: "Industrial Area",
        code: "3",
      },
      {
        name: "Diani",
        code: "4",
      },
      {
        name: "Malindi",
        code: "5",
      },
      {
        name: "Mombasa",
        code: "6",
      },
      {
        name: "Changamwe",
        code: "7",
      },
      {
        name: "Kilimani",
        code: "8",
      },
      {
        name: "New Muthaiga",
        code: "9",
      },
      {
        name: "Nyali",
        code: "10",
      },
    ],
    bank_code: "60",
  },
  {
    name: "HOUSING FINANCE CORPORATION OF KENYA",
    branches: [
      {
        name: "Head Office",
        code: "100",
      },
      {
        name: "Rehani House",
        code: "200",
      },
      {
        name: "Kenyatta Market",
        code: "210",
      },
      {
        name: "Gill House",
        code: "220",
      },
      {
        name: "Buru Buru",
        code: "230",
      },
      {
        name: "Mombasa",
        code: "300",
      },
      {
        name: "Kibera",
        code: "307",
      },
      {
        name: "Nyali",
        code: "310",
      },
      {
        name: "Nakuru",
        code: "400",
      },
      {
        name: "Eldoret",
        code: "410",
      },
      {
        name: "Thika",
        code: "500",
      },
      {
        name: "Nyeri",
        code: "510",
      },
      {
        name: "Meru",
        code: "520",
      },
      {
        name: "Kisumu",
        code: "600",
      },
    ],
    bank_code: "61",
  },
  {
    name: "DIAMOND TRUST BANK",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Nation Centre",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Kisumu",
        code: "3",
      },
      {
        name: "Parklands",
        code: "5",
      },
      {
        name: "Westgate",
        code: "6",
      },
      {
        name: "Mombasa Rd",
        code: "8",
      },
      {
        name: "Industrial Area",
        code: "9",
      },
      {
        name: "Kisii",
        code: "10",
      },
      {
        name: "Malindi",
        code: "11",
      },
      {
        name: "Thika",
        code: "12",
      },
      {
        name: "OTC",
        code: "13",
      },
      {
        name: "Eldoret",
        code: "14",
      },
      {
        name: "Eastleigh",
        code: "15",
      },
      {
        name: "Changamwe",
        code: "16",
      },
      {
        name: "T-Mall",
        code: "17",
      },
      {
        name: "Nakuru",
        code: "18",
      },
      {
        name: "Village Market",
        code: "19",
      },
      {
        name: "Diani",
        code: "20",
      },
      {
        name: "Bungoma",
        code: "21",
      },
      {
        name: "Kitale",
        code: "22",
      },
      {
        name: "Prestige",
        code: "23",
      },
      {
        name: "Buru Buru",
        code: "24",
      },
      {
        name: "Kitengela",
        code: "25",
      },
      {
        name: "Jomo Kenyatta",
        code: "26",
      },
      {
        name: "Kakamega",
        code: "27",
      },
      {
        name: "Kericho",
        code: "28",
      },
      {
        name: "Upper Hill",
        code: "29",
      },
      {
        name: "Wabera Street",
        code: "30",
      },
      {
        name: "Karen",
        code: "31",
      },
      {
        name: "Voi",
        code: "32",
      },
      {
        name: "Shimanzi",
        code: "33",
      },
      {
        name: "Meru",
        code: "34",
      },
      {
        name: "Diamond Plaza",
        code: "35",
      },
      {
        name: "crossroad",
        code: "36",
      },
      {
        name: "Jkia",
        code: "37",
      },
      {
        name: "Nyali",
        code: "38",
      },
      {
        name: "Migori",
        code: "39",
      },
      {
        name: "Madina Mall",
        code: "40",
      },
      {
        name: "Courtyard",
        code: "41",
      },
      {
        name: "Mtwapa",
        code: "42",
      },
      {
        name: "Lamu",
        code: "43",
      },
      {
        name: "Kilifi",
        code: "44",
      },
      {
        name: "Mariakani",
        code: "45",
      },
      {
        name: "Thika Road Mall",
        code: "46",
      },
      {
        name: "Ronald Ngala",
        code: "47",
      },
      {
        name: "Busia",
        code: "48",
      },
      {
        name: "Tom Mboya",
        code: "50",
      },
      {
        name: "DTB Centre",
        code: "52",
      },
    ],
    bank_code: "63",
  },
  {
    name: "CHARTERHOUSE BANK LTD",
    branches: [
      {
        name: "Longonot Place - Kijabe Street",
        code: "0",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Ngong",
        code: "3",
      },
      {
        name: "Uhuru H/way",
        code: "4",
      },
      {
        name: "Westlands",
        code: "5",
      },
      {
        name: "Village Market",
        code: "6",
      },
      {
        name: "Nyali",
        code: "7",
      },
      {
        name: "Likoni",
        code: "8",
      },
      {
        name: "Mega City",
        code: "9",
      },
    ],
    bank_code: "64",
  },
  {
    name: "K-REP BANK",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Main Office",
        code: "1",
      },
      {
        name: "Mombasa",
        code: "2",
      },
      {
        name: "Kenyatta Ave Nbi",
        code: "3",
      },
      {
        name: "Nakuru",
        code: "4",
      },
      {
        name: "Nyeri",
        code: "5",
      },
      {
        name: "Buruburu",
        code: "6",
      },
      {
        name: "Embu",
        code: "7",
      },
      {
        name: "Eldoret",
        code: "8",
      },
      {
        name: "Kisumu",
        code: "9",
      },
      {
        name: "Kericho",
        code: "10",
      },
      {
        name: "Mlolongo",
        code: "11",
      },
      {
        name: "Thika",
        code: "12",
      },
      {
        name: "Kerugoya",
        code: "13",
      },
      {
        name: "Kenyatta Market",
        code: "14",
      },
      {
        name: "Kisii",
        code: "15",
      },
      {
        name: "Chuka",
        code: "16",
      },
      {
        name: "Kitui",
        code: "17",
      },
      {
        name: "Machakos",
        code: "18",
      },
      {
        name: "Nanyuki",
        code: "19",
      },
      {
        name: "Kangemi",
        code: "20",
      },
      {
        name: "Emali",
        code: "21",
      },
      {
        name: "Naivasha",
        code: "22",
      },
      {
        name: "Nyahururu",
        code: "23",
      },
      {
        name: "Isiolo",
        code: "24",
      },
      {
        name: "Meru",
        code: "25",
      },
      {
        name: "Kitale",
        code: "26",
      },
      {
        name: "Kibwezi",
        code: "27",
      },
      {
        name: "Bungoma",
        code: "28",
      },
      {
        name: "Kajiado",
        code: "29",
      },
      {
        name: "Nkubu",
        code: "30",
      },
      {
        name: "Mtwapa",
        code: "31",
      },
      {
        name: "Busia",
        code: "32",
      },
      {
        name: "Moi Nairobi",
        code: "33",
      },
      {
        name: "Mwea",
        code: "34",
      },
      {
        name: "Kengeleni",
        code: "35",
      },
      {
        name: "Kilimani",
        code: "36",
      },
      {
        name: "Rongai",
        code: "37",
      },
    ],
    bank_code: "66",
  },
  {
    name: "EQUITY BANK",
    branches: [
      {
        name: "Equity Bank Head Office",
        code: "0",
      },
      {
        name: "Equity Bank Corporate",
        code: "1",
      },
      {
        name: "Equity Bank Fourways",
        code: "2",
      },
      {
        name: "Kangema",
        code: "3",
      },
      {
        name: "Karatina",
        code: "4",
      },
      {
        name: "Kiriaini",
        code: "5",
      },
      {
        name: "Murarandia",
        code: "6",
      },
      {
        name: "Kangari",
        code: "7",
      },
      {
        name: "Othaya",
        code: "8",
      },
      {
        name: "Thika / Equity Plaza",
        code: "9",
      },
      {
        name: "Kerugoya",
        code: "10",
      },
      {
        name: "Nyeri",
        code: "11",
      },
      {
        name: "Tom Mboya",
        code: "12",
      },
      {
        name: "Nakuru",
        code: "13",
      },
      {
        name: "Meru",
        code: "14",
      },
      {
        name: "Mama Ngina",
        code: "15",
      },
      {
        name: "Nyahururu",
        code: "16",
      },
      {
        name: "Community",
        code: "17",
      },
      {
        name: "Community Corporate",
        code: "18",
      },
      {
        name: "Embu",
        code: "19",
      },
      {
        name: "Naivasha",
        code: "20",
      },
      {
        name: "Chuka",
        code: "21",
      },
      {
        name: "Muranga",
        code: "22",
      },
      {
        name: "Molo",
        code: "23",
      },
      {
        name: "Harambee Avenu",
        code: "24",
      },
      {
        name: "Mombasa",
        code: "25",
      },
      {
        name: "Kimathi Street",
        code: "26",
      },
      {
        name: "Nanyuki",
        code: "27",
      },
      {
        name: "Kericho",
        code: "28",
      },
      {
        name: "Kisumu",
        code: "29",
      },
      {
        name: "Eldoret",
        code: "30",
      },
      {
        name: "Nakuru Kenyatta Avenue",
        code: "31",
      },
      {
        name: "Kariobangi",
        code: "32",
      },
      {
        name: "Kitale",
        code: "33",
      },
      {
        name: "Thika Kenyatta Avenue",
        code: "34",
      },
      {
        name: "Knut House",
        code: "35",
      },
      {
        name: "Narok",
        code: "36",
      },
      {
        name: "Nkubu",
        code: "37",
      },
      {
        name: "Mwea",
        code: "38",
      },
      {
        name: "Matuu",
        code: "39",
      },
      {
        name: "Maua",
        code: "40",
      },
      {
        name: "Isiolo",
        code: "41",
      },
      {
        name: "Kagio",
        code: "42",
      },
      {
        name: "Gikomba",
        code: "43",
      },
      {
        name: "Ukunda",
        code: "44",
      },
      {
        name: "Malindi",
        code: "45",
      },
      {
        name: "Mombasa Digo Road",
        code: "46",
      },
      {
        name: "Moi Avenue",
        code: "47",
      },
      {
        name: "Bungoma",
        code: "48",
      },
      {
        name: "Kapsabet",
        code: "49",
      },
      {
        name: "Kakamega",
        code: "50",
      },
      {
        name: "Kisii",
        code: "51",
      },
      {
        name: "Nyamira",
        code: "52",
      },
      {
        name: "Litein",
        code: "53",
      },
      {
        name: "Equity Centre Diaspora",
        code: "54",
      },
      {
        name: "Westlands",
        code: "55",
      },
      {
        name: "Industrial Area Kenpipe Plaza",
        code: "56",
      },
      {
        name: "Kikuyu",
        code: "57",
      },
      {
        name: "Garissa",
        code: "58",
      },
      {
        name: "Mwingi",
        code: "59",
      },
      {
        name: "Machakos",
        code: "60",
      },
      {
        name: "Ongata Rongai",
        code: "61",
      },
      {
        name: "Ol Kalou",
        code: "62",
      },
      {
        name: "Kawangware",
        code: "63",
      },
      {
        name: "Kiambu",
        code: "64",
      },
      {
        name: "Kayole",
        code: "65",
      },
      {
        name: "Gatundu",
        code: "66",
      },
      {
        name: "Wote",
        code: "67",
      },
      {
        name: "Mumias",
        code: "68",
      },
      {
        name: "Limuru",
        code: "69",
      },
      {
        name: "Kitengela",
        code: "70",
      },
      {
        name: "Githurai",
        code: "71",
      },
      {
        name: "Kitui",
        code: "72",
      },
      {
        name: "Ngong",
        code: "73",
      },
      {
        name: "Loitoktok",
        code: "74",
      },
      {
        name: "Bondo",
        code: "75",
      },
      {
        name: "Mbita",
        code: "76",
      },
      {
        name: "Gilgil",
        code: "77",
      },
      {
        name: "Busia",
        code: "78",
      },
      {
        name: "Voi",
        code: "79",
      },
      {
        name: "Enterprise Road",
        code: "80",
      },
      {
        name: "Equity Centre",
        code: "81",
      },
      {
        name: "Donholm",
        code: "82",
      },
      {
        name: "Mukurweini",
        code: "83",
      },
      {
        name: "Eastleigh",
        code: "84",
      },
      {
        name: "Namanga",
        code: "85",
      },
      {
        name: "Kajiado",
        code: "86",
      },
      {
        name: "Ruiru",
        code: "87",
      },
      {
        name: "OTC",
        code: "88",
      },
      {
        name: "Kenol",
        code: "89",
      },
      {
        name: "Tala",
        code: "90",
      },
      {
        name: "Ngara",
        code: "91",
      },
      {
        name: "Nandi Hills",
        code: "92",
      },
      {
        name: "Githunguri",
        code: "93",
      },
      {
        name: "Tea Room",
        code: "94",
      },
      {
        name: "Buru Buru",
        code: "95",
      },
      {
        name: "Mbale",
        code: "96",
      },
      {
        name: "Siaya",
        code: "97",
      },
      {
        name: "Homa Bay",
        code: "98",
      },
      {
        name: "Lodwar",
        code: "99",
      },
      {
        name: "Mandera",
        code: "100",
      },
      {
        name: "Marsabit",
        code: "101",
      },
      {
        name: "Moyale",
        code: "102",
      },
      {
        name: "Wajir",
        code: "103",
      },
      {
        name: "Meru Makutano",
        code: "104",
      },
      {
        name: "Malaba",
        code: "105",
      },
      {
        name: "Kilifi",
        code: "106",
      },
      {
        name: "Kapenguria",
        code: "107",
      },
      {
        name: "Mombasa Road",
        code: "108",
      },
      {
        name: "Eldoret Market",
        code: "109",
      },
      {
        name: "Maralal",
        code: "110",
      },
      {
        name: "Kimende",
        code: "111",
      },
      {
        name: "Luanda",
        code: "112",
      },
      {
        name: "KU Sub Branch",
        code: "113",
      },
      {
        name: "Kengeleni",
        code: "114",
      },
      {
        name: "Nyeri Kimathi Way",
        code: "115",
      },
      {
        name: "Migori",
        code: "116",
      },
      {
        name: "Kibera",
        code: "117",
      },
      {
        name: "Kasarani",
        code: "118",
      },
      {
        name: "Mtwapa",
        code: "119",
      },
      {
        name: "Changamwe",
        code: "120",
      },
      {
        name: "Hola",
        code: "121",
      },
      {
        name: "Bomet",
        code: "122",
      },
      {
        name: "Kilgoris",
        code: "123",
      },
      {
        name: "Keroka",
        code: "124",
      },
      {
        name: "Karen",
        code: "125",
      },
      {
        name: "Kisumu Angawa Ave",
        code: "126",
      },
      {
        name: "Mpeketoni",
        code: "127",
      },
      {
        name: "Nairobi West",
        code: "128",
      },
      {
        name: "Kenyatta Avenue",
        code: "129",
      },
      {
        name: "City Hall",
        code: "130",
      },
      {
        name: "Eldama Ravine",
        code: "131",
      },
      {
        name: "Embakasi",
        code: "132",
      },
      {
        name: "KPCU",
        code: "133",
      },
      {
        name: "Ridgeways",
        code: "134",
      },
      {
        name: "Runyenjes",
        code: "135",
      },
      {
        name: "Dadaab",
        code: "136",
      },
      {
        name: "Kangemi",
        code: "137",
      },
      {
        name: "Nyali Centre Corporate",
        code: "138",
      },
      {
        name: "Kabarnet",
        code: "139",
      },
      {
        name: "Westlands Corporae",
        code: "140",
      },
      {
        name: "Lavingon Corporate",
        code: "141",
      },
      {
        name: "Taita Taveta",
        code: "142",
      },
      {
        name: "Awendo",
        code: "143",
      },
      {
        name: "Ruai",
        code: "144",
      },
      {
        name: "Kilimani",
        code: "145",
      },
      {
        name: "Nakuru Corporate",
        code: "146",
      },
      {
        name: "Kilimani Supreme",
        code: "147",
      },
      {
        name: "JKIA  Cargo Centre",
        code: "148",
      },
      {
        name: "EPZ Athi River",
        code: "149",
      },
      {
        name: "Oyugis",
        code: "150",
      },
      {
        name: "Mayfair Supreme Centre",
        code: "151",
      },
      {
        name: "Juja",
        code: "152",
      },
      {
        name: "Iten",
        code: "153",
      },
      {
        name: "Nyali Supreme Centre",
        code: "154",
      },
      {
        name: "Thika Supreme Centre",
        code: "155",
      },
      {
        name: "Mombasa Supreme Centre",
        code: "156",
      },
      {
        name: "Kapsowar",
        code: "157",
      },
      {
        name: "Kwale",
        code: "158",
      },
      {
        name: "Lamu",
        code: "159",
      },
      {
        name: "Kenyatta Avenue Supreme",
        code: "160",
      },
      {
        name: "KPA SUB-BRANCH",
        code: "161",
      },
    ],
    bank_code: "68",
  },
  {
    name: "FAMILY BANK LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Kiambu",
        code: "1",
      },
      {
        name: "Githunguri",
        code: "2",
      },
      {
        name: "Sonalux",
        code: "3",
      },
      {
        name: "Gatundu",
        code: "4",
      },
      {
        name: "Thika",
        code: "5",
      },
      {
        name: "Muranga",
        code: "6",
      },
      {
        name: "Kangari",
        code: "7",
      },
      {
        name: "Kiriaini",
        code: "8",
      },
      {
        name: "Kangema",
        code: "9",
      },
      {
        name: "Othaya",
        code: "11",
      },
      {
        name: "Kenyatta Avenue",
        code: "12",
      },
      {
        name: "Cargen House",
        code: "14",
      },
      {
        name: "Laptrust",
        code: "15",
      },
      {
        name: "Kasarani",
        code: "17",
      },
      {
        name: "Nakuru Finance House",
        code: "18",
      },
      {
        name: "Nakuru Njoro House",
        code: "19",
      },
      {
        name: "Dagoretti",
        code: "21",
      },
      {
        name: "Kericho",
        code: "22",
      },
      {
        name: "Nyahururu",
        code: "23",
      },
      {
        name: "Ruiru",
        code: "24",
      },
      {
        name: "Kisumu Reliance",
        code: "25",
      },
      {
        name: "Nyamira",
        code: "26",
      },
      {
        name: "Kisii",
        code: "27",
      },
      {
        name: "Kisumu Al Imran",
        code: "28",
      },
      {
        name: "Narok",
        code: "29",
      },
      {
        name: "Industrial Area",
        code: "31",
      },
      {
        name: "Donholm",
        code: "33",
      },
      {
        name: "Fourways Towers",
        code: "35",
      },
      {
        name: "Olkalou",
        code: "37",
      },
      {
        name: "KTDA Plaza",
        code: "38",
      },
      {
        name: "Kariobangi",
        code: "41",
      },
      {
        name: "Gikomba",
        code: "42",
      },
      {
        name: "Gikomba 2",
        code: "43",
      },
      {
        name: "Githurai",
        code: "45",
      },
      {
        name: "Yaya",
        code: "46",
      },
      {
        name: "Limuru",
        code: "47",
      },
      {
        name: "Westlands",
        code: "48",
      },
      {
        name: "Kagwe",
        code: "49",
      },
      {
        name: "Banana",
        code: "51",
      },
      {
        name: "Naivasha",
        code: "53",
      },
      {
        name: "Nyeri",
        code: "55",
      },
      {
        name: "Karatina",
        code: "56",
      },
      {
        name: "Kerugoya",
        code: "57",
      },
      {
        name: "Tom Mboya",
        code: "58",
      },
      {
        name: "River Road",
        code: "59",
      },
      {
        name: "Kayole",
        code: "61",
      },
      {
        name: "Nkubu",
        code: "62",
      },
      {
        name: "Meru",
        code: "63",
      },
      {
        name: "Nanyuki",
        code: "64",
      },
      {
        name: "KTDA Plaza Corporate",
        code: "65",
      },
      {
        name: "Ongata Rongai",
        code: "66",
      },
      {
        name: "Kajiado",
        code: "67",
      },
      {
        name: "Fourways Towers Corporate",
        code: "68",
      },
      {
        name: "Ngara",
        code: "69",
      },
      {
        name: "Kitengela",
        code: "71",
      },
      {
        name: "Kitui",
        code: "72",
      },
      {
        name: "Mackakos",
        code: "73",
      },
      {
        name: "Embu",
        code: "75",
      },
      {
        name: "Bungoma",
        code: "77",
      },
      {
        name: "Kakamega",
        code: "78",
      },
      {
        name: "Busia",
        code: "79",
      },
      {
        name: "Mumias",
        code: "81",
      },
      {
        name: "Eldoret West",
        code: "82",
      },
      {
        name: "Molo",
        code: "83",
      },
      {
        name: "Eldoret",
        code: "85",
      },
      {
        name: "Digo",
        code: "92",
      },
      {
        name: "Kitale",
        code: "93",
      },
      {
        name: "Mtwapa",
        code: "94",
      },
      {
        name: "Mombasa Nkrumah Road",
        code: "95",
      },
      {
        name: "Mombasa Jomo Kenyatta Avenue",
        code: "96",
      },
      {
        name: "Kapsabet",
        code: "97",
      },
      {
        name: "Kikuyu",
        code: "102",
      },
    ],
    bank_code: "70",
  },
  {
    name: "GULF AFRICAN BANK LTD",
    branches: [
      {
        name: "Head Office",
        code: "0",
      },
      {
        name: "Central Clearing Centre",
        code: "1",
      },
      {
        name: "Upperhill",
        code: "2",
      },
      {
        name: "Eastleigh",
        code: "3",
      },
      {
        name: "Kenyatta Avenue",
        code: "4",
      },
      {
        name: "Mombasa",
        code: "5",
      },
      {
        name: "Garissa",
        code: "6",
      },
      {
        name: "Lamu",
        code: "7",
      },
      {
        name: "Malindi",
        code: "8",
      },
      {
        name: "Muthaiga",
        code: "9",
      },
      {
        name: "Bondeni",
        code: "10",
      },
      {
        name: "Eastleigh 7th Street",
        code: "11",
      },
      {
        name: "Eastleigh Athumani kipanga Street",
        code: "12",
      },
      {
        name: "Parklands",
        code: "13",
      },
      {
        name: "Industrial Area",
        code: "14",
      },
      {
        name: "Jomo Kenyatta",
        code: "15",
      },
      {
        name: "Bombululu",
        code: "16",
      },
    ],
    bank_code: "72",
  },
  {
    name: "FIRST COMMUNITY BANK",
    branches: [
      {
        name: "Wabera Street",
        code: "1",
      },
      {
        name: "Eastleigh 1",
        code: "2",
      },
      {
        name: "Mombasa 1",
        code: "3",
      },
      {
        name: "Garissa",
        code: "4",
      },
      {
        name: "Eastleigh 2 - General Waruing",
        code: "5",
      },
      {
        name: "Malindi",
        code: "6",
      },
      {
        name: "Kisumu",
        code: "7",
      },
      {
        name: "Kimathi Street",
        code: "8",
      },
      {
        name: "Westlands",
        code: "9",
      },
      {
        name: "South C",
        code: "10",
      },
      {
        name: "Industrial Area",
        code: "11",
      },
      {
        name: "Masalani",
        code: "12",
      },
      {
        name: "Habasweni",
        code: "13",
      },
      {
        name: "Wajir",
        code: "14",
      },
      {
        name: "Moyale",
        code: "15",
      },
      {
        name: "Nakuru",
        code: "16",
      },
      {
        name: "Mombasa 2",
        code: "17",
      },
      {
        name: "Head Office / Clearing Center",
        code: "999",
      },
    ],
    bank_code: "74",
  },
  {
    name: "UBA KENYA LTD",
    branches: [
      {
        name: "Westlands",
        code: "1",
      },
      {
        name: "Enterprise Road",
        code: "2",
      },
      {
        name: "Upper Hill",
        code: "3",
      },
      {
        name: "Industrial Area",
        code: "14",
      },
      {
        name: "Head Office",
        code: "99",
      },
    ],
    bank_code: "76",
  },
];
