export const addCourier = async (data) => {
    let res = await fetch(process.env.REACT_APP_BASE_URL + "/new_courier", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
      body: JSON.stringify(data),
    });
    return await res.json();
  };

  export const getCouriers = async () => {
    let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_courier", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        key: process.env.REACT_APP_API_KEY,
        secret: process.env.REACT_APP_API_SECRET,
        country: JSON.parse(sessionStorage.getItem("user"))?.country,
      },
    });
    return await res.json();
  };