import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Loader } from "components";

const EditCouponModal = (props) => {
  const { toastDispatch } = useToastContext();
  const {
    error: errorCoupons,
    loading: getCouponDetailsLoading,
    sendHttpRequest: getCouponDetails,
  } = useHttp();
  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();
  const [couponDetails, setCouponDetails] = useState({
    name: "",
    start_date: "",
    end_date: "",
    description: "",
    value: "",
    models: "",
  });
  const [models, setModels] = useState([]);
  const [selectedModels, setAssignedModels] = useState([]);
  const {
    error: errorCoupon,
    loading: isUpdatingCoupon,
    sendHttpRequest: editCoupon,
  } = useHttp();

  const getAllModels = () => {
    getModels("get_all_device_models", "GET", null, ({ data: { devices } }) => {
      const temp = devices.map((el) => {
        let tempObj = {
          value: el._id,
          label: el.modelName,
        };
        return tempObj;
      });
      setModels(temp);
    });
  };

  const getDetails = (id) => {
    getCouponDetails(
      `get_orders_per_coupon/${id}`,
      "GET",
      null,
      async ({ data }) => {
        setCouponDetails({
          name: data?.coupon?.name,
          start_date: data?.coupon?.start_date,
          end_date: data?.coupon?.end_date,
          description: data?.coupon?.description,
          value:
            data?.coupon?.currency +
            " " +
            data?.coupon?.value?.toLocaleString(),
          models: data?.coupon?.assignedModels,
        });
      }
    );
  };

  useEffect(() => {
    let getFilterData = models.filter((ele) => {
      return couponDetails?.models?.some((elem) => {
        return ele.value == elem;
      });
    });
    setAssignedModels(getFilterData);
  }, [couponDetails?.models]);

  function handleSelect(e) {
    setAssignedModels(
      Array.isArray(e) ? e.map((x) => ({ value: x.value, label: x.label })) : []
    );
  }

  useEffect(() => {
    getAllModels();
    getDetails(props?.id);
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setCouponDetails({ ...couponDetails, [name]: value });
  };
  const editCouponSubmit = async (val) => {
    let data = {
      id: props?.id,
      end_date: couponDetails.end_date,
      description: couponDetails.description,
      assignedModels: selectedModels?.map((curVal) => curVal?.value),
    };

    editCoupon("update_coupon", "PUT", data, ({ msg }) => {
      props.refresh();
      props.close();
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };
  return (
    <Modal close={props.close} title={`Edit Coupon`} size="md">
      {loadingModels && getCouponDetailsLoading && (
        <div
          style={{
            minHeight: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {!loadingModels && !getCouponDetailsLoading && (
        <Formik
          enableReinitialize
          initialValues={couponDetails}
          validationSchema={Yup.object().shape({
            end_date: Yup.string().required("Expiry Date is required"),
            description: Yup.string().required("Short Description is required"),
          })}
          onSubmit={() => editCouponSubmit()}
        >
          {({ errors, status, touched }) => (
            <Form id="addCupon">
              <div className="row">
                {/* <div className="col-md-3">
                <div className="form-group">
                  <label>Coupon Name</label>
                  <div class="form-group">
                    <input
                      className={
                        "form-control form-control-sm" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                      onChange={onChange}
                      name="name"
                      value={couponDetails.name}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Coupon Value</label>
                  <input
                    className={
                      "form-control form-control-sm" +
                      (errors.value && touched.value ? " is-invalid" : "")
                    }
                    onChange={onChange}
                    name="value"
                    type="number"
                    value={couponDetails.value}
                  />
                  <ErrorMessage
                    name="value"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    className={
                      "form-control form-control-sm" +
                      (errors.start_date && touched.start_date
                        ? " is-invalid"
                        : "")
                    }
                    onChange={onChange}
                    name="start_date"
                    type="date"
                    min={today}
                    value={couponDetails.start_date}
                  />
                  <ErrorMessage
                    name="start_date"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div> */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Name : <b>{couponDetails?.name}</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Value : <b>{couponDetails?.value}</b>
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>End Date</label>
                    <input
                      className={
                        "form-control form-control-sm" +
                        (errors.end_date && touched.end_date
                          ? " is-invalid"
                          : "")
                      }
                      onChange={onChange}
                      name="end_date"
                      type="date"
                      value={couponDetails?.end_date?.split("T")[0]}
                    />
                    <ErrorMessage
                      name="end_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label >Applies to phones</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        onChange={onChange}
                        name="notify"
                        value={""}
                      />
                      All
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        onChange={onChange}
                        name="notify"
                        value={""}
                      />
                      Specific
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Assigned Models</label>
                    <Select
                      options={models}
                      placeholder="Select model"
                      // value={
                      // }
                      value={selectedModels}
                      // value={newModelValue}
                      // value={newModelValue?.map((val) => ({
                      //   value: val.value,
                      //   label: val.label,
                      // }))}
                      //    value={()=> couponDetails?.assignedModels?.forEach(function (item, index) {
                      // return models?.map((currentValue) =>{
                      //     return   currentValue?.value == item ? {label:currentValue.label,value:currentValue.value } : []}
                      //     );
                      //   })}
                      //  value={couponDetails}
                      onChange={(e) => handleSelect(e)}
                      isSearchable={true}
                      isMulti
                    />
                    <ErrorMessage
                      name="models"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                
                <div className="col-md-12" style={{ marginTop: "18px" }}>
                  <div className="form-group">
                    <label>Short Description</label>
                    <textarea
                      className={
                        "form-control form-control-sm" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                      onChange={onChange}
                      name="description"
                      rows={1}
                      value={couponDetails?.description}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn bg-primary ld-ext-right float-right"
                disabled={isUpdatingCoupon}
              >
                Update
                {isUpdatingCoupon && (
                  <>
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                )}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default EditCouponModal;
