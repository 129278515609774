import React from "react";
import { LoaderWrapper } from "./Loader.style.js";

export default function Loader() {
  return (
    <>
      <LoaderWrapper>
        <div className="loader"> </div>
        <p>Just a moment...</p>
      </LoaderWrapper>
    </>
  );
}
