import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { deleteStore } from "./PartnersService";
import { useToastContext, ADD } from "context/ToastContext";

const DeleteStoreModal = (props) => {
  const [savingStore, setSavingStore] = useState(false);
 const { toastDispatch } = useToastContext();

  const deleteNewStore = async () => {
    setSavingStore(true);

    try {
      const { msg, success } = await deleteStore(props.id);
      if (success) {
        props.refresh();
        props.close();
          toastDispatch({
              type: ADD,
              payload: {
                content:
                  msg,
                type: "success",
              },
            });
        setSavingStore(false);
      } else {
          toastDispatch({
              type: ADD,
              payload: {
                content:
                  msg,
                type: "danger",
              },
            });
        setSavingStore(false);
      }
    } catch (err) {
      console.log(err);
      setSavingStore(false);
    }
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => deleteNewStore()}
      title="Are you sure?"
      loading={savingStore}
      size="sm"
    >
      You are about to delete <span className="text-danger">{props.name}</span>{" "}
      Store
    </Modal>
  );
};

export default DeleteStoreModal;
