import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import EditSpecsModal from "./EditSpecifications";

import ModalService from "components/modal/ModalService";

import { Alert, Loader } from "components";
import { AppContext } from "context/AppContext";
import { useNavigate, Link } from "react-router-dom";
import { format } from "date-fns";
import { useHttp } from "hooks";

import styles from "./productDetails.module.css";

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const { showAlert } = useContext(AppContext);
  const navigate = useNavigate();
  const { error, loading, sendHttpRequest: getSingleProduct } = useHttp();

  // const openModal = (title, details) => {
  //   ModalService.open(EditSpecsModal, {
  //     refresh: () =>  getSingleProduct(
  //     `get_single_transfer/${id}`,
  //     "GET",
  //     null,
  //     ({ data: { request, payload } }) => {
  //       setProduct(request);
  //       setImages(payload);
  //     }
  //   ),
  //     title: `Edit ${title} Specifications`,
  //     details,
  //     dataKey: title,
  //     modelId: product.details.modelId,
  //   });
  // };

  const configure = (product) => {
    navigate(`/inventory/add-product`, {
      state: {
        product,
      },
    });
  };

  useEffect(() => {
    getSingleProduct(
      `get_single_transfer/${id}`,
      "GET",
      null,
      ({ data: { request, payload } }) => {
        setProduct(request);
        setImages(payload);
      }
    );
  }, []);
  return (
    <>
      {loading && !product && <Loader />}
      {!loading && !product && "No product available"}
      {!loading && product && (
        <div className="d-flex align-items-start flex-column flex-md-row">
          <div className="w-100 overflow-auto order-2 order-md-1">
            <div className="card">
              <div className="card-header header-elements-md-inline">
                <h3 className="card-title">
                  {product.details.model.modelName +
                    " " +
                    "(" +
                    product.details.variant[0]?.storage +
                    "GB/" +
                    product.details.variant[0]?.ram +
                    "GB)"}
                </h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src={product.image}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "https://media.istockphoto.com/vectors/smartphone-icon-vector-id1137284756?b=1&k=20&m=1137284756&s=170667a&w=0&h=S8OxtjTNSFmreNwBP6_LdbeHN1H2lgIgKcqh3Z5aAIg=";
                      }}
                      alt="device"
                      className={styles["device-image"]}
                    />
                  </div>
                  <div className="col-md-9">
                    {!product.details.specs && (
                      <div className="row align-items-center h-100">
                        <div className="alert alert-info alert-styled-left alert-dismissible mx-md-5 mx-0">
                          <span className="font-weight-semibold">
                            Heads up!
                          </span>{" "}
                          This device specifications have not been added. Click{" "}
                          <Link
                            to={`/inventory/product-configuration/${product.details.modelId}`}
                            className="alert-link"
                          >
                            HERE
                          </Link>{" "}
                          to add .
                        </div>
                      </div>
                    )}
                    {product.details.specs && (
                      <>
                        <div>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item border-0 p-1">
                              <span className="font-weight-semibold">
                                <i className="icon-iphone mr-2" />

                                {product.details.specs.highlights.os}
                              </span>
                            </li>
                            <li className="list-group-item border-0 p-1">
                              <span className="font-weight-semibold">
                                <i className="icon-arrow-resize7 mr-2" />
                                {product.details.specs.highlights.connectivity}
                              </span>
                            </li>
                            <li className="list-group-item border-0 p-1">
                              <span className="font-weight-semibold">
                                <i className="icon-chip mr-2" />
                                {product.details.specs.highlights.processor}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <ul className={styles["head-summary"]}>
                          <li>
                            <i
                              className={`${styles["icon-head"]} icon-checkbox-unchecked2`}
                            />

                            <h6 className="mt-3">
                              {product.details.specs.highlights.display}
                            </h6>
                          </li>
                          <li>
                            <i
                              className={`${styles["icon-head"]} icon-shutter`}
                            />

                            <h6 className="mt-3">
                              {product.details.specs.highlights.main_camera}
                            </h6>
                          </li>
                          <li>
                            <i
                              className={`${styles["icon-head"]} icon-battery-charging `}
                            />

                            <h6 className="mt-3">
                              {product.details.specs.highlights.battery}
                            </h6>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" wmin-md-400 px-md-3 border-0 shadow-0 order-1 order-md-2 ">
            <div className="card">
              <div className="card-header bg-transparent header-elements-inline">
                <span className="text-uppercase font-size-sm font-weight-semibold">
                  At a glance
                </span>
                <div className="header-elements">
                  <div className="list-icons">
                    <a className="list-icons-item" data-action="collapse" />
                  </div>
                </div>
              </div>
              <table className="table table-borderless table-xs my-2">
                <tbody>
                  <tr>
                    <td>
                      <i className="icon-briefcase mr-2" /> Model:
                    </td>
                    <td className="text-right">
                      <a href="#">{product.details.model.modelName}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="icon-circles2 mr-2" /> variant:
                    </td>
                    <td className="text-right">
                      {product.details.variant[0]?.storage +
                        "GB / " +
                        product.details.variant[0]?.ram +
                        "GB"}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <i className="icon-file-check mr-2" /> Status:
                    </td>
                    <td className="text-right">
                      <div className="btn-group">
                        <a href="#" className="badge bg-info">
                          {product.details.current_status[0]?.name}
                        </a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <i className="icon-alarm-add mr-2" /> Posted On:
                    </td>
                    <td className="text-right text-muted">
                      {format(new Date(product.details.updatedAt), "PP")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="icon-alarm-check mr-2" /> IMEI:
                    </td>
                    <td className="text-right">{product.details.imei}</td>
                  </tr>
                  <tr>
                    <td>
                      <i className="icon-alarm-check mr-2" /> Serial No:
                    </td>
                    <td className="text-right ">
                      {product.details.serialNumber}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i className="icon-alarm-check mr-2" /> Source:
                    </td>
                    <td className="text-right ">{product.details.source}</td>
                  </tr>

                  <tr>
                    <td>
                      <i
                        className={`${
                          product.details.published
                            ? "icon-file-minus"
                            : "icon-file-plus"
                        } mr-2`}
                      />{" "}
                      Published:
                    </td>
                    <td className="text-right ">
                      {product.details.model.published &&
                        product.details.model.published.toString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {product.details.source === "online" && (
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    Attached Diagnostic Reports
                  </span>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse" />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ul className="media-list">
                    <li className="media">
                      <div className="mr-3 align-self-center">
                        <i className="icon-file-pdf icon-2x text-warning-300 top-0" />
                      </div>
                      <div className="media-body">
                        <div className="font-weight-semibold">
                          Final_reportt.pdf
                        </div>
                        <ul className="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                          <li className="list-inline-item">Size: 4.3Mb</li>
                          <li className="list-inline-item">
                            By <a href="#">Natalie</a>
                          </li>
                        </ul>
                      </div>
                      <div className="ml-3">
                        <div className="list-icons">
                          <a href="#" className="list-icons-item">
                            <i className="icon-download" />
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
