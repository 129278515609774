import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { addNewCity } from "./LocalitiesServices";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const AddCityModal = (props) => {
  const [savingPartner, setSavingPartner] = useState(false);
  const [cityDetails, setcityDetails] = useState({
    region: "",
    name: "",
  });
  const [regions, setRegions] = useState([]);
  const { toastDispatch } = useToastContext();
  const { error: errorRegions, sendHttpRequest: getRegions } = useHttp();

  const AddNewCity = async () => {
    setSavingPartner(true);
    let data = {
      name: cityDetails.name,
      region: cityDetails.region,
    };
    try {
      const { msg, success } = await addNewCity(data);
      if (success) {
        props.refresh();
        props.close();
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setSavingPartner(false);
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setSavingPartner(false);
      }
    } catch (err) {
      console.log(err);
      setSavingPartner(false);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setcityDetails({ ...cityDetails, [name]: value });
  };

  useEffect(() => {
    getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
  }, []);
  return (
    <Modal
      close={props.close}
      onAccept={() => AddNewCity()}
      title="Add new city"
      form="cityForm"
      loading={savingPartner}
    >
      <form onSubmit={(e) => e.preventDefault()} id="cityForm">
        <div className="form-group">
          <label>Region Name</label>
          <select
            placeholder="select a region"
            className={"form-control form-control-sm"}
            name="region"
            value={cityDetails?.region}
            onChange={(e) => {
              onChange(e);
            }}
          >
            <option value="">Select Region</option>
            {regions.map((region) => (
              <option value={region?._id} key={region?._id}>
                {region?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>City Name</label>
          <input
            name="name"
            type="text"
            placeholder=""
            onChange={onChange}
            className="form-control"
          />
        </div>
      </form>
    </Modal>
  );
};

export default AddCityModal;
