import React, { useEffect, useContext,useState } from "react";
import { MainNavbar, Sidebar, PageHeader, Footer } from "../components";
import { Route, Routes } from "react-router-dom";
import { InventoryRoutes } from "../app/modules/inventory/InventoryRoutes";
import ModalRoot from "components/modal/ModalRoot";
import { AppContext } from "context/AppContext";
import { useHttp } from "hooks";
 import '../assets/js/app.js'
const Dashboard = () => {
  const { setStatus } = useContext(AppContext);
  const {
    error: errorAuth,
    loading: loadingAuth,
    sendHttpRequest: getStatus,
  } = useHttp();

  const [isOpen, setIsOpen] = useState(true);
 

  useEffect(() =>  getStatus("get_status", "GET", null, ({ data: { status } }) => {
      setStatus(status);
    }), []);

    const handleTrigger = () => { console.log(!isOpen);setIsOpen(!isOpen)};
  return (
    <div>
      <MainNavbar onToggleClick={handleTrigger}/>
      <ModalRoot />
      {/* Page content   */}
      <div className="page-content">
        <Sidebar />
        {/* Main content  */}
        <div className="content-wrapper">
          <PageHeader data={'RTS'}/>
          {/* Content area */}
          <div className="content">
            <Routes>
              {InventoryRoutes.map((item, i) => (
                <Route
                  key={i}
                  exact
                  path={item.path}
                  element={
                    <React.Suspense fallback={"Loading...."}>
                      {item.element}
                    </React.Suspense>
                  }
                />
              ))}
            </Routes>
          </div>
          {/* /content area */}
          <Footer />
        </div>
        {/* /main content   */}
      </div>
      {/* /page content   */}
    </div>
  );
};

export default Dashboard;
