import { useQuery } from "@tanstack/react-query";
import { getTransfers } from "../StockTransferService";

export function useTransfers() {
  const {
    data,
    isLoading: gettingTransfers,
    error,
  } = useQuery({
    queryKey: ["transfers"],
    queryFn: getTransfers,
  });

  return { data, gettingTransfers, error };
}
