import React, { useState, useEffect } from "react";
import ModalService from "components/modal/ModalService";
import AddStoresModal from "./AddStoresModal";
import { format } from "date-fns";
import { Loader, Table } from "components";
import { useHttp } from "hooks";

const Stores = () => {
  const [coupons, setStoress] = useState([]);
  const { error, loading, sendHttpRequest: getStoress } = useHttp();
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setRowCount(coupons?.length);
    setProductsRowCount(coupons?.length);
  }, [coupons]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };
  const getAllStoress = () => {
    getStoress("get_stores", "GET", null, ({ data: { stores } }) =>
      setStoress(stores)
    );
  };

  const addCourierModal = () => {
    ModalService.open(AddStoresModal, {
      refresh: () => getAllStoress(),
    });
  };

  useEffect(() => {
    getAllStoress();
  }, []);
  const columns = [
   
    {
      label: "NAME",
      name: "name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ fontWeight: "bolder" }}> {value ? value : "-"}</p>;
        },
      },
    },
    {
      label: "Location",
      name: "location",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value : "-";
        },
      },
    },
    {
      label: "Country",
      name: "country",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value?.toUpperCase() : "-";
        },
      },
    },
    {
      label: "Opening Hours",
      name: "openingHours",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <small>{value}</small> : "-";
        },
      },
    },

    {
      label: "STATUS",
      name: "active",
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" ,textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <span className="text-success">Active</span>
          ) : (
            <span className="text-warning">Inactive</span>
          );
        },
      },
    },
    {
      label: "DATE CREATED",
      name: "createdAt",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre",textAlign: "center"  },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? format(new Date(value), "PP") : "-";
        },
      },
    },
    // {
    //   label: "ACTION",
    //   name: "date",
    //   options: {
    //     filter: false,
    //     download: false,
    //     print: false,
    //     sort: false,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div className="list-icons">
    //           <div className="list-icons-item dropdown">
    //             <button
    //               className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
    //               data-toggle="dropdown"
    //             >
    //               <i className="icon-menu7" />
    //             </button>
    //             <div className="dropdown-menu dropdown-menu-right">
    //               <a
    //                 href
    //                 className="dropdown-item"
    //                 onClick={() => viewStores(tableMeta.rowData[0])}
    //               >
    //                 <i className="icon-file-eye" /> View
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              {" "}
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3 className="font-weight-semibold mb-0">
                  {rowProductsCount}
                </h3>
                <span>Total Stores</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button
                className="dt-button buttons-pdf buttons-html5 btn bg-blue legitRipple "
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                onClick={() => addCourierModal()}
              >
                <span>New Store</span>
              </button>
            </div>{" "}
          </div>
          <Table
            title={
              "Stores"
             
            }
            data={coupons}
            columns={columns}
            option={options}
          />
        </>
      )}
    </>
  );
};

export default Stores;
