import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const SetAsUnsoldModal = (props) => {
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: toggleSoldDevice } = useHttp();

  const toggleUnSold = async () => {
    let data = {
      sold: false,
    };

    toggleSoldDevice(`toggle_sold/${props.id}`, "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => toggleUnSold()}
      title="Mark as Returned"
      form="set_as_unsold"
      size="sm"
      loading={loading}
    >
          <p>Do you want to mark <span className="text-danger">{props?.name}</span> as returned?</p>
    </Modal>
  );
};

export default SetAsUnsoldModal;
