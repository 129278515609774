import Listproducts from "./ListProducts";
import ProductDetails from "./ProductDetails";
import AddProduct from "./AddProduct";
import ProductConfiguration from "./ProductConfiguration";
import SingleProductConfiguration from './SingleProductConfig'

const ProductRoutes = [
  {
    path: "/products",
    element: <Listproducts />,
  },
  {
    path: "/product/:id",
    element: <ProductDetails />,
  },
  {
    path: "/add-product",
    element: <AddProduct />,
  },
  {
    path: "/product-configuration",
    element: <ProductConfiguration />,
  },
  {
    path: "/product-configuration/:id",
    element: <SingleProductConfiguration />,
  },
];

export default ProductRoutes;
