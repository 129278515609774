 import Consignment from "./Consignment";
 
const ConsignmentRoutes = [
  {
    path: "/consignment",
    element: <Consignment />,
  },
];

export default ConsignmentRoutes;
