import React from "react";
import { useNavigate, Link } from "react-router-dom";

import Modal from "components/modal/Modal";

const ViewCountyModal = (props) => {
  const navigate = useNavigate();

  const viewCounty = async (id) => {
    await props.close();
    navigate(`/sales/region/${id}`, { state: { feature: props.feature } });
  };
  return (
    <Modal close={props.close} title={`${props.feature.name} County`} size="lg">
      <div className="table-responsive">
        <table className="table text-nowrap">
          <thead>
            <tr className="table-active ">
              <td colSpan={3}>
                <b>
                  Total Dealers{" "}
                  <span className="text-success">(3)</span>
                </b>{" "}
              </td>
              
              <td />
              <td />
              <td className="text-right">
                <button
                  type="button"
                  className="btn btn-outline bg-primary text-primary-800 btn-icon ml-2 legitRipple"
                  onClick={() => viewCounty(props.feature.name)}
                >
                  <i class="icon-arrow-right7"></i>
                </button>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 300 }}>Dealer Name</th>
              <th style={{ width: 200 }}>Street Name</th>
              <th style={{ width: 200 }}>Sales Rep</th>
              <th style={{ width: 200 }}>Contacts</th>
              <th>Sales Made</th>
              <th className="text-center" style={{ width: 20 }}>
                Action
              </th>
            </tr>

            <tr>
              <td>
                <div className="d-flex align-items-center">
                 
                  <div>
                    <a
                      href="#"
                      className="text-default font-weight-semibold letter-icon-title"
                    >
                      I-Salute
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <a
                  href
                  className="text-default font-weight-semibold letter-icon-title"
                >
                  Banda Street
                </a>
              </td>
              <td>
                <a
                  href
                  className="text-default font-weight-semibold letter-icon-title"
                >
                  3
                </a>
              </td>

              <td>
                <div>
                  <a
                    href
                    className="text-default font-weight-semibold letter-icon-title"
                  >
                    0716614921
                  </a>
                </div>
                <div>
                  <a
                    href
                    className="text-muted font-weight-semibold letter-icon-title"
                  >
                    test@emil.com
                  </a>
                </div>
              </td>
              <td>
                <a
                  href
                  className="text-default font-weight-semibold letter-icon-title"
                >
                  34 devices
                </a>
              </td>

              <td className="text-center">
                <div className="list-icons">
                  <div className="list-icons-item dropdown">
                    <a href="#" className="list-icons-item">
                      view
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ViewCountyModal;
