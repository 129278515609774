import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Loader } from "components";
import { AppContext } from "context/AppContext";
import { getQuantity } from "./OrderService";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useHttp } from "hooks";

const NewTransfer = () => {
  const navigate = useNavigate();
  const { showAlert } = useContext(AppContext);
  const [brands, setBrands] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [rows, setRows] = useState([
    {
      id: 1,
      brand: "",
      model: "",
      variant: "",
      units: "-",
      quantity: "",
      price: "",
      discount: "",
      rrp: "",
      models: [],
      variants: [],
    },
  ]);
  const [newRow, setNewRow] = useState({
    brand: "",
    model: "",
    variant: "",
    units: "-",
    quantity: "",
    price: "",
    discount: "",
    rrp: "",
    models: [],
    variants: [],
  });

  const { error, loading, sendHttpRequest: getBrands } = useHttp();
  const {
    error: errorModels,
    loading: loadingModels,
    sendHttpRequest: getModels,
  } = useHttp();
   const {
     error: errorVariants,
     loading: loadingVariants,
     sendHttpRequest: getVariants,
   } = useHttp();

  const calculateDiscount = (discount, index) => {
    const rowsInput = [...rows];
    let rrp = rowsInput[index]["price"];
    let discountAmnt = (Number(rrp) * Number(discount)) / 100;

    rowsInput[index]["rrp"] = Number(rrp) - discountAmnt;
    setRows(rowsInput);
  };

  const onStockTransferChange = async (e, i) => {
    const { name, value } = e.target;
    const rowsInput = [...rows];
    if (name.toLowerCase() === "brand") {
      rowsInput[i][name] = value;

      getModels(
        `/get_device_model/${value}`,
        "GET",
        null,
        ({ data: { deviceModels } }) => {
          rowsInput[i]["models"] = deviceModels;
        }
      );
    }
    if (name.toLowerCase() === "model") {
      rowsInput[i][name] = value;

      getVariants(
        `/get_variant_model/${value}`,
        "GET",
        null,
        ({ data: { variant } }) => {
          rowsInput[i]["variants"] = variant;
        }
      );
    }
    if (name.toLowerCase() === "variant") {
      rowsInput[i][name] = value.split("&&")[0];
      const { success, quantity } = await getQuantity(value.split("&&")[0]);

      rowsInput[i]["price"] = value.substring(value.indexOf("&&") + 2);
      if (success) {
        rowsInput[i]["units"] = quantity;
        setRows(rowsInput);
      } else {
        rowsInput[i]["units"] = 0;
      }
    }
    if (name.toLowerCase() === "discount") {
      calculateDiscount(value, i);
    }

    rowsInput[i][name] = value;
    setRows(rowsInput);
  };
  const deleteRow = (index) => {
    const tempRows = [...rows];
    tempRows.splice(index, 1);
    setRows(tempRows);
  };

  const addNewItem = () => {
    setRows([...rows, { id: rows.length + 1, ...newRow }]);
    setNewRow({
      brand: "",
      model: "",
      variant: "",
      units: "-",
      quantity: "",
      price: "",
      discount: "",
      rrp: "",
      models: [],
      variants: [],
    });
  };
  useEffect(() => {
    getBrands(
      "get_device/6165c8453c6ca93814dc21aa",
      "GET",
      null,
      ({ data: { brands } }) => {
        setBrands(brands);
      }
    );
  }, []);
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="card">
            <div className="card-header header-elements-sm-inline">
              <div>
                {" "}
                <button
                  className="btn btn-sm bg-transparent ml-auto "
                  onClick={() => navigate(-1)}
                >
                  <i className="icon-circle-left2 text-teal-400 mr-2" /> Back
                </button>
              </div>
              <h4 className="card-title">Create new pre-order</h4>
              <div className="header-elements">
                <div>
                  {/* <a
                href="#"
                class="badge badge-flat text-teal-400 border-teal-400 legitRipple badge-icon px-3"
              >
                <i class="icon-envelop3 mr-1"></i> Email
              </a> */}
                </div>
              </div>
            </div>

            <div className="card-body">
              <form className="mt-3">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <div class="form-group">
                          <label>Select Source Warehouse</label>
                          <select
                            className="form-control form-control-sm"
                            onChange={onStockTransferChange}
                            name="source"
                          >
                            <option value="" selected>
                              Select Warehouse
                            </option>
                            <option value={1}>Badili</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="form-group">
                          <label>Select Destination Warehouse</label>
                          <select
                            className="form-control form-control-sm"
                            onChange={onStockTransferChange}
                            name="destination"
                          >
                            {/* <option value="" selected>
                              Select Warehouse
                            </option>
                            {[].map((brand) => (
                              <option value={brand._id} key={brand._id}>
                                {brand.brand}
                              </option>
                            </option> */}

                            <option value={1}>Warehouse A</option>
                            <option value={2}>Warehouse B</option>
                            <option value={3}>Warehouse C</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div class="form-group">
                          <label>Notes</label>
                          <textarea
                            className="form-control form-control-sm"
                            onChange={onStockTransferChange}
                            name="notes"
                            rows="2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex justify-content-start flex-column align-items-end">
                    <div className=" font-weight-bold mb-2">
                      Created Date:{" "}
                      <span className="font-weight-normal ml-2 ">
                        22/05/2022
                      </span>
                    </div>

                    <div className=" font-weight-bold mb-2">
                      Status:{" "}
                      <span className="badge badge-light font-weight-normal ml-2">
                        Draft
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="card">
            <div className="card-header header-elements-sm-inline">
              <button
                onClick={() => addNewItem()}
                class="badge badge-flat border-success text-success-600 badge-icon text-uppercase"
              >
                <i class="icon-add mr-1"></i> New Item
              </button>

              <div className="header-elements">
                <div>
                  <button className="btn btn-sm btn-success float-right">
                    Create
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger float-right mr-2"
                    onClick={() => navigate("/inventory/stock-transfers")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr className="table-active ">
                    <td colSpan={4}>Total Items</td>
                    <td />
                    <td />
                    <td />
                    <td />

                    <td className="text-right">
                      <span className="badge bg-blue badge-pill">
                        {rows.length}
                      </span>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Device Brand</th>
                    <th>Device Model</th>
                    <th>Device Variant</th>
                    <th>Scheduled date</th>
                    <th>Qty</th>
                    <th>RRP</th>
                    <th>Discount(%)</th>
                    <th>Wholesale Price</th>

                    <th className="text-center" style={{ width: 20 }}>
                      <i className="icon-arrow-down12" />
                    </th>
                  </tr>

                  {rows.map((row, i) => (
                    <tr>
                      <td>
                        {" "}
                        <div class="form-group">
                          <select
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="brand"
                            value={row.brand}
                          >
                            <option value="" selected>
                              Select Brand
                            </option>
                            {brands.map((brand) => (
                              <option value={brand._id} key={brand._id}>
                                {brand.brand}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div class="form-group">
                          <select
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="model"
                            value={row.model}
                            disabled={row.models.length === 0}
                          >
                            <option value="" selected>
                              Select Model
                            </option>
                            {row.models.map(({ details }) => (
                              <option value={details._id} key={details._id}>
                                {details.modelName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div class="form-group">
                          <select
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="variant"
                            value={row.variant}
                            disabled={row.variants.length === 0}
                          >
                            <option value="" selected>
                              Select Variant
                            </option>
                            {row.variants?.map((variant) => (
                              <option
                                value={`${variant?._id}&&${variant?.saleprice}`}
                                key={variant?._id}
                              >
                                {variant?.storage}{variant?.storageType}/{variant?.ram}GB -{" "}
                                {variant?.color}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td className="">
                        <div class="form-group">
                          {" "}
                          <div class="form-group">
                            <input
                              type="date"
                              className="form-control daterange-single"
                              defaultValue="03/18/2013"
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        {" "}
                        <div class="form-group">
                          <input
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="quantity"
                            type="text"
                            placeholder="quantity"
                            value={row.quantity}
                          />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="price"
                            type="text"
                            disabled
                            placeholder="rrp"
                            value={thousandCommaSeparator(row.price)}
                          />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="discount"
                            type="text"
                            disabled={!row.price}
                            placeholder="discount"
                            value={row.discount}
                          />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input
                            className="form-control form-control-sm"
                            onChange={(e) => onStockTransferChange(e, i)}
                            name="rrp"
                            type="text"
                            disabled
                            placeholder="wholesale price"
                            value={thousandCommaSeparator(row.rrp)}
                          />
                        </div>
                      </td>

                      <td className="text-center">
                        <div className="form-group">
                          <button
                            onClick={() => deleteRow(i)}
                            className="border-0 bg-transparent border-outline-0 "
                          >
                            <i className="icon-trash-alt text-danger" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewTransfer;
