import { useQuery } from "@tanstack/react-query";
import { getLeads } from "../LeadService";

export function useLeads() {
  const {
    data: leads,
    isLoading: gettingLeads,
    error,
  } = useQuery({
    queryKey: ["leads"],
    queryFn: () => getLeads(),
  });

  return { leads, gettingLeads, error };
}
