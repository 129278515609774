import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const SellInModel = (props) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [contactName, setCustomerName] = useState("");
  const [contactPhone, setCustomerPhone] = useState("");
  const { toastDispatch } = useToastContext();
  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: assignWarehouse,
  } = useHttp();

  const assign = async (e) => {
    e.preventDefault();
    setLoading(true);

    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      userId: loggedInUser?.id,
      address: address,
      contactName: contactName,
      contactPhone: contactPhone,
    };

    assignWarehouse(`update_warehouse/${props?.id}`, "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      setLoading(false);
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={assign}
      title="Edit Warehouse"
      form="sellInModelWare"
      size="sm"
      loading={loading}
      AcceptText="Update"
      onReject={props.close}
      footerOntop={true}
    >
      <form id="sellInModelWare">
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            className="form-control"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required={true}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactName">Contact Name</label>
          <input
            type="text"
            className="form-control"
            id="contactName"
            value={contactName}
            onChange={(e) => setCustomerName(e.target.value)}
            required={true}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactPhone">Contact Phone</label>
          <input
            type="tel"
            className="form-control"
            id="contactPhone"
            value={contactPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            required={true}
          />
        </div>
      </form>
    </Modal>
  );
};

export default SellInModel;
