import React from 'react';

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <div className="navbar navbar-expand-lg navbar-light">
        <div className="text-center d-lg-none w-100">
          <button
            type="button"
            className="navbar-toggler dropdown-toggle"
            data-toggle="collapse"
            data-target="#navbar-footer"
          >
            <i className="icon-unfold mr-2" />
            Footer
          </button>
        </div>
        <div className="navbar-collapse collapse" id="navbar-footer">
          <span className="navbar-text">
            © {new Date().getFullYear()}. <a href="http://badili.africa" target="_blank">Badili Africa</a> 
            
          </span>
          {/* <ul className="navbar-nav ml-lg-auto">
            <li className="nav-item">
              <a
                href="https://kopyov.ticksy.com/"
                className="navbar-nav-link"
                target="_blank"
              >
                <i className="icon-lifebuoy mr-2" /> Support
              </a>
            </li>
            <li className="nav-item">
              <a
                href="http://demo.interface.club/limitless/docs/"
                className="navbar-nav-link"
                target="_blank"
              >
                <i className="icon-file-text2 mr-2" /> Docs
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://themeforest.net/item/limitless-responsive-web-application-kit/13080328?ref=kopyov"
                className="navbar-nav-link font-weight-semibold"
              >
                <span className="text-pink-400">
                  <i className="icon-cart2 mr-2" /> Purchase
                </span>
              </a>
            </li>
          </ul> */}
        </div>
      </div>
      {/* /footer */}
    </>
  );
};

export default Footer;
