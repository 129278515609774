import ListDevices from "./ListDevices";

const ProductRoutes = [
  {
    path: "/registered-devices",
    element: <ListDevices />,
  },
  // {
  //   path: "/product/:id",
  //   element: <ProductDetails />,
  // },
  // {
  //   path: "/add-product",
  //   element: <AddProductDetails />,
  // },
];

export default ProductRoutes;
