import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const MediumOfSaleModal = (props) => {
  // const [loading, setLoading] = useState(false);
  const { toastDispatch } = useToastContext();
  const [medium, setMedium] = useState("");
  const [assignedDealer, setAssignedDealer] = useState("");
  const { error, loading, sendHttpRequest: toggleSoldDevice } = useHttp();
  const [dealers, setDealers] = useState([]);
  const {
    error: DealersError,
    loading: DealersLoading,
    sendHttpRequest: getDealers,
  } = useHttp();

  const toggleSold = async () => {
    let data = {
      sold: true,
      via: medium,
      dealer: medium === "dealerTransfer" ? true : false,
      dealerId: medium === "dealerTransfer" ? assignedDealer : null,
    };
  
    toggleSoldDevice(`toggle_sold/${props.id}`, "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };
  const getAllDealers = () => {
    getDealers("get_dealers", "GET", null, async ({ data: { dealers } }) => {
      setDealers(dealers);
    });
  };

  useEffect(() => {
    getAllDealers();
  }, []);

  return (
    <Modal
      close={props.close}
      onAccept={() => toggleSold()}
      title="Mark as Sold"
      form="mark_as_sold"
      size="sm"
      loading={loading}
    >
      <form onSubmit={(e) => e.preventDefault()} id="mark_as_sold">
        <div className="form-group">
          <label>Select medium of sale</label>
          <select
            className="form-control form-control-sm"
            onChange={(e) => setMedium(e.target.value)}
            name="via"
            defaultValue=""
          >
            <option value="" selected>
              Select an option
            </option>
            <option value="dealerTransfer">Via Dealer Transfer</option>
            <option value="BadiliStore">Via Badili Store</option>
            <option value="EcomerceStore">Via Ecomerce Store</option>
            <option value="teleSales">Via TeleSales</option>
            <option value="onDisplay">On Display</option>
            <option value="reserved">Reserved</option>
            <option value="importReturn">Import Return</option>
            <option value="Hold">Hold</option>
          </select>
        </div>
        {medium === "dealerTransfer" ? (
          <>
            <div class="form-group">
              <select
                className="form-control form-control-sm"
                onChange={(e) => setAssignedDealer(e.target.value)}
                name="dealer"
                value={assignedDealer}
              >
                <option value="" selected>
                  Select a Dealer
                </option>
                {dealers.map((dealer) => (
                  <option value={dealer._id} key={dealer._id}>
                    {dealer.name}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
    </Modal>
  );
};

export default MediumOfSaleModal;
