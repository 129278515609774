import React, { useState, useEffect } from "react";

import { useParams } from "react-router";
import { Loader } from "components";

import { format } from "date-fns";
import Drawer from "@mui/material/Drawer";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const CouponDetails = () => {
  const [orderDetails, setOrderDetails] = useState();

  const [fulfillDrawer, setFulfillDrawer] = useState(false);
  // const [loadingCouriers, setLoadingCouriers] = useState(false);
  const [loadingSkus, setLoadingSkus] = useState(false);
  const [couponDetails, setCouponDetails] = useState();
  const [dispatchDetails, setDispatchDetails] = useState({
    courier: "",
    comments: "",
    notify: false,
    cart: "",
    dropoff_location: "",
    order_number: "",
    free_delivery: "true",
  });
  const [isDispatching, setIsDispatching] = useState(false);
  const [availableSkus, setAvailableSkus] = React.useState([]);
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getCouponDetails } = useHttp();

  const onChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { name } = e.target;
    setDispatchDetails({ ...dispatchDetails, [name]: value });
  };

  const { id } = useParams();

  const getDetails = () => {
    getCouponDetails(
      `get_orders_per_coupon/${id}`,
      "GET",
      null,
      ({ data }) => setCouponDetails(data)
    );
  };

  useEffect(() => {
    getDetails();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFulfillDrawer(open);
  };

  const str2bool = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }

    return value;
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && couponDetails && (
        <div>
          <div>
            <div className="card">
              <div className="card-header bg-light">
                <h5 className="card-title font-weight-semibold">
                  Coupon <i className="text-primary">{couponDetails?.name}</i>{" "}
                  Details
                </h5>
              </div>
              <div className="card-body row">
                <ul className="col-md-6">
                  <li className="list-group-item">
                    <span className="font-weight-semibold">Coupon Name:</span>
                    <div className="ml-2">{couponDetails?.coupon?.name}</div>
                  </li>
                  <li className="list-group-item">
                    <span className="font-weight-semibold">Created Date:</span>
                    <div className="ml-2">
                      {format(
                        new Date(couponDetails?.coupon?.start_date),
                        "PP"
                      )}
                    </div>
                  </li>
                  <li className="list-group-item">
                    <span className="font-weight-semibold">Expiry Date:</span>
                    <div className="ml-2">
                      {format(new Date(couponDetails?.coupon?.end_date), "PP")}
                    </div>
                  </li>
                  <li className="list-group-item">
                    <span className="font-weight-semibold">Description:</span>
                    <div className="ml-2">
                      {couponDetails?.coupon?.description}
                    </div>
                  </li>
                </ul>

                <ul className="col-md-6">
                  <li className="list-group-item">
                    <span className="font-weight-semibold">Coupon Value:</span>
                    <div className="ml-2">
                      {couponDetails?.coupon?.currency +
                        " " +
                        couponDetails?.coupon?.value?.toLocaleString()}
                    </div>
                  </li>
                  <li className="list-group-item">
                    <span className="font-weight-semibold">
                      Assigned Models:
                    </span>
                    <div className="ml-2 text-capitalize">
                      {couponDetails?.modelsAllowed?.map(({ modelName }, i) => [
                        i > 0 && ", ",
                        modelName,
                      ])}
                    </div>
                  </li>

                  <li className="list-group-item">
                    <span className="font-weight-semibold">Coupon Status:</span>
                    <div className="ml-2">
                      {couponDetails?.coupon?.active ? (
                        <span className="text-success">Active</span>
                      ) : (
                        <span className="text-warning">Inactive</span>
                      )}
                    </div>
                  </li>
                  <li className="list-group-item">
                    <span className="font-weight-semibold">Used Times:</span>
                    <div className="ml-2 text-primary">
                      {couponDetails?.carts?.length}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card border-x-1 border-left-blue border-right-blue rounded-0">
            <div className="card-header bg-light">
              <h5 className="card-title font-weight-semibold">Orders</h5>
            </div>
            <div className="nav-tabs-responsive bg-light border-top">
              <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
                <li className="nav-item">
                  <a
                    href="#billing"
                    className="nav-link active"
                    data-toggle="tab"
                  >
                    <i className="icon-list mr-2" /> Orders made using this
                    coupon
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="billing">
                <div className="card-body">
                  <div className="d-flex justify-content-center align-items-center">
                    {couponDetails?.carts &&
                      couponDetails?.carts?.length === 0 &&
                      "No orders have been made"}
                    {couponDetails?.carts?.length !== 0 && (
                      <table className="table table-orders-history">
                        <thead>
                          <tr>
                            <th className="font-weight-bold">Order ID</th>
                            <th className="font-weight-bold">
                              Model(s) Purchased
                            </th>
                            <th className="font-weight-bold">Payment Method</th>
                            <th className="font-weight-bold">Payment Status</th>
                            <th className="font-weight-bold">Client Name </th>
                            <th className="font-weight-bold">Client Phone </th>
                            <th className="font-weight-bold">Amount Paid</th>
                          </tr>
                        </thead>
                        <tbody>
                          {couponDetails?.carts?.map(
                            ({
                              coupon: {
                                _id,
                                orderNumber,
                                billTo,
                                cummulativeTotal,
                                paymentMethod,
                                payment,
                              },
                              names: [{ quickDetails }],
                            }) => (
                              <tr key={_id}>
                                <td>{orderNumber}</td>
                                <td>
                                  {`${quickDetails?.name} (${
                                    quickDetails?.ram
                                  }GB/${
                                    quickDetails?.storage +
                                    quickDetails?.storageType
                                  })`}
                                </td>
                                <td>{paymentMethod}</td>
                                <td>{payment}</td>
                                <td>{billTo?.clientName}</td>
                                <td>{billTo?.phone}</td>
                                <td>
                                  {couponDetails?.coupon?.currency +
                                    " " +
                                    cummulativeTotal?.toLocaleString()}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CouponDetails;
