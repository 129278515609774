import MainView from "./landing/MainView";

const GeneralRoutes = [
  {
    path: "/",
    element: <MainView />,
  },
];

export default GeneralRoutes;
