import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import { Form } from "antd";

const CountryTransferConsignmentModel = (props) => {
  const { toastDispatch } = useToastContext();
  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: ReturnToWip,
  } = useHttp();
  const [country, setCountry] = useState("");
  const handleCountryChange = (value) => {
    setCountry(value);
  };
  const assign = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      devices: props?.selectedRepairRequest,
      userId: loggedInUser?.id,

      country,
    };
    ReturnToWip(`transfer_to_country`, "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title="Bulk Country Transfer"
      form="ReturnToWIPModels"
      size="sm"
      loading={assigningWarehouse}
    >
      <br />
      <form onSubmit={(e) => e.preventDefault()} id="ReturnToWIPModels">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Item
            name="country"
            label="Select a Country"
            rules={[
              {
                required: true,
                message: "Please select a Country",
              },
            ]}
          >
            <select
              placeholder="Select a Country"
              onChange={(e) => handleCountryChange(e.target.value)}
              className="form-control form-control-sm"
              required={true}
            >
              <option value="" disabled selected>
                Select a Country
              </option>
              <option value="ke">Kenya</option>
              <option value="ug">Uganda</option>
              <option value="tz">Tanzania</option>
            </select>
          </Form.Item>
        </div>
      </form>
    </Modal>
  );
};

export default CountryTransferConsignmentModel;
