import ListCity from "./ListCity";

const RegionRoutes = [
  {
    path: "/city",
    element: <ListCity />,
  },
];

export default RegionRoutes;
