import ListRegions from "./ListRegions";
import CountyDetails from "./CountyDetails";

const RegionRoutes = [
  {
    path: "/regions",
    element: <ListRegions />,
  },
  {
    path: "/region/:id",
    element: <CountyDetails />,
  },
];

export default RegionRoutes;
