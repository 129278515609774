export const addDropoff = async (data) => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/new_dealer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
    },
    body: JSON.stringify(data),
  });
  return await res.json();
};


export const getDropoff = async () => {
  let res = await fetch(process.env.REACT_APP_BASE_URL + "/get_regions", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      key: process.env.REACT_APP_API_KEY,
      secret: process.env.REACT_APP_API_SECRET,
      country: JSON.parse(sessionStorage.getItem("user"))?.country,
    },
  });
  return await res.json();
};

