import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";

const ReturnToOutboundModel = (props) => {
  const { toastDispatch } = useToastContext();
  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: ReturnToWip,
  } = useHttp();

  const assign = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    let data = {
      devices: props?.selectedRepairRequest,
      userId: loggedInUser?.id,
    };
    ReturnToWip(`push_to_outbound_from_returns`, "PUT", data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
      props.refresh();
      props.close();
    });
  };

  return (
    <Modal
      close={props.close}
      onAccept={() => assign()}
      title="Return to OUTBOUND"
      form="ReturnToOUTBOUNDModels"
      size="sm"
      loading={assigningWarehouse}
    >
      <strong>Do you want to return to OUTBOUND?</strong> <br />
      <br />
      <form
        onSubmit={(e) => e.preventDefault()}
        id="ReturnToOUTBOUNDModels"
      ></form>
    </Modal>
  );
};

export default ReturnToOutboundModel;