import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToastContext, ADD } from "context/ToastContext";
import { Loader } from "components";
import { useQueryClient } from "@tanstack/react-query";
import {
  getDealerTransfers,
  newTransfer,
  getSingleDealerTransfer,
  initiateStockTransfer,
} from "./StockTransferService";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import SingleRow from "./SingleRow";
import { useHttp } from "hooks";

const NewTransfer = () => {
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const { toastDispatch } = useToastContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [loadingReturn, setLoadingReturn] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [Warehouse, setWarehouses] = useState([]);
  const [returnDevices, setReturnDevices] = useState(null);
  const [newTransactionType, setNewTransaction] = useState("");
  const [newWarehouse, setNewWarehouse] = useState("");
  const { werror, wloading, sendHttpRequest: getWarehouses } = useHttp();
  const [shippingDetails, setShippingDetails] = useState({
    source: "",
    destination: "",
    notes: "",
    contactName: "",
    stockType: "",
    warehouse: "",
    transactionType: "",
    contactPhone: "",
    paymentPeriod: "",
    transfer: "",
    returnItems: [],
  });
  const [transferDetails, setTransferDetails] = useState({
    items: [
      {
        id: 1,
        brand: "",
        modelId: "",
        variantId: "",
        units: "",
        quantity: "",
        subtotal: "",
        colorId: "",
        rrp: "",
        wsp: "",
        models: [],
        variants: [],
      },
    ],
  });
  const {
    error: errorBrands,
    loading: loadingBrands,
    sendHttpRequest: getBrands,
  } = useHttp();
  const {
    error: errorStores,
    loading: loadingStores,
    sendHttpRequest: getStores,
  } = useHttp();
  const country = JSON.parse(
    sessionStorage.getItem("user")
  )?.country?.toLowerCase();
  const addNewItem = (e) => {
    e.preventDefault();
    setTransferDetails({
      ...transferDetails,
      items: [
        ...transferDetails.items,
        {
          id: transferDetails.items.length + 1,
          ...{
            brand: "",
            modelId: "",
            variantId: "",
            units: "",
            quantity: "",

            // discount: "",
            colorId: "",
            rrp: "",
            wsp: "",
            subtotal: "",
            models: [],
            variants: [],
          },
        },
      ],
    });
  };
  const getAllBrands = async () => {
    setLoading(true);
    try {
      getStores("get_stores", "GET", null, ({ data: { stores } }) =>
        setStores(stores)
      );
      getBrands(
        "get_device/6165c8453c6ca93814dc21aa",
        "GET",
        null,
        ({ data: { brands } }) => {
          setBrands(brands);
        }
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const initiateTransfer = async (values, resetForm, setSubmitting) => {
    setLoadingReturn(true);
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));

    try {
      let data = {
        userId: loggedInUser?.id,
        transferId: shippingDetails?.transfer,
        notes: values?.notes,
        returns: values?.returnItems,
      };

      const { success, msg } = await initiateStockTransfer(data);

      if (success) {
        queryClient.invalidateQueries({
          queryKey: ["transfers"],
        });
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setLoadingTransfer(false);
        navigate("/inventory/stock-transfers");
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setLoadingReturn(false);
      }
    } catch (err) {
      setLoadingReturn(false);
    }
  };

  const onShippingDetailsChange = (e) => {
    const { name, value } = e.target;
    if (name === "transactionType") {
      setNewTransaction(value);
    }
    if (name === "warehouse") {
      setNewWarehouse(value);
      setTransferDetails({
        items: [
          {
            id: 1,
            brand: "",
            modelId: "",
            variantId: "",
            units: "",
            quantity: "",
            subtotal: "",
            colorId: "",
            rrp: "",
            wsp: "",
            models: [],
            variants: [],
          },
        ],
      });
    }
    setShippingDetails({ ...shippingDetails, [name]: value });
  };

  useEffect(() => {
    if (state?.dealers) {
      getAllBrands();
      getWarehouses(
        "get_all_warehouses",
        "GET",
        null,
        ({ data: { warehouse } }) => setWarehouses(warehouse)
      );
    } else {
      navigate("/inventory/stock-transfers");
    }
  }, []);

  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + item.wsp * item.quantity, 0);
  };
  const addNewTransfer = async (values, resetForm, setSubmitting) => {
    setLoadingTransfer(true);
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    try {
      let data = {
        userId: loggedInUser.id,
        source: values?.source,
        destination: values?.destination,
        notes: values?.notes,
        contactName: values?.contactName,
        stockType: values?.stockType,
        warehouse: values?.warehouse,
        transactionType: values?.transactionType,
        contactPhone: values?.contactPhone,
        paymentPeriod: values?.paymentPeriod,
        stock: values?.items?.map(({ id, models, variants, ...rest }) => ({
          ...rest,
          warehouse: values?.warehouse,
        })),
      };

      const { success, msg } = await newTransfer(data);
      if (success) {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        setLoadingTransfer(false);
        navigate("/inventory/stock-transfers");
      } else {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "danger",
          },
        });
        setLoadingTransfer(false);
      }
    } catch (err) {
      setLoadingTransfer(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && !isReturn && (
        <Formik
          enableReinitialize
          initialValues={{ ...shippingDetails, ...transferDetails }}
          validationSchema={Yup.object().shape({
            source: Yup.string().required("Source/Dispatch is required"),
            destination: Yup.string().required(
              "Destination/Client is required"
            ),
            stockType: Yup.string().required("Stock Type is required"),
            warehouse: Yup.string().required("Warehouse is required"),
            transactionType: Yup.string().required(
              "Transaction Type is required"
            ),
            contactName: Yup.string().required("Contact Name is required"),
            contactPhone: Yup.number("Must be a number")
              .positive()
              .required("Contact Phone is required"),
            paymentPeriod: Yup.string().required("Payment Term is required"),
            items: Yup.array().of(
              Yup.object({
                brand: Yup.string().required("Brand is required"),
                modelId: Yup.string().required("Model is required"),
                variantId: Yup.string().required("Variant is required"),
                colorId: Yup.string().required("Color is required"),
                // wsp: Yup.number("Price must be a number").required(
                //   "Price is required"
                // ),

                units: Yup.number("Must be a number"),
                quantity: Yup.number("Quantity must be a number")
                  .positive("Quantity cannot be a negative number or zero")
                  .required("Quantity is required.")
                  .max(
                    Yup.ref("units"),
                    `Quantity can't be greater than available units.`
                  ),
              })
            ),
          })}
          onSubmit={(values) => addNewTransfer(values)}
        >
          <Form>
            <FieldArray name="items" validateOnChange>
              {({ form: { errors, touched } }) => {
                return (
                  <>
                    <div className="card">
                      <div className="card-header header-elements-sm-inline">
                        <div>
                          {" "}
                          <button
                            className="btn btn-sm bg-transparent ml-auto "
                            onClick={() => navigate(-1)}
                          >
                            <i className="icon-circle-left2 text-teal-400 mr-2" />{" "}
                            Back
                          </button>
                        </div>
                        <h4 className="card-title">{"New Sales Order"}</h4>
                        <div className="header-elements">
                          <div>
                            {/* <a
                              href="#"
                              class="badge badge-flat text-teal-400 border-teal-400 legitRipple badge-icon px-3"
                            >
                              <i class="icon-envelop3 mr-1"></i> Email
                            </a> */}
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="mt-3">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="row">
                                {!isReturn && (
                                  <div className="col-md-4">
                                    <div class="form-group">
                                      <label className="font-weight-bold">
                                        Select Dispatch
                                      </label>
                                      <select
                                        className={
                                          "form-control form-control-sm" +
                                          (errors.source && touched.source
                                            ? " is-invalid "
                                            : "")
                                        }
                                        onChange={(e) =>
                                          onShippingDetailsChange(e)
                                        }
                                        name="source"
                                      >
                                        <option value="">
                                          Select Dispatch
                                        </option>

                                        {stores.map(({ _id, name }) => (
                                          <option value={_id} key={_id}>
                                            {name}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage
                                        name="source"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                  <button
                                    type="button"
                                    // onClick={() => toggleTransfer()}
                                    className="badge bg-blue-400 border-0"
                                  >
                                    <i class="icon-transmission"></i>
                                  </button>
                                </div>
                                {!isReturn && (
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="font-weight-bold">
                                        Select Client
                                      </label>
                                      <select
                                        className={
                                          "form-control form-control-sm" +
                                          (errors.destination &&
                                          touched.destination
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={(e) =>
                                          onShippingDetailsChange(e)
                                        }
                                        name="destination"
                                      >
                                        <option value="">Select Client</option>
                                        {state?.dealers?.map((el) => (
                                          <option value={el._id} key={el._id}>
                                            {el.name}
                                          </option>
                                        ))}
                                      </select>
                                      <ErrorMessage
                                        name="destination"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className={"col-md-12"}>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div class="form-group">
                                          <label className="font-weight-bold">
                                            Warehouse
                                          </label>
                                          <select
                                            className={
                                              "form-control form-control-sm" +
                                              (errors.warehouse &&
                                              touched.warehouse
                                                ? " is-invalid "
                                                : "")
                                            }
                                            onChange={(e) =>
                                              onShippingDetailsChange(e)
                                            }
                                            name="warehouse"
                                          >
                                            <option value="">
                                              Select Warehouse
                                            </option>
                                            {Warehouse?.map(({ _id, name }) => (
                                              <>
                                                <option value={_id} key={_id}>
                                                  {name}
                                                </option>
                                              </>
                                            ))}
                                          </select>
                                          <ErrorMessage
                                            name="warehouse"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>{" "}
                                      <div className="col-sm-2"></div>
                                      <div className="col-md-4">
                                        <div class="form-group">
                                          <label className="font-weight-bold">
                                            Transaction Type
                                          </label>
                                          <select
                                            className={
                                              "form-control form-control-sm" +
                                              (errors.transactionType &&
                                              touched.transactionType
                                                ? " is-invalid "
                                                : "")
                                            }
                                            onChange={(e) =>
                                              onShippingDetailsChange(e)
                                            }
                                            name="transactionType"
                                            disabled={newTransactionType !== ""}
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            <option value={"b2b"}>B2B</option>
                                            <option value={"b2c"}>B2C</option>
                                          </select>
                                          <ErrorMessage
                                            name="transactionType"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>
                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div class="form-group">
                                          <label className="font-weight-bold">
                                            Stock Type
                                          </label>
                                          <select
                                            className={
                                              "form-control form-control-sm" +
                                              (errors.stockType &&
                                              touched.stockType
                                                ? " is-invalid "
                                                : "")
                                            }
                                            onChange={(e) =>
                                              onShippingDetailsChange(e)
                                            }
                                            name="stockType"
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            <option value={"sales"}>
                                              Sales
                                            </option>
                                          </select>
                                          <ErrorMessage
                                            name="stockType"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </div>{" "}
                                      <div className="col-sm-2"></div>
                                      <div className="col-md-4">
                                        <label className="font-weight-bold">
                                          Payment Term
                                        </label>
                                        <input
                                          className={
                                            "form-control form-control-sm" +
                                            (errors.paymentPeriod &&
                                            touched.paymentPeriod
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={(e) =>
                                            onShippingDetailsChange(e)
                                          }
                                          name="paymentPeriod"
                                          type="text"
                                          placeholder=""
                                          // onChange={onChange}
                                          // value={productDetails.quantity}
                                        />
                                        <ErrorMessage
                                          name="paymentPeriod"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </div>{" "}
                                  </div>

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <label className="font-weight-bold">
                                          Contact Name
                                        </label>
                                        <input
                                          name="contactName"
                                          type="text"
                                          placeholder=""
                                          // onChange={onChange}
                                          // value={productDetails.component}
                                          className={
                                            "form-control form-control-sm" +
                                            (errors.contactName &&
                                            touched.contactName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={(e) =>
                                            onShippingDetailsChange(e)
                                          }
                                        />
                                        <ErrorMessage
                                          name="contactName"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className="col-sm-2"></div>
                                      <div className="col-md-4">
                                        <label className="font-weight-bold">
                                          Contact Phone
                                        </label>
                                        <input
                                          name="contactPhone"
                                          type="text"
                                          placeholder=""
                                          // onChange={onChange}
                                          // value={productDetails.quantity}
                                          className={
                                            "form-control form-control-sm" +
                                            (errors.contactPhone &&
                                            touched.contactPhone
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={(e) =>
                                            onShippingDetailsChange(e)
                                          }
                                        />
                                        <ErrorMessage
                                          name="contactPhone"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <label className="font-weight-bold">
                                      Notes
                                    </label>
                                    <textarea
                                      className="form-control form-control-sm"
                                      onChange={(e) =>
                                        onShippingDetailsChange(e)
                                      }
                                      name="notes"
                                      rows=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-4 d-flex justify-content-start flex-column"
                              style={{ textAlignLast: "center" }}
                            >
                              {/* <div className=" font-weight-bold mb-2">
                                Created Date:{" "}
                                <span className="font-weight-normal ml-2 ">
                                </span>
                              </div> */}
                              <div className=" font-weight-bold mb-2">
                                Agent Name:{" "}
                                <span className="font-weight-normal ml-2">
                                  {JSON.parse(sessionStorage.getItem("user"))
                                    .fname +
                                    " " +
                                    JSON.parse(sessionStorage.getItem("user"))
                                      .lname}
                                </span>
                              </div>
                              {/* <div className=" font-weight-bold mb-2">
                                Status:{" "}
                                <span className="badge badge-light font-weight-normal ml-2">
                                  Draft
                                </span>
                              </div> */}
                              <div className=" font-weight-bold mb-2">
                                Total Value:{" "}
                                <span className="font-weight-normal ml-2">
                                {`${country === "ke" ? 'KES' :
    country === "ug" ? 'UGX' :
    country === "tz" ? 'TZS' : ''} ${calculateTotal(transferDetails.items).toFixed(2)}`}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!isReturn && (
                      <div className="card">
                        <div className="card-header header-elements-sm-inline">
                          <button
                            type="button"
                            onClick={(e) => addNewItem(e)}
                            className="badge badge-flat border-success text-success-600 badge-icon text-uppercase"
                          >
                            <i class="icon-add mr-1"></i> New Item
                          </button>
                          <div className="header-elements"></div>
                        </div>
                        <div className="table-responsive">
                          <table className="table text-nowrap">
                            <thead>
                              <tr className="table-active ">
                                <td colSpan={4}>Total Items</td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td className="text-right">
                                  <span className="badge bg-blue badge-pill">
                                    {transferDetails.items.length}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Device Brand</th>
                                <th>Device Model</th>
                                <th>Device Variant</th>
                                <th>Device Color</th>
                                <th>Available units</th>
                                <th>Qty</th>
                                {/* <th>RRP</th> */}
                                {/* <th>Discount(%)</th> */}
                                <th>Price</th>
                                <th>Subtotal</th>
                                <th
                                  className="text-center"
                                  style={{ width: 20 }}
                                >
                                  <i className="icon-arrow-down12" />
                                </th>
                              </tr>

                              {transferDetails?.items?.map((row, i) => (
                                <SingleRow
                                  key={row?.id}
                                  index={i}
                                  row={row}
                                  brands={brands}
                                  transferDetails={transferDetails}
                                  setTransferDetails={setTransferDetails}
                                  newTransactionType={newTransactionType}
                                  newWarehouse={newWarehouse}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    <div className="card">
                      <div className="card-footer">
                        {" "}
                        <div>
                          <button
                            className="btn btn-sm btn-success float-right"
                            type="submit"
                            disbaled={loadingTransfer}
                          >
                            {"Request Dispatch"}
                            {/* {!isReturn ? "Request Dispatch" : "Initiate Return"} */}
                          </button>
                          <button
                            className="btn btn-sm btn-danger float-right mr-2"
                            onClick={() =>
                              navigate("/inventory/stock-transfers")
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            </FieldArray>
          </Form>
        </Formik>
      )}
      {!loading && isReturn && (
        <Formik
          enableReinitialize
          initialValues={{ ...shippingDetails, ...transferDetails }}
          validationSchema={Yup.object().shape({
            source: Yup.string().required("Source warehouse is required"),
            destination: Yup.string().required(
              "Destination warehouse is required"
            ),
            transfer: Yup.string().required("Transfer is required"),
          })}
          onSubmit={(values) => initiateTransfer(values)}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="card">
                <div className="card-header header-elements-sm-inline">
                  <div>
                    {" "}
                    <button
                      className="btn btn-sm bg-transparent ml-auto "
                      onClick={() => navigate(-1)}
                    >
                      <i className="icon-circle-left2 text-teal-400 mr-2" />{" "}
                      Back
                    </button>
                  </div>
                  <h4 className="card-title">{"New Sales Order"}</h4>
                  <div className="header-elements">
                    <div></div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default NewTransfer;
