import { useQuery } from "@tanstack/react-query";
import { getRegisteredDevices } from "../TotalDevicesService";

export function useRegisteredDevices() {
  const {
    data: devices,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["devices"],
    queryFn: getRegisteredDevices,
  });

  return { devices, isLoading, error };
}
